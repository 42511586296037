import { Button, LinearProgress } from '@material-ui/core';
import { useAuth, usePermission, UserPermissionsEnum } from 'auth';
import * as React from 'react';
import { MainHeader } from './main-header';
import { MiniLayout } from './mini-layout';
import { ErrorDisplay } from '../pages/error-display';
import { ThemeProvider } from '@SBGSports/referee-react';

interface ErrorLayoutProps {
    code: string;
    title: string;
    message?: React.ReactElement;
}

export const ErrorLayout: React.FC<React.PropsWithChildren<ErrorLayoutProps>> = ({
    code,
    title,
    message,
    children,
}) => {
    const auth = useAuth();
    const calledOnce = React.useRef(false);
    const isSharedLinkLogin = usePermission(UserPermissionsEnum.SharedLink);

    React.useEffect(() => {
        if (calledOnce.current) {
            return;
        }
        calledOnce.current = true;
        auth.checkAuthentication();
    });

    const buttonStyle = { margin: '30px 15px' };

    return auth.indeterminate ? (
        <LinearProgress />
    ) : (
        <>
            <MainHeader withNav={!!auth.user} />
            <MiniLayout center logo={false} dark={false}>
                <ThemeProvider theme={'light'}>
                    <ErrorDisplay code={code} title={title} message={message} />
                    {children}
                    <Button
                        style={buttonStyle}
                        href="/"
                        variant="contained"
                        color="primary"
                        disabled={isSharedLinkLogin}
                    >
                        Go to home page
                    </Button>

                    {!!auth.user && (
                        <Button style={buttonStyle} href="/logout">
                            Log out
                        </Button>
                    )}
                </ThemeProvider>
            </MiniLayout>
        </>
    );
};
