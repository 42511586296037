import { useGetActivities, useGetAllTagsOfTagType } from 'api';
import { ActivitiesSortEnum, Activity } from 'oas';
import { filterSideMenuTagsFromTags } from 'reporting/helpers';
import { DAY_CODE_TAG_TYPE, FETCH_ACTIVITIES_PAGE_SIZE, getEndOfDay, getStartOfDay } from 'shared';
import { useSideMenuFilterState } from 'shared/activity-side-menu/use-side-menu-filter-state';
import { useState, useEffect } from 'react';
import { MATCH_DAY, REPORT_TYPE } from 'reporting/constants';
import { useReportTypeStore } from 'shared/state/reporting/reportTypeStore';

const getDateForQuery = (date: Date | undefined, shouldNullDate: boolean) => {
    return shouldNullDate || !date ? undefined : date.getTime() / 1000;
};

export const useFetchTags = () => {
    const { data: tags } = useGetAllTagsOfTagType(DAY_CODE_TAG_TYPE);
    const { textFilter, dateFilters } = useSideMenuFilterState();

    const { reportType } = useReportTypeStore();

    const [updatedFilterSideMenuDisplayTags, setUpdatedFilterSideMenuDisplayTags] = useState(() =>
        filterSideMenuTagsFromTags(tags, ''),
    );

    const activitiesList = useGetActivities(
        FETCH_ACTIVITIES_PAGE_SIZE,
        ActivitiesSortEnum.StartTimeDesc,
        0,
        undefined,
        getDateForQuery(getStartOfDay(dateFilters.from), false),
        getDateForQuery(getEndOfDay(dateFilters.to), false),
        textFilter,
        {
            getNextPageParam: (lastPage: Activity[], pages: Activity[][]) => {
                return lastPage.length >= FETCH_ACTIVITIES_PAGE_SIZE ? pages.length + 1 : undefined;
            },
        },
        undefined,
    );

    useEffect(() => {
        if (tags && activitiesList.data) {
            const filterSideMenuDisplayTags = filterSideMenuTagsFromTags(tags, '');

            const flattenedResults = activitiesList.data.pages.flatMap((page) => page);

            const updatedTags = filterSideMenuDisplayTags.map((tag) => {
                const isDisabled = !flattenedResults.some((activity) =>
                    activity?.tags?.some((activityTag) => activityTag.name === tag.value),
                );
                return { ...tag, isDisabled };
            });

            //ensure MD tag is always enabled if it exists in md report
            if (reportType === REPORT_TYPE.match_day) {
                updatedTags.forEach((tag) => {
                    if (tag.value === MATCH_DAY) {
                        tag.isDisabled = false;
                    }
                });
            }

            setUpdatedFilterSideMenuDisplayTags(updatedTags);
        }
    }, [tags, activitiesList.data, reportType]);

    return updatedFilterSideMenuDisplayTags;
};
