/* tslint:disable */
/* eslint-disable */
/**
 * OpenField API
 * OpenField API documentation
 *
 * The version of the OpenAPI document: v6
 * Contact: dan.prior@catapultsports.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface ActDicStatuses
 */
export interface ActDicStatuses {
    /**
     * 
     * @type {ActDicStatusesBake}
     * @memberof ActDicStatuses
     */
    'bake'?: ActDicStatusesBake;
    /**
     * 
     * @type {ActDicStatusesBallSensor}
     * @memberof ActDicStatuses
     */
    'ballSensor'?: ActDicStatusesBallSensor;
    /**
     * 
     * @type {ActDicStatusesBe}
     * @memberof ActDicStatuses
     */
    'be'?: ActDicStatusesBe;
    /**
     * 
     * @type {ActDicStatusesBe}
     * @memberof ActDicStatuses
     */
    'bs'?: ActDicStatusesBe;
    /**
     * 
     * @type {ActDicStatusesBallSensor}
     * @memberof ActDicStatuses
     */
    'fr'?: ActDicStatusesBallSensor;
    /**
     * 
     * @type {ActDicStatusesHighFreq}
     * @memberof ActDicStatuses
     */
    'highFreq'?: ActDicStatusesHighFreq;
    /**
     * 
     * @type {ActDicStatusesBe}
     * @memberof ActDicStatuses
     */
    'ima'?: ActDicStatusesBe;
    /**
     * 
     * @type {ActDicStatusesBe}
     * @memberof ActDicStatuses
     */
    'oneHz'?: ActDicStatusesBe;
    /**
     * 
     * @type {ActDicStatusesBe}
     * @memberof ActDicStatuses
     */
    'raw'?: ActDicStatusesBe;
    /**
     * 
     * @type {ActDicStatusesBe}
     * @memberof ActDicStatuses
     */
    'sd'?: ActDicStatusesBe;
    /**
     * 
     * @type {ActDicStatusesVideo}
     * @memberof ActDicStatuses
     */
    'video'?: ActDicStatusesVideo;
}
/**
 * 
 * @export
 * @interface ActDicStatusesBake
 */
export interface ActDicStatusesBake {
    /**
     * 
     * @type {ActDicSyncStatus}
     * @memberof ActDicStatusesBake
     */
    'bake'?: ActDicSyncStatus;
}
/**
 * 
 * @export
 * @interface ActDicStatusesBallSensor
 */
export interface ActDicStatusesBallSensor {
    /**
     * 
     * @type {ActDicSyncStatus}
     * @memberof ActDicStatusesBallSensor
     */
    'live'?: ActDicSyncStatus;
}
/**
 * 
 * @export
 * @interface ActDicStatusesBe
 */
export interface ActDicStatusesBe {
    /**
     * 
     * @type {ActDicSyncStatus}
     * @memberof ActDicStatusesBe
     */
    'live'?: ActDicSyncStatus;
    /**
     * 
     * @type {ActDicSyncStatus}
     * @memberof ActDicStatusesBe
     */
    'munged'?: ActDicSyncStatus;
}
/**
 * 
 * @export
 * @interface ActDicStatusesHighFreq
 */
export interface ActDicStatusesHighFreq {
    /**
     * 
     * @type {ActDicSyncStatus}
     * @memberof ActDicStatusesHighFreq
     */
    'munged'?: ActDicSyncStatus;
}
/**
 * 
 * @export
 * @interface ActDicStatusesVideo
 */
export interface ActDicStatusesVideo {
    /**
     * 
     * @type {ActDicSyncStatus}
     * @memberof ActDicStatusesVideo
     */
    'recorded'?: ActDicSyncStatus;
}
/**
 * 
 * @export
 * @interface ActDicSyncStatus
 */
export interface ActDicSyncStatus {
    /**
     * 
     * @type {number}
     * @memberof ActDicSyncStatus
     */
    'synced'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActDicSyncStatus
     */
    'unsynced'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ActivitiesSortEnum = {
    StartTimeAsc: 'start_time',
    NameAsc: 'name',
    DurationAsc: 'duration',
    ModifiedAtAsc: 'modified_at',
    PeriodCountAsc: 'period_count',
    AthleteCountAsc: 'athlete_count',
    OwnerNameAsc: 'owner_name',
    StartTimeDesc: '-start_time',
    NameDesc: '-name',
    DurationDesc: '-duration',
    ModifiedAtDesc: '-modified_at',
    PeriodCountDesc: '-period_count',
    AthleteCountDesc: '-athlete_count',
    OwnerNameDesc: '-owner_name'
} as const;

export type ActivitiesSortEnum = typeof ActivitiesSortEnum[keyof typeof ActivitiesSortEnum];


/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'venue_field_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'period_depth_type_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'sport_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    'start_time': number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    'end_time': number;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'timezone'?: string;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    'is_synced'?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    'is_deleted'?: number;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'modified_at': string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'process_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'bake_status'?: string;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    'data_start'?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    'data_end'?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    'is_injected': number;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    'owner_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    'athlete_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof Activity
     */
    'period_count'?: number;
    /**
     * 
     * @type {User}
     * @memberof Activity
     */
    'owner'?: User;
    /**
     * 
     * @type {Array<string>}
     * @memberof Activity
     */
    'tag_list'?: Array<string>;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof Activity
     */
    'tags'?: Array<TagItem>;
    /**
     * 
     * @type {Team}
     * @memberof Activity
     */
    'home_team'?: Team;
    /**
     * 
     * @type {Array<Team>}
     * @memberof Activity
     */
    'teams'?: Array<Team>;
    /**
     * 
     * @type {ActivityListItemVenue}
     * @memberof Activity
     */
    'venue'?: ActivityListItemVenue;
    /**
     * 
     * @type {Array<Period>}
     * @memberof Activity
     */
    'periods'?: Array<Period>;
    /**
     * 
     * @type {Array<Athlete>}
     * @memberof Activity
     */
    'athletes'?: Array<Athlete>;
    /**
     * 
     * @type {ActDicStatuses}
     * @memberof Activity
     */
    'actdic_status'?: ActDicStatuses;
}
/**
 * 
 * @export
 * @interface ActivityAthlete
 */
export interface ActivityAthlete {
    /**
     * 
     * @type {string}
     * @memberof ActivityAthlete
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAthlete
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAthlete
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAthlete
     */
    'jersey'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAthlete
     */
    'nickname'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityAthlete
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityAthlete
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityAthlete
     */
    'date_of_birth'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityAthlete
     */
    'velocity_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityAthlete
     */
    'acceleration_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityAthlete
     */
    'heart_rate_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityAthlete
     */
    'player_load_max'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityAthlete
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAthlete
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAthlete
     */
    'stroke_colour'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAthlete
     */
    'fill_colour'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAthlete
     */
    'trail_colour_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAthlete
     */
    'trail_colour_end'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityAthlete
     */
    'is_synced'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityAthlete
     */
    'is_deleted'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityAthlete
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAthlete
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAthlete
     */
    'activity_athlete_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityAthlete
     */
    'tag_list'?: Array<string>;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof ActivityAthlete
     */
    'tags'?: Array<TagItem>;
    /**
     * 
     * @type {string}
     * @memberof ActivityAthlete
     */
    'position'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAthlete
     */
    'position_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityAthlete
     */
    'position_name'?: string;
    /**
     * 
     * @type {DeviceInfo}
     * @memberof ActivityAthlete
     */
    'device_info'?: DeviceInfo;
}
/**
 * 
 * @export
 * @interface ActivityDictionary
 */
export interface ActivityDictionary {
    /**
     * 
     * @type {string}
     * @memberof ActivityDictionary
     */
    'data_type': ActivityDictionaryDataTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ActivityDictionary
     */
    'data_source': ActivityDictionaryDataSourceEnum;
    /**
     * 
     * @type {number}
     * @memberof ActivityDictionary
     */
    'device_id': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityDictionary
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ActivityDictionary
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDictionary
     */
    'modified_at': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityDictionary
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ActivityDictionary
     */
    'start_time': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityDictionary
     */
    'end_time': number;
    /**
     * 
     * @type {string}
     * @memberof ActivityDictionary
     */
    'synced_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityDictionary
     */
    'has_data'?: boolean;
}

export const ActivityDictionaryDataTypeEnum = {
    Bake: 'bake',
    BallSensor: 'ballSensor',
    Be: 'be',
    Bs: 'bs',
    Fr: 'fr',
    HighFreq: 'highFreq',
    Ima: 'ima',
    OneHz: 'oneHz',
    Raw: 'raw',
    Sd: 'sd',
    Video: 'video'
} as const;

export type ActivityDictionaryDataTypeEnum = typeof ActivityDictionaryDataTypeEnum[keyof typeof ActivityDictionaryDataTypeEnum];
export const ActivityDictionaryDataSourceEnum = {
    Bake: 'bake',
    Live: 'live',
    Munged: 'munged',
    Recorded: 'recorded'
} as const;

export type ActivityDictionaryDataSourceEnum = typeof ActivityDictionaryDataSourceEnum[keyof typeof ActivityDictionaryDataSourceEnum];

/**
 * 
 * @export
 * @interface ActivityListItem
 */
export interface ActivityListItem {
    /**
     * 
     * @type {string}
     * @memberof ActivityListItem
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityListItem
     */
    'game_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityListItem
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ActivityListItem
     */
    'start_time': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityListItem
     */
    'end_time': number;
    /**
     * 
     * @type {string}
     * @memberof ActivityListItem
     */
    'owner_id': string;
    /**
     * 
     * @type {number}
     * @memberof ActivityListItem
     */
    'athlete_count': number;
    /**
     * 
     * @type {number}
     * @memberof ActivityListItem
     */
    'period_count': number;
    /**
     * 
     * @type {User}
     * @memberof ActivityListItem
     */
    'owner': User;
    /**
     * 
     * @type {string}
     * @memberof ActivityListItem
     */
    'modified_at': string;
    /**
     * Only returned if deleted activities were requested using deleted=1 in query string
     * @type {number}
     * @memberof ActivityListItem
     */
    'is_deleted'?: ActivityListItemIsDeletedEnum;
    /**
     * 
     * @type {ActivityListItemVenue}
     * @memberof ActivityListItem
     */
    'venue'?: ActivityListItemVenue;
    /**
     * 
     * @type {Array<ActivityListItemPeriods>}
     * @memberof ActivityListItem
     */
    'periods': Array<ActivityListItemPeriods>;
    /**
     * 
     * @type {Array<ActivityListItemActivityAthletes>}
     * @memberof ActivityListItem
     */
    'activity_athletes'?: Array<ActivityListItemActivityAthletes>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityListItem
     */
    'tags': Array<string>;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof ActivityListItem
     */
    'tag_list': Array<TagItem>;
    /**
     * 
     * @type {ActDicStatuses}
     * @memberof ActivityListItem
     */
    'actdic_status'?: ActDicStatuses;
}

export const ActivityListItemIsDeletedEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1
} as const;

export type ActivityListItemIsDeletedEnum = typeof ActivityListItemIsDeletedEnum[keyof typeof ActivityListItemIsDeletedEnum];

/**
 * 
 * @export
 * @interface ActivityListItemActivityAthletes
 */
export interface ActivityListItemActivityAthletes {
    /**
     * 
     * @type {string}
     * @memberof ActivityListItemActivityAthletes
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityListItemActivityAthletes
     */
    'athlete_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityListItemActivityAthletes
     */
    'name'?: string;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof ActivityListItemActivityAthletes
     */
    'tags'?: Array<TagItem>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityListItemActivityAthletes
     */
    'tags_list'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ActivityListItemPeriods
 */
export interface ActivityListItemPeriods {
    /**
     * 
     * @type {string}
     * @memberof ActivityListItemPeriods
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityListItemPeriods
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityListItemPeriods
     */
    'start_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityListItemPeriods
     */
    'end_time'?: number;
}
/**
 * 
 * @export
 * @interface ActivityListItemVenue
 */
export interface ActivityListItemVenue {
    /**
     * 
     * @type {string}
     * @memberof ActivityListItemVenue
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityListItemVenue
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityListItemVenue
     */
    'length'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityListItemVenue
     */
    'rotation'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityListItemVenue
     */
    'lat'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityListItemVenue
     */
    'lng'?: number;
}
/**
 * 
 * @export
 * @interface ActivityPeriod
 */
export interface ActivityPeriod {
    /**
     * 
     * @type {string}
     * @memberof ActivityPeriod
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityPeriod
     */
    'activity_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityPeriod
     */
    'period_depth_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityPeriod
     */
    'name'?: string;
    /**
     * Epoch Timestamp, seconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof ActivityPeriod
     */
    'start_time'?: number;
    /**
     * Centiseconds part of epoch timestamp (hundredths of a second, a number between 0 and 99)
     * @type {number}
     * @memberof ActivityPeriod
     */
    'start_centiseconds'?: number;
    /**
     * Epoch Timestamp, seconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof ActivityPeriod
     */
    'end_time'?: number;
    /**
     * Centiseconds part of epoch timestamp (hundredths of a second, a number between 0 and 99)
     * @type {number}
     * @memberof ActivityPeriod
     */
    'end_centiseconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPeriod
     */
    'lft'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPeriod
     */
    'rgt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPeriod
     */
    'is_synced'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPeriod
     */
    'is_deleted'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPeriod
     */
    'is_injected'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityPeriod
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityPeriod
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityPeriod
     */
    'user_id'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityPeriod
     */
    'tag_list'?: Array<string>;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof ActivityPeriod
     */
    'tags'?: Array<TagItem>;
}
/**
 * 
 * @export
 * @interface ActivityPeriodAthlete
 */
export interface ActivityPeriodAthlete {
    /**
     * 
     * @type {string}
     * @memberof ActivityPeriodAthlete
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityPeriodAthlete
     */
    'activity_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityPeriodAthlete
     */
    'period_depth_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityPeriodAthlete
     */
    'name'?: string;
    /**
     * Epoch Timestamp, seconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof ActivityPeriodAthlete
     */
    'start_time'?: number;
    /**
     * Centiseconds part of epoch timestamp (hundredths of a second, a number between 0 and 99)
     * @type {number}
     * @memberof ActivityPeriodAthlete
     */
    'start_centiseconds'?: number;
    /**
     * Epoch Timestamp, seconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof ActivityPeriodAthlete
     */
    'end_time'?: number;
    /**
     * Centiseconds part of epoch timestamp (hundredths of a second, a number between 0 and 99)
     * @type {number}
     * @memberof ActivityPeriodAthlete
     */
    'end_centiseconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPeriodAthlete
     */
    'lft'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPeriodAthlete
     */
    'rgt'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPeriodAthlete
     */
    'is_synced'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPeriodAthlete
     */
    'is_deleted'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityPeriodAthlete
     */
    'is_injected'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityPeriodAthlete
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityPeriodAthlete
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityPeriodAthlete
     */
    'user_id'?: string | null;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof ActivityPeriodAthlete
     */
    'tags'?: Array<TagItem>;
    /**
     * 
     * @type {Array<ActivityPeriodAthletePeriodAthletes>}
     * @memberof ActivityPeriodAthlete
     */
    'period_athletes'?: Array<ActivityPeriodAthletePeriodAthletes>;
}
/**
 * 
 * @export
 * @interface ActivityPeriodAthletePeriodAthletes
 */
export interface ActivityPeriodAthletePeriodAthletes {
    /**
     * 
     * @type {string}
     * @memberof ActivityPeriodAthletePeriodAthletes
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityPeriodAthletePeriodAthletes
     */
    'athlete_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityPeriodAthletePeriodAthletes
     */
    'is_injected'?: number;
}
/**
 * 
 * @export
 * @interface ActivityTag
 */
export interface ActivityTag {
    /**
     * 
     * @type {string}
     * @memberof ActivityTag
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityTag
     */
    'tag_type_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityTag
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityTag
     */
    'is_synced'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityTag
     */
    'is_deleted'?: number;
    /**
     * 
     * @type {string}
     * @memberof ActivityTag
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityTag
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityTag
     */
    'tag_type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityTag
     */
    'entity_tag_table_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityTag
     */
    'tag_name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityTag
     */
    'readonly'?: boolean;
    /**
     * 
     * @type {ActivityTagTagType}
     * @memberof ActivityTag
     */
    'tag_type'?: ActivityTagTagType;
}
/**
 * 
 * @export
 * @interface ActivityTagTagType
 */
export interface ActivityTagTagType {
    /**
     * 
     * @type {string}
     * @memberof ActivityTagTagType
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityTagTagType
     */
    'name'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ActivityTagTagType
     */
    'locked'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ActivityTagTagType
     */
    'entity_max'?: number | null;
}
/**
 * 
 * @export
 * @interface ActivityUpdateBatchRequest
 */
export interface ActivityUpdateBatchRequest {
    /**
     * Name of the activity
     * @type {string}
     * @memberof ActivityUpdateBatchRequest
     */
    'name'?: string;
    /**
     * The list of periods.
     * @type {Array<ActivityUpdateRequestPeriodAndAthletes>}
     * @memberof ActivityUpdateBatchRequest
     */
    'periods'?: Array<ActivityUpdateRequestPeriodAndAthletes>;
}
/**
 * 
 * @export
 * @interface ActivityUpdateBatchRequestAthletes
 */
export interface ActivityUpdateBatchRequestAthletes {
    /**
     * The ID of the athlete.
     * @type {string}
     * @memberof ActivityUpdateBatchRequestAthletes
     */
    'athlete_id': string;
}
/**
 * 
 * @export
 * @interface ActivityUpdateBatchResponse
 */
export interface ActivityUpdateBatchResponse {
    /**
     * 
     * @type {ActivityUpdateBatchResponseOk}
     * @memberof ActivityUpdateBatchResponse
     */
    'ok'?: ActivityUpdateBatchResponseOk;
    /**
     * 
     * @type {ActivityUpdateBatchResponseError}
     * @memberof ActivityUpdateBatchResponse
     */
    'error'?: ActivityUpdateBatchResponseError;
}
/**
 * 
 * @export
 * @interface ActivityUpdateBatchResponseError
 */
export interface ActivityUpdateBatchResponseError {
    /**
     * 
     * @type {Array<ActivityUpdateBatchResponseErrorErrors>}
     * @memberof ActivityUpdateBatchResponseError
     */
    'errors'?: Array<ActivityUpdateBatchResponseErrorErrors>;
    /**
     * 
     * @type {Array<ActivityUpdateBatchResponseErrorPeriods>}
     * @memberof ActivityUpdateBatchResponseError
     */
    'periods'?: Array<ActivityUpdateBatchResponseErrorPeriods>;
}
/**
 * 
 * @export
 * @interface ActivityUpdateBatchResponseErrorAthletes
 */
export interface ActivityUpdateBatchResponseErrorAthletes {
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateBatchResponseErrorAthletes
     */
    'athlete_id'?: string;
    /**
     * 
     * @type {Array<ActivityUpdateBatchResponseErrorErrors1>}
     * @memberof ActivityUpdateBatchResponseErrorAthletes
     */
    'errors'?: Array<ActivityUpdateBatchResponseErrorErrors1>;
}
/**
 * 
 * @export
 * @interface ActivityUpdateBatchResponseErrorErrors
 */
export interface ActivityUpdateBatchResponseErrorErrors {
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateBatchResponseErrorErrors
     */
    'error_code'?: string;
}
/**
 * 
 * @export
 * @interface ActivityUpdateBatchResponseErrorErrors1
 */
export interface ActivityUpdateBatchResponseErrorErrors1 {
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateBatchResponseErrorErrors1
     */
    'error_code'?: string;
    /**
     * 
     * @type {Array<ActivityUpdateBatchResponseErrorOverlappingPeriods>}
     * @memberof ActivityUpdateBatchResponseErrorErrors1
     */
    'overlapping_periods'?: Array<ActivityUpdateBatchResponseErrorOverlappingPeriods>;
}
/**
 * 
 * @export
 * @interface ActivityUpdateBatchResponseErrorOverlappingPeriods
 */
export interface ActivityUpdateBatchResponseErrorOverlappingPeriods {
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateBatchResponseErrorOverlappingPeriods
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateBatchResponseErrorOverlappingPeriods
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateBatchResponseErrorOverlappingPeriods
     */
    'activity_id': string;
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateBatchResponseErrorOverlappingPeriods
     */
    'activity_name': string;
}
/**
 * 
 * @export
 * @interface ActivityUpdateBatchResponseErrorPeriods
 */
export interface ActivityUpdateBatchResponseErrorPeriods {
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateBatchResponseErrorPeriods
     */
    'id'?: string;
    /**
     * 
     * @type {Array<ActivityUpdateBatchResponseErrorErrors>}
     * @memberof ActivityUpdateBatchResponseErrorPeriods
     */
    'errors'?: Array<ActivityUpdateBatchResponseErrorErrors>;
    /**
     * 
     * @type {Array<ActivityUpdateBatchResponseErrorAthletes>}
     * @memberof ActivityUpdateBatchResponseErrorPeriods
     */
    'athletes'?: Array<ActivityUpdateBatchResponseErrorAthletes>;
}
/**
 * 
 * @export
 * @interface ActivityUpdateBatchResponseOk
 */
export interface ActivityUpdateBatchResponseOk {
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateBatchResponseOk
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ActivityUpdateBatchResponseOk
     */
    'start_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityUpdateBatchResponseOk
     */
    'end_time'?: number;
    /**
     * 
     * @type {Array<ActivityUpdateBatchResponseOkAthletes>}
     * @memberof ActivityUpdateBatchResponseOk
     */
    'athletes'?: Array<ActivityUpdateBatchResponseOkAthletes>;
    /**
     * 
     * @type {Array<ActivityUpdateBatchResponseOkPeriods>}
     * @memberof ActivityUpdateBatchResponseOk
     */
    'periods': Array<ActivityUpdateBatchResponseOkPeriods>;
}
/**
 * 
 * @export
 * @interface ActivityUpdateBatchResponseOkAthletes
 */
export interface ActivityUpdateBatchResponseOkAthletes {
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateBatchResponseOkAthletes
     */
    'athlete_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateBatchResponseOkAthletes
     */
    'activity_athlete_id'?: string;
}
/**
 * 
 * @export
 * @interface ActivityUpdateBatchResponseOkAthletes1
 */
export interface ActivityUpdateBatchResponseOkAthletes1 {
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateBatchResponseOkAthletes1
     */
    'athlete_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateBatchResponseOkAthletes1
     */
    'period_athlete_id'?: string;
    /**
     * 
     * @type {Array<ActivityUpdateBatchResponseOkRotations>}
     * @memberof ActivityUpdateBatchResponseOkAthletes1
     */
    'rotations'?: Array<ActivityUpdateBatchResponseOkRotations>;
}
/**
 * 
 * @export
 * @interface ActivityUpdateBatchResponseOkPeriods
 */
export interface ActivityUpdateBatchResponseOkPeriods {
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateBatchResponseOkPeriods
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityUpdateBatchResponseOkPeriods
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ActivityUpdateBatchResponseOkPeriods
     */
    'start_time_ms'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityUpdateBatchResponseOkPeriods
     */
    'end_time_ms'?: number;
    /**
     * 
     * @type {Array<ActivityUpdateBatchResponseOkAthletes1>}
     * @memberof ActivityUpdateBatchResponseOkPeriods
     */
    'athletes'?: Array<ActivityUpdateBatchResponseOkAthletes1>;
}
/**
 * 
 * @export
 * @interface ActivityUpdateBatchResponseOkRotations
 */
export interface ActivityUpdateBatchResponseOkRotations {
    /**
     * 
     * @type {number}
     * @memberof ActivityUpdateBatchResponseOkRotations
     */
    'start_time_ms'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityUpdateBatchResponseOkRotations
     */
    'end_time_ms'?: number;
    /**
     * 
     * @type {number}
     * @memberof ActivityUpdateBatchResponseOkRotations
     */
    'number'?: number;
}
/**
 * 
 * @export
 * @interface ActivityUpdateRequest
 */
export interface ActivityUpdateRequest {
    /**
     * Activity name
     * @type {string}
     * @memberof ActivityUpdateRequest
     */
    'name'?: string;
    /**
     * Venue Field Id the activity relates to
     * @type {string}
     * @memberof ActivityUpdateRequest
     */
    'venue_field_id'?: string;
}
/**
 * 
 * @export
 * @interface ActivityUpdateRequestPeriodAndAthletes
 */
export interface ActivityUpdateRequestPeriodAndAthletes {
    /**
     * Period ID
     * @type {string}
     * @memberof ActivityUpdateRequestPeriodAndAthletes
     */
    'id': string;
    /**
     * The name of the period.
     * @type {string}
     * @memberof ActivityUpdateRequestPeriodAndAthletes
     */
    'name': string;
    /**
     * The start time of the period in milliseconds.
     * @type {number}
     * @memberof ActivityUpdateRequestPeriodAndAthletes
     */
    'start_time_ms': number;
    /**
     * The end time of the period in milliseconds.
     * @type {number}
     * @memberof ActivityUpdateRequestPeriodAndAthletes
     */
    'end_time_ms': number;
    /**
     * 
     * @type {Array<ActivityUpdateBatchRequestAthletes>}
     * @memberof ActivityUpdateRequestPeriodAndAthletes
     */
    'athletes': Array<ActivityUpdateBatchRequestAthletes>;
}
/**
 * 
 * @export
 * @interface AnalyticsConfig
 */
export interface AnalyticsConfig {
    /**
     * 
     * @type {AnalyticsConfigMixpanel}
     * @memberof AnalyticsConfig
     */
    'mixpanel': AnalyticsConfigMixpanel;
    /**
     * 
     * @type {AnalyticsConfigSuperProperties}
     * @memberof AnalyticsConfig
     */
    'super_properties': AnalyticsConfigSuperProperties;
}
/**
 * 
 * @export
 * @interface AnalyticsConfigMixpanel
 */
export interface AnalyticsConfigMixpanel {
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConfigMixpanel
     */
    'project_token': string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConfigMixpanel
     */
    'cookie_domain'?: string;
}
/**
 * 
 * @export
 * @interface AnalyticsConfigSuperProperties
 */
export interface AnalyticsConfigSuperProperties {
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConfigSuperProperties
     */
    'product': string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConfigSuperProperties
     */
    'env': string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConfigSuperProperties
     */
    'app_version': string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConfigSuperProperties
     */
    'region': string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConfigSuperProperties
     */
    'user_agent': string;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConfigSuperProperties
     */
    'api_client_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsConfigSuperProperties
     */
    'customer_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConfigSuperProperties
     */
    'customer_name'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnalyticsConfigSuperProperties
     */
    'modules'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConfigSuperProperties
     */
    'sport'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConfigSuperProperties
     */
    'industry'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalyticsConfigSuperProperties
     */
    'salesforce_id'?: string | null;
}
/**
 * Annotation
 * @export
 * @interface Annotation
 */
export interface Annotation {
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'activity_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'parent_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'period_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'name'?: string;
    /**
     * Epoch Timestamp, seconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof Annotation
     */
    'start_time'?: number;
    /**
     * Centiseconds part of epoch timestamp (hundredths of a second, a number between 0 and 99)
     * @type {number}
     * @memberof Annotation
     */
    'start_centiseconds'?: number;
    /**
     * Epoch Timestamp, seconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof Annotation
     */
    'end_time'?: number;
    /**
     * Centiseconds part of epoch timestamp (hundredths of a second, a number between 0 and 99)
     * @type {number}
     * @memberof Annotation
     */
    'end_centiseconds'?: number;
    /**
     * HTML color, e.g. #FF5500, the color to use when displaying this annotation on the timeline
     * @type {string}
     * @memberof Annotation
     */
    'draw_color'?: string;
    /**
     * Controls vertical position (row) when displaying this annotation on the timeline
     * @type {number}
     * @memberof Annotation
     */
    'row'?: number;
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'modified_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof Annotation
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof Annotation
     */
    'y'?: number;
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof Annotation
     */
    'imported_id'?: string | null;
    /**
     * 
     * @type {AnnotationCategory}
     * @memberof Annotation
     */
    'annotation_category'?: AnnotationCategory;
    /**
     * 
     * @type {Array<AnnotationMetadata>}
     * @memberof Annotation
     */
    'annotation_metadata'?: Array<AnnotationMetadata>;
    /**
     * Array of athlete ids on annotation
     * @type {Array<string>}
     * @memberof Annotation
     */
    'athlete_ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AnnotationAthlete
 */
export interface AnnotationAthlete {
    /**
     * 
     * @type {string}
     * @memberof AnnotationAthlete
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationAthlete
     */
    'athlete_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationAthlete
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationAthlete
     */
    'modified_at'?: string;
    /**
     * 
     * @type {Annotation}
     * @memberof AnnotationAthlete
     */
    'annotation'?: Annotation;
}
/**
 * Annotation Category
 * @export
 * @interface AnnotationCategory
 */
export interface AnnotationCategory {
    /**
     * 
     * @type {string}
     * @memberof AnnotationCategory
     */
    'id'?: string;
    /**
     * Display name
     * @type {string}
     * @memberof AnnotationCategory
     */
    'name'?: string;
    /**
     * Internal name
     * @type {string}
     * @memberof AnnotationCategory
     */
    'import_name'?: string;
    /**
     * Pre-roll in whole seconds
     * @type {number}
     * @memberof AnnotationCategory
     */
    'start_offset'?: number;
    /**
     * Post-roll in whole seconds
     * @type {number}
     * @memberof AnnotationCategory
     */
    'end_offset'?: number;
    /**
     * HTML color, e.g. #FF5500, the color to use for annotations of this type
     * @type {string}
     * @memberof AnnotationCategory
     */
    'default_color'?: string;
    /**
     * 0(false) or 1(true) annotation category should be baked. If is_event = 1 then is_bakeable will be 0
     * @type {number}
     * @memberof AnnotationCategory
     */
    'is_bakeable'?: number;
    /**
     * 0(false) or 1(true). An event might be a single event in time (like a Goal Keeper Save) vs a period of time (like ball in possession)
     * @type {number}
     * @memberof AnnotationCategory
     */
    'is_event'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationCategory
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationCategory
     */
    'modified_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationCategory
     */
    'is_editable'?: number;
}
/**
 * 
 * @export
 * @interface AnnotationCategoryCreateRequest
 */
export interface AnnotationCategoryCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof AnnotationCategoryCreateRequest
     */
    'name': string;
    /**
     * defaults to the value from \'name\' when absent
     * @type {string}
     * @memberof AnnotationCategoryCreateRequest
     */
    'import_name'?: string;
    /**
     * Pre-roll in whole seconds
     * @type {number}
     * @memberof AnnotationCategoryCreateRequest
     */
    'start_offset'?: number;
    /**
     * Post-roll in whole seconds
     * @type {number}
     * @memberof AnnotationCategoryCreateRequest
     */
    'end_offset'?: number;
    /**
     * HTML color, e.g. #FF5500, the color to use for annotations of this type
     * @type {string}
     * @memberof AnnotationCategoryCreateRequest
     */
    'default_color'?: string;
    /**
     * 0(false) or 1(true) annotation category should be baked. If is_event = 1 then is_bakeable will be 0
     * @type {number}
     * @memberof AnnotationCategoryCreateRequest
     */
    'is_bakeable'?: number;
    /**
     * 0(false) or 1(true). An event might be a single event in time (like a Goal Keeper Save) vs a period of time (like ball in possession)
     * @type {number}
     * @memberof AnnotationCategoryCreateRequest
     */
    'is_event'?: number;
    /**
     * 0(false) or 1(true). An event might be a single event in time (like a Goal Keeper Save) vs a period of time (like ball in possession)
     * @type {number}
     * @memberof AnnotationCategoryCreateRequest
     */
    'is_editable'?: number;
}
/**
 * 
 * @export
 * @interface AnnotationCategoryUpdateRequest
 */
export interface AnnotationCategoryUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof AnnotationCategoryUpdateRequest
     */
    'name'?: string;
    /**
     * defaults to the value from \'name\' when absent
     * @type {string}
     * @memberof AnnotationCategoryUpdateRequest
     */
    'import_name'?: string;
    /**
     * Pre-roll in whole seconds
     * @type {number}
     * @memberof AnnotationCategoryUpdateRequest
     */
    'start_offset'?: number;
    /**
     * Post-roll in whole seconds
     * @type {number}
     * @memberof AnnotationCategoryUpdateRequest
     */
    'end_offset'?: number;
    /**
     * HTML color, e.g. #FF5500, the color to use for annotations of this type
     * @type {string}
     * @memberof AnnotationCategoryUpdateRequest
     */
    'default_color'?: string;
    /**
     * 0(false) or 1(true) annotation category should be baked. If is_event = 1 then is_bakeable will be 0
     * @type {number}
     * @memberof AnnotationCategoryUpdateRequest
     */
    'is_bakeable'?: number;
    /**
     * 0(false) or 1(true). An event might be a single event in time (like a Goal Keeper Save) vs a period of time (like ball in possession)
     * @type {number}
     * @memberof AnnotationCategoryUpdateRequest
     */
    'is_event'?: number;
}
/**
 * 
 * @export
 * @interface AnnotationCreateRequest
 */
export interface AnnotationCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof AnnotationCreateRequest
     */
    'activity_id': string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationCreateRequest
     */
    'period_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationCreateRequest
     */
    'annotation_category_id': string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationCreateRequest
     */
    'start_time': number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationCreateRequest
     */
    'start_centiseconds': number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationCreateRequest
     */
    'end_time': number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationCreateRequest
     */
    'end_centiseconds': number;
    /**
     * Array of athlete IDs
     * @type {Array<string>}
     * @memberof AnnotationCreateRequest
     */
    'athlete_ids'?: Array<string>;
    /**
     * Parent annotation ID
     * @type {string}
     * @memberof AnnotationCreateRequest
     */
    'parent_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationCreateRequest
     */
    'draw_color'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationCreateRequest
     */
    'row'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationCreateRequest
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationCreateRequest
     */
    'y'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationCreateRequest
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface AnnotationMetadata
 */
export interface AnnotationMetadata {
    /**
     * 
     * @type {string}
     * @memberof AnnotationMetadata
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationMetadata
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationMetadata
     */
    'value'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationMetadata
     */
    'is_visible'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationMetadata
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationMetadata
     */
    'modified_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationMetadata
     */
    'type'?: number;
}
/**
 * 
 * @export
 * @interface AnnotationUpdateRequest
 */
export interface AnnotationUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof AnnotationUpdateRequest
     */
    'period_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationUpdateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationUpdateRequest
     */
    'start_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationUpdateRequest
     */
    'start_centiseconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationUpdateRequest
     */
    'end_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationUpdateRequest
     */
    'end_centiseconds'?: number;
    /**
     * Array of athlete IDs
     * @type {Array<string>}
     * @memberof AnnotationUpdateRequest
     */
    'athlete_ids'?: Array<string>;
    /**
     * Parent annotation ID
     * @type {string}
     * @memberof AnnotationUpdateRequest
     */
    'parent_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnotationUpdateRequest
     */
    'draw_color'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnnotationUpdateRequest
     */
    'row'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationUpdateRequest
     */
    'x'?: number;
    /**
     * 
     * @type {number}
     * @memberof AnnotationUpdateRequest
     */
    'y'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnotationUpdateRequest
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface Athlete
 */
export interface Athlete {
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'gender': string | null;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'jersey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'nickname'?: string;
    /**
     * 
     * @type {number}
     * @memberof Athlete
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof Athlete
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof Athlete
     */
    'date_of_birth'?: number;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'date_of_birth_date': string;
    /**
     * 
     * @type {number}
     * @memberof Athlete
     */
    'velocity_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof Athlete
     */
    'acceleration_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof Athlete
     */
    'heart_rate_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof Athlete
     */
    'player_load_max'?: number;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'stroke_colour'?: string;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'fill_colour'?: string;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'trail_colour_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'trail_colour_end'?: string;
    /**
     * 
     * @type {number}
     * @memberof Athlete
     */
    'is_synced'?: number;
    /**
     * 
     * @type {number}
     * @memberof Athlete
     */
    'is_deleted'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Athlete
     */
    'is_demo'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'modified_at'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Athlete
     */
    'tag_list'?: Array<string>;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof Athlete
     */
    'tags'?: Array<TagItem>;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'current_team_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Athlete
     */
    'max_player_load_per_minute'?: number;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'position': string;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'position_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Athlete
     */
    'position_name': string;
}
/**
 * 
 * @export
 * @interface AthleteCreationParameters
 */
export interface AthleteCreationParameters {
    /**
     * 
     * @type {string}
     * @memberof AthleteCreationParameters
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof AthleteCreationParameters
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof AthleteCreationParameters
     */
    'gender': string;
    /**
     * 
     * @type {string}
     * @memberof AthleteCreationParameters
     */
    'jersey': string;
    /**
     * 
     * @type {string}
     * @memberof AthleteCreationParameters
     */
    'team_id': string;
    /**
     * 
     * @type {string}
     * @memberof AthleteCreationParameters
     */
    'position_id': string;
    /**
     * 
     * @type {number}
     * @memberof AthleteCreationParameters
     */
    'date_of_birth'?: number;
    /**
     * 
     * @type {string}
     * @memberof AthleteCreationParameters
     */
    'date_of_birth_date': string;
}
/**
 * 
 * @export
 * @interface AthleteProfileSettings
 */
export interface AthleteProfileSettings {
    /**
     * 
     * @type {string}
     * @memberof AthleteProfileSettings
     */
    'gender': string;
    /**
     * 
     * @type {number}
     * @memberof AthleteProfileSettings
     */
    'height': number;
    /**
     * 
     * @type {number}
     * @memberof AthleteProfileSettings
     */
    'max_heart_rate': number;
    /**
     * 
     * @type {number}
     * @memberof AthleteProfileSettings
     */
    'max_velocity': number;
    /**
     * 
     * @type {number}
     * @memberof AthleteProfileSettings
     */
    'weight': number;
}
/**
 * 
 * @export
 * @interface AthleteUpdateRequest
 */
export interface AthleteUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof AthleteUpdateRequest
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AthleteUpdateRequest
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AthleteUpdateRequest
     */
    'gender'?: string;
    /**
     * 
     * @type {string}
     * @memberof AthleteUpdateRequest
     */
    'position_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AthleteUpdateRequest
     */
    'team_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AthleteUpdateRequest
     */
    'jersey'?: string;
    /**
     * 
     * @type {string}
     * @memberof AthleteUpdateRequest
     */
    'nickname'?: string;
    /**
     * 
     * @type {number}
     * @memberof AthleteUpdateRequest
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof AthleteUpdateRequest
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof AthleteUpdateRequest
     */
    'date_of_birth'?: number;
    /**
     * 
     * @type {string}
     * @memberof AthleteUpdateRequest
     */
    'date_of_birth_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof AthleteUpdateRequest
     */
    'velocity_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof AthleteUpdateRequest
     */
    'acceleration_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof AthleteUpdateRequest
     */
    'heart_rate_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof AthleteUpdateRequest
     */
    'player_load_max'?: number;
    /**
     * 
     * @type {string}
     * @memberof AthleteUpdateRequest
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof AthleteUpdateRequest
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof AthleteUpdateRequest
     */
    'stroke_colour'?: string;
    /**
     * 
     * @type {string}
     * @memberof AthleteUpdateRequest
     */
    'fill_colour'?: string;
    /**
     * 
     * @type {string}
     * @memberof AthleteUpdateRequest
     */
    'trail_colour_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof AthleteUpdateRequest
     */
    'trail_colour_end'?: string;
}
/**
 * 
 * @export
 * @interface AverageSet
 */
export interface AverageSet {
    /**
     * 
     * @type {string}
     * @memberof AverageSet
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AverageSet
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AverageSet
     */
    'resource': string;
    /**
     * 
     * @type {string}
     * @memberof AverageSet
     */
    'source': string;
}
/**
 * Parameters for the average set create
 * @export
 * @interface AverageSetCreateRequest
 */
export interface AverageSetCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof AverageSetCreateRequest
     */
    'resource': string;
    /**
     * 
     * @type {string}
     * @memberof AverageSetCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AverageSetCreateRequest
     */
    'source'?: string;
    /**
     * 
     * @type {Array<AverageSetFilter>}
     * @memberof AverageSetCreateRequest
     */
    'filters'?: Array<AverageSetFilter>;
    /**
     * 
     * @type {boolean}
     * @memberof AverageSetCreateRequest
     */
    'autobuild'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AverageSetCreateRequest
     */
    'build'?: boolean;
}
/**
 * Response for the average set create
 * @export
 * @interface AverageSetCreateResponse
 */
export interface AverageSetCreateResponse {
    /**
     * 
     * @type {string}
     * @memberof AverageSetCreateResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AverageSetCreateResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AverageSetCreateResponse
     */
    'resource': string;
}
/**
 * Filters for the average set
 * @export
 * @interface AverageSetFilter
 */
export interface AverageSetFilter {
    /**
     * 
     * @type {string}
     * @memberof AverageSetFilter
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AverageSetFilter
     */
    'comparison': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AverageSetFilter
     */
    'values'?: Array<string>;
}
/**
 * Average set brief summary
 * @export
 * @interface AverageSetSummary
 */
export interface AverageSetSummary {
    /**
     * 
     * @type {string}
     * @memberof AverageSetSummary
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof AverageSetSummary
     */
    'is_empty'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AverageSetSummary
     */
    'type'?: string;
}
/**
 * Band Model
 * @export
 * @interface Band
 */
export interface Band {
    /**
     * 
     * @type {string}
     * @memberof Band
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Band
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Band
     */
    'table_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Band
     */
    'table_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Band
     */
    'band_min'?: number;
    /**
     * 
     * @type {number}
     * @memberof Band
     */
    'band_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof Band
     */
    'number'?: number;
    /**
     * 
     * @type {number}
     * @memberof Band
     */
    'percentage'?: number;
    /**
     * 
     * @type {number}
     * @memberof Band
     */
    'start_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof Band
     */
    'end_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof Band
     */
    'current'?: number;
    /**
     * 
     * @type {string}
     * @memberof Band
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Band
     */
    'modified_at'?: string;
}
/**
 * 
 * @export
 * @interface Benchmark
 */
export interface Benchmark {
    /**
     * 
     * @type {string}
     * @memberof Benchmark
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Benchmark
     */
    'sport_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Benchmark
     */
    'name'?: string;
    /**
     * 
     * @type {Array<BenchmarkParameters>}
     * @memberof Benchmark
     */
    'parameters'?: Array<BenchmarkParameters>;
}
/**
 * 
 * @export
 * @interface BenchmarkParameters
 */
export interface BenchmarkParameters {
    /**
     * 
     * @type {string}
     * @memberof BenchmarkParameters
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkParameters
     */
    'benchmark_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkParameters
     */
    'parameter_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof BenchmarkParameters
     */
    'target'?: number;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkParameters
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkParameters
     */
    'original_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BenchmarkParameters
     */
    'slug'?: string;
}
/**
 * Create upload url response
 * @export
 * @interface CatrCreateUrlResponse
 */
export interface CatrCreateUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof CatrCreateUrlResponse
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof CatrCreateUrlResponse
     */
    'bucket'?: string;
    /**
     * 
     * @type {CatrCreateUrlResponseJob}
     * @memberof CatrCreateUrlResponse
     */
    'job'?: CatrCreateUrlResponseJob;
}
/**
 * 
 * @export
 * @interface CatrCreateUrlResponseJob
 */
export interface CatrCreateUrlResponseJob {
    /**
     * 
     * @type {string}
     * @memberof CatrCreateUrlResponseJob
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatrCreateUrlResponseJob
     */
    'CreatedAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatrCreateUrlResponseJob
     */
    'ClientId'?: string;
    /**
     * 
     * @type {number}
     * @memberof CatrCreateUrlResponseJob
     */
    'Status'?: number;
    /**
     * 
     * @type {string}
     * @memberof CatrCreateUrlResponseJob
     */
    'OriginalFilename'?: string;
    /**
     * 
     * @type {string}
     * @memberof CatrCreateUrlResponseJob
     */
    'RequestId'?: string;
}
/**
 * 
 * @export
 * @interface CatrEntityTagRequestObject
 */
export interface CatrEntityTagRequestObject {
    /**
     * the name of DB Table this EntityTag refers to.
     * @type {string}
     * @memberof CatrEntityTagRequestObject
     */
    'table_name'?: string;
    /**
     * the name of the Tag record.
     * @type {string}
     * @memberof CatrEntityTagRequestObject
     */
    'tag_name'?: string;
    /**
     * the name of the TagType record.
     * @type {string}
     * @memberof CatrEntityTagRequestObject
     */
    'tag_type'?: string;
}
/**
 * Connection
 * @export
 * @interface Connection
 */
export interface Connection {
    /**
     * 
     * @type {string}
     * @memberof Connection
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Connection
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Connection
     */
    'lastUsed'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Connection
     */
    'authenticated'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Connection
     */
    'connectionType': string;
    /**
     * 
     * @type {object}
     * @memberof Connection
     */
    'values': object;
    /**
     * 
     * @type {string}
     * @memberof Connection
     */
    'apiId'?: string;
}
/**
 * 
 * @export
 * @interface ConnectionCreateRequest
 */
export interface ConnectionCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionCreateRequest
     */
    'connectionType': string;
    /**
     * 
     * @type {object}
     * @memberof ConnectionCreateRequest
     */
    'values': object;
}
/**
 * 
 * @export
 * @interface ConnectionType
 */
export interface ConnectionType {
    /**
     * 
     * @type {string}
     * @memberof ConnectionType
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionType
     */
    'name': string;
    /**
     * 
     * @type {Array<ConnectionTypeMeta>}
     * @memberof ConnectionType
     */
    'meta': Array<ConnectionTypeMeta>;
}
/**
 * 
 * @export
 * @interface ConnectionTypeMeta
 */
export interface ConnectionTypeMeta {
    /**
     * 
     * @type {string}
     * @memberof ConnectionTypeMeta
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionTypeMeta
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionTypeMeta
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionTypeMeta
     */
    'placeholder'?: string;
    /**
     * 
     * @type {string}
     * @memberof ConnectionTypeMeta
     */
    'label': string;
    /**
     * 
     * @type {boolean}
     * @memberof ConnectionTypeMeta
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface ConnectionUpdateRequest
 */
export interface ConnectionUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ConnectionUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {object}
     * @memberof ConnectionUpdateRequest
     */
    'values': object;
}
/**
 * 
 * @export
 * @interface CreateDeviceAthleteMappingRequest
 */
export interface CreateDeviceAthleteMappingRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateDeviceAthleteMappingRequest
     */
    'athlete_id': string;
    /**
     * 
     * @type {number}
     * @memberof CreateDeviceAthleteMappingRequest
     */
    'device_id': number;
    /**
     * unix timestamp in seconds. If not specified current server timestamp will be used
     * @type {number}
     * @memberof CreateDeviceAthleteMappingRequest
     */
    'start_time'?: number;
    /**
     * unix timestamp in seconds. Used for creating fixed mappings. If not specified a current mapping will be created
     * @type {number}
     * @memberof CreateDeviceAthleteMappingRequest
     */
    'end_time'?: number;
}
/**
 * 
 * @export
 * @interface CreateOrUpdateDeviceRequest
 */
export interface CreateOrUpdateDeviceRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateOrUpdateDeviceRequest
     */
    'team_id'?: string;
}
/**
 * Create personal token request
 * @export
 * @interface CreatePersonalTokenRequest
 */
export interface CreatePersonalTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalTokenRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreatePersonalTokenRequest
     */
    'minutes': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreatePersonalTokenRequest
     */
    'scopes'?: Array<string>;
}
/**
 * Create personal token response
 * @export
 * @interface CreatePersonalTokenResponse
 */
export interface CreatePersonalTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalTokenResponse
     */
    'access_token_id': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePersonalTokenResponse
     */
    'access_token': string;
}
/**
 * 
 * @export
 * @interface CreateThresholdSetRequest
 */
export interface CreateThresholdSetRequest {
    /**
     * The name of the threshold set
     * @type {string}
     * @memberof CreateThresholdSetRequest
     */
    'name'?: string;
    /**
     * 
     * @type {Array<ThresholdSetsAlert>}
     * @memberof CreateThresholdSetRequest
     */
    'alert'?: Array<ThresholdSetsAlert>;
}
/**
 * Activity IDs
 * @export
 * @interface CtrActivityIds
 */
export interface CtrActivityIds {
    /**
     * 
     * @type {Array<string>}
     * @memberof CtrActivityIds
     */
    'ids': Array<string>;
}
/**
 * Ctr export settings
 * @export
 * @interface CtrExportSettings
 */
export interface CtrExportSettings {
    /**
     * 
     * @type {Array<CtrExportSettingsParameterGroup>}
     * @memberof CtrExportSettings
     */
    'parameter_group': Array<CtrExportSettingsParameterGroup>;
    /**
     * 
     * @type {Array<CtrExportSettingsPeriodTags>}
     * @memberof CtrExportSettings
     */
    'period_tags': Array<CtrExportSettingsPeriodTags>;
    /**
     * 
     * @type {Array<CtrExportSettingsAthletes>}
     * @memberof CtrExportSettings
     */
    'athletes': Array<CtrExportSettingsAthletes>;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettings
     */
    'email_list': string;
}
/**
 * 
 * @export
 * @interface CtrExportSettingsAthletes
 */
export interface CtrExportSettingsAthletes {
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsAthletes
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsAthletes
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsAthletes
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsAthletes
     */
    'jersey'?: string;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsAthletes
     */
    'nickname'?: string;
    /**
     * 
     * @type {number}
     * @memberof CtrExportSettingsAthletes
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof CtrExportSettingsAthletes
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof CtrExportSettingsAthletes
     */
    'date_of_birth'?: number;
    /**
     * 
     * @type {number}
     * @memberof CtrExportSettingsAthletes
     */
    'velocity_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof CtrExportSettingsAthletes
     */
    'acceleration_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof CtrExportSettingsAthletes
     */
    'heart_rate_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof CtrExportSettingsAthletes
     */
    'player_load_max'?: number;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsAthletes
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsAthletes
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsAthletes
     */
    'stroke_colour'?: string;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsAthletes
     */
    'fill_colour'?: string;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsAthletes
     */
    'trail_colour_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsAthletes
     */
    'trail_colour_end'?: string;
    /**
     * 
     * @type {number}
     * @memberof CtrExportSettingsAthletes
     */
    'is_synced'?: number;
    /**
     * 
     * @type {number}
     * @memberof CtrExportSettingsAthletes
     */
    'is_deleted'?: number;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsAthletes
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsAthletes
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsAthletes
     */
    'position'?: string;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsAthletes
     */
    'position_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsAthletes
     */
    'position_name'?: string;
}
/**
 * 
 * @export
 * @interface CtrExportSettingsParameterGroup
 */
export interface CtrExportSettingsParameterGroup {
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsParameterGroup
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof CtrExportSettingsParameterGroup
     */
    'user_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsParameterGroup
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CtrExportSettingsParameterGroup
     */
    'athlete_access'?: number;
    /**
     * 
     * @type {number}
     * @memberof CtrExportSettingsParameterGroup
     */
    'readonly'?: number;
    /**
     * 
     * @type {number}
     * @memberof CtrExportSettingsParameterGroup
     */
    'is_synced'?: number;
    /**
     * 
     * @type {number}
     * @memberof CtrExportSettingsParameterGroup
     */
    'is_deleted'?: number;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsParameterGroup
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsParameterGroup
     */
    'modified_at'?: string;
}
/**
 * 
 * @export
 * @interface CtrExportSettingsPeriodTags
 */
export interface CtrExportSettingsPeriodTags {
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsPeriodTags
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsPeriodTags
     */
    'tag_type_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsPeriodTags
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CtrExportSettingsPeriodTags
     */
    'tag_name'?: string;
}
/**
 * Generate Ctr Post Request Model
 * @export
 * @interface CtrReportParameters
 */
export interface CtrReportParameters {
    /**
     * 
     * @type {Array<string>}
     * @memberof CtrReportParameters
     */
    'activities': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CtrReportParameters
     */
    'parameter_group_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CtrReportParameters
     */
    'filter_athletes'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CtrReportParameters
     */
    'fill_blank_fields_with'?: string;
    /**
     * 
     * @type {number}
     * @memberof CtrReportParameters
     */
    'export_decimals': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CtrReportParameters
     */
    'exclude_tags': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CtrReportParameters
     */
    'append_tag_options': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CtrReportParameters
     */
    'email_list': Array<string>;
}
/**
 * 
 * @export
 * @interface CtrReportResponse
 */
export interface CtrReportResponse {
    /**
     * Link to download ctr report
     * @type {string}
     * @memberof CtrReportResponse
     */
    'csv_download_link'?: string;
}
/**
 * Generate Ctr Post Request Model
 * @export
 * @interface CtrSingleReportParameters
 */
export interface CtrSingleReportParameters {
    /**
     * 
     * @type {Array<string>}
     * @memberof CtrSingleReportParameters
     */
    'activities': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CtrSingleReportParameters
     */
    'parameter_group_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CtrSingleReportParameters
     */
    'filter_athletes'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CtrSingleReportParameters
     */
    'fill_blank_fields_with'?: string;
    /**
     * 
     * @type {number}
     * @memberof CtrSingleReportParameters
     */
    'export_decimals': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CtrSingleReportParameters
     */
    'exclude_tags': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CtrSingleReportParameters
     */
    'append_tag_options': Array<string>;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'aws_region': string;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'webserver': string;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'is_combined': number;
    /**
     * 
     * @type {string}
     * @memberof Customer
     */
    'user_default_timezone': string;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'parent'?: number;
    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'data_setup': number;
}
/**
 * 
 * @export
 * @interface DeepActivity
 */
export interface DeepActivity {
    /**
     * 
     * @type {string}
     * @memberof DeepActivity
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DeepActivity
     */
    'game_id': string;
    /**
     * 
     * @type {string}
     * @memberof DeepActivity
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof DeepActivity
     */
    'start_time': number;
    /**
     * 
     * @type {number}
     * @memberof DeepActivity
     */
    'end_time': number;
    /**
     * 
     * @type {string}
     * @memberof DeepActivity
     */
    'modified_at': string;
    /**
     * 
     * @type {number}
     * @memberof DeepActivity
     */
    'is_injected'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeepActivity
     */
    'activity_tag_list': Array<string>;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof DeepActivity
     */
    'activity_tags': Array<TagItem>;
    /**
     * 
     * @type {User}
     * @memberof DeepActivity
     */
    'owner': User;
    /**
     * 
     * @type {string}
     * @memberof DeepActivity
     */
    'owner_id': string;
    /**
     * 
     * @type {Array<DeepActivityAthlete>}
     * @memberof DeepActivity
     */
    'athletes': Array<DeepActivityAthlete>;
    /**
     * 
     * @type {Array<Team>}
     * @memberof DeepActivity
     */
    'teams': Array<Team>;
    /**
     * 
     * @type {Array<DeepActivityPeriod>}
     * @memberof DeepActivity
     */
    'periods': Array<DeepActivityPeriod>;
    /**
     * 
     * @type {DeepActivityVenue}
     * @memberof DeepActivity
     */
    'venue': DeepActivityVenue;
}
/**
 * 
 * @export
 * @interface DeepActivityActivityParticipation
 */
export interface DeepActivityActivityParticipation {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeepActivityActivityParticipation
     */
    'participation_tag_list'?: Array<string>;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof DeepActivityActivityParticipation
     */
    'participation_tags'?: Array<TagItem>;
}
/**
 * 
 * @export
 * @interface DeepActivityAthlete
 */
export interface DeepActivityAthlete {
    /**
     * 
     * @type {string}
     * @memberof DeepActivityAthlete
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeepActivityAthlete
     */
    'name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeepActivityAthlete
     */
    'athlete_tag_list'?: Array<string>;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof DeepActivityAthlete
     */
    'athlete_tags'?: Array<TagItem>;
    /**
     * 
     * @type {Array<DeepActivityParticipation>}
     * @memberof DeepActivityAthlete
     */
    'participation'?: Array<DeepActivityParticipation>;
    /**
     * 
     * @type {Array<DeepActivityFlaggedRegion>}
     * @memberof DeepActivityAthlete
     */
    'flagged_region'?: Array<DeepActivityFlaggedRegion>;
    /**
     * 
     * @type {DeepActivityActivityParticipation}
     * @memberof DeepActivityAthlete
     */
    'activity_participation'?: DeepActivityActivityParticipation;
}
/**
 * 
 * @export
 * @interface DeepActivityFlaggedRegion
 */
export interface DeepActivityFlaggedRegion {
    /**
     * 
     * @type {string}
     * @memberof DeepActivityFlaggedRegion
     */
    'period_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeepActivityFlaggedRegion
     */
    'flagged_region_tag_list'?: Array<string>;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof DeepActivityFlaggedRegion
     */
    'flagged_region_tags'?: Array<TagItem>;
}
/**
 * 
 * @export
 * @interface DeepActivityParticipation
 */
export interface DeepActivityParticipation {
    /**
     * 
     * @type {string}
     * @memberof DeepActivityParticipation
     */
    'period_id'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeepActivityParticipation
     */
    'participation_tag_list'?: Array<string>;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof DeepActivityParticipation
     */
    'participation_tags'?: Array<TagItem>;
}
/**
 * 
 * @export
 * @interface DeepActivityPeriod
 */
export interface DeepActivityPeriod {
    /**
     * 
     * @type {string}
     * @memberof DeepActivityPeriod
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeepActivityPeriod
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeepActivityPeriod
     */
    'start_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeepActivityPeriod
     */
    'end_time'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeepActivityPeriod
     */
    'period_tag_list'?: Array<string>;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof DeepActivityPeriod
     */
    'period_tags'?: Array<TagItem>;
    /**
     * 
     * @type {Array<Athlete>}
     * @memberof DeepActivityPeriod
     */
    'athletes'?: Array<Athlete>;
}
/**
 * 
 * @export
 * @interface DeepActivityVenue
 */
export interface DeepActivityVenue {
    /**
     * 
     * @type {string}
     * @memberof DeepActivityVenue
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeepActivityVenue
     */
    'width'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeepActivityVenue
     */
    'length'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeepActivityVenue
     */
    'rotation'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeepActivityVenue
     */
    'lat'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeepActivityVenue
     */
    'lng'?: number;
}
/**
 * 
 * @export
 * @interface DeleteActivitiesRequest
 */
export interface DeleteActivitiesRequest {
    /**
     * List of IDs to delete
     * @type {Array<string>}
     * @memberof DeleteActivitiesRequest
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface DeleteDeviceAthletes
 */
export interface DeleteDeviceAthletes {
    /**
     * 
     * @type {Array<DeleteDeviceAthletesDeviceAthletes>}
     * @memberof DeleteDeviceAthletes
     */
    'device_athletes'?: Array<DeleteDeviceAthletesDeviceAthletes>;
}
/**
 * 
 * @export
 * @interface DeleteDeviceAthletesDeviceAthletes
 */
export interface DeleteDeviceAthletesDeviceAthletes {
    /**
     * 
     * @type {string}
     * @memberof DeleteDeviceAthletesDeviceAthletes
     */
    'athlete_id': string;
    /**
     * 
     * @type {number}
     * @memberof DeleteDeviceAthletesDeviceAthletes
     */
    'device_id': number;
    /**
     * 
     * @type {number}
     * @memberof DeleteDeviceAthletesDeviceAthletes
     */
    'start_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeleteDeviceAthletesDeviceAthletes
     */
    'end_time'?: number;
}
/**
 * 
 * @export
 * @interface DeleteInjectedDataRequest
 */
export interface DeleteInjectedDataRequest {
    /**
     * Activity id
     * @type {string}
     * @memberof DeleteInjectedDataRequest
     */
    'activity_id'?: string;
}
/**
 * 
 * @export
 * @interface DeleteThresholdSetsRequest
 */
export interface DeleteThresholdSetsRequest {
    /**
     * List of IDs to delete
     * @type {Array<string>}
     * @memberof DeleteThresholdSetsRequest
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface DemoDataStatusResponse
 */
export interface DemoDataStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof DemoDataStatusResponse
     */
    'has_demo_data'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DemoDataStatusResponse
     */
    'has_non_demo_data'?: boolean;
}
/**
 * 
 * @export
 * @interface DeviceAthlete
 */
export interface DeviceAthlete {
    /**
     * 
     * @type {string}
     * @memberof DeviceAthlete
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceAthlete
     */
    'athlete_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof DeviceAthlete
     */
    'device_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceAthlete
     */
    'start_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceAthlete
     */
    'end_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceAthlete
     */
    'current'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceAthlete
     */
    'modified_at'?: string;
}
/**
 * 
 * @export
 * @interface DeviceInfo
 */
export interface DeviceInfo {
    /**
     * 
     * @type {number}
     * @memberof DeviceInfo
     */
    'device_id': number;
    /**
     * 
     * @type {Array<ActivityDictionary>}
     * @memberof DeviceInfo
     */
    'activity_dictionaries': Array<ActivityDictionary>;
    /**
     * 
     * @type {string}
     * @memberof DeviceInfo
     */
    'athlete_id'?: string;
}
/**
 * 
 * @export
 * @interface Efforts
 */
export interface Efforts {
    /**
     * 
     * @type {string}
     * @memberof Efforts
     */
    'athlete_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Efforts
     */
    'device_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Efforts
     */
    'player_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Efforts
     */
    'athlete_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Efforts
     */
    'athlete_last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Efforts
     */
    'jersey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Efforts
     */
    'team_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Efforts
     */
    'team_name'?: string;
    /**
     * 
     * @type {EffortsData}
     * @memberof Efforts
     */
    'data'?: EffortsData;
}
/**
 * 
 * @export
 * @interface EffortsData
 */
export interface EffortsData {
    /**
     * 
     * @type {Array<EffortsDataVelocityEfforts>}
     * @memberof EffortsData
     */
    'velocity_efforts'?: Array<EffortsDataVelocityEfforts>;
    /**
     * 
     * @type {Array<EffortsDataAccelerationEfforts>}
     * @memberof EffortsData
     */
    'acceleration_efforts'?: Array<EffortsDataAccelerationEfforts>;
}
/**
 * 
 * @export
 * @interface EffortsDataAccelerationEfforts
 */
export interface EffortsDataAccelerationEfforts {
    /**
     * 
     * @type {number}
     * @memberof EffortsDataAccelerationEfforts
     */
    'start_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof EffortsDataAccelerationEfforts
     */
    'end_time'?: number;
    /**
     * 
     * @type {string}
     * @memberof EffortsDataAccelerationEfforts
     */
    'band'?: string;
    /**
     * 
     * @type {number}
     * @memberof EffortsDataAccelerationEfforts
     */
    'acceleration'?: number;
    /**
     * 
     * @type {number}
     * @memberof EffortsDataAccelerationEfforts
     */
    'distance'?: number;
}
/**
 * 
 * @export
 * @interface EffortsDataVelocityEfforts
 */
export interface EffortsDataVelocityEfforts {
    /**
     * 
     * @type {number}
     * @memberof EffortsDataVelocityEfforts
     */
    'start_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof EffortsDataVelocityEfforts
     */
    'end_time'?: number;
    /**
     * 
     * @type {string}
     * @memberof EffortsDataVelocityEfforts
     */
    'band'?: string;
    /**
     * 
     * @type {number}
     * @memberof EffortsDataVelocityEfforts
     */
    'start_velocity'?: number;
    /**
     * 
     * @type {number}
     * @memberof EffortsDataVelocityEfforts
     */
    'end_velocity'?: number;
    /**
     * 
     * @type {number}
     * @memberof EffortsDataVelocityEfforts
     */
    'max_velocity'?: number;
    /**
     * 
     * @type {number}
     * @memberof EffortsDataVelocityEfforts
     */
    'distance'?: number;
}
/**
 * 
 * @export
 * @interface EncryptionKeys
 */
export interface EncryptionKeys {
    /**
     * 
     * @type {string}
     * @memberof EncryptionKeys
     */
    'public_key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EncryptionKeys
     */
    'private_key'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EncryptionKeys
     */
    'root'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EncryptionKeys
     */
    'uid'?: string | null;
}
/**
 * 
 * @export
 * @interface EntityTagRequestObject
 */
export interface EntityTagRequestObject {
    /**
     * Entity id to be tagged
     * @type {string}
     * @memberof EntityTagRequestObject
     */
    'table_id'?: string;
    /**
     * Entity type to be tagged
     * @type {string}
     * @memberof EntityTagRequestObject
     */
    'table_name'?: EntityTagRequestObjectTableNameEnum;
    /**
     * Tag ID
     * @type {string}
     * @memberof EntityTagRequestObject
     */
    'tag_id'?: string;
}

export const EntityTagRequestObjectTableNameEnum = {
    Activity: 'activity',
    ActivityAthlete: 'activity_athlete',
    Athlete: 'athlete',
    PeriodAthlete: 'period_athlete',
    Period: 'period',
    Position: 'position',
    Team: 'team',
    Venue: 'venue'
} as const;

export type EntityTagRequestObjectTableNameEnum = typeof EntityTagRequestObjectTableNameEnum[keyof typeof EntityTagRequestObjectTableNameEnum];

/**
 * 
 * @export
 * @interface Events
 */
export interface Events {
    /**
     * 
     * @type {string}
     * @memberof Events
     */
    'athlete_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Events
     */
    'device_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Events
     */
    'player_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Events
     */
    'athlete_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Events
     */
    'athlete_last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Events
     */
    'jersey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Events
     */
    'team_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Events
     */
    'team_name'?: string;
    /**
     * 
     * @type {EventsData}
     * @memberof Events
     */
    'data'?: EventsData;
}
/**
 * Events Data Model (ima_acceleration, ima_jump etc)
 * @export
 * @interface EventsData
 */
export interface EventsData {
    /**
     * 
     * @type {Array<EventsDataImaAcceleration>}
     * @memberof EventsData
     */
    'ima_acceleration'?: Array<EventsDataImaAcceleration>;
    /**
     * 
     * @type {Array<EventsDataImaAcceleration>}
     * @memberof EventsData
     */
    'ima_jump'?: Array<EventsDataImaAcceleration>;
}
/**
 * 
 * @export
 * @interface EventsDataImaAcceleration
 */
export interface EventsDataImaAcceleration {
    /**
     * 
     * @type {number}
     * @memberof EventsDataImaAcceleration
     */
    'start_time'?: number;
    /**
     * 
     * @type {number}
     * @memberof EventsDataImaAcceleration
     */
    'end_time'?: number;
    /**
     * 
     * @type {string}
     * @memberof EventsDataImaAcceleration
     */
    'version'?: string;
    /**
     * 
     * @type {number}
     * @memberof EventsDataImaAcceleration
     */
    'height'?: number;
}
/**
 * 
 * @export
 * @interface Fields
 */
export interface Fields {
    /**
     * 
     * @type {string}
     * @memberof Fields
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Fields
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface FrontendConfig
 */
export interface FrontendConfig {
    /**
     * 
     * @type {AnalyticsConfig}
     * @memberof FrontendConfig
     */
    'analytics': AnalyticsConfig;
    /**
     * 
     * @type {FrontendConfigApp}
     * @memberof FrontendConfig
     */
    'app': FrontendConfigApp;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrontendConfig
     */
    'features': Array<FrontendConfigFeaturesEnum>;
}

export const FrontendConfigFeaturesEnum = {
    EditorZoom: 'EditorZoom',
    FullAthleteBands: 'FullAthleteBands',
    FullAthleteForm: 'FullAthleteForm',
    FullMyAccountForm: 'FullMyAccountForm',
    FullTeamForm: 'FullTeamForm',
    LimitedActivityAdmin: 'LimitedActivityAdmin',
    LimitedSettings: 'LimitedSettings',
    MyAccountReactJs: 'MyAccountReactJs',
    ShowAthleteDob: 'ShowAthleteDob',
    ShowCoreOnboarding: 'ShowCoreOnboarding',
    ShowDownloads: 'ShowDownloads',
    ShowEditor: 'ShowEditor',
    ShowLeague: 'ShowLeague',
    ShowReporting: 'ShowReporting',
    ShowTimeline: 'ShowTimeline',
    ShowCoreHelpCenter: 'ShowCoreHelpCenter',
    ShowUpdates: 'ShowUpdates',
    ShowConnections: 'ShowConnections',
    ProReporting: 'ProReporting',
    ShowSystemAverages: 'ShowSystemAverages',
    ShowAnnotationsImport: 'ShowAnnotationsImport',
    ShowUserAndPermissions: 'ShowUserAndPermissions',
    MissingActivityDataBanner: 'MissingActivityDataBanner'
} as const;

export type FrontendConfigFeaturesEnum = typeof FrontendConfigFeaturesEnum[keyof typeof FrontendConfigFeaturesEnum];

/**
 * 
 * @export
 * @interface FrontendConfigApp
 */
export interface FrontendConfigApp {
    /**
     * 
     * @type {string}
     * @memberof FrontendConfigApp
     */
    'login_info_message'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfigApp
     */
    'aws_default_region'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendConfigApp
     */
    'icp_information'?: string | null;
}
/**
 * 
 * @export
 * @interface GetInjectionTemplateRequest
 */
export interface GetInjectionTemplateRequest {
    /**
     * Activity id
     * @type {string}
     * @memberof GetInjectionTemplateRequest
     */
    'activity_id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetInjectionTemplateRequest
     */
    'parameters': Array<string>;
}
/**
 * 
 * @export
 * @interface ImportGamesRequest
 */
export interface ImportGamesRequest {
    /**
     * array of connectorData Activity EntityTags
     * @type {Array<string>}
     * @memberof ImportGamesRequest
     */
    'connectorDataTags'?: Array<string>;
}
/**
 * 
 * @export
 * @interface InjectedActivityCreateRequest
 */
export interface InjectedActivityCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof InjectedActivityCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof InjectedActivityCreateRequest
     */
    'start_time': number;
    /**
     * 
     * @type {number}
     * @memberof InjectedActivityCreateRequest
     */
    'end_time': number;
    /**
     * 
     * @type {string}
     * @memberof InjectedActivityCreateRequest
     */
    'home_team_id'?: string;
    /**
     * List of team ids for Activity
     * @type {Array<string>}
     * @memberof InjectedActivityCreateRequest
     */
    'team_ids'?: Array<string>;
    /**
     * 
     * @type {Array<InjectedActivityCreateRequestPeriods>}
     * @memberof InjectedActivityCreateRequest
     */
    'periods': Array<InjectedActivityCreateRequestPeriods>;
}
/**
 * 
 * @export
 * @interface InjectedActivityCreateRequestPeriods
 */
export interface InjectedActivityCreateRequestPeriods {
    /**
     * 
     * @type {string}
     * @memberof InjectedActivityCreateRequestPeriods
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof InjectedActivityCreateRequestPeriods
     */
    'start_time': number;
    /**
     * 
     * @type {number}
     * @memberof InjectedActivityCreateRequestPeriods
     */
    'end_time': number;
    /**
     * Athlete IDs
     * @type {Array<string>}
     * @memberof InjectedActivityCreateRequestPeriods
     */
    'athletes': Array<string>;
}
/**
 * 
 * @export
 * @interface InjectedActivityUpdateRequest
 */
export interface InjectedActivityUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof InjectedActivityUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof InjectedActivityUpdateRequest
     */
    'start_time': number;
    /**
     * 
     * @type {number}
     * @memberof InjectedActivityUpdateRequest
     */
    'end_time': number;
    /**
     * 
     * @type {Array<InjectedActivityUpdateRequestPeriods>}
     * @memberof InjectedActivityUpdateRequest
     */
    'periods': Array<InjectedActivityUpdateRequestPeriods>;
}
/**
 * 
 * @export
 * @interface InjectedActivityUpdateRequestPeriods
 */
export interface InjectedActivityUpdateRequestPeriods {
    /**
     * 
     * @type {string}
     * @memberof InjectedActivityUpdateRequestPeriods
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof InjectedActivityUpdateRequestPeriods
     */
    'start_time': number;
    /**
     * 
     * @type {number}
     * @memberof InjectedActivityUpdateRequestPeriods
     */
    'end_time': number;
    /**
     * Athlete IDs
     * @type {Array<string>}
     * @memberof InjectedActivityUpdateRequestPeriods
     */
    'athletes': Array<string>;
}
/**
 * 
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
    /**
     * Array of athlete IDs
     * @type {Array<string>}
     * @memberof InlineObject
     */
    'athlete_ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface InlineObject1
 */
export interface InlineObject1 {
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    'activity_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof InlineObject1
     */
    'opponent_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof InlineObject1
     */
    'our_score'?: number;
    /**
     * 
     * @type {number}
     * @memberof InlineObject1
     */
    'opponent_score'?: number;
    /**
     * 
     * @type {MatchLocationEnum}
     * @memberof InlineObject1
     */
    'location'?: MatchLocationEnum;
    /**
     * 
     * @type {object}
     * @memberof InlineObject1
     */
    'matchday_opponent'?: object;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * Return highest version of all user accounts.
     * @type {number}
     * @memberof InlineResponse200
     */
    'highest_version'?: number;
    /**
     * Return highest version of all user accounts.
     * @type {Array<User>}
     * @memberof InlineResponse200
     */
    'users'?: Array<User>;
}
/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * Number of records deleted
     * @type {number}
     * @memberof InlineResponse2001
     */
    'deletedCount': number;
}
/**
 * 
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
    /**
     * Activity id
     * @type {string}
     * @memberof InlineResponse2002
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
    /**
     * Semantic version including build number
     * @type {string}
     * @memberof InlineResponse2003
     */
    'version': string;
}
/**
 * 
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
    /**
     * 
     * @type {string}
     * @memberof InlineResponse2004
     */
    'id'?: string;
}
/**
 * 
 * @export
 * @interface LeagueActivityDetail
 */
export interface LeagueActivityDetail {
    /**
     * 
     * @type {LeagueActivityWithResults}
     * @memberof LeagueActivityDetail
     */
    'activity'?: LeagueActivityWithResults;
    /**
     * 
     * @type {Array<LeaguePeriodWithResults>}
     * @memberof LeagueActivityDetail
     */
    'periods'?: Array<LeaguePeriodWithResults>;
    /**
     * 
     * @type {Customer}
     * @memberof LeagueActivityDetail
     */
    'child'?: Customer;
    /**
     * 
     * @type {Activity}
     * @memberof LeagueActivityDetail
     */
    'parent_activity'?: Activity;
    /**
     * 
     * @type {Customer}
     * @memberof LeagueActivityDetail
     */
    'parent'?: Customer;
    /**
     * 
     * @type {string}
     * @memberof LeagueActivityDetail
     */
    'time_format'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeagueActivityDetail
     */
    'annotations'?: Array<string>;
}
/**
 * 
 * @export
 * @interface LeagueActivityWithResults
 */
export interface LeagueActivityWithResults {
    /**
     * 
     * @type {string}
     * @memberof LeagueActivityWithResults
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LeagueActivityWithResults
     */
    'venue_field_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeagueActivityWithResults
     */
    'period_depth_type_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeagueActivityWithResults
     */
    'sport_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeagueActivityWithResults
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof LeagueActivityWithResults
     */
    'start_time': number;
    /**
     * 
     * @type {number}
     * @memberof LeagueActivityWithResults
     */
    'end_time': number;
    /**
     * 
     * @type {string}
     * @memberof LeagueActivityWithResults
     */
    'timezone'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeagueActivityWithResults
     */
    'is_synced'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeagueActivityWithResults
     */
    'is_deleted'?: number;
    /**
     * 
     * @type {string}
     * @memberof LeagueActivityWithResults
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeagueActivityWithResults
     */
    'modified_at': string;
    /**
     * 
     * @type {string}
     * @memberof LeagueActivityWithResults
     */
    'process_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeagueActivityWithResults
     */
    'bake_status'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeagueActivityWithResults
     */
    'data_start'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeagueActivityWithResults
     */
    'data_end'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeagueActivityWithResults
     */
    'is_injected': number;
    /**
     * 
     * @type {string}
     * @memberof LeagueActivityWithResults
     */
    'owner_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeagueActivityWithResults
     */
    'athlete_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeagueActivityWithResults
     */
    'period_count'?: number;
    /**
     * 
     * @type {User}
     * @memberof LeagueActivityWithResults
     */
    'owner'?: User;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeagueActivityWithResults
     */
    'tag_list'?: Array<string>;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof LeagueActivityWithResults
     */
    'tags'?: Array<TagItem>;
    /**
     * 
     * @type {Team}
     * @memberof LeagueActivityWithResults
     */
    'home_team'?: Team;
    /**
     * 
     * @type {Array<Team>}
     * @memberof LeagueActivityWithResults
     */
    'teams'?: Array<Team>;
    /**
     * 
     * @type {ActivityListItemVenue}
     * @memberof LeagueActivityWithResults
     */
    'venue'?: ActivityListItemVenue;
    /**
     * 
     * @type {Array<Period>}
     * @memberof LeagueActivityWithResults
     */
    'periods'?: Array<Period>;
    /**
     * 
     * @type {Array<Athlete>}
     * @memberof LeagueActivityWithResults
     */
    'athletes'?: Array<Athlete>;
    /**
     * 
     * @type {ActDicStatuses}
     * @memberof LeagueActivityWithResults
     */
    'actdic_status'?: ActDicStatuses;
    /**
     * 
     * @type {string}
     * @memberof LeagueActivityWithResults
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeagueActivityWithResults
     */
    'status_desc'?: string;
    /**
     * 
     * @type {Array<StatRow>}
     * @memberof LeagueActivityWithResults
     */
    'results'?: Array<StatRow>;
}
/**
 * 
 * @export
 * @interface LeagueActivityWithResultsAllOf
 */
export interface LeagueActivityWithResultsAllOf {
    /**
     * 
     * @type {string}
     * @memberof LeagueActivityWithResultsAllOf
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeagueActivityWithResultsAllOf
     */
    'status_desc'?: string;
    /**
     * 
     * @type {Array<StatRow>}
     * @memberof LeagueActivityWithResultsAllOf
     */
    'results'?: Array<StatRow>;
}
/**
 * 
 * @export
 * @interface LeagueMigration
 */
export interface LeagueMigration {
    /**
     * migrate_job.id
     * @type {string}
     * @memberof LeagueMigration
     */
    'id': string;
    /**
     * 
     * @type {Activity}
     * @memberof LeagueMigration
     */
    'activity': Activity;
    /**
     * 
     * @type {string}
     * @memberof LeagueMigration
     */
    'cloudbaker_queue_location'?: string;
    /**
     * 
     * @type {Customer}
     * @memberof LeagueMigration
     */
    'customer': Customer;
    /**
     * 
     * @type {number}
     * @memberof LeagueMigration
     */
    'customer_id': number;
    /**
     * 
     * @type {string}
     * @memberof LeagueMigration
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof LeagueMigration
     */
    'modified_at': string;
    /**
     * 
     * @type {number}
     * @memberof LeagueMigration
     */
    'percentage': number;
    /**
     * 
     * @type {string}
     * @memberof LeagueMigration
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof LeagueMigration
     */
    'status_message'?: string | null;
}
/**
 * 
 * @export
 * @interface LeagueMigrationParameters
 */
export interface LeagueMigrationParameters {
    /**
     * 
     * @type {Array<string>}
     * @memberof LeagueMigrationParameters
     */
    'activity_ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface LeagueMigrations
 */
export interface LeagueMigrations {
    /**
     * 
     * @type {Array<LeagueMigration>}
     * @memberof LeagueMigrations
     */
    'running_migrations': Array<LeagueMigration>;
}
/**
 * 
 * @export
 * @interface LeaguePeriodWithResults
 */
export interface LeaguePeriodWithResults {
    /**
     * 
     * @type {string}
     * @memberof LeaguePeriodWithResults
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LeaguePeriodWithResults
     */
    'activity_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaguePeriodWithResults
     */
    'period_depth_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaguePeriodWithResults
     */
    'name': string;
    /**
     * Epoch Timestamp, seconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof LeaguePeriodWithResults
     */
    'start_time': number;
    /**
     * Centiseconds part of epoch timestamp (hundredths of a second, a number between 0 and 99)
     * @type {number}
     * @memberof LeaguePeriodWithResults
     */
    'start_centiseconds'?: number;
    /**
     * Epoch Timestamp, seconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof LeaguePeriodWithResults
     */
    'end_time': number;
    /**
     * Centiseconds part of epoch timestamp (hundredths of a second, a number between 0 and 99)
     * @type {number}
     * @memberof LeaguePeriodWithResults
     */
    'end_centiseconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeaguePeriodWithResults
     */
    'lft'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeaguePeriodWithResults
     */
    'rgt'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeaguePeriodWithResults
     */
    'is_synced'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeaguePeriodWithResults
     */
    'is_deleted'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeaguePeriodWithResults
     */
    'is_injected'?: number;
    /**
     * 
     * @type {string}
     * @memberof LeaguePeriodWithResults
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaguePeriodWithResults
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeaguePeriodWithResults
     */
    'user_id'?: string | null;
    /**
     * 
     * @type {Array<PeriodAthlete>}
     * @memberof LeaguePeriodWithResults
     */
    'period_athletes'?: Array<PeriodAthlete>;
    /**
     * 
     * @type {Array<StatRow>}
     * @memberof LeaguePeriodWithResults
     */
    'results'?: Array<StatRow>;
}
/**
 * 
 * @export
 * @interface LeaguePeriodWithResultsAllOf
 */
export interface LeaguePeriodWithResultsAllOf {
    /**
     * 
     * @type {Array<StatRow>}
     * @memberof LeaguePeriodWithResultsAllOf
     */
    'results'?: Array<StatRow>;
}
/**
 * 
 * @export
 * @interface LeagueRefreshActiveMigrationParameters
 */
export interface LeagueRefreshActiveMigrationParameters {
    /**
     * 
     * @type {Array<string>}
     * @memberof LeagueRefreshActiveMigrationParameters
     */
    'activity_ids': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof LeagueRefreshActiveMigrationParameters
     */
    'childId'?: number | null;
}
/**
 * 
 * @export
 * @interface LeagueTeamActivity
 */
export interface LeagueTeamActivity {
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivity
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivity
     */
    'venue_field_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivity
     */
    'period_depth_type_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivity
     */
    'sport_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivity
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof LeagueTeamActivity
     */
    'start_time': number;
    /**
     * 
     * @type {number}
     * @memberof LeagueTeamActivity
     */
    'end_time': number;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivity
     */
    'timezone'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeagueTeamActivity
     */
    'is_synced'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeagueTeamActivity
     */
    'is_deleted'?: number;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivity
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivity
     */
    'modified_at': string;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivity
     */
    'process_status'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivity
     */
    'bake_status'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeagueTeamActivity
     */
    'data_start'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeagueTeamActivity
     */
    'data_end'?: number;
    /**
     * 
     * @type {number}
     * @memberof LeagueTeamActivity
     */
    'is_injected': number;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivity
     */
    'owner_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeagueTeamActivity
     */
    'athlete_count': number;
    /**
     * 
     * @type {number}
     * @memberof LeagueTeamActivity
     */
    'period_count': number;
    /**
     * 
     * @type {User}
     * @memberof LeagueTeamActivity
     */
    'owner'?: User;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeagueTeamActivity
     */
    'tag_list'?: Array<string>;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof LeagueTeamActivity
     */
    'tags'?: Array<TagItem>;
    /**
     * 
     * @type {Team}
     * @memberof LeagueTeamActivity
     */
    'home_team'?: Team;
    /**
     * 
     * @type {Array<Team>}
     * @memberof LeagueTeamActivity
     */
    'teams'?: Array<Team>;
    /**
     * 
     * @type {ActivityListItemVenue}
     * @memberof LeagueTeamActivity
     */
    'venue'?: ActivityListItemVenue;
    /**
     * 
     * @type {Array<Period>}
     * @memberof LeagueTeamActivity
     */
    'periods'?: Array<Period>;
    /**
     * 
     * @type {Array<Athlete>}
     * @memberof LeagueTeamActivity
     */
    'athletes'?: Array<Athlete>;
    /**
     * 
     * @type {ActDicStatuses}
     * @memberof LeagueTeamActivity
     */
    'actdic_status'?: ActDicStatuses;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivity
     */
    'activity_date': string;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivity
     */
    'last_fullsync_time': string | null;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivity
     */
    'last_fullsync_time_ago'?: string;
    /**
     * 
     * @type {Array<ActivityDictionary>}
     * @memberof LeagueTeamActivity
     */
    'child_documents': Array<ActivityDictionary>;
    /**
     * 
     * @type {Array<ActivityDictionary>}
     * @memberof LeagueTeamActivity
     */
    'parent_documents': Array<ActivityDictionary>;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivity
     */
    'migration_status': string;
    /**
     * 
     * @type {number}
     * @memberof LeagueTeamActivity
     */
    'master_athlete_count': number;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivity
     */
    'migrate_job_id': string | null;
    /**
     * 
     * @type {number}
     * @memberof LeagueTeamActivity
     */
    'annotation_count': number;
}
/**
 * 
 * @export
 * @interface LeagueTeamActivityAllOf
 */
export interface LeagueTeamActivityAllOf {
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivityAllOf
     */
    'activity_date': string;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivityAllOf
     */
    'last_fullsync_time': string | null;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivityAllOf
     */
    'last_fullsync_time_ago'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeagueTeamActivityAllOf
     */
    'athlete_count': number;
    /**
     * 
     * @type {Array<ActivityDictionary>}
     * @memberof LeagueTeamActivityAllOf
     */
    'child_documents': Array<ActivityDictionary>;
    /**
     * 
     * @type {Array<ActivityDictionary>}
     * @memberof LeagueTeamActivityAllOf
     */
    'parent_documents': Array<ActivityDictionary>;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivityAllOf
     */
    'migration_status': string;
    /**
     * 
     * @type {number}
     * @memberof LeagueTeamActivityAllOf
     */
    'master_athlete_count': number;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamActivityAllOf
     */
    'migrate_job_id': string | null;
    /**
     * 
     * @type {number}
     * @memberof LeagueTeamActivityAllOf
     */
    'period_count': number;
    /**
     * 
     * @type {number}
     * @memberof LeagueTeamActivityAllOf
     */
    'annotation_count': number;
}
/**
 * 
 * @export
 * @interface LeagueTeamChild
 */
export interface LeagueTeamChild {
    /**
     * 
     * @type {Customer}
     * @memberof LeagueTeamChild
     */
    'customer': Customer;
    /**
     * 
     * @type {Activity}
     * @memberof LeagueTeamChild
     */
    'last_activity'?: Activity;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamChild
     */
    'last_activity_time'?: string;
    /**
     * 
     * @type {Activity}
     * @memberof LeagueTeamChild
     */
    'last_modified_activity'?: Activity;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamChild
     */
    'last_sync_time': string;
    /**
     * 
     * @type {string}
     * @memberof LeagueTeamChild
     */
    'latest_game_status': string;
    /**
     * 
     * @type {number}
     * @memberof LeagueTeamChild
     */
    'total_activities': number;
    /**
     * 
     * @type {number}
     * @memberof LeagueTeamChild
     */
    'total_migrations': number;
}
/**
 * 
 * @export
 * @interface LeagueTeamDetail
 */
export interface LeagueTeamDetail {
    /**
     * 
     * @type {Array<LeagueTeamActivity>}
     * @memberof LeagueTeamDetail
     */
    'activities': Array<LeagueTeamActivity>;
    /**
     * 
     * @type {Customer}
     * @memberof LeagueTeamDetail
     */
    'customer': Customer;
}
/**
 * 
 * @export
 * @interface LeagueTeams
 */
export interface LeagueTeams {
    /**
     * 
     * @type {Array<LeagueTeamChild>}
     * @memberof LeagueTeams
     */
    'children': Array<LeagueTeamChild>;
}
/**
 * 
 * @export
 * @interface LiveAthlete
 */
export interface LiveAthlete {
    /**
     * 
     * @type {string}
     * @memberof LiveAthlete
     */
    'athlete_id': string;
    /**
     * 
     * @type {string}
     * @memberof LiveAthlete
     */
    'athlete_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof LiveAthlete
     */
    'athlete_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof LiveAthlete
     */
    'jersey': string;
    /**
     * 
     * @type {string}
     * @memberof LiveAthlete
     */
    'team_id': string;
    /**
     * 
     * @type {string}
     * @memberof LiveAthlete
     */
    'team_name': string;
    /**
     * 
     * @type {string}
     * @memberof LiveAthlete
     */
    'team_slug': string;
    /**
     * 
     * @type {string}
     * @memberof LiveAthlete
     */
    'position_id': string;
    /**
     * 
     * @type {string}
     * @memberof LiveAthlete
     */
    'position_name': string;
    /**
     * 
     * @type {string}
     * @memberof LiveAthlete
     */
    'period_id': string;
    /**
     * 
     * @type {string}
     * @memberof LiveAthlete
     */
    'period_name': string;
    /**
     * 
     * @type {string}
     * @memberof LiveAthlete
     */
    'league_id': string;
    /**
     * 
     * @type {number}
     * @memberof LiveAthlete
     */
    'field_seconds': number;
    /**
     * 
     * @type {number}
     * @memberof LiveAthlete
     */
    'heart_rate': number;
    /**
     * 
     * @type {number}
     * @memberof LiveAthlete
     */
    'mean_heart_rate': number;
    /**
     * 
     * @type {number}
     * @memberof LiveAthlete
     */
    'max_heart_rate': number;
    /**
     * Time spent in each heart rate band, in seconds.
     * @type {Array<number>}
     * @memberof LiveAthlete
     */
    'heart_rate_durations': Array<number>;
    /**
     * Distance covered, in units according to OpenField account settings.
     * @type {number}
     * @memberof LiveAthlete
     */
    'total_distance': number;
    /**
     * Distance covered, in metres.
     * @type {number}
     * @memberof LiveAthlete
     */
    'meterage': number;
    /**
     * Velocity, in units according to OpenField account settings.
     * @type {number}
     * @memberof LiveAthlete
     */
    'velocity': number;
    /**
     * Max velocity, in units according to OpenField account settings.
     * @type {object}
     * @memberof LiveAthlete
     */
    'max_velocity': object;
    /**
     * Distance covered in each velocity band, in units according to OpenField account settings .
     * @type {Array<number>}
     * @memberof LiveAthlete
     */
    'velocity_band_distances': Array<number>;
    /**
     * Time spent in each velocity band, in seconds.
     * @type {Array<number>}
     * @memberof LiveAthlete
     */
    'velocity_band_durations': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof LiveAthlete
     */
    'velocity_efforts': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof LiveAthlete
     */
    'acceleration_efforts': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof LiveAthlete
     */
    'total_player_load': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof LiveAthlete
     */
    'player_load_totals': Array<number>;
    /**
     * 
     * @type {number}
     * @memberof LiveAthlete
     */
    'profile_max_player_load': number;
    /**
     * 
     * @type {number}
     * @memberof LiveAthlete
     */
    'profile_max_player_load_per_minute': number;
    /**
     * 
     * @type {number}
     * @memberof LiveAthlete
     */
    'profile_percentage_max_player_load': number;
    /**
     * 
     * @type {number}
     * @memberof LiveAthlete
     */
    'profile_percentage_max_player_load_per_minute': number;
    /**
     * 
     * @type {number}
     * @memberof LiveAthlete
     */
    'step_count': number;
}
/**
 * 
 * @export
 * @interface LiveInfo
 */
export interface LiveInfo {
    /**
     * 
     * @type {Array<LiveInfoPeriods>}
     * @memberof LiveInfo
     */
    'periods': Array<LiveInfoPeriods>;
    /**
     * 
     * @type {string}
     * @memberof LiveInfo
     */
    'activityId'?: string;
    /**
     * 
     * @type {string}
     * @memberof LiveInfo
     */
    'activityName'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LiveInfo
     */
    'isActivityFinished'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof LiveInfo
     */
    'speedUnit'?: LiveInfoSpeedUnitEnum;
    /**
     * 
     * @type {string}
     * @memberof LiveInfo
     */
    'distanceUnit'?: LiveInfoDistanceUnitEnum;
}

export const LiveInfoSpeedUnitEnum = {
    MetersPerSecond: 'MetersPerSecond',
    KilometersPerHour: 'KilometersPerHour',
    YardsPerSecond: 'YardsPerSecond',
    MilesPerHour: 'MilesPerHour'
} as const;

export type LiveInfoSpeedUnitEnum = typeof LiveInfoSpeedUnitEnum[keyof typeof LiveInfoSpeedUnitEnum];
export const LiveInfoDistanceUnitEnum = {
    Meters: 'Meters',
    Kilometers: 'Kilometers',
    Yards: 'Yards',
    Miles: 'Miles'
} as const;

export type LiveInfoDistanceUnitEnum = typeof LiveInfoDistanceUnitEnum[keyof typeof LiveInfoDistanceUnitEnum];

/**
 * 
 * @export
 * @interface LiveInfoPeriods
 */
export interface LiveInfoPeriods {
    /**
     * 
     * @type {string}
     * @memberof LiveInfoPeriods
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof LiveInfoPeriods
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof LiveInfoPeriods
     */
    'start_time': number;
    /**
     * 
     * @type {number}
     * @memberof LiveInfoPeriods
     */
    'end_time': number;
    /**
     * 
     * @type {number}
     * @memberof LiveInfoPeriods
     */
    'duration': number;
}
/**
 * 
 * @export
 * @interface Match
 */
export interface Match {
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    'activity_id': string;
    /**
     * 
     * @type {string}
     * @memberof Match
     */
    'opponent_id': string;
    /**
     * 
     * @type {number}
     * @memberof Match
     */
    'our_score': number;
    /**
     * 
     * @type {number}
     * @memberof Match
     */
    'opponent_score': number;
    /**
     * 
     * @type {MatchLocationEnum}
     * @memberof Match
     */
    'location': MatchLocationEnum;
    /**
     * 
     * @type {object}
     * @memberof Match
     */
    'matchday_opponent': object;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MatchLocationEnum = {
    Home: 'home',
    Away: 'away',
    Neutral: 'neutral'
} as const;

export type MatchLocationEnum = typeof MatchLocationEnum[keyof typeof MatchLocationEnum];


/**
 * 
 * @export
 * @interface MatchOpponent
 */
export interface MatchOpponent {
    /**
     * 
     * @type {string}
     * @memberof MatchOpponent
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatchOpponent
     */
    'opponent_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatchOpponent
     */
    'opponent_logo_url'?: string;
}
/**
 * 
 * @export
 * @interface MatchRequest
 */
export interface MatchRequest {
    /**
     * 
     * @type {string}
     * @memberof MatchRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatchRequest
     */
    'activity_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatchRequest
     */
    'opponent_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof MatchRequest
     */
    'our_score'?: number;
    /**
     * 
     * @type {number}
     * @memberof MatchRequest
     */
    'opponent_score'?: number;
    /**
     * 
     * @type {MatchLocationEnum}
     * @memberof MatchRequest
     */
    'location'?: MatchLocationEnum;
    /**
     * 
     * @type {object}
     * @memberof MatchRequest
     */
    'matchday_opponent'?: object;
}
/**
 * 
 * @export
 * @interface MicroAuthResourceIdentifiedObject
 */
export interface MicroAuthResourceIdentifiedObject {
    /**
     * Resource Type
     * @type {string}
     * @memberof MicroAuthResourceIdentifiedObject
     */
    'type'?: string;
    /**
     * Resource Identifier
     * @type {string}
     * @memberof MicroAuthResourceIdentifiedObject
     */
    'identifier'?: string;
    /**
     * Authorisation To Read
     * @type {boolean}
     * @memberof MicroAuthResourceIdentifiedObject
     */
    'read'?: boolean;
}
/**
 * 
 * @export
 * @interface MicroAuthResourcesCheckRequest
 */
export interface MicroAuthResourcesCheckRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof MicroAuthResourcesCheckRequest
     */
    'user'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MicroAuthResourcesCheckRequest
     */
    'athlete'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MicroAuthResourcesCheckRequest
     */
    'activity'?: Array<string>;
}
/**
 * 
 * @export
 * @interface MicroAuthResourcesCheckResponse
 */
export interface MicroAuthResourcesCheckResponse {
    /**
     * 
     * @type {Array<MicroAuthResourceIdentifiedObject>}
     * @memberof MicroAuthResourcesCheckResponse
     */
    'user'?: Array<MicroAuthResourceIdentifiedObject>;
    /**
     * 
     * @type {Array<MicroAuthResourceIdentifiedObject>}
     * @memberof MicroAuthResourcesCheckResponse
     */
    'athlete'?: Array<MicroAuthResourceIdentifiedObject>;
    /**
     * 
     * @type {Array<MicroAuthResourceIdentifiedObject>}
     * @memberof MicroAuthResourcesCheckResponse
     */
    'activity'?: Array<MicroAuthResourceIdentifiedObject>;
}
/**
 * 
 * @export
 * @interface MicroAuthToken
 */
export interface MicroAuthToken {
    /**
     * 
     * @type {string}
     * @memberof MicroAuthToken
     */
    'jwt'?: string;
}
/**
 * 
 * @export
 * @interface MicroAuthTokenRequest
 */
export interface MicroAuthTokenRequest {
    /**
     * Scope Names
     * @type {Array<string>}
     * @memberof MicroAuthTokenRequest
     */
    'scopes': Array<string>;
}
/**
 * Notice Model
 * @export
 * @interface Notice
 */
export interface Notice {
    /**
     * 
     * @type {string}
     * @memberof Notice
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Notice
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Notice
     */
    'copy': string;
    /**
     * 
     * @type {string}
     * @memberof Notice
     */
    'image'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Notice
     */
    'notification_category_id': string;
    /**
     * 
     * @type {number}
     * @memberof Notice
     */
    'notified': number;
    /**
     * 
     * @type {number}
     * @memberof Notice
     */
    'notify_on_login': number;
    /**
     * 
     * @type {string}
     * @memberof Notice
     */
    'published_at': string;
    /**
     * 
     * @type {number}
     * @memberof Notice
     */
    'read': number;
    /**
     * 
     * @type {string}
     * @memberof Notice
     */
    'category': string | null;
}
/**
 * 
 * @export
 * @interface NoticeUpdateRequest
 */
export interface NoticeUpdateRequest {
    /**
     * 
     * @type {boolean}
     * @memberof NoticeUpdateRequest
     */
    'read'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NoticeUpdateRequest
     */
    'notified'?: boolean;
}
/**
 * Notification Model
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'type': NotificationTypeEnum;
    /**
     * 
     * @type {Array<Activity>}
     * @memberof Notification
     */
    'activities'?: Array<Activity>;
    /**
     * 
     * @type {Array<NotificationAthlete>}
     * @memberof Notification
     */
    'athletes'?: Array<NotificationAthlete>;
    /**
     * 
     * @type {Period}
     * @memberof Notification
     */
    'period'?: Period;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'notified': number;
    /**
     * 
     * @type {number}
     * @memberof Notification
     */
    'read': number;
    /**
     * 
     * @type {NotificationPayload}
     * @memberof Notification
     */
    'payload'?: NotificationPayload | null;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'created_at': string;
}

export const NotificationTypeEnum = {
    ActivityOwnership: 'activity_ownership',
    Info: 'info',
    NewActivity: 'new_activity',
    NewDownload: 'new_download',
    NewMaxHr: 'new_max_hr',
    NewMaxVel: 'new_max_vel',
    PasswordExpiring: 'password_expiring',
    Welcome: 'welcome'
} as const;

export type NotificationTypeEnum = typeof NotificationTypeEnum[keyof typeof NotificationTypeEnum];

/**
 * 
 * @export
 * @interface NotificationAthlete
 */
export interface NotificationAthlete {
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'last_name': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'jersey'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'nickname'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationAthlete
     */
    'height'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationAthlete
     */
    'weight'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationAthlete
     */
    'date_of_birth'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'date_of_birth_date': string;
    /**
     * 
     * @type {number}
     * @memberof NotificationAthlete
     */
    'velocity_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationAthlete
     */
    'acceleration_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationAthlete
     */
    'heart_rate_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationAthlete
     */
    'player_load_max'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'image_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'icon'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'stroke_colour'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'fill_colour'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'trail_colour_start'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'trail_colour_end'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationAthlete
     */
    'is_synced'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationAthlete
     */
    'is_deleted'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'modified_at'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationAthlete
     */
    'tag_list'?: Array<string>;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof NotificationAthlete
     */
    'tags'?: Array<TagItem>;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'current_team_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationAthlete
     */
    'max_player_load_per_minute'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'position': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'position_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationAthlete
     */
    'position_name': string;
}
/**
 * Notification IDs
 * @export
 * @interface NotificationIds
 */
export interface NotificationIds {
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationIds
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface NotificationPayload
 */
export interface NotificationPayload {
    /**
     * 
     * @type {number}
     * @memberof NotificationPayload
     */
    'days_left'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationPayload
     */
    'old_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof NotificationPayload
     */
    'new_max'?: number;
    /**
     * 
     * @type {string}
     * @memberof NotificationPayload
     */
    'unit'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationPayload
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationPayload
     */
    'download_installer_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationPayload
     */
    'download_installer_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationPayload
     */
    'download_installer_release_notes_url'?: string;
}
/**
 * 
 * @export
 * @interface NotificationsDeleteRequest
 */
export interface NotificationsDeleteRequest {
    /**
     * List of IDs to delete
     * @type {Array<string>}
     * @memberof NotificationsDeleteRequest
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface NotificationsUpdateRequest
 */
export interface NotificationsUpdateRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationsUpdateRequest
     */
    'ids': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsUpdateRequest
     */
    'read'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NotificationsUpdateRequest
     */
    'notified'?: boolean;
}
/**
 * OAuth Authorize Data Response
 * @export
 * @interface OAuthAuthorizeDataResponse
 */
export interface OAuthAuthorizeDataResponse {
    /**
     * 
     * @type {string}
     * @memberof OAuthAuthorizeDataResponse
     */
    'result'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthAuthorizeDataResponse
     */
    'message'?: string;
    /**
     * 
     * @type {OAuthAuthorizeDataResponseData}
     * @memberof OAuthAuthorizeDataResponse
     */
    'data'?: OAuthAuthorizeDataResponseData;
}
/**
 * 
 * @export
 * @interface OAuthAuthorizeDataResponseData
 */
export interface OAuthAuthorizeDataResponseData {
    /**
     * 
     * @type {OAuthClient}
     * @memberof OAuthAuthorizeDataResponseData
     */
    'client'?: OAuthClient;
    /**
     * 
     * @type {OAuthUser}
     * @memberof OAuthAuthorizeDataResponseData
     */
    'user'?: OAuthUser;
    /**
     * 
     * @type {Array<OAuthScope>}
     * @memberof OAuthAuthorizeDataResponseData
     */
    'scopes'?: Array<OAuthScope>;
    /**
     * 
     * @type {object}
     * @memberof OAuthAuthorizeDataResponseData
     */
    'request'?: object;
    /**
     * 
     * @type {string}
     * @memberof OAuthAuthorizeDataResponseData
     */
    'authToken'?: string;
}
/**
 * OAuth Authorize Error Response
 * @export
 * @interface OAuthAuthorizeErrorResponse
 */
export interface OAuthAuthorizeErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof OAuthAuthorizeErrorResponse
     */
    'result'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthAuthorizeErrorResponse
     */
    'message'?: string;
    /**
     * 
     * @type {object}
     * @memberof OAuthAuthorizeErrorResponse
     */
    'data'?: object;
}
/**
 * OAuth Authorize Token Request
 * @export
 * @interface OAuthAuthorizeTokenRequest
 */
export interface OAuthAuthorizeTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof OAuthAuthorizeTokenRequest
     */
    'auth_token'?: string;
}
/**
 * OAuth Authorize Token Response
 * @export
 * @interface OAuthAuthorizeTokenResponse
 */
export interface OAuthAuthorizeTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof OAuthAuthorizeTokenResponse
     */
    'result'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthAuthorizeTokenResponse
     */
    'message'?: string;
    /**
     * 
     * @type {OAuthAuthorizeTokenResponseData}
     * @memberof OAuthAuthorizeTokenResponse
     */
    'data'?: OAuthAuthorizeTokenResponseData;
}
/**
 * 
 * @export
 * @interface OAuthAuthorizeTokenResponseData
 */
export interface OAuthAuthorizeTokenResponseData {
    /**
     * 
     * @type {string}
     * @memberof OAuthAuthorizeTokenResponseData
     */
    'url'?: string;
}
/**
 * OAuthClient Model
 * @export
 * @interface OAuthClient
 */
export interface OAuthClient {
    /**
     * 
     * @type {string}
     * @memberof OAuthClient
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthClient
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthClient
     */
    'domain'?: string;
}
/**
 * OAuthResponse Model
 * @export
 * @interface OAuthResponse
 */
export interface OAuthResponse {
    /**
     * 
     * @type {number}
     * @memberof OAuthResponse
     */
    'expires'?: number;
    /**
     * 
     * @type {number}
     * @memberof OAuthResponse
     */
    'expires_at'?: number;
    /**
     * 
     * @type {string}
     * @memberof OAuthResponse
     */
    'user_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthResponse
     */
    'token_type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OAuthResponse
     */
    'expires_in'?: number;
    /**
     * 
     * @type {string}
     * @memberof OAuthResponse
     */
    'access_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthResponse
     */
    'refresh_token'?: string;
}
/**
 * OAuthScope Model
 * @export
 * @interface OAuthScope
 */
export interface OAuthScope {
    /**
     * 
     * @type {string}
     * @memberof OAuthScope
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthScope
     */
    'description'?: string;
}
/**
 * OAuthUser Model
 * @export
 * @interface OAuthUser
 */
export interface OAuthUser {
    /**
     * 
     * @type {string}
     * @memberof OAuthUser
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OAuthUser
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface Parameter
 */
export interface Parameter {
    /**
     * 
     * @type {string}
     * @memberof Parameter
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Parameter
     */
    'parameter_type_id': string;
    /**
     * 
     * @type {string}
     * @memberof Parameter
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Parameter
     */
    'original_name': string;
    /**
     * 
     * @type {string}
     * @memberof Parameter
     */
    'slug': string;
    /**
     * The type of units that will be used to display this parameter. Empty string indicates arbitrary units, for example player load.
     * @type {string}
     * @memberof Parameter
     */
    'unit_type'?: ParameterUnitTypeEnum;
    /**
     * Equation that defines how this parameter will be calculated
     * @type {string}
     * @memberof Parameter
     */
    'calculation'?: string;
    /**
     * Position of this parameter in CTR report
     * @type {number}
     * @memberof Parameter
     */
    'ctr_order': number;
    /**
     * 
     * @type {string}
     * @memberof Parameter
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Parameter
     */
    'modified_at': string;
}

export const ParameterUnitTypeEnum = {
    Abitrary: '',
    Count: 'count',
    Distance: 'distance',
    Duration: 'duration',
    Percentage: 'percentage',
    Speed: 'speed',
    Weight: 'weight'
} as const;

export type ParameterUnitTypeEnum = typeof ParameterUnitTypeEnum[keyof typeof ParameterUnitTypeEnum];

/**
 * 
 * @export
 * @interface ParameterCreateRequest
 */
export interface ParameterCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ParameterCreateRequest
     */
    'name': string;
    /**
     * Equation that defines how this parameter will be calculated
     * @type {string}
     * @memberof ParameterCreateRequest
     */
    'calculation': string;
    /**
     * 
     * @type {UnitType}
     * @memberof ParameterCreateRequest
     */
    'unit_type': UnitType;
    /**
     * Position of this parameter in CTR report
     * @type {number}
     * @memberof ParameterCreateRequest
     */
    'ctr_order'?: number;
}
/**
 * 
 * @export
 * @interface ParameterGroup
 */
export interface ParameterGroup {
    /**
     * 
     * @type {string}
     * @memberof ParameterGroup
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ParameterGroup
     */
    'name': string;
    /**
     * 
     * @type {Array<Parameter>}
     * @memberof ParameterGroup
     */
    'parameters': Array<Parameter>;
}
/**
 * ParameterType Model
 * @export
 * @interface ParameterType
 */
export interface ParameterType {
    /**
     * 
     * @type {string}
     * @memberof ParameterType
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParameterType
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ParameterType
     */
    'is_synced'?: number;
    /**
     * 
     * @type {number}
     * @memberof ParameterType
     */
    'is_deleted'?: number;
    /**
     * 
     * @type {string}
     * @memberof ParameterType
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ParameterType
     */
    'modified_at'?: string;
}
/**
 * 
 * @export
 * @interface ParameterUpdateRequest
 */
export interface ParameterUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ParameterUpdateRequest
     */
    'name'?: string;
    /**
     * Equation that defines how this parameter will be calculated
     * @type {string}
     * @memberof ParameterUpdateRequest
     */
    'calculation'?: string;
    /**
     * 
     * @type {UnitType}
     * @memberof ParameterUpdateRequest
     */
    'unit_type'?: UnitType;
    /**
     * Position of this parameter in CTR report
     * @type {number}
     * @memberof ParameterUpdateRequest
     */
    'ctr_order'?: number;
}
/**
 * PasswordGrantRequest Model
 * @export
 * @interface PasswordGrantRequest
 */
export interface PasswordGrantRequest {
    /**
     * 
     * @type {string}
     * @memberof PasswordGrantRequest
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordGrantRequest
     */
    'password'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordGrantRequest
     */
    'grant_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordGrantRequest
     */
    'client_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordGrantRequest
     */
    'client_secret'?: string;
}
/**
 * 
 * @export
 * @interface PerformanceFlag
 */
export interface PerformanceFlag {
    /**
     * 
     * @type {string}
     * @memberof PerformanceFlag
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFlag
     */
    'name': string;
    /**
     * 
     * @type {PerformanceFlagTypeEnum}
     * @memberof PerformanceFlag
     */
    'type_slug': PerformanceFlagTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFlag
     */
    'parameter_slug': string;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFlag
     */
    'set_name': string;
    /**
     * 
     * @type {number}
     * @memberof PerformanceFlag
     */
    'order': number;
    /**
     * 
     * @type {Array<PerformanceFlagRule>}
     * @memberof PerformanceFlag
     */
    'performance_flag_rules': Array<PerformanceFlagRule>;
}
/**
 * 
 * @export
 * @interface PerformanceFlag1
 */
export interface PerformanceFlag1 {
    /**
     * 
     * @type {string}
     * @memberof PerformanceFlag1
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFlag1
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof PerformanceFlag1
     */
    'order': number;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFlag1
     */
    'parameter_slug': string;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFlag1
     */
    'set_name': string;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFlag1
     */
    'type_slug': string;
}
/**
 * 
 * @export
 * @interface PerformanceFlagRequest
 */
export interface PerformanceFlagRequest {
    /**
     * 
     * @type {Array<PerformanceFlag>}
     * @memberof PerformanceFlagRequest
     */
    'performance_flags': Array<PerformanceFlag>;
}
/**
 * 
 * @export
 * @interface PerformanceFlagRequest1
 */
export interface PerformanceFlagRequest1 {
    /**
     * 
     * @type {Array<PerformanceFlag1>}
     * @memberof PerformanceFlagRequest1
     */
    'performance_flags': Array<PerformanceFlag1>;
}
/**
 * 
 * @export
 * @interface PerformanceFlagRule
 */
export interface PerformanceFlagRule {
    /**
     * 
     * @type {string}
     * @memberof PerformanceFlagRule
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFlagRule
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFlagRule
     */
    'comparison': string;
    /**
     * 
     * @type {number}
     * @memberof PerformanceFlagRule
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof PerformanceFlagRule
     */
    'performance_flag_id': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PerformanceFlagTypeEnum = {
    AcRatio: 'ac_ratio',
    Velocity: 'velocity'
} as const;

export type PerformanceFlagTypeEnum = typeof PerformanceFlagTypeEnum[keyof typeof PerformanceFlagTypeEnum];


/**
 * 
 * @export
 * @interface Period
 */
export interface Period {
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'activity_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'period_depth_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'name': string;
    /**
     * Epoch Timestamp, seconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof Period
     */
    'start_time': number;
    /**
     * Centiseconds part of epoch timestamp (hundredths of a second, a number between 0 and 99)
     * @type {number}
     * @memberof Period
     */
    'start_centiseconds'?: number;
    /**
     * Epoch Timestamp, seconds since 1970-01-01T00:00:00Z
     * @type {number}
     * @memberof Period
     */
    'end_time': number;
    /**
     * Centiseconds part of epoch timestamp (hundredths of a second, a number between 0 and 99)
     * @type {number}
     * @memberof Period
     */
    'end_centiseconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof Period
     */
    'lft'?: number;
    /**
     * 
     * @type {number}
     * @memberof Period
     */
    'rgt'?: number;
    /**
     * 
     * @type {number}
     * @memberof Period
     */
    'is_synced'?: number;
    /**
     * 
     * @type {number}
     * @memberof Period
     */
    'is_deleted'?: number;
    /**
     * 
     * @type {number}
     * @memberof Period
     */
    'is_injected'?: number;
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Period
     */
    'user_id'?: string | null;
    /**
     * 
     * @type {Array<PeriodAthlete>}
     * @memberof Period
     */
    'period_athletes'?: Array<PeriodAthlete>;
}
/**
 * 
 * @export
 * @interface PeriodAthlete
 */
export interface PeriodAthlete {
    /**
     * 
     * @type {string}
     * @memberof PeriodAthlete
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PeriodAthlete
     */
    'athlete_id': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodAthlete
     */
    'period_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof PeriodAthlete
     */
    'is_injected': number;
    /**
     * 
     * @type {Array<Rotation>}
     * @memberof PeriodAthlete
     */
    'rotations'?: Array<Rotation>;
}
/**
 * 
 * @export
 * @interface PeriodBatchRequest
 */
export interface PeriodBatchRequest {
    /**
     * The id of the activity.
     * @type {string}
     * @memberof PeriodBatchRequest
     */
    'activity_id': string;
    /**
     * The list of periods.
     * @type {Array<PeriodBatchRequestPeriodAndAthletes>}
     * @memberof PeriodBatchRequest
     */
    'periods': Array<PeriodBatchRequestPeriodAndAthletes>;
    /**
     * The list of activity athlete tags (participation tags).
     * @type {Array<PeriodBatchRequestAthleteTags>}
     * @memberof PeriodBatchRequest
     */
    'athlete_tags': Array<PeriodBatchRequestAthleteTags>;
}
/**
 * 
 * @export
 * @interface PeriodBatchRequestAthleteTags
 */
export interface PeriodBatchRequestAthleteTags {
    /**
     * The id of the athlete.
     * @type {string}
     * @memberof PeriodBatchRequestAthleteTags
     */
    'athlete_id'?: string;
    /**
     * The id of the activity athlete participation tag.
     * @type {string}
     * @memberof PeriodBatchRequestAthleteTags
     */
    'tag_id'?: string;
}
/**
 * 
 * @export
 * @interface PeriodBatchRequestPeriod
 */
export interface PeriodBatchRequestPeriod {
    /**
     * The name of the period.
     * @type {string}
     * @memberof PeriodBatchRequestPeriod
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PeriodBatchRequestPeriod
     */
    'period_depth_id'?: string;
    /**
     * The start time of the period.
     * @type {number}
     * @memberof PeriodBatchRequestPeriod
     */
    'start_time': number;
    /**
     * The start centiseconds of the period.
     * @type {number}
     * @memberof PeriodBatchRequestPeriod
     */
    'start_centiseconds': number;
    /**
     * The end time of the period.
     * @type {number}
     * @memberof PeriodBatchRequestPeriod
     */
    'end_time': number;
    /**
     * The end centiseconds of the period.
     * @type {number}
     * @memberof PeriodBatchRequestPeriod
     */
    'end_centiseconds': number;
    /**
     * The left value of the period.
     * @type {number}
     * @memberof PeriodBatchRequestPeriod
     */
    'lft': number;
    /**
     * The right value of the period.
     * @type {number}
     * @memberof PeriodBatchRequestPeriod
     */
    'rgt': number;
}
/**
 * 
 * @export
 * @interface PeriodBatchRequestPeriodAndAthletes
 */
export interface PeriodBatchRequestPeriodAndAthletes {
    /**
     * 
     * @type {PeriodBatchRequestPeriod}
     * @memberof PeriodBatchRequestPeriodAndAthletes
     */
    'period'?: PeriodBatchRequestPeriod;
    /**
     * The list of athlete ids.
     * @type {Array<string>}
     * @memberof PeriodBatchRequestPeriodAndAthletes
     */
    'athletes'?: Array<string>;
}
/**
 * Personl access token
 * @export
 * @interface PersonalAccessToken
 */
export interface PersonalAccessToken {
    /**
     * 
     * @type {string}
     * @memberof PersonalAccessToken
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalAccessToken
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalAccessToken
     */
    'client_id': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalAccessToken
     */
    'name': string;
    /**
     * 
     * @type {boolean}
     * @memberof PersonalAccessToken
     */
    'revoked': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PersonalAccessToken
     */
    'scopes'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof PersonalAccessToken
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalAccessToken
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof PersonalAccessToken
     */
    'expires_at': string;
}
/**
 * Position Model
 * @export
 * @interface Position
 */
export interface Position {
    /**
     * 
     * @type {string}
     * @memberof Position
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Position
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Position
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof Position
     */
    'sport_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Position
     */
    'sport_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Position
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Position
     */
    'modified_at': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Position
     */
    'tag_list'?: Array<string>;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof Position
     */
    'tags'?: Array<TagItem>;
}
/**
 * 
 * @export
 * @interface ProximityReportParameters
 */
export interface ProximityReportParameters {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProximityReportParameters
     */
    'activity_ids': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ProximityReportParameters
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof ProximityReportParameters
     */
    'proximity_dwell_time': number;
    /**
     * 
     * @type {number}
     * @memberof ProximityReportParameters
     */
    'proximity_time_threshold': number;
    /**
     * 
     * @type {number}
     * @memberof ProximityReportParameters
     */
    'proximity_zone': number;
    /**
     * 
     * @type {number}
     * @memberof ProximityReportParameters
     */
    'generate_proximity_csv'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProximityReportParameters
     */
    'generate_vision_csv'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProximityReportParameters
     */
    'zip'?: number;
}
/**
 * 
 * @export
 * @interface ProximityReportResponse
 */
export interface ProximityReportResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProximityReportResponse
     */
    'filenames': Array<string>;
}
/**
 * RefreshTokenGrantRequest Model
 * @export
 * @interface RefreshTokenGrantRequest
 */
export interface RefreshTokenGrantRequest {
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenGrantRequest
     */
    'refresh_token'?: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenGrantRequest
     */
    'client_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RefreshTokenGrantRequest
     */
    'client_secret'?: string;
}
/**
 * 
 * @export
 * @interface ReprocessActivitiesRequest
 */
export interface ReprocessActivitiesRequest {
    /**
     * List of IDs to reprocess
     * @type {Array<string>}
     * @memberof ReprocessActivitiesRequest
     */
    'ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RestoreActivitiesRequest
 */
export interface RestoreActivitiesRequest {
    /**
     * List of IDs to restore
     * @type {Array<string>}
     * @memberof RestoreActivitiesRequest
     */
    'ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface RestoreThresholdSetsRequest
 */
export interface RestoreThresholdSetsRequest {
    /**
     * List of IDs to delete
     * @type {Array<string>}
     * @memberof RestoreThresholdSetsRequest
     */
    'ids': Array<string>;
}
/**
 * 
 * @export
 * @interface Rotation
 */
export interface Rotation {
    /**
     * The start time of the rotation in milliseconds.
     * @type {number}
     * @memberof Rotation
     */
    'start_time_ms': number;
    /**
     * The end time of the rotation in milliseconds.
     * @type {number}
     * @memberof Rotation
     */
    'end_time_ms': number;
}
/**
 * An object that contains a token value generated from a specific secret service
 * @export
 * @interface SecretsToken
 */
export interface SecretsToken {
    /**
     * A token generated by a specific secret service
     * @type {string}
     * @memberof SecretsToken
     */
    'token': string;
}
/**
 * An object that contains a boolean value if the token exists for given key
 * @export
 * @interface SecretsTokenStatus
 */
export interface SecretsTokenStatus {
    /**
     * Boolean if the token exist for given key
     * @type {boolean}
     * @memberof SecretsTokenStatus
     */
    'has_token': boolean;
}
/**
 * Sensor Model
 * @export
 * @interface Sensor
 */
export interface Sensor {
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'athlete_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Sensor
     */
    'device_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'player_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'athlete_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'athlete_last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'jersey'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'team_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'team_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'stream_type'?: string;
    /**
     * 
     * @type {Array<SensorData>}
     * @memberof Sensor
     */
    'data'?: Array<SensorData>;
}
/**
 * Sensor Data Model (Allowed Parameters)
 * @export
 * @interface SensorData
 */
export interface SensorData {
    /**
     * UnixTimestamp (seconds)
     * @type {number}
     * @memberof SensorData
     */
    'ts'?: number;
    /**
     * Centiseconds
     * @type {number}
     * @memberof SensorData
     */
    'cs'?: number;
    /**
     * Latitude
     * @type {number}
     * @memberof SensorData
     */
    'lat'?: number;
    /**
     * Longitude
     * @type {number}
     * @memberof SensorData
     */
    'long'?: number;
    /**
     * Odometer
     * @type {number}
     * @memberof SensorData
     */
    'o'?: number;
    /**
     * Velocity
     * @type {number}
     * @memberof SensorData
     */
    'v'?: number;
    /**
     * Acceleration
     * @type {number}
     * @memberof SensorData
     */
    'a'?: number;
    /**
     * HeartRate
     * @type {number}
     * @memberof SensorData
     */
    'hr'?: number;
    /**
     * Player Load
     * @type {number}
     * @memberof SensorData
     */
    'pl'?: number;
    /**
     * Instantaneous Player Load
     * @type {number}
     * @memberof SensorData
     */
    'pli'?: number;
    /**
     * Metabolic Power
     * @type {number}
     * @memberof SensorData
     */
    'mp'?: number;
    /**
     * Smooth Load
     * @type {number}
     * @memberof SensorData
     */
    'sl'?: number;
    /**
     * X Field relative coordinate
     * @type {number}
     * @memberof SensorData
     */
    'x'?: number;
    /**
     * Y Field relative coordinate
     * @type {number}
     * @memberof SensorData
     */
    'y'?: number;
    /**
     * SensorData database identifier
     * @type {string}
     * @memberof SensorData
     */
    'id'?: string;
    /**
     * Position Quality
     * @type {number}
     * @memberof SensorData
     */
    'pq'?: number;
    /**
     * Number of reference GNSS satellites or RTLS anchors used in a fix
     * @type {number}
     * @memberof SensorData
     */
    'ref'?: number;
    /**
     * GPS Horizontal Dilution of Precision
     * @type {number}
     * @memberof SensorData
     */
    'hdop'?: number;
    /**
     * Raw Velocity
     * @type {number}
     * @memberof SensorData
     */
    'rv'?: number;
    /**
     * Altitude
     * @type {number}
     * @memberof SensorData
     */
    'alt'?: number;
    /**
     * Facing
     * @type {number}
     * @memberof SensorData
     */
    'face'?: number;
}
/**
 * Setting value for the currently authenticated user.
 * @export
 * @interface Setting
 */
export interface Setting {
    /**
     * The setting key/name
     * @type {string}
     * @memberof Setting
     */
    'key': string;
    /**
     * The type of value
     * @type {string}
     * @memberof Setting
     */
    'type'?: string;
    /**
     * The setting value
     * @type {string}
     * @memberof Setting
     */
    'value': string;
    /**
     * Created timestamp
     * @type {string}
     * @memberof Setting
     */
    'created_at'?: string;
    /**
     * Updated timestamp
     * @type {string}
     * @memberof Setting
     */
    'modified_at'?: string;
    /**
     * ID of user, or null if this is the customer-wide setting value
     * @type {string}
     * @memberof Setting
     */
    'user_guid'?: string | null;
}
/**
 * 
 * @export
 * @interface SettingUpdateRequest
 */
export interface SettingUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof SettingUpdateRequest
     */
    'value'?: string;
}
/**
 * 
 * @export
 * @interface ShareToken
 */
export interface ShareToken {
    /**
     * 
     * @type {string}
     * @memberof ShareToken
     */
    'token'?: string;
}
/**
 * Stat values, indexed by parameter name. Any parameters that had a failed calculation will have a value of null, with the error detail contained in the \'errors\' property.
 * @export
 * @interface StatRow
 */
export interface StatRow {
    [key: string]: object | any;

    /**
     * Details of any errors encountered while calculating parameters, *indexed by parameter name*
     * @type {{ [key: string]: StatRowErrors; }}
     * @memberof StatRow
     */
    'errors'?: { [key: string]: StatRowErrors; };
}
/**
 * 
 * @export
 * @interface StatRowErrors
 */
export interface StatRowErrors {
    /**
     * Type of calculation error
     * @type {string}
     * @memberof StatRowErrors
     */
    'type'?: StatRowErrorsTypeEnum;
    /**
     * Details of calculation error
     * @type {string}
     * @memberof StatRowErrors
     */
    'message'?: string;
    /**
     * The calculation that failed
     * @type {string}
     * @memberof StatRowErrors
     */
    'calculation'?: string;
}

export const StatRowErrorsTypeEnum = {
    DivZero: 'DIV_ZERO',
    NoEq: 'NO_EQ',
    NoSet: 'NO_SET',
    NoVar: 'NO_VAR',
    Parse: 'PARSE',
    Unspecified: 'UNSPECIFIED'
} as const;

export type StatRowErrorsTypeEnum = typeof StatRowErrorsTypeEnum[keyof typeof StatRowErrorsTypeEnum];

/**
 * 
 * @export
 * @interface StatsFilter
 */
export interface StatsFilter {
    /**
     * Field to filter on
     * @type {string}
     * @memberof StatsFilter
     */
    'name'?: string;
    /**
     * Type of comparison to use
     * @type {string}
     * @memberof StatsFilter
     */
    'comparison'?: StatsFilterComparisonEnum;
    /**
     * Values to apply to the comparison
     * @type {Array<string>}
     * @memberof StatsFilter
     */
    'values'?: Array<string>;
}

export const StatsFilterComparisonEnum = {
    Equal: '=',
    NotEqual: '<>',
    GreaterThan: '>',
    GreaterThanOrEqualTo: '>=',
    LessThan: '<',
    LessThanOrEqualTo: '<='
} as const;

export type StatsFilterComparisonEnum = typeof StatsFilterComparisonEnum[keyof typeof StatsFilterComparisonEnum];

/**
 * 
 * @export
 * @interface StatsRequest
 */
export interface StatsRequest {
    /**
     * Limit the stats returned
     * @type {Array<StatsFilter>}
     * @memberof StatsRequest
     */
    'filters'?: Array<StatsFilter>;
    /**
     * Group by
     * @type {Array<string>}
     * @memberof StatsRequest
     */
    'group_by'?: Array<string>;
    /**
     * Parameters to include in each row of stats
     * @type {Array<string>}
     * @memberof StatsRequest
     */
    'parameters'?: Array<string>;
    /**
     * List of parameters to sort by
     * @type {Array<string>}
     * @memberof StatsRequest
     */
    'sorting'?: Array<string>;
    /**
     * Stats source
     * @type {string}
     * @memberof StatsRequest
     */
    'source'?: StatsRequestSourceEnum;
    /**
     * Return only the values indicated in parameters input
     * @type {boolean}
     * @memberof StatsRequest
     */
    'requested_only'?: boolean;
}

export const StatsRequestSourceEnum = {
    CachedStats: 'cached_stats',
    AnnotationStats: 'annotation_stats'
} as const;

export type StatsRequestSourceEnum = typeof StatsRequestSourceEnum[keyof typeof StatsRequestSourceEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const SupportedLanguage = {
    En: 'en',
    Es: 'es',
    Fr: 'fr',
    Ja: 'ja',
    Ptbr: 'ptbr',
    Zh: 'zh'
} as const;

export type SupportedLanguage = typeof SupportedLanguage[keyof typeof SupportedLanguage];


/**
 * New data since the given sync time in a pre-defined table order
 * @export
 * @interface SyncIncremental
 */
export interface SyncIncremental {
    /**
     * Time the sync was started. This is used as the value of `from` in the next sync.
     * @type {number}
     * @memberof SyncIncremental
     */
    'sync_time': number;
    /**
     * 
     * @type {SyncIncrementalNext}
     * @memberof SyncIncremental
     */
    'next'?: SyncIncrementalNext;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'settings'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'sports'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'athletes'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'venues'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'fields'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'period_depth_types'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'parameter_types'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'bands'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'tag_types'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'retriever_settings'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'property_groups'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'athlete_groups'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'tags'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'positions'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'teams'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'venue_fields'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'lps_points'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'activities'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'dashboards'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'parameter_groups'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'parameters'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'devices'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'period_depths'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'property_values'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'athlete_group_athletes'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'periods'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'activity_teams'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'activity_athletes'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'athlete_positions'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'athlete_teams'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'device_athletes'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'parameter_group_items'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'period_depth_attributes'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'dashboard_items'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'athlete_targets'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'entity_tags'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'anchor_configurations'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'venue_field_points'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'venue_field_attributes'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'team_metadata'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'athlete_metadata'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'period_athletes'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'period_attributes'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'dashboard_resources'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'dashboard_resource_filters'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'users'?: Array<object>;
    /**
     * 
     * @type {Array<object>}
     * @memberof SyncIncremental
     */
    'activity_users'?: Array<object>;
}
/**
 * If there are more records than could fit into this request, this holds data that should be passed in next request.
 * @export
 * @interface SyncIncrementalNext
 */
export interface SyncIncrementalNext {
    /**
     * This is the table that was reached in this request. This value should be passed for `table` in next request.
     * @type {string}
     * @memberof SyncIncrementalNext
     */
    'table'?: string;
    /**
     * Corresponds to the `modified_at` value of the last record returned. This value should be passed for `table_from` in next request. 
     * @type {number}
     * @memberof SyncIncrementalNext
     */
    'table_from'?: number;
    /**
     * Corresponds to the `id` of the last record returned. This value should be passed for `table_from_id` in next request. 
     * @type {string}
     * @memberof SyncIncrementalNext
     */
    'table_from_id'?: string;
    /**
     * Same as the `from` value in the request. This value should be passed for `from` in next request. 
     * @type {number}
     * @memberof SyncIncrementalNext
     */
    'from'?: number;
    /**
     * Same as the `to` value in the request, if it was passed in, otherwise defaults to current time. This value should be passed for `to` in next request. 
     * @type {number}
     * @memberof SyncIncrementalNext
     */
    'to'?: number;
}
/**
 * Tag Model
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'tag_type_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    'is_synced'?: number;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    'is_deleted'?: number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'modified_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    'tag_name'?: string;
}
/**
 * 
 * @export
 * @interface TagCreationParameters
 */
export interface TagCreationParameters {
    /**
     * 
     * @type {string}
     * @memberof TagCreationParameters
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TagCreationParameters
     */
    'tag_type_id'?: string;
}
/**
 * 
 * @export
 * @interface TagItem
 */
export interface TagItem {
    /**
     * 
     * @type {string}
     * @memberof TagItem
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagItem
     */
    'tag_type_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagItem
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagItem
     */
    'tag_type_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagItem
     */
    'tag_name'?: string;
}
/**
 * TagType Model
 * @export
 * @interface TagType
 */
export interface TagType {
    /**
     * 
     * @type {string}
     * @memberof TagType
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagType
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof TagType
     */
    'readonly'?: number;
    /**
     * 
     * @type {string}
     * @memberof TagType
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof TagType
     */
    'modified_at'?: string;
}
/**
 * 
 * @export
 * @interface TagTypeCreateRequest
 */
export interface TagTypeCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof TagTypeCreateRequest
     */
    'name': string;
    /**
     * Setting read-only for a tag type restricts the tag type and associated tags *in the cloud UI only*:   - Read-only tag types are hidden and cannot be updated   - Tags cannot be created for read-only tag types  These restrictions do not apply to creating, reading or updating read-only tag types via the API.
     * @type {number}
     * @memberof TagTypeCreateRequest
     */
    'readonly'?: number;
}
/**
 * 
 * @export
 * @interface TagTypeUpdateRequest
 */
export interface TagTypeUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof TagTypeUpdateRequest
     */
    'name': string;
    /**
     * Setting read-only for a tag type restricts the tag type and associated tags *in the cloud UI only*:   - Read-only tag types are hidden and cannot be updated   - Tags cannot be created for read-only tag types  These restrictions do not apply to creating, reading or updating read-only tag types via the API.
     * @type {number}
     * @memberof TagTypeUpdateRequest
     */
    'readonly'?: number;
}
/**
 * Tags Model
 * @export
 * @interface Tags
 */
export interface Tags {
    /**
     * 
     * @type {string}
     * @memberof Tags
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tags
     */
    'tag_type_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tags
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tags
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Tags
     */
    'modified_at'?: string;
}
/**
 * 
 * @export
 * @interface Tags1
 */
export interface Tags1 {
    /**
     * List of tags to apply during activity creation
     * @type {Array<CatrEntityTagRequestObject>}
     * @memberof Tags1
     */
    'tags'?: Array<CatrEntityTagRequestObject>;
}
/**
 * 
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'home'?: number;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'sport_name': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'modified_at': string;
    /**
     * 
     * @type {boolean}
     * @memberof Team
     */
    'is_default'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Team
     */
    'is_additional'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'acceleration_rhie_bands': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'velocity_rhie_bands': string;
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'rhie_effort_recovery': number;
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'rhie_effort_count': number;
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'player_load_dwell_time': number;
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'heart_rate_dwell_time': number;
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'acceleration_dwell_time': number;
    /**
     * 
     * @type {number}
     * @memberof Team
     */
    'velocity_dwell_time': number;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'secondary_colour': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'primary_colour': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'slug': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'logo': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'logo_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    'sport_id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Team
     */
    'tag_list': Array<string>;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof Team
     */
    'tags': Array<TagItem>;
    /**
     * 
     * @type {Array<TeamSetting>}
     * @memberof Team
     */
    'settings'?: Array<TeamSetting>;
}
/**
 * Settings for a team
 * @export
 * @interface TeamSetting
 */
export interface TeamSetting {
    /**
     * 
     * @type {string}
     * @memberof TeamSetting
     */
    'key': string;
    /**
     * 
     * @type {number}
     * @memberof TeamSetting
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof TeamSetting
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof TeamSetting
     */
    'modified_at': string;
}
/**
 * 
 * @export
 * @interface ThresholdAlertSettings
 */
export interface ThresholdAlertSettings {
    /**
     * The green alert threshold
     * @type {number}
     * @memberof ThresholdAlertSettings
     */
    'green': number;
    /**
     * The amber alert threshold
     * @type {number}
     * @memberof ThresholdAlertSettings
     */
    'amber': number;
    /**
     * The red alert threshold
     * @type {number}
     * @memberof ThresholdAlertSettings
     */
    'red': number;
}
/**
 * 
 * @export
 * @interface ThresholdSet
 */
export interface ThresholdSet {
    /**
     * 
     * @type {string}
     * @memberof ThresholdSet
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ThresholdSet
     */
    'name': string;
    /**
     * 
     * @type {Array<ThresholdSetsAlert>}
     * @memberof ThresholdSet
     */
    'alert'?: Array<ThresholdSetsAlert>;
    /**
     * 
     * @type {number}
     * @memberof ThresholdSet
     */
    'is_deleted': number;
    /**
     * 
     * @type {string}
     * @memberof ThresholdSet
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ThresholdSet
     */
    'modified_at': string;
    /**
     * Number of athletes in the set
     * @type {number}
     * @memberof ThresholdSet
     */
    'athlete_count': number;
    /**
     * 
     * @type {Array<ThresholdSetAthlete>}
     * @memberof ThresholdSet
     */
    'thresholdSetAthletes'?: Array<ThresholdSetAthlete>;
}
/**
 * 
 * @export
 * @interface ThresholdSetAthlete
 */
export interface ThresholdSetAthlete {
    /**
     * 
     * @type {string}
     * @memberof ThresholdSetAthlete
     */
    'athlete_id': string | null;
    /**
     * 
     * @type {string}
     * @memberof ThresholdSetAthlete
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThresholdSetAthlete
     */
    'modified_at'?: string;
    /**
     * 
     * @type {Array<ThresholdSetParameter>}
     * @memberof ThresholdSetAthlete
     */
    'parameters': Array<ThresholdSetParameter>;
    /**
     * 
     * @type {Athlete}
     * @memberof ThresholdSetAthlete
     */
    'athlete'?: Athlete;
}
/**
 * 
 * @export
 * @interface ThresholdSetAthleteUpdateRequest
 */
export interface ThresholdSetAthleteUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ThresholdSetAthleteUpdateRequest
     */
    'athlete_id': string | null;
    /**
     * 
     * @type {Array<ThresholdSetParameterUpdateRequest>}
     * @memberof ThresholdSetAthleteUpdateRequest
     */
    'parameters': Array<ThresholdSetParameterUpdateRequest>;
}
/**
 * 
 * @export
 * @interface ThresholdSetParameter
 */
export interface ThresholdSetParameter {
    /**
     * 
     * @type {string}
     * @memberof ThresholdSetParameter
     */
    'parameter_slug': string;
    /**
     * 
     * @type {number}
     * @memberof ThresholdSetParameter
     */
    'parameter_value': number;
    /**
     * 
     * @type {string}
     * @memberof ThresholdSetParameter
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof ThresholdSetParameter
     */
    'modified_at'?: string;
}
/**
 * 
 * @export
 * @interface ThresholdSetParameterUpdateRequest
 */
export interface ThresholdSetParameterUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ThresholdSetParameterUpdateRequest
     */
    'parameter_slug': string;
    /**
     * 
     * @type {number}
     * @memberof ThresholdSetParameterUpdateRequest
     */
    'parameter_value': number;
}
/**
 * 
 * @export
 * @interface ThresholdSetSimple
 */
export interface ThresholdSetSimple {
    /**
     * 
     * @type {string}
     * @memberof ThresholdSetSimple
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ThresholdSetSimple
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ThresholdSetSimple
     */
    'is_deleted': number;
    /**
     * 
     * @type {string}
     * @memberof ThresholdSetSimple
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof ThresholdSetSimple
     */
    'modified_at': string;
    /**
     * Number of athletes in the set
     * @type {number}
     * @memberof ThresholdSetSimple
     */
    'athlete_count': number;
}
/**
 * 
 * @export
 * @interface ThresholdSetUpdateRequest
 */
export interface ThresholdSetUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ThresholdSetUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {Array<ThresholdSetUpdateRequestAlert>}
     * @memberof ThresholdSetUpdateRequest
     */
    'alert'?: Array<ThresholdSetUpdateRequestAlert>;
    /**
     * 
     * @type {Array<ThresholdSetAthleteUpdateRequest>}
     * @memberof ThresholdSetUpdateRequest
     */
    'thresholdSetAthletes': Array<ThresholdSetAthleteUpdateRequest>;
}
/**
 * 
 * @export
 * @interface ThresholdSetUpdateRequestAlert
 */
export interface ThresholdSetUpdateRequestAlert {
    /**
     * 
     * @type {string}
     * @memberof ThresholdSetUpdateRequestAlert
     */
    'colour'?: string;
    /**
     * 
     * @type {number}
     * @memberof ThresholdSetUpdateRequestAlert
     */
    'level'?: number;
    /**
     * 
     * @type {number}
     * @memberof ThresholdSetUpdateRequestAlert
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface ThresholdSetsAlert
 */
export interface ThresholdSetsAlert {
    /**
     * 
     * @type {string}
     * @memberof ThresholdSetsAlert
     */
    'colour'?: string;
    /**
     * 
     * @type {number}
     * @memberof ThresholdSetsAlert
     */
    'level'?: number;
    /**
     * 
     * @type {number}
     * @memberof ThresholdSetsAlert
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ThresholdSetsSortEnum = {
    NameAsc: 'name',
    ModifiedAtAsc: 'modified_at',
    AthleteCountAsc: 'athlete_count',
    NameDesc: '-name',
    ModifiedAtDesc: '-modified_at',
    AthleteCountDesc: '-athlete_count'
} as const;

export type ThresholdSetsSortEnum = typeof ThresholdSetsSortEnum[keyof typeof ThresholdSetsSortEnum];


/**
 * The type of units that will be used to display this parameter. Empty string indicates arbitrary units, for example player load.
 * @export
 * @enum {string}
 */

export const UnitType = {
    Abitrary: '',
    Count: 'count',
    Distance: 'distance',
    Duration: 'duration',
    Percentage: 'percentage',
    Speed: 'speed',
    Weight: 'weight'
} as const;

export type UnitType = typeof UnitType[keyof typeof UnitType];


/**
 * 
 * @export
 * @interface UpdateActivitiesUserRequest
 */
export interface UpdateActivitiesUserRequest {
    /**
     * Array of activity uuids
     * @type {Array<string>}
     * @memberof UpdateActivitiesUserRequest
     */
    'ids'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateInjectedDataRequest
 */
export interface UpdateInjectedDataRequest {
    /**
     * Activity id
     * @type {string}
     * @memberof UpdateInjectedDataRequest
     */
    'activity_id': string;
    /**
     * Populated injection template containing data to be injected
     * @type {any}
     * @memberof UpdateInjectedDataRequest
     */
    'csv_file': any;
}
/**
 * 
 * @export
 * @interface UpdateUserPreferencesRequest
 */
export interface UpdateUserPreferencesRequest {
    /**
     * Default Team (team name)
     * @type {string}
     * @memberof UpdateUserPreferencesRequest
     */
    'default_team': string;
    /**
     * Additional Teams to display (team ids)
     * @type {Array<string>}
     * @memberof UpdateUserPreferencesRequest
     */
    'relevant_teams'?: Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateUserRequest
 */
export interface UpdateUserRequest {
    /**
     * User full name
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'fullName': string;
    /**
     * User email
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'email'?: string;
    /**
     * User password required when new email is provided
     * @type {string}
     * @memberof UpdateUserRequest
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'full_name': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'account_type_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'customer_id': number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'default': number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'is_deleted': number;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'is_synced'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'timezone'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'modified_at': string;
    /**
     * 
     * @type {UserSoftwareVersion}
     * @memberof User
     */
    'software_version': UserSoftwareVersion;
    /**
     * Whether the user must carry out MFA in the current session before being fully authenticated.
     * @type {number}
     * @memberof User
     */
    'require_mfa'?: number;
    /**
     * The prompt displayed to the user when asking them to enter an MFA code. This depends on the type of MFA required (SMS or OTP).
     * @type {string}
     * @memberof User
     */
    'mfa_message'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'mfa_type'?: UserMfaTypeEnum;
    /**
     * If present and set to 1, user needs to set their password.
     * @type {number}
     * @memberof User
     */
    'need_to_set_password'?: number;
    /**
     * If present and non-zero, password update is required and the value is the number of days since last password update.
     * @type {number}
     * @memberof User
     */
    'needs_password_update'?: number;
    /**
     * If present and set to 1, this user needs to accept EULA.
     * @type {number}
     * @memberof User
     */
    'need_to_accept_eula'?: number;
    /**
     * If present and set to 1, user needs to be offered MFA setup.
     * @type {number}
     * @memberof User
     */
    'need_to_setup_mfa'?: number;
    /**
     * If present and set to 1, user needs to be finish the account registration setup.
     * @type {number}
     * @memberof User
     */
    'need_to_setup'?: number;
    /**
     * Permissions for this user, optional include.
     * @type {Array<string>}
     * @memberof User
     */
    'permissions'?: Array<UserPermissionsEnum>;
}

export const UserMfaTypeEnum = {
    Sms: 'sms',
    Otp: 'otp'
} as const;

export type UserMfaTypeEnum = typeof UserMfaTypeEnum[keyof typeof UserMfaTypeEnum];
export const UserPermissionsEnum = {
    Editor: 'editor',
    Timeline: 'timeline',
    VectorAppWelcome: 'vector-app-welcome',
    Download: 'download',
    Report: 'report',
    Reporting: 'reporting',
    League: 'league',
    Settings: 'settings',
    SettingsActivities: 'settings/activities',
    SettingsAthletes: 'settings/athletes',
    SettingsAverages: 'settings/averages',
    SettingsBands: 'settings/bands',
    SettingsFields: 'settings/fields',
    SettingsParameters: 'settings/parameters',
    SettingsPeriods: 'settings/periods',
    SettingsPositions: 'settings/positions',
    SettingsRawfiles: 'settings/rawfiles',
    SettingsSdexport: 'settings/sdexport',
    SettingsSmartabase: 'settings/smartabase',
    SettingsTags: 'settings/tags',
    SettingsTeams: 'settings/teams',
    SettingsThresholds: 'settings/thresholds',
    SettingsTokens: 'settings/tokens',
    SettingsUsers: 'settings/users',
    SettingsVenues: 'settings/venues',
    SettingsWebhook: 'settings/webhook',
    SharedLink: 'shared-link'
} as const;

export type UserPermissionsEnum = typeof UserPermissionsEnum[keyof typeof UserPermissionsEnum];

/**
 * 
 * @export
 * @interface UserFile
 */
export interface UserFile {
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    'user_id': string;
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    'mime': string;
    /**
     * 
     * @type {number}
     * @memberof UserFile
     */
    'device_id': number;
    /**
     * 
     * @type {number}
     * @memberof UserFile
     */
    'start_time': number;
    /**
     * 
     * @type {number}
     * @memberof UserFile
     */
    'end_time': number;
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof UserFile
     */
    'modified_at': string;
}
/**
 * 
 * @export
 * @interface UserSoftwareVersion
 */
export interface UserSoftwareVersion {
    /**
     * 
     * @type {number}
     * @memberof UserSoftwareVersion
     */
    'major'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserSoftwareVersion
     */
    'minor'?: number;
    /**
     * 
     * @type {number}
     * @memberof UserSoftwareVersion
     */
    'subminor'?: number;
}
/**
 * 
 * @export
 * @interface Venue
 */
export interface Venue {
    /**
     * 
     * @type {string}
     * @memberof Venue
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Venue
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Venue
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof Venue
     */
    'modified_at'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Venue
     */
    'tag_list'?: Array<string>;
    /**
     * 
     * @type {Array<TagItem>}
     * @memberof Venue
     */
    'tags'?: Array<TagItem>;
    /**
     * 
     * @type {Array<VenueField>}
     * @memberof Venue
     */
    'venue_fields'?: Array<VenueField>;
}
/**
 * 
 * @export
 * @interface VenueCreateRequest
 */
export interface VenueCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof VenueCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof VenueCreateRequest
     */
    'field_id': string;
    /**
     * 
     * @type {Array<VenueCreateRequestAttributes>}
     * @memberof VenueCreateRequest
     */
    'attributes': Array<VenueCreateRequestAttributes>;
    /**
     * 
     * @type {Array<VenueCreateRequestVenueFieldPoints>}
     * @memberof VenueCreateRequest
     */
    'venue_field_points': Array<VenueCreateRequestVenueFieldPoints>;
}
/**
 * 
 * @export
 * @interface VenueCreateRequestAttributes
 */
export interface VenueCreateRequestAttributes {
    /**
     * 
     * @type {string}
     * @memberof VenueCreateRequestAttributes
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof VenueCreateRequestAttributes
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface VenueCreateRequestVenueFieldPoints
 */
export interface VenueCreateRequestVenueFieldPoints {
    /**
     * 
     * @type {string}
     * @memberof VenueCreateRequestVenueFieldPoints
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof VenueCreateRequestVenueFieldPoints
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof VenueCreateRequestVenueFieldPoints
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface VenueField
 */
export interface VenueField {
    /**
     * 
     * @type {string}
     * @memberof VenueField
     */
    'id'?: string;
    /**
     * 
     * @type {VenueFieldField}
     * @memberof VenueField
     */
    'field'?: VenueFieldField;
    /**
     * 
     * @type {Array<VenueFieldVenueFieldAttributes>}
     * @memberof VenueField
     */
    'venue_field_attributes'?: Array<VenueFieldVenueFieldAttributes>;
    /**
     * 
     * @type {Array<VenueFieldVenueFieldPoints>}
     * @memberof VenueField
     */
    'venue_field_points'?: Array<VenueFieldVenueFieldPoints>;
}
/**
 * 
 * @export
 * @interface VenueFieldField
 */
export interface VenueFieldField {
    /**
     * 
     * @type {string}
     * @memberof VenueFieldField
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFieldField
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface VenueFieldUpdateRequest
 */
export interface VenueFieldUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof VenueFieldUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof VenueFieldUpdateRequest
     */
    'field_id': string;
    /**
     * 
     * @type {Array<VenueFieldUpdateRequestAttributes>}
     * @memberof VenueFieldUpdateRequest
     */
    'attributes': Array<VenueFieldUpdateRequestAttributes>;
    /**
     * 
     * @type {Array<VenueFieldUpdateRequestVenueFieldPoints>}
     * @memberof VenueFieldUpdateRequest
     */
    'venue_field_points': Array<VenueFieldUpdateRequestVenueFieldPoints>;
}
/**
 * 
 * @export
 * @interface VenueFieldUpdateRequestAttributes
 */
export interface VenueFieldUpdateRequestAttributes {
    /**
     * 
     * @type {string}
     * @memberof VenueFieldUpdateRequestAttributes
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFieldUpdateRequestAttributes
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof VenueFieldUpdateRequestAttributes
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface VenueFieldUpdateRequestVenueFieldPoints
 */
export interface VenueFieldUpdateRequestVenueFieldPoints {
    /**
     * 
     * @type {string}
     * @memberof VenueFieldUpdateRequestVenueFieldPoints
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFieldUpdateRequestVenueFieldPoints
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof VenueFieldUpdateRequestVenueFieldPoints
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof VenueFieldUpdateRequestVenueFieldPoints
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface VenueFieldVenueFieldAttributes
 */
export interface VenueFieldVenueFieldAttributes {
    /**
     * 
     * @type {string}
     * @memberof VenueFieldVenueFieldAttributes
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFieldVenueFieldAttributes
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof VenueFieldVenueFieldAttributes
     */
    'value'?: number;
}
/**
 * 
 * @export
 * @interface VenueFieldVenueFieldPoints
 */
export interface VenueFieldVenueFieldPoints {
    /**
     * 
     * @type {string}
     * @memberof VenueFieldVenueFieldPoints
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof VenueFieldVenueFieldPoints
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof VenueFieldVenueFieldPoints
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof VenueFieldVenueFieldPoints
     */
    'longitude'?: number;
}
/**
 * WebhookConfig Model
 * @export
 * @interface WebhookConfig
 */
export interface WebhookConfig {
    /**
     * 
     * @type {string}
     * @memberof WebhookConfig
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfig
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfig
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfig
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfig
     */
    'type'?: string;
    /**
     * 
     * @type {WebhookConfigSettings}
     * @memberof WebhookConfig
     */
    'settings'?: WebhookConfigSettings;
    /**
     * 
     * @type {boolean}
     * @memberof WebhookConfig
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfig
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfig
     */
    'modified_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof WebhookConfig
     */
    'customer_id'?: number;
}
/**
 * Webhook Config Create Request Model
 * @export
 * @interface WebhookConfigCreateRequest
 */
export interface WebhookConfigCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigCreateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigCreateRequest
     */
    'topic': string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigCreateRequest
     */
    'action': string;
    /**
     * 
     * @type {boolean}
     * @memberof WebhookConfigCreateRequest
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigCreateRequest
     */
    'callback': string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigCreateRequest
     */
    'secret'?: string;
}
/**
 * Webhook Config Create Response Model
 * @export
 * @interface WebhookConfigCreateResponse
 */
export interface WebhookConfigCreateResponse {
    /**
     * 
     * @type {WebhookConfig}
     * @memberof WebhookConfigCreateResponse
     */
    'webhook'?: WebhookConfig;
    /**
     * The keys in \'audit\' will be dynamic based on the customer\'s stored webhook configs.
     * @type {Array<WebhookSimulate>}
     * @memberof WebhookConfigCreateResponse
     */
    'simulate'?: Array<WebhookSimulate>;
}
/**
 * WebhookConfigDetail Model
 * @export
 * @interface WebhookConfigDetail
 */
export interface WebhookConfigDetail {
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigDetail
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigDetail
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigDetail
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigDetail
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigDetail
     */
    'type'?: string;
    /**
     * 
     * @type {WebhookConfigSettings}
     * @memberof WebhookConfigDetail
     */
    'settings'?: WebhookConfigSettings;
    /**
     * 
     * @type {boolean}
     * @memberof WebhookConfigDetail
     */
    'active'?: boolean;
}
/**
 * 
 * @export
 * @interface WebhookConfigSettings
 */
export interface WebhookConfigSettings {
    /**
     * 
     * @type {OAuthAuthorizeTokenResponseData}
     * @memberof WebhookConfigSettings
     */
    'http'?: OAuthAuthorizeTokenResponseData;
    /**
     * 
     * @type {WebhookConfigSettingsKey}
     * @memberof WebhookConfigSettings
     */
    'key'?: WebhookConfigSettingsKey;
}
/**
 * 
 * @export
 * @interface WebhookConfigSettingsKey
 */
export interface WebhookConfigSettingsKey {
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigSettingsKey
     */
    'secret'?: string;
}
/**
 * Webhook Config Update Request Model
 * @export
 * @interface WebhookConfigUpdateRequest
 */
export interface WebhookConfigUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigUpdateRequest
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigUpdateRequest
     */
    'topic': string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigUpdateRequest
     */
    'action': string;
    /**
     * 
     * @type {boolean}
     * @memberof WebhookConfigUpdateRequest
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigUpdateRequest
     */
    'callback': string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigUpdateRequest
     */
    'secret'?: string;
}
/**
 * Webhook Config Update Response Model
 * @export
 * @interface WebhookConfigUpdateResponse
 */
export interface WebhookConfigUpdateResponse {
    /**
     * 
     * @type {WebhookConfigUpdateResponseWebhook}
     * @memberof WebhookConfigUpdateResponse
     */
    'webhook'?: WebhookConfigUpdateResponseWebhook;
}
/**
 * 
 * @export
 * @interface WebhookConfigUpdateResponseWebhook
 */
export interface WebhookConfigUpdateResponseWebhook {
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigUpdateResponseWebhook
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigUpdateResponseWebhook
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigUpdateResponseWebhook
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigUpdateResponseWebhook
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigUpdateResponseWebhook
     */
    'type'?: string;
    /**
     * 
     * @type {WebhookConfigSettings}
     * @memberof WebhookConfigUpdateResponseWebhook
     */
    'settings'?: WebhookConfigSettings;
    /**
     * 
     * @type {boolean}
     * @memberof WebhookConfigUpdateResponseWebhook
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigUpdateResponseWebhook
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookConfigUpdateResponseWebhook
     */
    'modified_at'?: string;
    /**
     * 
     * @type {number}
     * @memberof WebhookConfigUpdateResponseWebhook
     */
    'customer_id'?: number;
}
/**
 * WebhookEvent Model
 * @export
 * @interface WebhookEvent
 */
export interface WebhookEvent {
    /**
     * 
     * @type {string}
     * @memberof WebhookEvent
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookEvent
     */
    'user_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookEvent
     */
    'configuration_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookEvent
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookEvent
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookEvent
     */
    'type'?: string;
    /**
     * 
     * @type {WebhookConfigSettings}
     * @memberof WebhookEvent
     */
    'settings'?: WebhookConfigSettings;
    /**
     * 
     * @type {WebhookEventPayload}
     * @memberof WebhookEvent
     */
    'payload'?: WebhookEventPayload;
    /**
     * 
     * @type {string}
     * @memberof WebhookEvent
     */
    'status'?: string;
    /**
     * 
     * @type {WebhookEventAudit}
     * @memberof WebhookEvent
     */
    'audit'?: WebhookEventAudit;
    /**
     * 
     * @type {string}
     * @memberof WebhookEvent
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookEvent
     */
    'modified_at'?: string;
}
/**
 * 
 * @export
 * @interface WebhookEventAudit
 */
export interface WebhookEventAudit {
    /**
     * 
     * @type {WebhookEventAudit1594090953}
     * @memberof WebhookEventAudit
     */
    '1594090953'?: WebhookEventAudit1594090953;
    /**
     * 
     * @type {WebhookEventAudit1594090954}
     * @memberof WebhookEventAudit
     */
    '1594090954'?: WebhookEventAudit1594090954;
}
/**
 * 
 * @export
 * @interface WebhookEventAudit1594090953
 */
export interface WebhookEventAudit1594090953 {
    /**
     * 
     * @type {WebhookEventAudit1594090953297}
     * @memberof WebhookEventAudit1594090953
     */
    '297'?: WebhookEventAudit1594090953297;
    /**
     * 
     * @type {WebhookEventAudit1594090953297}
     * @memberof WebhookEventAudit1594090953
     */
    '0343'?: WebhookEventAudit1594090953297;
}
/**
 * 
 * @export
 * @interface WebhookEventAudit1594090953297
 */
export interface WebhookEventAudit1594090953297 {
    /**
     * 
     * @type {string}
     * @memberof WebhookEventAudit1594090953297
     */
    'message'?: string;
}
/**
 * 
 * @export
 * @interface WebhookEventAudit1594090954
 */
export interface WebhookEventAudit1594090954 {
    /**
     * 
     * @type {WebhookEventAudit15940909545825}
     * @memberof WebhookEventAudit1594090954
     */
    '5825'?: WebhookEventAudit15940909545825;
}
/**
 * 
 * @export
 * @interface WebhookEventAudit15940909545825
 */
export interface WebhookEventAudit15940909545825 {
    /**
     * 
     * @type {string}
     * @memberof WebhookEventAudit15940909545825
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookEventAudit15940909545825
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface WebhookEventPayload
 */
export interface WebhookEventPayload {
    /**
     * 
     * @type {WebhookEventPayloadSystem}
     * @memberof WebhookEventPayload
     */
    'system'?: WebhookEventPayloadSystem;
    /**
     * 
     * @type {WebhookEventPayloadCustomer}
     * @memberof WebhookEventPayload
     */
    'customer'?: WebhookEventPayloadCustomer;
    /**
     * 
     * @type {string}
     * @memberof WebhookEventPayload
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookEventPayload
     */
    'action'?: string;
    /**
     * 
     * @type {InlineResponse2004}
     * @memberof WebhookEventPayload
     */
    'trigger'?: InlineResponse2004;
    /**
     * 
     * @type {string}
     * @memberof WebhookEventPayload
     */
    'custom'?: string | null;
}
/**
 * 
 * @export
 * @interface WebhookEventPayloadCustomer
 */
export interface WebhookEventPayloadCustomer {
    /**
     * 
     * @type {number}
     * @memberof WebhookEventPayloadCustomer
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof WebhookEventPayloadCustomer
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface WebhookEventPayloadSystem
 */
export interface WebhookEventPayloadSystem {
    /**
     * 
     * @type {string}
     * @memberof WebhookEventPayloadSystem
     */
    'provider'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookEventPayloadSystem
     */
    'service'?: string;
    /**
     * 
     * @type {WebhookEventPayloadSystemHost}
     * @memberof WebhookEventPayloadSystem
     */
    'host'?: WebhookEventPayloadSystemHost;
}
/**
 * 
 * @export
 * @interface WebhookEventPayloadSystemHost
 */
export interface WebhookEventPayloadSystemHost {
    /**
     * 
     * @type {string}
     * @memberof WebhookEventPayloadSystemHost
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookEventPayloadSystemHost
     */
    'uri'?: string;
}
/**
 * WebhookEvent Simulated Model
 * @export
 * @interface WebhookEventSimulated
 */
export interface WebhookEventSimulated {
    /**
     * 
     * @type {string}
     * @memberof WebhookEventSimulated
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookEventSimulated
     */
    'status'?: string;
    /**
     * 
     * @type {WebhookEventSimulatedConfiguration}
     * @memberof WebhookEventSimulated
     */
    'configuration'?: WebhookEventSimulatedConfiguration;
    /**
     * 
     * @type {WebhookEventPayload}
     * @memberof WebhookEventSimulated
     */
    'payload'?: WebhookEventPayload;
    /**
     * 
     * @type {object}
     * @memberof WebhookEventSimulated
     */
    'audit'?: object;
}
/**
 * 
 * @export
 * @interface WebhookEventSimulatedConfiguration
 */
export interface WebhookEventSimulatedConfiguration {
    /**
     * 
     * @type {string}
     * @memberof WebhookEventSimulatedConfiguration
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookEventSimulatedConfiguration
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookEventSimulatedConfiguration
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookEventSimulatedConfiguration
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookEventSimulatedConfiguration
     */
    'type'?: string;
}
/**
 * Webhook Simulate Model
 * @export
 * @interface WebhookSimulate
 */
export interface WebhookSimulate {
    /**
     * 
     * @type {string}
     * @memberof WebhookSimulate
     */
    'id'?: string;
    /**
     * 
     * @type {WebhookEventSimulatedConfiguration}
     * @memberof WebhookSimulate
     */
    'configuration'?: WebhookEventSimulatedConfiguration;
    /**
     * 
     * @type {WebhookSimulatePayload}
     * @memberof WebhookSimulate
     */
    'payload'?: WebhookSimulatePayload;
    /**
     * 
     * @type {WebhookEventAudit}
     * @memberof WebhookSimulate
     */
    'audit'?: WebhookEventAudit;
    /**
     * 
     * @type {string}
     * @memberof WebhookSimulate
     */
    'status'?: string;
}
/**
 * 
 * @export
 * @interface WebhookSimulatePayload
 */
export interface WebhookSimulatePayload {
    /**
     * 
     * @type {WebhookEventPayloadSystem}
     * @memberof WebhookSimulatePayload
     */
    'system'?: WebhookEventPayloadSystem;
    /**
     * 
     * @type {WebhookEventPayloadCustomer}
     * @memberof WebhookSimulatePayload
     */
    'customer'?: WebhookEventPayloadCustomer;
    /**
     * 
     * @type {string}
     * @memberof WebhookSimulatePayload
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookSimulatePayload
     */
    'action'?: string;
    /**
     * 
     * @type {InlineResponse2004}
     * @memberof WebhookSimulatePayload
     */
    'trigger'?: InlineResponse2004;
    /**
     * 
     * @type {string}
     * @memberof WebhookSimulatePayload
     */
    'custom'?: string;
}
/**
 * Webhook Simulation Request Model
 * @export
 * @interface WebhookSimulationRequest
 */
export interface WebhookSimulationRequest {
    /**
     * 
     * @type {string}
     * @memberof WebhookSimulationRequest
     */
    'configuration_id': string;
    /**
     * 
     * @type {boolean}
     * @memberof WebhookSimulationRequest
     */
    'queue': boolean;
    /**
     * 
     * @type {string}
     * @memberof WebhookSimulationRequest
     */
    'topic': string;
    /**
     * 
     * @type {string}
     * @memberof WebhookSimulationRequest
     */
    'action': string;
    /**
     * 
     * @type {InlineResponse2004}
     * @memberof WebhookSimulationRequest
     */
    'trigger': InlineResponse2004;
}
/**
 * Webhook Simulation Response Model
 * @export
 * @interface WebhookSimulationResponse
 */
export interface WebhookSimulationResponse {
    /**
     * 
     * @type {WebhookSimulationResponseData}
     * @memberof WebhookSimulationResponse
     */
    'data'?: WebhookSimulationResponseData;
}
/**
 * 
 * @export
 * @interface WebhookSimulationResponseData
 */
export interface WebhookSimulationResponseData {
    /**
     * 
     * @type {string}
     * @memberof WebhookSimulationResponseData
     */
    'topic'?: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookSimulationResponseData
     */
    'action'?: string;
    /**
     * 
     * @type {InlineResponse2004}
     * @memberof WebhookSimulationResponseData
     */
    'trigger'?: InlineResponse2004;
    /**
     * 
     * @type {Array<WebhookEventSimulated>}
     * @memberof WebhookSimulationResponseData
     */
    'events'?: Array<WebhookEventSimulated>;
}

/**
 * APITokenApi - axios parameter creator
 * @export
 */
export const APITokenApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create personal access token 
         * @summary Create personal token
         * @param {CreatePersonalTokenRequest} [createPersonalTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPersonalToken: async (createPersonalTokenRequest?: CreatePersonalTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/personal_tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPersonalTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of personal access tokens
         * @summary Get personal token
         * @param {string} [name] Name of personal token
         * @param {string} [prefix] Personal token name prefix before colon
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalToken: async (name?: string, prefix?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/personal_tokens`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (prefix !== undefined) {
                localVarQueryParameter['prefix'] = prefix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * APITokenApi - functional programming interface
 * @export
 */
export const APITokenApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = APITokenApiAxiosParamCreator(configuration)
    return {
        /**
         * Create personal access token 
         * @summary Create personal token
         * @param {CreatePersonalTokenRequest} [createPersonalTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPersonalToken(createPersonalTokenRequest?: CreatePersonalTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatePersonalTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPersonalToken(createPersonalTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of personal access tokens
         * @summary Get personal token
         * @param {string} [name] Name of personal token
         * @param {string} [prefix] Personal token name prefix before colon
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPersonalToken(name?: string, prefix?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PersonalAccessToken>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonalToken(name, prefix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * APITokenApi - factory interface
 * @export
 */
export const APITokenApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = APITokenApiFp(configuration)
    return {
        /**
         * Create personal access token 
         * @summary Create personal token
         * @param {CreatePersonalTokenRequest} [createPersonalTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPersonalToken(createPersonalTokenRequest?: CreatePersonalTokenRequest, options?: any): AxiosPromise<CreatePersonalTokenResponse> {
            return localVarFp.createPersonalToken(createPersonalTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * List of personal access tokens
         * @summary Get personal token
         * @param {string} [name] Name of personal token
         * @param {string} [prefix] Personal token name prefix before colon
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPersonalToken(name?: string, prefix?: string, options?: any): AxiosPromise<Array<PersonalAccessToken>> {
            return localVarFp.getPersonalToken(name, prefix, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * APITokenApi - object-oriented interface
 * @export
 * @class APITokenApi
 * @extends {BaseAPI}
 */
export class APITokenApi extends BaseAPI {
    /**
     * Create personal access token 
     * @summary Create personal token
     * @param {CreatePersonalTokenRequest} [createPersonalTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APITokenApi
     */
    public createPersonalToken(createPersonalTokenRequest?: CreatePersonalTokenRequest, options?: AxiosRequestConfig) {
        return APITokenApiFp(this.configuration).createPersonalToken(createPersonalTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of personal access tokens
     * @summary Get personal token
     * @param {string} [name] Name of personal token
     * @param {string} [prefix] Personal token name prefix before colon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof APITokenApi
     */
    public getPersonalToken(name?: string, prefix?: string, options?: AxiosRequestConfig) {
        return APITokenApiFp(this.configuration).getPersonalToken(name, prefix, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActivitiesApi - axios parameter creator
 * @export
 */
export const ActivitiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes one or more activities.
         * @summary Delete activities
         * @param {DeleteActivitiesRequest} [deleteActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivities: async (deleteActivitiesRequest?: DeleteActivitiesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteActivitiesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API returns activity details as well as deep activity details.
         * @summary Get Activity Details
         * @param {string} id Activity id
         * @param {Array<'actdic_status' | 'home_team' | 'owner' | 'periods.period_athletes' | 'periods.period_athletes.rotations' | 'venue_field'>} [include] Entities to include under each item, comma separated. Set this to \&#39;all\&#39; to get the deep activity details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivity: async (id: string, include?: Array<'actdic_status' | 'home_team' | 'owner' | 'periods.period_athletes' | 'periods.period_athletes.rotations' | 'venue_field'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivity', 'id', id)
            const localVarPath = `/activities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API returns a list of `activities`.
         * @summary Get List of Activities
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page for pagination
         * @param {ActivitiesSortEnum} [sort] Which field to sort by. Prepend \&#39;-\&#39; to sort in descending order.
         * @param {number} [deleted] Set to 1 to return deleted activities instead
         * @param {Array<'actdic_status' | 'activity_athletes'>} [include] Entities to include under each item, comma separated.
         * @param {number} [startTime] Filter out activities with start time less than this value (POSIX time in seconds)
         * @param {number} [endTime] Filter out activities with end time greater than this value (POSIX time in seconds)
         * @param {string} [query] Filter activities by name
         * @param {Array<string>} [tags] Filter activities by tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActivities: async (page?: number, pageSize?: number, sort?: ActivitiesSortEnum, deleted?: number, include?: Array<'actdic_status' | 'activity_athletes'>, startTime?: number, endTime?: number, query?: string, tags?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (tags) {
                localVarQueryParameter['tags'] = tags.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the list of athletes for the provided activity id.
         * @summary Get all Athletes for an Activity
         * @param {string} id Activity Id
         * @param {Array<'device_info'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthletesInActivity: async (id: string, include?: Array<'device_info'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAthletesInActivity', 'id', id)
            const localVarPath = `/activities/{id}/athletes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API returns the deep activity details for the given activity id.
         * @summary Get Deep Activity Details
         * @param {string} id Activity Id
         * @param {Array<'all'>} [include] Entities to include, comma separated. (\&quot;all\&quot; returns \&quot;deep activity\&quot; details)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeepActivity: async (id: string, include?: Array<'all'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeepActivity', 'id', id)
            const localVarPath = `/activities/{id}?include=all`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all activity-dictionary records for the provided activity id including device and mapped athlete info.
         * @summary Get devices for an Activity
         * @param {string} id Activity id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDevices', 'id', id)
            const localVarPath = `/activities/{id}/devices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API returns the list of periods for the provided activity id.
         * @summary Get all Periods for an Activity
         * @param {string} id Activity Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriodsInActivity: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPeriodsInActivity', 'id', id)
            const localVarPath = `/activities/{id}/periods`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns all the tags associated with the provided activity id.
         * @summary Get all Tags for an Activity
         * @param {string} id Activity id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsInActivity: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTagsInActivity', 'id', id)
            const localVarPath = `/activities/{id}/tags`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API call updates everything about an activity in a single request.
         * @summary Update an activity, its periods, athletes and rotations
         * @param {string} id Activity id
         * @param {ActivityUpdateBatchRequest} [activityUpdateBatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putActivityBatch: async (id: string, activityUpdateBatchRequest?: ActivityUpdateBatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putActivityBatch', 'id', id)
            const localVarPath = `/activities/{id}/batch`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityUpdateBatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reprocesses one or more activities.
         * @summary Reprocess activities
         * @param {ReprocessActivitiesRequest} [reprocessActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reprocessActivities: async (reprocessActivitiesRequest?: ReprocessActivitiesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activities/reprocess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reprocessActivitiesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Restores one or more activities.
         * @summary Restore activities
         * @param {RestoreActivitiesRequest} [restoreActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreActivities: async (restoreActivitiesRequest?: RestoreActivitiesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/activities/restore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restoreActivitiesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update user for activity ids
         * @summary Update user for activity ids
         * @param {string} userId User id
         * @param {UpdateActivitiesUserRequest} [updateActivitiesUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivitiesUser: async (userId: string, updateActivitiesUserRequest?: UpdateActivitiesUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateActivitiesUser', 'userId', userId)
            const localVarPath = `/activities/user/{user_id}`
                .replace(`{${"user_id"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateActivitiesUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update activity - only the name can be updated.
         * @summary Update an activity
         * @param {string} id Activity Id
         * @param {ActivityUpdateRequest} [activityUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivity: async (id: string, activityUpdateRequest?: ActivityUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateActivity', 'id', id)
            const localVarPath = `/activities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(activityUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the rotation information for an athlete within a specific period for a activity, athlete, and period. 
         * @summary Update Rotations for an athlete in a Period
         * @param {string} activityId Activity id.
         * @param {string} periodId Period Id.
         * @param {string} athleteId Athlete Id.
         * @param {Array<Rotation>} rotation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAthleteRotations: async (activityId: string, periodId: string, athleteId: string, rotation: Array<Rotation>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('updateAthleteRotations', 'activityId', activityId)
            // verify required parameter 'periodId' is not null or undefined
            assertParamExists('updateAthleteRotations', 'periodId', periodId)
            // verify required parameter 'athleteId' is not null or undefined
            assertParamExists('updateAthleteRotations', 'athleteId', athleteId)
            // verify required parameter 'rotation' is not null or undefined
            assertParamExists('updateAthleteRotations', 'rotation', rotation)
            const localVarPath = `/activities/{activity_id}/periods/{period_id}/athletes/{athlete_id}/rotations`
                .replace(`{${"activity_id"}}`, encodeURIComponent(String(activityId)))
                .replace(`{${"period_id"}}`, encodeURIComponent(String(periodId)))
                .replace(`{${"athlete_id"}}`, encodeURIComponent(String(athleteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rotation, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivitiesApi - functional programming interface
 * @export
 */
export const ActivitiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivitiesApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes one or more activities.
         * @summary Delete activities
         * @param {DeleteActivitiesRequest} [deleteActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteActivities(deleteActivitiesRequest?: DeleteActivitiesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteActivities(deleteActivitiesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API returns activity details as well as deep activity details.
         * @summary Get Activity Details
         * @param {string} id Activity id
         * @param {Array<'actdic_status' | 'home_team' | 'owner' | 'periods.period_athletes' | 'periods.period_athletes.rotations' | 'venue_field'>} [include] Entities to include under each item, comma separated. Set this to \&#39;all\&#39; to get the deep activity details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivity(id: string, include?: Array<'actdic_status' | 'home_team' | 'owner' | 'periods.period_athletes' | 'periods.period_athletes.rotations' | 'venue_field'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Activity>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivity(id, include, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API returns a list of `activities`.
         * @summary Get List of Activities
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page for pagination
         * @param {ActivitiesSortEnum} [sort] Which field to sort by. Prepend \&#39;-\&#39; to sort in descending order.
         * @param {number} [deleted] Set to 1 to return deleted activities instead
         * @param {Array<'actdic_status' | 'activity_athletes'>} [include] Entities to include under each item, comma separated.
         * @param {number} [startTime] Filter out activities with start time less than this value (POSIX time in seconds)
         * @param {number} [endTime] Filter out activities with end time greater than this value (POSIX time in seconds)
         * @param {string} [query] Filter activities by name
         * @param {Array<string>} [tags] Filter activities by tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllActivities(page?: number, pageSize?: number, sort?: ActivitiesSortEnum, deleted?: number, include?: Array<'actdic_status' | 'activity_athletes'>, startTime?: number, endTime?: number, query?: string, tags?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityListItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllActivities(page, pageSize, sort, deleted, include, startTime, endTime, query, tags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the list of athletes for the provided activity id.
         * @summary Get all Athletes for an Activity
         * @param {string} id Activity Id
         * @param {Array<'device_info'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAthletesInActivity(id: string, include?: Array<'device_info'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityAthlete>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAthletesInActivity(id, include, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API returns the deep activity details for the given activity id.
         * @summary Get Deep Activity Details
         * @param {string} id Activity Id
         * @param {Array<'all'>} [include] Entities to include, comma separated. (\&quot;all\&quot; returns \&quot;deep activity\&quot; details)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeepActivity(id: string, include?: Array<'all'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeepActivity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeepActivity(id, include, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all activity-dictionary records for the provided activity id including device and mapped athlete info.
         * @summary Get devices for an Activity
         * @param {string} id Activity id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevices(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceInfo>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevices(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API returns the list of periods for the provided activity id.
         * @summary Get all Periods for an Activity
         * @param {string} id Activity Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPeriodsInActivity(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityPeriod>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPeriodsInActivity(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns all the tags associated with the provided activity id.
         * @summary Get all Tags for an Activity
         * @param {string} id Activity id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagsInActivity(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityTag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagsInActivity(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API call updates everything about an activity in a single request.
         * @summary Update an activity, its periods, athletes and rotations
         * @param {string} id Activity id
         * @param {ActivityUpdateBatchRequest} [activityUpdateBatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putActivityBatch(id: string, activityUpdateBatchRequest?: ActivityUpdateBatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityUpdateBatchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putActivityBatch(id, activityUpdateBatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reprocesses one or more activities.
         * @summary Reprocess activities
         * @param {ReprocessActivitiesRequest} [reprocessActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reprocessActivities(reprocessActivitiesRequest?: ReprocessActivitiesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reprocessActivities(reprocessActivitiesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Restores one or more activities.
         * @summary Restore activities
         * @param {RestoreActivitiesRequest} [restoreActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreActivities(restoreActivitiesRequest?: RestoreActivitiesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreActivities(restoreActivitiesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update user for activity ids
         * @summary Update user for activity ids
         * @param {string} userId User id
         * @param {UpdateActivitiesUserRequest} [updateActivitiesUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActivitiesUser(userId: string, updateActivitiesUserRequest?: UpdateActivitiesUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivitiesUser(userId, updateActivitiesUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update activity - only the name can be updated.
         * @summary Update an activity
         * @param {string} id Activity Id
         * @param {ActivityUpdateRequest} [activityUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActivity(id: string, activityUpdateRequest?: ActivityUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActivity(id, activityUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the rotation information for an athlete within a specific period for a activity, athlete, and period. 
         * @summary Update Rotations for an athlete in a Period
         * @param {string} activityId Activity id.
         * @param {string} periodId Period Id.
         * @param {string} athleteId Athlete Id.
         * @param {Array<Rotation>} rotation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAthleteRotations(activityId: string, periodId: string, athleteId: string, rotation: Array<Rotation>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Rotation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAthleteRotations(activityId, periodId, athleteId, rotation, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivitiesApi - factory interface
 * @export
 */
export const ActivitiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivitiesApiFp(configuration)
    return {
        /**
         * Deletes one or more activities.
         * @summary Delete activities
         * @param {DeleteActivitiesRequest} [deleteActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteActivities(deleteActivitiesRequest?: DeleteActivitiesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteActivities(deleteActivitiesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This API returns activity details as well as deep activity details.
         * @summary Get Activity Details
         * @param {string} id Activity id
         * @param {Array<'actdic_status' | 'home_team' | 'owner' | 'periods.period_athletes' | 'periods.period_athletes.rotations' | 'venue_field'>} [include] Entities to include under each item, comma separated. Set this to \&#39;all\&#39; to get the deep activity details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivity(id: string, include?: Array<'actdic_status' | 'home_team' | 'owner' | 'periods.period_athletes' | 'periods.period_athletes.rotations' | 'venue_field'>, options?: any): AxiosPromise<Activity> {
            return localVarFp.getActivity(id, include, options).then((request) => request(axios, basePath));
        },
        /**
         * This API returns a list of `activities`.
         * @summary Get List of Activities
         * @param {number} [page] Page number for pagination
         * @param {number} [pageSize] Number of items per page for pagination
         * @param {ActivitiesSortEnum} [sort] Which field to sort by. Prepend \&#39;-\&#39; to sort in descending order.
         * @param {number} [deleted] Set to 1 to return deleted activities instead
         * @param {Array<'actdic_status' | 'activity_athletes'>} [include] Entities to include under each item, comma separated.
         * @param {number} [startTime] Filter out activities with start time less than this value (POSIX time in seconds)
         * @param {number} [endTime] Filter out activities with end time greater than this value (POSIX time in seconds)
         * @param {string} [query] Filter activities by name
         * @param {Array<string>} [tags] Filter activities by tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllActivities(page?: number, pageSize?: number, sort?: ActivitiesSortEnum, deleted?: number, include?: Array<'actdic_status' | 'activity_athletes'>, startTime?: number, endTime?: number, query?: string, tags?: Array<string>, options?: any): AxiosPromise<Array<ActivityListItem>> {
            return localVarFp.getAllActivities(page, pageSize, sort, deleted, include, startTime, endTime, query, tags, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the list of athletes for the provided activity id.
         * @summary Get all Athletes for an Activity
         * @param {string} id Activity Id
         * @param {Array<'device_info'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthletesInActivity(id: string, include?: Array<'device_info'>, options?: any): AxiosPromise<Array<ActivityAthlete>> {
            return localVarFp.getAthletesInActivity(id, include, options).then((request) => request(axios, basePath));
        },
        /**
         * This API returns the deep activity details for the given activity id.
         * @summary Get Deep Activity Details
         * @param {string} id Activity Id
         * @param {Array<'all'>} [include] Entities to include, comma separated. (\&quot;all\&quot; returns \&quot;deep activity\&quot; details)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeepActivity(id: string, include?: Array<'all'>, options?: any): AxiosPromise<Array<DeepActivity>> {
            return localVarFp.getDeepActivity(id, include, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all activity-dictionary records for the provided activity id including device and mapped athlete info.
         * @summary Get devices for an Activity
         * @param {string} id Activity id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevices(id: string, options?: any): AxiosPromise<Array<DeviceInfo>> {
            return localVarFp.getDevices(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This API returns the list of periods for the provided activity id.
         * @summary Get all Periods for an Activity
         * @param {string} id Activity Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriodsInActivity(id: string, options?: any): AxiosPromise<Array<ActivityPeriod>> {
            return localVarFp.getPeriodsInActivity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns all the tags associated with the provided activity id.
         * @summary Get all Tags for an Activity
         * @param {string} id Activity id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsInActivity(id: string, options?: any): AxiosPromise<Array<ActivityTag>> {
            return localVarFp.getTagsInActivity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This API call updates everything about an activity in a single request.
         * @summary Update an activity, its periods, athletes and rotations
         * @param {string} id Activity id
         * @param {ActivityUpdateBatchRequest} [activityUpdateBatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putActivityBatch(id: string, activityUpdateBatchRequest?: ActivityUpdateBatchRequest, options?: any): AxiosPromise<ActivityUpdateBatchResponse> {
            return localVarFp.putActivityBatch(id, activityUpdateBatchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Reprocesses one or more activities.
         * @summary Reprocess activities
         * @param {ReprocessActivitiesRequest} [reprocessActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reprocessActivities(reprocessActivitiesRequest?: ReprocessActivitiesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.reprocessActivities(reprocessActivitiesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Restores one or more activities.
         * @summary Restore activities
         * @param {RestoreActivitiesRequest} [restoreActivitiesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreActivities(restoreActivitiesRequest?: RestoreActivitiesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.restoreActivities(restoreActivitiesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update user for activity ids
         * @summary Update user for activity ids
         * @param {string} userId User id
         * @param {UpdateActivitiesUserRequest} [updateActivitiesUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivitiesUser(userId: string, updateActivitiesUserRequest?: UpdateActivitiesUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateActivitiesUser(userId, updateActivitiesUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update activity - only the name can be updated.
         * @summary Update an activity
         * @param {string} id Activity Id
         * @param {ActivityUpdateRequest} [activityUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActivity(id: string, activityUpdateRequest?: ActivityUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateActivity(id, activityUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the rotation information for an athlete within a specific period for a activity, athlete, and period. 
         * @summary Update Rotations for an athlete in a Period
         * @param {string} activityId Activity id.
         * @param {string} periodId Period Id.
         * @param {string} athleteId Athlete Id.
         * @param {Array<Rotation>} rotation 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAthleteRotations(activityId: string, periodId: string, athleteId: string, rotation: Array<Rotation>, options?: any): AxiosPromise<Array<Rotation>> {
            return localVarFp.updateAthleteRotations(activityId, periodId, athleteId, rotation, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivitiesApi - object-oriented interface
 * @export
 * @class ActivitiesApi
 * @extends {BaseAPI}
 */
export class ActivitiesApi extends BaseAPI {
    /**
     * Deletes one or more activities.
     * @summary Delete activities
     * @param {DeleteActivitiesRequest} [deleteActivitiesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public deleteActivities(deleteActivitiesRequest?: DeleteActivitiesRequest, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).deleteActivities(deleteActivitiesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API returns activity details as well as deep activity details.
     * @summary Get Activity Details
     * @param {string} id Activity id
     * @param {Array<'actdic_status' | 'home_team' | 'owner' | 'periods.period_athletes' | 'periods.period_athletes.rotations' | 'venue_field'>} [include] Entities to include under each item, comma separated. Set this to \&#39;all\&#39; to get the deep activity details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public getActivity(id: string, include?: Array<'actdic_status' | 'home_team' | 'owner' | 'periods.period_athletes' | 'periods.period_athletes.rotations' | 'venue_field'>, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).getActivity(id, include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API returns a list of `activities`.
     * @summary Get List of Activities
     * @param {number} [page] Page number for pagination
     * @param {number} [pageSize] Number of items per page for pagination
     * @param {ActivitiesSortEnum} [sort] Which field to sort by. Prepend \&#39;-\&#39; to sort in descending order.
     * @param {number} [deleted] Set to 1 to return deleted activities instead
     * @param {Array<'actdic_status' | 'activity_athletes'>} [include] Entities to include under each item, comma separated.
     * @param {number} [startTime] Filter out activities with start time less than this value (POSIX time in seconds)
     * @param {number} [endTime] Filter out activities with end time greater than this value (POSIX time in seconds)
     * @param {string} [query] Filter activities by name
     * @param {Array<string>} [tags] Filter activities by tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public getAllActivities(page?: number, pageSize?: number, sort?: ActivitiesSortEnum, deleted?: number, include?: Array<'actdic_status' | 'activity_athletes'>, startTime?: number, endTime?: number, query?: string, tags?: Array<string>, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).getAllActivities(page, pageSize, sort, deleted, include, startTime, endTime, query, tags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the list of athletes for the provided activity id.
     * @summary Get all Athletes for an Activity
     * @param {string} id Activity Id
     * @param {Array<'device_info'>} [include] Entities to include under each item, comma separated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public getAthletesInActivity(id: string, include?: Array<'device_info'>, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).getAthletesInActivity(id, include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API returns the deep activity details for the given activity id.
     * @summary Get Deep Activity Details
     * @param {string} id Activity Id
     * @param {Array<'all'>} [include] Entities to include, comma separated. (\&quot;all\&quot; returns \&quot;deep activity\&quot; details)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public getDeepActivity(id: string, include?: Array<'all'>, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).getDeepActivity(id, include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all activity-dictionary records for the provided activity id including device and mapped athlete info.
     * @summary Get devices for an Activity
     * @param {string} id Activity id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public getDevices(id: string, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).getDevices(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API returns the list of periods for the provided activity id.
     * @summary Get all Periods for an Activity
     * @param {string} id Activity Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public getPeriodsInActivity(id: string, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).getPeriodsInActivity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns all the tags associated with the provided activity id.
     * @summary Get all Tags for an Activity
     * @param {string} id Activity id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public getTagsInActivity(id: string, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).getTagsInActivity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API call updates everything about an activity in a single request.
     * @summary Update an activity, its periods, athletes and rotations
     * @param {string} id Activity id
     * @param {ActivityUpdateBatchRequest} [activityUpdateBatchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public putActivityBatch(id: string, activityUpdateBatchRequest?: ActivityUpdateBatchRequest, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).putActivityBatch(id, activityUpdateBatchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reprocesses one or more activities.
     * @summary Reprocess activities
     * @param {ReprocessActivitiesRequest} [reprocessActivitiesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public reprocessActivities(reprocessActivitiesRequest?: ReprocessActivitiesRequest, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).reprocessActivities(reprocessActivitiesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Restores one or more activities.
     * @summary Restore activities
     * @param {RestoreActivitiesRequest} [restoreActivitiesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public restoreActivities(restoreActivitiesRequest?: RestoreActivitiesRequest, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).restoreActivities(restoreActivitiesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update user for activity ids
     * @summary Update user for activity ids
     * @param {string} userId User id
     * @param {UpdateActivitiesUserRequest} [updateActivitiesUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public updateActivitiesUser(userId: string, updateActivitiesUserRequest?: UpdateActivitiesUserRequest, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).updateActivitiesUser(userId, updateActivitiesUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update activity - only the name can be updated.
     * @summary Update an activity
     * @param {string} id Activity Id
     * @param {ActivityUpdateRequest} [activityUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public updateActivity(id: string, activityUpdateRequest?: ActivityUpdateRequest, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).updateActivity(id, activityUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the rotation information for an athlete within a specific period for a activity, athlete, and period. 
     * @summary Update Rotations for an athlete in a Period
     * @param {string} activityId Activity id.
     * @param {string} periodId Period Id.
     * @param {string} athleteId Athlete Id.
     * @param {Array<Rotation>} rotation 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesApi
     */
    public updateAthleteRotations(activityId: string, periodId: string, athleteId: string, rotation: Array<Rotation>, options?: AxiosRequestConfig) {
        return ActivitiesApiFp(this.configuration).updateAthleteRotations(activityId, periodId, athleteId, rotation, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Import an activity XML file
         * @param {any} xmlFile 
         * @param {string} [jobId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivityFile: async (xmlFile: any, jobId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xmlFile' is not null or undefined
            assertParamExists('postActivityFile', 'xmlFile', xmlFile)
            const localVarPath = `/file/activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (xmlFile !== undefined) { 
                localVarFormParams.append('xml_file', xmlFile as any);
            }
    
            if (jobId !== undefined) { 
                localVarFormParams.append('jobId', jobId as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Import an activity XML file
         * @param {any} xmlFile 
         * @param {string} [jobId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postActivityFile(xmlFile: any, jobId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postActivityFile(xmlFile, jobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityApiFp(configuration)
    return {
        /**
         * 
         * @summary Import an activity XML file
         * @param {any} xmlFile 
         * @param {string} [jobId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postActivityFile(xmlFile: any, jobId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.postActivityFile(xmlFile, jobId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * 
     * @summary Import an activity XML file
     * @param {any} xmlFile 
     * @param {string} [jobId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public postActivityFile(xmlFile: any, jobId?: string, options?: AxiosRequestConfig) {
        return ActivityApiFp(this.configuration).postActivityFile(xmlFile, jobId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnnotationsApi - axios parameter creator
 * @export
 */
export const AnnotationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete an annotation
         * @summary Delete an annotation
         * @param {string} id Annotation Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAnnotation', 'id', id)
            const localVarPath = `/annotations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an unused annotation category
         * @summary Delete an unused Annotation Category
         * @param {string} id Annotation category Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotationCategory: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAnnotationCategory', 'id', id)
            const localVarPath = `/annotation_categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will return all the annotations for a given activity id.
         * @summary Get all Annotations For an Activity
         * @param {string} id Activity Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityAnnotations: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getActivityAnnotations', 'id', id)
            const localVarPath = `/activities/{id}/annotations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will return an annotation for the provided annotation id.
         * @summary Get an Annotation
         * @param {string} id Annotation Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotation: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAnnotation', 'id', id)
            const localVarPath = `/annotations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will return all the annotation categories for the current account.
         * @summary Get all Annotation Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotation_categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will return an annotation for the provided athlete id and annotation id.
         * @summary Get an Athlete Annotation
         * @param {string} athleteId Athlete Id
         * @param {string} annotationAthleteId Annotation Athlete Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthleteAnnotation: async (athleteId: string, annotationAthleteId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'athleteId' is not null or undefined
            assertParamExists('getAthleteAnnotation', 'athleteId', athleteId)
            // verify required parameter 'annotationAthleteId' is not null or undefined
            assertParamExists('getAthleteAnnotation', 'annotationAthleteId', annotationAthleteId)
            const localVarPath = `/athletes/{athlete_id}/annotations/{annotation_athlete_id}`
                .replace(`{${"athlete_id"}}`, encodeURIComponent(String(athleteId)))
                .replace(`{${"annotation_athlete_id"}}`, encodeURIComponent(String(annotationAthleteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will return all the athlete annotations for a given athlete id.
         * @summary Get all Athlete Annotations For an Athlete
         * @param {string} id Athlete Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthleteAnnotations: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAthleteAnnotations', 'id', id)
            const localVarPath = `/athletes/{id}/annotations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will return all the annotations for a given period id.
         * @summary Get all Annotations For a Period
         * @param {string} id Period Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriodAnnotations: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPeriodAnnotations', 'id', id)
            const localVarPath = `/periods/{id}/annotations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an annotation
         * @summary Update an annotation
         * @param {string} id Annotation Id
         * @param {AnnotationUpdateRequest} [annotationUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAnnotation: async (id: string, annotationUpdateRequest?: AnnotationUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchAnnotation', 'id', id)
            const localVarPath = `/annotations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an annotation category
         * @summary Update an Annotation Category
         * @param {string} id Annotation category Id
         * @param {AnnotationCategoryUpdateRequest} [annotationCategoryUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAnnotationCategory: async (id: string, annotationCategoryUpdateRequest?: AnnotationCategoryUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchAnnotationCategory', 'id', id)
            const localVarPath = `/annotation_categories/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationCategoryUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an annotation category.
         * @summary Create an Annotation Category
         * @param {AnnotationCategoryCreateRequest} [annotationCategoryCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAnnotationCategory: async (annotationCategoryCreateRequest?: AnnotationCategoryCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotation_categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationCategoryCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create an Annotation.
         * @summary Create an Annotation
         * @param {AnnotationCreateRequest} [annotationCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAnnotations: async (annotationCreateRequest?: AnnotationCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/annotations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(annotationCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnotationsApi - functional programming interface
 * @export
 */
export const AnnotationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnotationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete an annotation
         * @summary Delete an annotation
         * @param {string} id Annotation Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnotation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnnotation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an unused annotation category
         * @summary Delete an unused Annotation Category
         * @param {string} id Annotation category Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnnotationCategory(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnnotationCategory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API will return all the annotations for a given activity id.
         * @summary Get all Annotations For an Activity
         * @param {string} id Activity Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getActivityAnnotations(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Annotation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityAnnotations(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API will return an annotation for the provided annotation id.
         * @summary Get an Annotation
         * @param {string} id Annotation Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnotation(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Annotation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnotation(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API will return all the annotation categories for the current account.
         * @summary Get all Annotation Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnnotationCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnotationCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnnotationCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API will return an annotation for the provided athlete id and annotation id.
         * @summary Get an Athlete Annotation
         * @param {string} athleteId Athlete Id
         * @param {string} annotationAthleteId Annotation Athlete Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAthleteAnnotation(athleteId: string, annotationAthleteId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationAthlete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAthleteAnnotation(athleteId, annotationAthleteId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API will return all the athlete annotations for a given athlete id.
         * @summary Get all Athlete Annotations For an Athlete
         * @param {string} id Athlete Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAthleteAnnotations(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnnotationAthlete>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAthleteAnnotations(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API will return all the annotations for a given period id.
         * @summary Get all Annotations For a Period
         * @param {string} id Period Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPeriodAnnotations(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Annotation>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPeriodAnnotations(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an annotation
         * @summary Update an annotation
         * @param {string} id Annotation Id
         * @param {AnnotationUpdateRequest} [annotationUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAnnotation(id: string, annotationUpdateRequest?: AnnotationUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Annotation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAnnotation(id, annotationUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an annotation category
         * @summary Update an Annotation Category
         * @param {string} id Annotation category Id
         * @param {AnnotationCategoryUpdateRequest} [annotationCategoryUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAnnotationCategory(id: string, annotationCategoryUpdateRequest?: AnnotationCategoryUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAnnotationCategory(id, annotationCategoryUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create an annotation category.
         * @summary Create an Annotation Category
         * @param {AnnotationCategoryCreateRequest} [annotationCategoryCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAnnotationCategory(annotationCategoryCreateRequest?: AnnotationCategoryCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnnotationCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAnnotationCategory(annotationCategoryCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create an Annotation.
         * @summary Create an Annotation
         * @param {AnnotationCreateRequest} [annotationCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAnnotations(annotationCreateRequest?: AnnotationCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Annotation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAnnotations(annotationCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnnotationsApi - factory interface
 * @export
 */
export const AnnotationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnotationsApiFp(configuration)
    return {
        /**
         * Delete an annotation
         * @summary Delete an annotation
         * @param {string} id Annotation Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotation(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnnotation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an unused annotation category
         * @summary Delete an unused Annotation Category
         * @param {string} id Annotation category Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnnotationCategory(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnnotationCategory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will return all the annotations for a given activity id.
         * @summary Get all Annotations For an Activity
         * @param {string} id Activity Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityAnnotations(id: string, options?: any): AxiosPromise<Array<Annotation>> {
            return localVarFp.getActivityAnnotations(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will return an annotation for the provided annotation id.
         * @summary Get an Annotation
         * @param {string} id Annotation Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotation(id: string, options?: any): AxiosPromise<Annotation> {
            return localVarFp.getAnnotation(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will return all the annotation categories for the current account.
         * @summary Get all Annotation Categories
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnnotationCategories(options?: any): AxiosPromise<Array<AnnotationCategory>> {
            return localVarFp.getAnnotationCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * This API will return an annotation for the provided athlete id and annotation id.
         * @summary Get an Athlete Annotation
         * @param {string} athleteId Athlete Id
         * @param {string} annotationAthleteId Annotation Athlete Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthleteAnnotation(athleteId: string, annotationAthleteId: string, options?: any): AxiosPromise<AnnotationAthlete> {
            return localVarFp.getAthleteAnnotation(athleteId, annotationAthleteId, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will return all the athlete annotations for a given athlete id.
         * @summary Get all Athlete Annotations For an Athlete
         * @param {string} id Athlete Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthleteAnnotations(id: string, options?: any): AxiosPromise<Array<AnnotationAthlete>> {
            return localVarFp.getAthleteAnnotations(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will return all the annotations for a given period id.
         * @summary Get all Annotations For a Period
         * @param {string} id Period Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriodAnnotations(id: string, options?: any): AxiosPromise<Array<Annotation>> {
            return localVarFp.getPeriodAnnotations(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an annotation
         * @summary Update an annotation
         * @param {string} id Annotation Id
         * @param {AnnotationUpdateRequest} [annotationUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAnnotation(id: string, annotationUpdateRequest?: AnnotationUpdateRequest, options?: any): AxiosPromise<Annotation> {
            return localVarFp.patchAnnotation(id, annotationUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an annotation category
         * @summary Update an Annotation Category
         * @param {string} id Annotation category Id
         * @param {AnnotationCategoryUpdateRequest} [annotationCategoryUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAnnotationCategory(id: string, annotationCategoryUpdateRequest?: AnnotationCategoryUpdateRequest, options?: any): AxiosPromise<AnnotationCategory> {
            return localVarFp.patchAnnotationCategory(id, annotationCategoryUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an annotation category.
         * @summary Create an Annotation Category
         * @param {AnnotationCategoryCreateRequest} [annotationCategoryCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAnnotationCategory(annotationCategoryCreateRequest?: AnnotationCategoryCreateRequest, options?: any): AxiosPromise<AnnotationCategory> {
            return localVarFp.postAnnotationCategory(annotationCategoryCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create an Annotation.
         * @summary Create an Annotation
         * @param {AnnotationCreateRequest} [annotationCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAnnotations(annotationCreateRequest?: AnnotationCreateRequest, options?: any): AxiosPromise<Annotation> {
            return localVarFp.postAnnotations(annotationCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnotationsApi - object-oriented interface
 * @export
 * @class AnnotationsApi
 * @extends {BaseAPI}
 */
export class AnnotationsApi extends BaseAPI {
    /**
     * Delete an annotation
     * @summary Delete an annotation
     * @param {string} id Annotation Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public deleteAnnotation(id: string, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).deleteAnnotation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an unused annotation category
     * @summary Delete an unused Annotation Category
     * @param {string} id Annotation category Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public deleteAnnotationCategory(id: string, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).deleteAnnotationCategory(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API will return all the annotations for a given activity id.
     * @summary Get all Annotations For an Activity
     * @param {string} id Activity Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public getActivityAnnotations(id: string, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).getActivityAnnotations(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API will return an annotation for the provided annotation id.
     * @summary Get an Annotation
     * @param {string} id Annotation Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public getAnnotation(id: string, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).getAnnotation(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API will return all the annotation categories for the current account.
     * @summary Get all Annotation Categories
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public getAnnotationCategories(options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).getAnnotationCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API will return an annotation for the provided athlete id and annotation id.
     * @summary Get an Athlete Annotation
     * @param {string} athleteId Athlete Id
     * @param {string} annotationAthleteId Annotation Athlete Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public getAthleteAnnotation(athleteId: string, annotationAthleteId: string, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).getAthleteAnnotation(athleteId, annotationAthleteId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API will return all the athlete annotations for a given athlete id.
     * @summary Get all Athlete Annotations For an Athlete
     * @param {string} id Athlete Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public getAthleteAnnotations(id: string, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).getAthleteAnnotations(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API will return all the annotations for a given period id.
     * @summary Get all Annotations For a Period
     * @param {string} id Period Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public getPeriodAnnotations(id: string, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).getPeriodAnnotations(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an annotation
     * @summary Update an annotation
     * @param {string} id Annotation Id
     * @param {AnnotationUpdateRequest} [annotationUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public patchAnnotation(id: string, annotationUpdateRequest?: AnnotationUpdateRequest, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).patchAnnotation(id, annotationUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an annotation category
     * @summary Update an Annotation Category
     * @param {string} id Annotation category Id
     * @param {AnnotationCategoryUpdateRequest} [annotationCategoryUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public patchAnnotationCategory(id: string, annotationCategoryUpdateRequest?: AnnotationCategoryUpdateRequest, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).patchAnnotationCategory(id, annotationCategoryUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an annotation category.
     * @summary Create an Annotation Category
     * @param {AnnotationCategoryCreateRequest} [annotationCategoryCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public postAnnotationCategory(annotationCategoryCreateRequest?: AnnotationCategoryCreateRequest, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).postAnnotationCategory(annotationCategoryCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create an Annotation.
     * @summary Create an Annotation
     * @param {AnnotationCreateRequest} [annotationCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnotationsApi
     */
    public postAnnotations(annotationCreateRequest?: AnnotationCreateRequest, options?: AxiosRequestConfig) {
        return AnnotationsApiFp(this.configuration).postAnnotations(annotationCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AthleteThresholdsApi - axios parameter creator
 * @export
 */
export const AthleteThresholdsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates an empty threshold set.
         * @summary Create a threshold set
         * @param {CreateThresholdSetRequest} [createThresholdSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThresholdSet: async (createThresholdSetRequest?: CreateThresholdSetRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/threshold_sets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createThresholdSetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes one or more threshold sets.
         * @summary Delete threshold sets
         * @param {DeleteThresholdSetsRequest} [deleteThresholdSetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteThresholdSets: async (deleteThresholdSetsRequest?: DeleteThresholdSetsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/threshold_sets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteThresholdSetsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get threshold alert settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThresholdAlerts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/threshold_alerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Show a threshold set
         * @param {string} id ThresholdSet Id
         * @param {Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>} [include] Entities to include, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThresholdSet: async (id: string, include?: Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getThresholdSet', 'id', id)
            const localVarPath = `/threshold_sets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of threshold sets
         * @summary List threshold sets
         * @param {number} [deleted] Whether to return deleted items
         * @param {ThresholdSetsSortEnum} [sort] Which field to sort by. Prepend \&#39;-\&#39; to sort in descending order.
         * @param {Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listThresholdSets: async (deleted?: number, sort?: ThresholdSetsSortEnum, include?: Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/threshold_sets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deleted !== undefined) {
                localVarQueryParameter['deleted'] = deleted;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Restores one or more threshold sets.
         * @summary Restore (undelete) threshold sets
         * @param {RestoreThresholdSetsRequest} [restoreThresholdSetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreThresholdSets: async (restoreThresholdSetsRequest?: RestoreThresholdSetsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/threshold_sets/restore`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(restoreThresholdSetsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update threshold alert settings
         * @param {ThresholdAlertSettings} [thresholdAlertSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateThresholdAlerts: async (thresholdAlertSettings?: ThresholdAlertSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/threshold_alerts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(thresholdAlertSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a threshold set
         * @param {string} id ThresholdSet Id
         * @param {Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>} [include] Entities to include under each item, comma separated.
         * @param {ThresholdSetUpdateRequest} [thresholdSetUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateThresholdSet: async (id: string, include?: Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>, thresholdSetUpdateRequest?: ThresholdSetUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateThresholdSet', 'id', id)
            const localVarPath = `/threshold_sets/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(thresholdSetUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AthleteThresholdsApi - functional programming interface
 * @export
 */
export const AthleteThresholdsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AthleteThresholdsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates an empty threshold set.
         * @summary Create a threshold set
         * @param {CreateThresholdSetRequest} [createThresholdSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createThresholdSet(createThresholdSetRequest?: CreateThresholdSetRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThresholdSetSimple>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createThresholdSet(createThresholdSetRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes one or more threshold sets.
         * @summary Delete threshold sets
         * @param {DeleteThresholdSetsRequest} [deleteThresholdSetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteThresholdSets(deleteThresholdSetsRequest?: DeleteThresholdSetsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteThresholdSets(deleteThresholdSetsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get threshold alert settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThresholdAlerts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThresholdAlertSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThresholdAlerts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Show a threshold set
         * @param {string} id ThresholdSet Id
         * @param {Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>} [include] Entities to include, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getThresholdSet(id: string, include?: Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThresholdSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getThresholdSet(id, include, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of threshold sets
         * @summary List threshold sets
         * @param {number} [deleted] Whether to return deleted items
         * @param {ThresholdSetsSortEnum} [sort] Which field to sort by. Prepend \&#39;-\&#39; to sort in descending order.
         * @param {Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listThresholdSets(deleted?: number, sort?: ThresholdSetsSortEnum, include?: Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ThresholdSet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listThresholdSets(deleted, sort, include, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Restores one or more threshold sets.
         * @summary Restore (undelete) threshold sets
         * @param {RestoreThresholdSetsRequest} [restoreThresholdSetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async restoreThresholdSets(restoreThresholdSetsRequest?: RestoreThresholdSetsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.restoreThresholdSets(restoreThresholdSetsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update threshold alert settings
         * @param {ThresholdAlertSettings} [thresholdAlertSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateThresholdAlerts(thresholdAlertSettings?: ThresholdAlertSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateThresholdAlerts(thresholdAlertSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a threshold set
         * @param {string} id ThresholdSet Id
         * @param {Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>} [include] Entities to include under each item, comma separated.
         * @param {ThresholdSetUpdateRequest} [thresholdSetUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateThresholdSet(id: string, include?: Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>, thresholdSetUpdateRequest?: ThresholdSetUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ThresholdSet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateThresholdSet(id, include, thresholdSetUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AthleteThresholdsApi - factory interface
 * @export
 */
export const AthleteThresholdsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AthleteThresholdsApiFp(configuration)
    return {
        /**
         * Creates an empty threshold set.
         * @summary Create a threshold set
         * @param {CreateThresholdSetRequest} [createThresholdSetRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThresholdSet(createThresholdSetRequest?: CreateThresholdSetRequest, options?: any): AxiosPromise<ThresholdSetSimple> {
            return localVarFp.createThresholdSet(createThresholdSetRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes one or more threshold sets.
         * @summary Delete threshold sets
         * @param {DeleteThresholdSetsRequest} [deleteThresholdSetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteThresholdSets(deleteThresholdSetsRequest?: DeleteThresholdSetsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteThresholdSets(deleteThresholdSetsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get threshold alert settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThresholdAlerts(options?: any): AxiosPromise<ThresholdAlertSettings> {
            return localVarFp.getThresholdAlerts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Show a threshold set
         * @param {string} id ThresholdSet Id
         * @param {Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>} [include] Entities to include, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getThresholdSet(id: string, include?: Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>, options?: any): AxiosPromise<ThresholdSet> {
            return localVarFp.getThresholdSet(id, include, options).then((request) => request(axios, basePath));
        },
        /**
         * List of threshold sets
         * @summary List threshold sets
         * @param {number} [deleted] Whether to return deleted items
         * @param {ThresholdSetsSortEnum} [sort] Which field to sort by. Prepend \&#39;-\&#39; to sort in descending order.
         * @param {Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listThresholdSets(deleted?: number, sort?: ThresholdSetsSortEnum, include?: Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>, options?: any): AxiosPromise<Array<ThresholdSet>> {
            return localVarFp.listThresholdSets(deleted, sort, include, options).then((request) => request(axios, basePath));
        },
        /**
         * Restores one or more threshold sets.
         * @summary Restore (undelete) threshold sets
         * @param {RestoreThresholdSetsRequest} [restoreThresholdSetsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreThresholdSets(restoreThresholdSetsRequest?: RestoreThresholdSetsRequest, options?: any): AxiosPromise<void> {
            return localVarFp.restoreThresholdSets(restoreThresholdSetsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update threshold alert settings
         * @param {ThresholdAlertSettings} [thresholdAlertSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateThresholdAlerts(thresholdAlertSettings?: ThresholdAlertSettings, options?: any): AxiosPromise<void> {
            return localVarFp.updateThresholdAlerts(thresholdAlertSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a threshold set
         * @param {string} id ThresholdSet Id
         * @param {Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>} [include] Entities to include under each item, comma separated.
         * @param {ThresholdSetUpdateRequest} [thresholdSetUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateThresholdSet(id: string, include?: Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>, thresholdSetUpdateRequest?: ThresholdSetUpdateRequest, options?: any): AxiosPromise<ThresholdSet> {
            return localVarFp.updateThresholdSet(id, include, thresholdSetUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AthleteThresholdsApi - object-oriented interface
 * @export
 * @class AthleteThresholdsApi
 * @extends {BaseAPI}
 */
export class AthleteThresholdsApi extends BaseAPI {
    /**
     * Creates an empty threshold set.
     * @summary Create a threshold set
     * @param {CreateThresholdSetRequest} [createThresholdSetRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteThresholdsApi
     */
    public createThresholdSet(createThresholdSetRequest?: CreateThresholdSetRequest, options?: AxiosRequestConfig) {
        return AthleteThresholdsApiFp(this.configuration).createThresholdSet(createThresholdSetRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes one or more threshold sets.
     * @summary Delete threshold sets
     * @param {DeleteThresholdSetsRequest} [deleteThresholdSetsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteThresholdsApi
     */
    public deleteThresholdSets(deleteThresholdSetsRequest?: DeleteThresholdSetsRequest, options?: AxiosRequestConfig) {
        return AthleteThresholdsApiFp(this.configuration).deleteThresholdSets(deleteThresholdSetsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get threshold alert settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteThresholdsApi
     */
    public getThresholdAlerts(options?: AxiosRequestConfig) {
        return AthleteThresholdsApiFp(this.configuration).getThresholdAlerts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Show a threshold set
     * @param {string} id ThresholdSet Id
     * @param {Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>} [include] Entities to include, comma separated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteThresholdsApi
     */
    public getThresholdSet(id: string, include?: Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>, options?: AxiosRequestConfig) {
        return AthleteThresholdsApiFp(this.configuration).getThresholdSet(id, include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of threshold sets
     * @summary List threshold sets
     * @param {number} [deleted] Whether to return deleted items
     * @param {ThresholdSetsSortEnum} [sort] Which field to sort by. Prepend \&#39;-\&#39; to sort in descending order.
     * @param {Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>} [include] Entities to include under each item, comma separated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteThresholdsApi
     */
    public listThresholdSets(deleted?: number, sort?: ThresholdSetsSortEnum, include?: Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>, options?: AxiosRequestConfig) {
        return AthleteThresholdsApiFp(this.configuration).listThresholdSets(deleted, sort, include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Restores one or more threshold sets.
     * @summary Restore (undelete) threshold sets
     * @param {RestoreThresholdSetsRequest} [restoreThresholdSetsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteThresholdsApi
     */
    public restoreThresholdSets(restoreThresholdSetsRequest?: RestoreThresholdSetsRequest, options?: AxiosRequestConfig) {
        return AthleteThresholdsApiFp(this.configuration).restoreThresholdSets(restoreThresholdSetsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update threshold alert settings
     * @param {ThresholdAlertSettings} [thresholdAlertSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteThresholdsApi
     */
    public updateThresholdAlerts(thresholdAlertSettings?: ThresholdAlertSettings, options?: AxiosRequestConfig) {
        return AthleteThresholdsApiFp(this.configuration).updateThresholdAlerts(thresholdAlertSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a threshold set
     * @param {string} id ThresholdSet Id
     * @param {Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>} [include] Entities to include under each item, comma separated.
     * @param {ThresholdSetUpdateRequest} [thresholdSetUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthleteThresholdsApi
     */
    public updateThresholdSet(id: string, include?: Array<'thresholdSetAthletes' | 'thresholdSetAthletes.athlete' | 'thresholdSetAthletes.parameters'>, thresholdSetUpdateRequest?: ThresholdSetUpdateRequest, options?: AxiosRequestConfig) {
        return AthleteThresholdsApiFp(this.configuration).updateThresholdSet(id, include, thresholdSetUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AthletesApi - axios parameter creator
 * @export
 */
export const AthletesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This API will return athlete details for a given athlete id.
         * @summary Get Athlete Details
         * @param {string} id Athlete Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthlete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAthlete', 'id', id)
            const localVarPath = `/athletes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will return an athlete\'s bands for a given athlete id.
         * @summary Get Athlete Bands Details
         * @param {string} id Athlete Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthleteBands: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAthleteBands', 'id', id)
            const localVarPath = `/athletes/{id}/bands`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API returns the list of all athletes.
         * @summary Get List of Athletes
         * @param {string} [jersey] Jersey of athlete
         * @param {string} [firstName] Athlete\&#39;s first name
         * @param {string} [lastName] Athlete\&#39;s last name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthletes: async (jersey?: string, firstName?: string, lastName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/athletes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (jersey !== undefined) {
                localVarQueryParameter['jersey'] = jersey;
            }

            if (firstName !== undefined) {
                localVarQueryParameter['first_name'] = firstName;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['last_name'] = lastName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an Athlete
         * @param {AthleteCreationParameters} [athleteCreationParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAthlete: async (athleteCreationParameters?: AthleteCreationParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/athletes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(athleteCreationParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will update and return athlete details for a single athlete.
         * @summary Update an Athlete
         * @param {string} id Athlete Id
         * @param {AthleteUpdateRequest} [athleteUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAthlete: async (id: string, athleteUpdateRequest?: AthleteUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putAthlete', 'id', id)
            const localVarPath = `/athletes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(athleteUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AthletesApi - functional programming interface
 * @export
 */
export const AthletesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AthletesApiAxiosParamCreator(configuration)
    return {
        /**
         * This API will return athlete details for a given athlete id.
         * @summary Get Athlete Details
         * @param {string} id Athlete Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAthlete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Athlete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAthlete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API will return an athlete\'s bands for a given athlete id.
         * @summary Get Athlete Bands Details
         * @param {string} id Athlete Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAthleteBands(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Band>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAthleteBands(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API returns the list of all athletes.
         * @summary Get List of Athletes
         * @param {string} [jersey] Jersey of athlete
         * @param {string} [firstName] Athlete\&#39;s first name
         * @param {string} [lastName] Athlete\&#39;s last name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAthletes(jersey?: string, firstName?: string, lastName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Athlete>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAthletes(jersey, firstName, lastName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an Athlete
         * @param {AthleteCreationParameters} [athleteCreationParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAthlete(athleteCreationParameters?: AthleteCreationParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Athlete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAthlete(athleteCreationParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API will update and return athlete details for a single athlete.
         * @summary Update an Athlete
         * @param {string} id Athlete Id
         * @param {AthleteUpdateRequest} [athleteUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putAthlete(id: string, athleteUpdateRequest?: AthleteUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Athlete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putAthlete(id, athleteUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AthletesApi - factory interface
 * @export
 */
export const AthletesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AthletesApiFp(configuration)
    return {
        /**
         * This API will return athlete details for a given athlete id.
         * @summary Get Athlete Details
         * @param {string} id Athlete Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthlete(id: string, options?: any): AxiosPromise<Athlete> {
            return localVarFp.getAthlete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will return an athlete\'s bands for a given athlete id.
         * @summary Get Athlete Bands Details
         * @param {string} id Athlete Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthleteBands(id: string, options?: any): AxiosPromise<Array<Band>> {
            return localVarFp.getAthleteBands(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This API returns the list of all athletes.
         * @summary Get List of Athletes
         * @param {string} [jersey] Jersey of athlete
         * @param {string} [firstName] Athlete\&#39;s first name
         * @param {string} [lastName] Athlete\&#39;s last name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthletes(jersey?: string, firstName?: string, lastName?: string, options?: any): AxiosPromise<Array<Athlete>> {
            return localVarFp.getAthletes(jersey, firstName, lastName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an Athlete
         * @param {AthleteCreationParameters} [athleteCreationParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAthlete(athleteCreationParameters?: AthleteCreationParameters, options?: any): AxiosPromise<Athlete> {
            return localVarFp.postAthlete(athleteCreationParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will update and return athlete details for a single athlete.
         * @summary Update an Athlete
         * @param {string} id Athlete Id
         * @param {AthleteUpdateRequest} [athleteUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putAthlete(id: string, athleteUpdateRequest?: AthleteUpdateRequest, options?: any): AxiosPromise<Athlete> {
            return localVarFp.putAthlete(id, athleteUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AthletesApi - object-oriented interface
 * @export
 * @class AthletesApi
 * @extends {BaseAPI}
 */
export class AthletesApi extends BaseAPI {
    /**
     * This API will return athlete details for a given athlete id.
     * @summary Get Athlete Details
     * @param {string} id Athlete Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthletesApi
     */
    public getAthlete(id: string, options?: AxiosRequestConfig) {
        return AthletesApiFp(this.configuration).getAthlete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API will return an athlete\'s bands for a given athlete id.
     * @summary Get Athlete Bands Details
     * @param {string} id Athlete Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthletesApi
     */
    public getAthleteBands(id: string, options?: AxiosRequestConfig) {
        return AthletesApiFp(this.configuration).getAthleteBands(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API returns the list of all athletes.
     * @summary Get List of Athletes
     * @param {string} [jersey] Jersey of athlete
     * @param {string} [firstName] Athlete\&#39;s first name
     * @param {string} [lastName] Athlete\&#39;s last name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthletesApi
     */
    public getAthletes(jersey?: string, firstName?: string, lastName?: string, options?: AxiosRequestConfig) {
        return AthletesApiFp(this.configuration).getAthletes(jersey, firstName, lastName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an Athlete
     * @param {AthleteCreationParameters} [athleteCreationParameters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthletesApi
     */
    public postAthlete(athleteCreationParameters?: AthleteCreationParameters, options?: AxiosRequestConfig) {
        return AthletesApiFp(this.configuration).postAthlete(athleteCreationParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API will update and return athlete details for a single athlete.
     * @summary Update an Athlete
     * @param {string} id Athlete Id
     * @param {AthleteUpdateRequest} [athleteUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AthletesApi
     */
    public putAthlete(id: string, athleteUpdateRequest?: AthleteUpdateRequest, options?: AxiosRequestConfig) {
        return AthletesApiFp(this.configuration).putAthlete(id, athleteUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete an auth token for an already requested authorization code to invalidate the token for any further usage.
         * @summary Delete Authorize Token
         * @param {OAuthAuthorizeTokenRequest} [oAuthAuthorizeTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuthorizeTokenForCodeGrant: async (oAuthAuthorizeTokenRequest?: OAuthAuthorizeTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oAuthAuthorizeTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes personal access token
         * @summary Delete Personal Access Token
         * @param {string} id Personal Token Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePersonalAccessToken: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePersonalAccessToken', 'id', id)
            const localVarPath = `/oauth/personal_tokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a payload of information about an authorization code grant pending approval
         * @summary Get Authorize Code
         * @param {string} clientId Client Id
         * @param {string} redirectUri Redirection URI for when approve or decline
         * @param {string} responseType OAuth Response type, this should be code for auth code grants
         * @param {string} scope Scope is a space separated string of scopes being requested
         * @param {string} state State is a unique value that is relevant to the client service and used to identify the returning callback URI request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizeDataForCodeGrant: async (clientId: string, redirectUri: string, responseType: string, scope: string, state: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('getAuthorizeDataForCodeGrant', 'clientId', clientId)
            // verify required parameter 'redirectUri' is not null or undefined
            assertParamExists('getAuthorizeDataForCodeGrant', 'redirectUri', redirectUri)
            // verify required parameter 'responseType' is not null or undefined
            assertParamExists('getAuthorizeDataForCodeGrant', 'responseType', responseType)
            // verify required parameter 'scope' is not null or undefined
            assertParamExists('getAuthorizeDataForCodeGrant', 'scope', scope)
            // verify required parameter 'state' is not null or undefined
            assertParamExists('getAuthorizeDataForCodeGrant', 'state', state)
            const localVarPath = `/oauth/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (redirectUri !== undefined) {
                localVarQueryParameter['redirect_uri'] = redirectUri;
            }

            if (responseType !== undefined) {
                localVarQueryParameter['response_type'] = responseType;
            }

            if (scope !== undefined) {
                localVarQueryParameter['scope'] = scope;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Post an auth token for an already requested authorization code to get back the code if valid.
         * @summary Post Authorize Token
         * @param {OAuthAuthorizeTokenRequest} [oAuthAuthorizeTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthorizeTokenForCodeGrant: async (oAuthAuthorizeTokenRequest?: OAuthAuthorizeTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/authorize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(oAuthAuthorizeTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns a token for user
         * @summary Get Token for a User
         * @param {string} [accept] Accept Header e.g. \&#39;application/json\&#39;
         * @param {string} [contentType] Content Type e.g. \&#39;application/json\&#39;
         * @param {PasswordGrantRequest} [passwordGrantRequest] OAuth Request Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPasswordGrant: async (accept?: string, contentType?: string, passwordGrantRequest?: PasswordGrantRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accept !== undefined && accept !== null) {
                localVarHeaderParameter['Accept'] = String(accept);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['Content-Type'] = String(contentType);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordGrantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Refresh and generate a new token for user
         * @summary Get Token for a User using a refresh token
         * @param {RefreshTokenGrantRequest} [refreshTokenGrantRequest] OAuth Refresh Token Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRefreshTokenGrant: async (refreshTokenGrantRequest?: RefreshTokenGrantRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/oauth/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(refreshTokenGrantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete an auth token for an already requested authorization code to invalidate the token for any further usage.
         * @summary Delete Authorize Token
         * @param {OAuthAuthorizeTokenRequest} [oAuthAuthorizeTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAuthorizeTokenForCodeGrant(oAuthAuthorizeTokenRequest?: OAuthAuthorizeTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthAuthorizeTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAuthorizeTokenForCodeGrant(oAuthAuthorizeTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes personal access token
         * @summary Delete Personal Access Token
         * @param {string} id Personal Token Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePersonalAccessToken(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePersonalAccessToken(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a payload of information about an authorization code grant pending approval
         * @summary Get Authorize Code
         * @param {string} clientId Client Id
         * @param {string} redirectUri Redirection URI for when approve or decline
         * @param {string} responseType OAuth Response type, this should be code for auth code grants
         * @param {string} scope Scope is a space separated string of scopes being requested
         * @param {string} state State is a unique value that is relevant to the client service and used to identify the returning callback URI request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthorizeDataForCodeGrant(clientId: string, redirectUri: string, responseType: string, scope: string, state: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthAuthorizeDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthorizeDataForCodeGrant(clientId, redirectUri, responseType, scope, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Post an auth token for an already requested authorization code to get back the code if valid.
         * @summary Post Authorize Token
         * @param {OAuthAuthorizeTokenRequest} [oAuthAuthorizeTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAuthorizeTokenForCodeGrant(oAuthAuthorizeTokenRequest?: OAuthAuthorizeTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthAuthorizeTokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAuthorizeTokenForCodeGrant(oAuthAuthorizeTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns a token for user
         * @summary Get Token for a User
         * @param {string} [accept] Accept Header e.g. \&#39;application/json\&#39;
         * @param {string} [contentType] Content Type e.g. \&#39;application/json\&#39;
         * @param {PasswordGrantRequest} [passwordGrantRequest] OAuth Request Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postPasswordGrant(accept?: string, contentType?: string, passwordGrantRequest?: PasswordGrantRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postPasswordGrant(accept, contentType, passwordGrantRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Refresh and generate a new token for user
         * @summary Get Token for a User using a refresh token
         * @param {RefreshTokenGrantRequest} [refreshTokenGrantRequest] OAuth Refresh Token Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRefreshTokenGrant(refreshTokenGrantRequest?: RefreshTokenGrantRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OAuthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRefreshTokenGrant(refreshTokenGrantRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * Delete an auth token for an already requested authorization code to invalidate the token for any further usage.
         * @summary Delete Authorize Token
         * @param {OAuthAuthorizeTokenRequest} [oAuthAuthorizeTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuthorizeTokenForCodeGrant(oAuthAuthorizeTokenRequest?: OAuthAuthorizeTokenRequest, options?: any): AxiosPromise<OAuthAuthorizeTokenResponse> {
            return localVarFp.deleteAuthorizeTokenForCodeGrant(oAuthAuthorizeTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes personal access token
         * @summary Delete Personal Access Token
         * @param {string} id Personal Token Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePersonalAccessToken(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePersonalAccessToken(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a payload of information about an authorization code grant pending approval
         * @summary Get Authorize Code
         * @param {string} clientId Client Id
         * @param {string} redirectUri Redirection URI for when approve or decline
         * @param {string} responseType OAuth Response type, this should be code for auth code grants
         * @param {string} scope Scope is a space separated string of scopes being requested
         * @param {string} state State is a unique value that is relevant to the client service and used to identify the returning callback URI request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthorizeDataForCodeGrant(clientId: string, redirectUri: string, responseType: string, scope: string, state: string, options?: any): AxiosPromise<OAuthAuthorizeDataResponse> {
            return localVarFp.getAuthorizeDataForCodeGrant(clientId, redirectUri, responseType, scope, state, options).then((request) => request(axios, basePath));
        },
        /**
         * Post an auth token for an already requested authorization code to get back the code if valid.
         * @summary Post Authorize Token
         * @param {OAuthAuthorizeTokenRequest} [oAuthAuthorizeTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAuthorizeTokenForCodeGrant(oAuthAuthorizeTokenRequest?: OAuthAuthorizeTokenRequest, options?: any): AxiosPromise<OAuthAuthorizeTokenResponse> {
            return localVarFp.postAuthorizeTokenForCodeGrant(oAuthAuthorizeTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns a token for user
         * @summary Get Token for a User
         * @param {string} [accept] Accept Header e.g. \&#39;application/json\&#39;
         * @param {string} [contentType] Content Type e.g. \&#39;application/json\&#39;
         * @param {PasswordGrantRequest} [passwordGrantRequest] OAuth Request Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postPasswordGrant(accept?: string, contentType?: string, passwordGrantRequest?: PasswordGrantRequest, options?: any): AxiosPromise<OAuthResponse> {
            return localVarFp.postPasswordGrant(accept, contentType, passwordGrantRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Refresh and generate a new token for user
         * @summary Get Token for a User using a refresh token
         * @param {RefreshTokenGrantRequest} [refreshTokenGrantRequest] OAuth Refresh Token Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRefreshTokenGrant(refreshTokenGrantRequest?: RefreshTokenGrantRequest, options?: any): AxiosPromise<OAuthResponse> {
            return localVarFp.postRefreshTokenGrant(refreshTokenGrantRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * Delete an auth token for an already requested authorization code to invalidate the token for any further usage.
     * @summary Delete Authorize Token
     * @param {OAuthAuthorizeTokenRequest} [oAuthAuthorizeTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public deleteAuthorizeTokenForCodeGrant(oAuthAuthorizeTokenRequest?: OAuthAuthorizeTokenRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).deleteAuthorizeTokenForCodeGrant(oAuthAuthorizeTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes personal access token
     * @summary Delete Personal Access Token
     * @param {string} id Personal Token Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public deletePersonalAccessToken(id: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).deletePersonalAccessToken(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a payload of information about an authorization code grant pending approval
     * @summary Get Authorize Code
     * @param {string} clientId Client Id
     * @param {string} redirectUri Redirection URI for when approve or decline
     * @param {string} responseType OAuth Response type, this should be code for auth code grants
     * @param {string} scope Scope is a space separated string of scopes being requested
     * @param {string} state State is a unique value that is relevant to the client service and used to identify the returning callback URI request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getAuthorizeDataForCodeGrant(clientId: string, redirectUri: string, responseType: string, scope: string, state: string, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getAuthorizeDataForCodeGrant(clientId, redirectUri, responseType, scope, state, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Post an auth token for an already requested authorization code to get back the code if valid.
     * @summary Post Authorize Token
     * @param {OAuthAuthorizeTokenRequest} [oAuthAuthorizeTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public postAuthorizeTokenForCodeGrant(oAuthAuthorizeTokenRequest?: OAuthAuthorizeTokenRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).postAuthorizeTokenForCodeGrant(oAuthAuthorizeTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns a token for user
     * @summary Get Token for a User
     * @param {string} [accept] Accept Header e.g. \&#39;application/json\&#39;
     * @param {string} [contentType] Content Type e.g. \&#39;application/json\&#39;
     * @param {PasswordGrantRequest} [passwordGrantRequest] OAuth Request Object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public postPasswordGrant(accept?: string, contentType?: string, passwordGrantRequest?: PasswordGrantRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).postPasswordGrant(accept, contentType, passwordGrantRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Refresh and generate a new token for user
     * @summary Get Token for a User using a refresh token
     * @param {RefreshTokenGrantRequest} [refreshTokenGrantRequest] OAuth Refresh Token Object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public postRefreshTokenGrant(refreshTokenGrantRequest?: RefreshTokenGrantRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).postRefreshTokenGrant(refreshTokenGrantRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AverageSetsApi - axios parameter creator
 * @export
 */
export const AverageSetsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This API return the average sets currently defined
         * @summary Get Average Sets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageSets: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/average_sets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns Average Set Stats
         * @summary Get grouped stats for an Average Set
         * @param {string} id Average Set Id
         * @param {Array<string>} [parameters] Parameters that should be returned, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupedStats: async (id: string, parameters?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGroupedStats', 'id', id)
            const localVarPath = `/average_sets/{id}/grouped_stats`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (parameters) {
                localVarQueryParameter['parameters'] = parameters.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is used to get the summary of the average set such as if it has data or not
         * @summary Average Set brief summary
         * @param {string} [type] type of average set
         * @param {Array<string>} [params] comma separated list of averages to check for data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummary: async (type?: string, params?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/average_sets/summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (params) {
                localVarQueryParameter['params'] = params.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API is used to create a new average set
         * @summary Create Average Set
         * @param {AverageSetCreateRequest} [averageSetCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAverageSet: async (averageSetCreateRequest?: AverageSetCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/average_sets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(averageSetCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AverageSetsApi - functional programming interface
 * @export
 */
export const AverageSetsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AverageSetsApiAxiosParamCreator(configuration)
    return {
        /**
         * This API return the average sets currently defined
         * @summary Get Average Sets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAverageSets(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AverageSet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAverageSets(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns Average Set Stats
         * @summary Get grouped stats for an Average Set
         * @param {string} id Average Set Id
         * @param {Array<string>} [parameters] Parameters that should be returned, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroupedStats(id: string, parameters?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatRow>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroupedStats(id, parameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API is used to get the summary of the average set such as if it has data or not
         * @summary Average Set brief summary
         * @param {string} [type] type of average set
         * @param {Array<string>} [params] comma separated list of averages to check for data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSummary(type?: string, params?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AverageSetSummary>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSummary(type, params, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API is used to create a new average set
         * @summary Create Average Set
         * @param {AverageSetCreateRequest} [averageSetCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAverageSet(averageSetCreateRequest?: AverageSetCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AverageSetCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAverageSet(averageSetCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AverageSetsApi - factory interface
 * @export
 */
export const AverageSetsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AverageSetsApiFp(configuration)
    return {
        /**
         * This API return the average sets currently defined
         * @summary Get Average Sets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAverageSets(options?: any): AxiosPromise<Array<AverageSet>> {
            return localVarFp.getAverageSets(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns Average Set Stats
         * @summary Get grouped stats for an Average Set
         * @param {string} id Average Set Id
         * @param {Array<string>} [parameters] Parameters that should be returned, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroupedStats(id: string, parameters?: Array<string>, options?: any): AxiosPromise<Array<StatRow>> {
            return localVarFp.getGroupedStats(id, parameters, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is used to get the summary of the average set such as if it has data or not
         * @summary Average Set brief summary
         * @param {string} [type] type of average set
         * @param {Array<string>} [params] comma separated list of averages to check for data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSummary(type?: string, params?: Array<string>, options?: any): AxiosPromise<Array<AverageSetSummary>> {
            return localVarFp.getSummary(type, params, options).then((request) => request(axios, basePath));
        },
        /**
         * This API is used to create a new average set
         * @summary Create Average Set
         * @param {AverageSetCreateRequest} [averageSetCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAverageSet(averageSetCreateRequest?: AverageSetCreateRequest, options?: any): AxiosPromise<AverageSetCreateResponse> {
            return localVarFp.postAverageSet(averageSetCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AverageSetsApi - object-oriented interface
 * @export
 * @class AverageSetsApi
 * @extends {BaseAPI}
 */
export class AverageSetsApi extends BaseAPI {
    /**
     * This API return the average sets currently defined
     * @summary Get Average Sets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AverageSetsApi
     */
    public getAverageSets(options?: AxiosRequestConfig) {
        return AverageSetsApiFp(this.configuration).getAverageSets(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns Average Set Stats
     * @summary Get grouped stats for an Average Set
     * @param {string} id Average Set Id
     * @param {Array<string>} [parameters] Parameters that should be returned, comma separated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AverageSetsApi
     */
    public getGroupedStats(id: string, parameters?: Array<string>, options?: AxiosRequestConfig) {
        return AverageSetsApiFp(this.configuration).getGroupedStats(id, parameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is used to get the summary of the average set such as if it has data or not
     * @summary Average Set brief summary
     * @param {string} [type] type of average set
     * @param {Array<string>} [params] comma separated list of averages to check for data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AverageSetsApi
     */
    public getSummary(type?: string, params?: Array<string>, options?: AxiosRequestConfig) {
        return AverageSetsApiFp(this.configuration).getSummary(type, params, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API is used to create a new average set
     * @summary Create Average Set
     * @param {AverageSetCreateRequest} [averageSetCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AverageSetsApi
     */
    public postAverageSet(averageSetCreateRequest?: AverageSetCreateRequest, options?: AxiosRequestConfig) {
        return AverageSetsApiFp(this.configuration).postAverageSet(averageSetCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BandsApi - axios parameter creator
 * @export
 */
export const BandsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns all the bands for the current user
         * @summary Get list of bands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBands: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bands`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BandsApi - functional programming interface
 * @export
 */
export const BandsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BandsApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns all the bands for the current user
         * @summary Get list of bands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBands(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Band>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBands(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BandsApi - factory interface
 * @export
 */
export const BandsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BandsApiFp(configuration)
    return {
        /**
         * Returns all the bands for the current user
         * @summary Get list of bands
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBands(options?: any): AxiosPromise<Array<Band>> {
            return localVarFp.getAllBands(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BandsApi - object-oriented interface
 * @export
 * @class BandsApi
 * @extends {BaseAPI}
 */
export class BandsApi extends BaseAPI {
    /**
     * Returns all the bands for the current user
     * @summary Get list of bands
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BandsApi
     */
    public getAllBands(options?: AxiosRequestConfig) {
        return BandsApiFp(this.configuration).getAllBands(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BenchmarksApi - axios parameter creator
 * @export
 */
export const BenchmarksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get list of sport specific default fallback Benchmarks
         * @param {string} [sportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFallbackBenchmarks: async (sportId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fallback_benchmarks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sportId !== undefined) {
                localVarQueryParameter['sport_id'] = sportId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BenchmarksApi - functional programming interface
 * @export
 */
export const BenchmarksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BenchmarksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get list of sport specific default fallback Benchmarks
         * @param {string} [sportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFallbackBenchmarks(sportId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Benchmark>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFallbackBenchmarks(sportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BenchmarksApi - factory interface
 * @export
 */
export const BenchmarksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BenchmarksApiFp(configuration)
    return {
        /**
         * 
         * @summary Get list of sport specific default fallback Benchmarks
         * @param {string} [sportId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFallbackBenchmarks(sportId?: string, options?: any): AxiosPromise<Array<Benchmark>> {
            return localVarFp.getFallbackBenchmarks(sportId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BenchmarksApi - object-oriented interface
 * @export
 * @class BenchmarksApi
 * @extends {BaseAPI}
 */
export class BenchmarksApi extends BaseAPI {
    /**
     * 
     * @summary Get list of sport specific default fallback Benchmarks
     * @param {string} [sportId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BenchmarksApi
     */
    public getFallbackBenchmarks(sportId?: string, options?: AxiosRequestConfig) {
        return BenchmarksApiFp(this.configuration).getFallbackBenchmarks(sportId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConnectionsApi - axios parameter creator
 * @export
 */
export const ConnectionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create connections
         * @summary Create a connections
         * @param {ConnectionCreateRequest} [connectionCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnection: async (connectionCreateRequest?: ConnectionCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectionCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes Connection
         * @summary Delete Connection
         * @param {string} id connections Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConnection: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteConnection', 'id', id)
            const localVarPath = `/connections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API returns list of Connection Types
         * @summary Get Connection Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connection_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API returns all customer connections
         * @summary Get Connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnections: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/connections`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Import selected games
         * @param {ImportGamesRequest} importGamesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importGames: async (importGamesRequest: ImportGamesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'importGamesRequest' is not null or undefined
            assertParamExists('importGames', 'importGamesRequest', importGamesRequest)
            const localVarPath = `/connections/import_games`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(importGamesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update connections
         * @summary Update a connections
         * @param {string} id connections Id
         * @param {ConnectionUpdateRequest} [connectionUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnection: async (id: string, connectionUpdateRequest?: ConnectionUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateConnection', 'id', id)
            const localVarPath = `/connections/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectionUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectionsApi - functional programming interface
 * @export
 */
export const ConnectionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectionsApiAxiosParamCreator(configuration)
    return {
        /**
         * Create connections
         * @summary Create a connections
         * @param {ConnectionCreateRequest} [connectionCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConnection(connectionCreateRequest?: ConnectionCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Connection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConnection(connectionCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes Connection
         * @summary Delete Connection
         * @param {string} id connections Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConnection(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConnection(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API returns list of Connection Types
         * @summary Get Connection Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectionTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConnectionType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectionTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API returns all customer connections
         * @summary Get Connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnections(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Connection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnections(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Import selected games
         * @param {ImportGamesRequest} importGamesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async importGames(importGamesRequest: ImportGamesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.importGames(importGamesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update connections
         * @summary Update a connections
         * @param {string} id connections Id
         * @param {ConnectionUpdateRequest} [connectionUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnection(id: string, connectionUpdateRequest?: ConnectionUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Connection>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnection(id, connectionUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConnectionsApi - factory interface
 * @export
 */
export const ConnectionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectionsApiFp(configuration)
    return {
        /**
         * Create connections
         * @summary Create a connections
         * @param {ConnectionCreateRequest} [connectionCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnection(connectionCreateRequest?: ConnectionCreateRequest, options?: any): AxiosPromise<Connection> {
            return localVarFp.createConnection(connectionCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes Connection
         * @summary Delete Connection
         * @param {string} id connections Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConnection(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteConnection(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This API returns list of Connection Types
         * @summary Get Connection Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionTypes(options?: any): AxiosPromise<Array<ConnectionType>> {
            return localVarFp.getConnectionTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * This API returns all customer connections
         * @summary Get Connections
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnections(options?: any): AxiosPromise<Array<Connection>> {
            return localVarFp.getConnections(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Import selected games
         * @param {ImportGamesRequest} importGamesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importGames(importGamesRequest: ImportGamesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.importGames(importGamesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update connections
         * @summary Update a connections
         * @param {string} id connections Id
         * @param {ConnectionUpdateRequest} [connectionUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnection(id: string, connectionUpdateRequest?: ConnectionUpdateRequest, options?: any): AxiosPromise<Connection> {
            return localVarFp.updateConnection(id, connectionUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConnectionsApi - object-oriented interface
 * @export
 * @class ConnectionsApi
 * @extends {BaseAPI}
 */
export class ConnectionsApi extends BaseAPI {
    /**
     * Create connections
     * @summary Create a connections
     * @param {ConnectionCreateRequest} [connectionCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public createConnection(connectionCreateRequest?: ConnectionCreateRequest, options?: AxiosRequestConfig) {
        return ConnectionsApiFp(this.configuration).createConnection(connectionCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes Connection
     * @summary Delete Connection
     * @param {string} id connections Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public deleteConnection(id: string, options?: AxiosRequestConfig) {
        return ConnectionsApiFp(this.configuration).deleteConnection(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API returns list of Connection Types
     * @summary Get Connection Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public getConnectionTypes(options?: AxiosRequestConfig) {
        return ConnectionsApiFp(this.configuration).getConnectionTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API returns all customer connections
     * @summary Get Connections
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public getConnections(options?: AxiosRequestConfig) {
        return ConnectionsApiFp(this.configuration).getConnections(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Import selected games
     * @param {ImportGamesRequest} importGamesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public importGames(importGamesRequest: ImportGamesRequest, options?: AxiosRequestConfig) {
        return ConnectionsApiFp(this.configuration).importGames(importGamesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update connections
     * @summary Update a connections
     * @param {string} id connections Id
     * @param {ConnectionUpdateRequest} [connectionUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionsApi
     */
    public updateConnection(id: string, connectionUpdateRequest?: ConnectionUpdateRequest, options?: AxiosRequestConfig) {
        return ConnectionsApiFp(this.configuration).updateConnection(id, connectionUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CustomerApi - axios parameter creator
 * @export
 */
export const CustomerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Return highest version of all user accounts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerHighestVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/highestversion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerUsers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/customer/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerApi - functional programming interface
 * @export
 */
export const CustomerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Return highest version of all user accounts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerHighestVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerHighestVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Customer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomerUsers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomerUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerApi - factory interface
 * @export
 */
export const CustomerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerApiFp(configuration)
    return {
        /**
         * 
         * @summary Return highest version of all user accounts.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerHighestVersion(options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.getCustomerHighestVersion(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get customer.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerInfo(options?: any): AxiosPromise<Customer> {
            return localVarFp.getCustomerInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get users.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomerUsers(options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getCustomerUsers(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerApi - object-oriented interface
 * @export
 * @class CustomerApi
 * @extends {BaseAPI}
 */
export class CustomerApi extends BaseAPI {
    /**
     * 
     * @summary Return highest version of all user accounts.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomerHighestVersion(options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomerHighestVersion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get customer.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomerInfo(options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomerInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get users.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerApi
     */
    public getCustomerUsers(options?: AxiosRequestConfig) {
        return CustomerApiFp(this.configuration).getCustomerUsers(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DataInjectionApi - axios parameter creator
 * @export
 */
export const DataInjectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create an injected activity with periods and athletes
         * @summary Create activity
         * @param {InjectedActivityCreateRequest} [injectedActivityCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInjectedActivity: async (injectedActivityCreateRequest?: InjectedActivityCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/injection/activities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(injectedActivityCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove injected data from an activity
         * @summary Delete injected data
         * @param {DeleteInjectedDataRequest} [deleteInjectedDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInjectedData: async (deleteInjectedDataRequest?: DeleteInjectedDataRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/injection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteInjectedDataRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get data injection CSV template for an activity
         * @summary Get template
         * @param {GetInjectionTemplateRequest} [getInjectionTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInjectionTemplate: async (getInjectionTemplateRequest?: GetInjectionTemplateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/injection/template`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getInjectionTemplateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update an activity with injected periods and athletes
         * @summary Update activity
         * @param {string} id Activity Id
         * @param {InjectedActivityUpdateRequest} [injectedActivityUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInjectedActivity: async (id: string, injectedActivityUpdateRequest?: InjectedActivityUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateInjectedActivity', 'id', id)
            const localVarPath = `/injection/activities/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(injectedActivityUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add injected data to an activity
         * @summary Update injected data
         * @param {string} activityId Activity id
         * @param {any} csvFile Populated injection template containing data to be injected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInjectedData: async (activityId: string, csvFile: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('updateInjectedData', 'activityId', activityId)
            // verify required parameter 'csvFile' is not null or undefined
            assertParamExists('updateInjectedData', 'csvFile', csvFile)
            const localVarPath = `/injection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (activityId !== undefined) { 
                localVarFormParams.append('activity_id', activityId as any);
            }
    
            if (csvFile !== undefined) { 
                localVarFormParams.append('csv_file', csvFile as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DataInjectionApi - functional programming interface
 * @export
 */
export const DataInjectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DataInjectionApiAxiosParamCreator(configuration)
    return {
        /**
         * Create an injected activity with periods and athletes
         * @summary Create activity
         * @param {InjectedActivityCreateRequest} [injectedActivityCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInjectedActivity(injectedActivityCreateRequest?: InjectedActivityCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2002>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInjectedActivity(injectedActivityCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove injected data from an activity
         * @summary Delete injected data
         * @param {DeleteInjectedDataRequest} [deleteInjectedDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteInjectedData(deleteInjectedDataRequest?: DeleteInjectedDataRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2001>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteInjectedData(deleteInjectedDataRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get data injection CSV template for an activity
         * @summary Get template
         * @param {GetInjectionTemplateRequest} [getInjectionTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInjectionTemplate(getInjectionTemplateRequest?: GetInjectionTemplateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInjectionTemplate(getInjectionTemplateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update an activity with injected periods and athletes
         * @summary Update activity
         * @param {string} id Activity Id
         * @param {InjectedActivityUpdateRequest} [injectedActivityUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInjectedActivity(id: string, injectedActivityUpdateRequest?: InjectedActivityUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInjectedActivity(id, injectedActivityUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add injected data to an activity
         * @summary Update injected data
         * @param {string} activityId Activity id
         * @param {any} csvFile Populated injection template containing data to be injected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInjectedData(activityId: string, csvFile: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInjectedData(activityId, csvFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DataInjectionApi - factory interface
 * @export
 */
export const DataInjectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DataInjectionApiFp(configuration)
    return {
        /**
         * Create an injected activity with periods and athletes
         * @summary Create activity
         * @param {InjectedActivityCreateRequest} [injectedActivityCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInjectedActivity(injectedActivityCreateRequest?: InjectedActivityCreateRequest, options?: any): AxiosPromise<InlineResponse2002> {
            return localVarFp.createInjectedActivity(injectedActivityCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Remove injected data from an activity
         * @summary Delete injected data
         * @param {DeleteInjectedDataRequest} [deleteInjectedDataRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInjectedData(deleteInjectedDataRequest?: DeleteInjectedDataRequest, options?: any): AxiosPromise<InlineResponse2001> {
            return localVarFp.deleteInjectedData(deleteInjectedDataRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Get data injection CSV template for an activity
         * @summary Get template
         * @param {GetInjectionTemplateRequest} [getInjectionTemplateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInjectionTemplate(getInjectionTemplateRequest?: GetInjectionTemplateRequest, options?: any): AxiosPromise<any> {
            return localVarFp.getInjectionTemplate(getInjectionTemplateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update an activity with injected periods and athletes
         * @summary Update activity
         * @param {string} id Activity Id
         * @param {InjectedActivityUpdateRequest} [injectedActivityUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInjectedActivity(id: string, injectedActivityUpdateRequest?: InjectedActivityUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateInjectedActivity(id, injectedActivityUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Add injected data to an activity
         * @summary Update injected data
         * @param {string} activityId Activity id
         * @param {any} csvFile Populated injection template containing data to be injected
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInjectedData(activityId: string, csvFile: any, options?: any): AxiosPromise<void> {
            return localVarFp.updateInjectedData(activityId, csvFile, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DataInjectionApi - object-oriented interface
 * @export
 * @class DataInjectionApi
 * @extends {BaseAPI}
 */
export class DataInjectionApi extends BaseAPI {
    /**
     * Create an injected activity with periods and athletes
     * @summary Create activity
     * @param {InjectedActivityCreateRequest} [injectedActivityCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataInjectionApi
     */
    public createInjectedActivity(injectedActivityCreateRequest?: InjectedActivityCreateRequest, options?: AxiosRequestConfig) {
        return DataInjectionApiFp(this.configuration).createInjectedActivity(injectedActivityCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Remove injected data from an activity
     * @summary Delete injected data
     * @param {DeleteInjectedDataRequest} [deleteInjectedDataRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataInjectionApi
     */
    public deleteInjectedData(deleteInjectedDataRequest?: DeleteInjectedDataRequest, options?: AxiosRequestConfig) {
        return DataInjectionApiFp(this.configuration).deleteInjectedData(deleteInjectedDataRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get data injection CSV template for an activity
     * @summary Get template
     * @param {GetInjectionTemplateRequest} [getInjectionTemplateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataInjectionApi
     */
    public getInjectionTemplate(getInjectionTemplateRequest?: GetInjectionTemplateRequest, options?: AxiosRequestConfig) {
        return DataInjectionApiFp(this.configuration).getInjectionTemplate(getInjectionTemplateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update an activity with injected periods and athletes
     * @summary Update activity
     * @param {string} id Activity Id
     * @param {InjectedActivityUpdateRequest} [injectedActivityUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataInjectionApi
     */
    public updateInjectedActivity(id: string, injectedActivityUpdateRequest?: InjectedActivityUpdateRequest, options?: AxiosRequestConfig) {
        return DataInjectionApiFp(this.configuration).updateInjectedActivity(id, injectedActivityUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add injected data to an activity
     * @summary Update injected data
     * @param {string} activityId Activity id
     * @param {any} csvFile Populated injection template containing data to be injected
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DataInjectionApi
     */
    public updateInjectedData(activityId: string, csvFile: any, options?: AxiosRequestConfig) {
        return DataInjectionApiFp(this.configuration).updateInjectedData(activityId, csvFile, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemoDataApi - axios parameter creator
 * @export
 */
export const DemoDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Demo Data Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoDataStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demo_data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Remove all demo data for the current customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDemoData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/demo_data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemoDataApi - functional programming interface
 * @export
 */
export const DemoDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemoDataApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Demo Data Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demoDataStatus(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DemoDataStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.demoDataStatus(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Remove all demo data for the current customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeDemoData(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeDemoData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemoDataApi - factory interface
 * @export
 */
export const DemoDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemoDataApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Demo Data Status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demoDataStatus(options?: any): AxiosPromise<DemoDataStatusResponse> {
            return localVarFp.demoDataStatus(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Remove all demo data for the current customer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeDemoData(options?: any): AxiosPromise<void> {
            return localVarFp.removeDemoData(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemoDataApi - object-oriented interface
 * @export
 * @class DemoDataApi
 * @extends {BaseAPI}
 */
export class DemoDataApi extends BaseAPI {
    /**
     * 
     * @summary Get Demo Data Status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoDataApi
     */
    public demoDataStatus(options?: AxiosRequestConfig) {
        return DemoDataApiFp(this.configuration).demoDataStatus(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Remove all demo data for the current customer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoDataApi
     */
    public removeDemoData(options?: AxiosRequestConfig) {
        return DemoDataApiFp(this.configuration).removeDemoData(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DevicesApi - axios parameter creator
 * @export
 */
export const DevicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create or update a device.  If device doesn\'t exist, it is created using the team_id supplied, or if no team_id is supplied, the ID of the default team.
         * @summary Create or update a device
         * @param {number} id Device Id
         * @param {CreateOrUpdateDeviceRequest} [createOrUpdateDeviceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateDevice: async (id: number, createOrUpdateDeviceRequest?: CreateOrUpdateDeviceRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createOrUpdateDevice', 'id', id)
            const localVarPath = `/devices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrUpdateDeviceRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Device - Athlete mappings
         * @param {DeleteDeviceAthletes} [deleteDeviceAthletes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceAthletes: async (deleteDeviceAthletes?: DeleteDeviceAthletes, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/devices/athletes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteDeviceAthletes, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the authorized user\'s encryption keys.
         * @summary Get the authorized user\'s encryption keys.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEncryptionKeys: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/encryptionkeys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a list of Device - Athlete mappings
         * @param {number} [current] Set to 1 to return current mappings only, otherwise returns all records
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeviceAthletes: async (current?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/devices/athletes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (current !== undefined) {
                localVarQueryParameter['current'] = current;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a Device - Athlete mapping
         * @param {CreateDeviceAthleteMappingRequest} [createDeviceAthleteMappingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeviceAthletes: async (createDeviceAthleteMappingRequest?: CreateDeviceAthleteMappingRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/devices/athletes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeviceAthleteMappingRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DevicesApi - functional programming interface
 * @export
 */
export const DevicesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DevicesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create or update a device.  If device doesn\'t exist, it is created using the team_id supplied, or if no team_id is supplied, the ID of the default team.
         * @summary Create or update a device
         * @param {number} id Device Id
         * @param {CreateOrUpdateDeviceRequest} [createOrUpdateDeviceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createOrUpdateDevice(id: number, createOrUpdateDeviceRequest?: CreateOrUpdateDeviceRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createOrUpdateDevice(id, createOrUpdateDeviceRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Device - Athlete mappings
         * @param {DeleteDeviceAthletes} [deleteDeviceAthletes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeviceAthletes(deleteDeviceAthletes?: DeleteDeviceAthletes, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeviceAthletes(deleteDeviceAthletes, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the authorized user\'s encryption keys.
         * @summary Get the authorized user\'s encryption keys.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEncryptionKeys(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EncryptionKeys>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEncryptionKeys(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a list of Device - Athlete mappings
         * @param {number} [current] Set to 1 to return current mappings only, otherwise returns all records
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listDeviceAthletes(current?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeviceAthlete>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listDeviceAthletes(current, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a Device - Athlete mapping
         * @param {CreateDeviceAthleteMappingRequest} [createDeviceAthleteMappingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeviceAthletes(createDeviceAthleteMappingRequest?: CreateDeviceAthleteMappingRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceAthlete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeviceAthletes(createDeviceAthleteMappingRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DevicesApi - factory interface
 * @export
 */
export const DevicesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DevicesApiFp(configuration)
    return {
        /**
         * Create or update a device.  If device doesn\'t exist, it is created using the team_id supplied, or if no team_id is supplied, the ID of the default team.
         * @summary Create or update a device
         * @param {number} id Device Id
         * @param {CreateOrUpdateDeviceRequest} [createOrUpdateDeviceRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrUpdateDevice(id: number, createOrUpdateDeviceRequest?: CreateOrUpdateDeviceRequest, options?: any): AxiosPromise<void> {
            return localVarFp.createOrUpdateDevice(id, createOrUpdateDeviceRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Device - Athlete mappings
         * @param {DeleteDeviceAthletes} [deleteDeviceAthletes] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceAthletes(deleteDeviceAthletes?: DeleteDeviceAthletes, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDeviceAthletes(deleteDeviceAthletes, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the authorized user\'s encryption keys.
         * @summary Get the authorized user\'s encryption keys.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEncryptionKeys(options?: any): AxiosPromise<EncryptionKeys> {
            return localVarFp.getEncryptionKeys(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a list of Device - Athlete mappings
         * @param {number} [current] Set to 1 to return current mappings only, otherwise returns all records
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listDeviceAthletes(current?: number, options?: any): AxiosPromise<Array<DeviceAthlete>> {
            return localVarFp.listDeviceAthletes(current, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a Device - Athlete mapping
         * @param {CreateDeviceAthleteMappingRequest} [createDeviceAthleteMappingRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeviceAthletes(createDeviceAthleteMappingRequest?: CreateDeviceAthleteMappingRequest, options?: any): AxiosPromise<DeviceAthlete> {
            return localVarFp.postDeviceAthletes(createDeviceAthleteMappingRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DevicesApi - object-oriented interface
 * @export
 * @class DevicesApi
 * @extends {BaseAPI}
 */
export class DevicesApi extends BaseAPI {
    /**
     * Create or update a device.  If device doesn\'t exist, it is created using the team_id supplied, or if no team_id is supplied, the ID of the default team.
     * @summary Create or update a device
     * @param {number} id Device Id
     * @param {CreateOrUpdateDeviceRequest} [createOrUpdateDeviceRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public createOrUpdateDevice(id: number, createOrUpdateDeviceRequest?: CreateOrUpdateDeviceRequest, options?: AxiosRequestConfig) {
        return DevicesApiFp(this.configuration).createOrUpdateDevice(id, createOrUpdateDeviceRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Device - Athlete mappings
     * @param {DeleteDeviceAthletes} [deleteDeviceAthletes] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public deleteDeviceAthletes(deleteDeviceAthletes?: DeleteDeviceAthletes, options?: AxiosRequestConfig) {
        return DevicesApiFp(this.configuration).deleteDeviceAthletes(deleteDeviceAthletes, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the authorized user\'s encryption keys.
     * @summary Get the authorized user\'s encryption keys.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public getEncryptionKeys(options?: AxiosRequestConfig) {
        return DevicesApiFp(this.configuration).getEncryptionKeys(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a list of Device - Athlete mappings
     * @param {number} [current] Set to 1 to return current mappings only, otherwise returns all records
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public listDeviceAthletes(current?: number, options?: AxiosRequestConfig) {
        return DevicesApiFp(this.configuration).listDeviceAthletes(current, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a Device - Athlete mapping
     * @param {CreateDeviceAthleteMappingRequest} [createDeviceAthleteMappingRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DevicesApi
     */
    public postDeviceAthletes(createDeviceAthleteMappingRequest?: CreateDeviceAthleteMappingRequest, options?: AxiosRequestConfig) {
        return DevicesApiFp(this.configuration).postDeviceAthletes(createDeviceAthleteMappingRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EffortsDataApi - axios parameter creator
 * @export
 */
export const EffortsDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This API retrieves efforts data for athlete in an activity.
         * @summary Get Efforts Data for Athlete in Activity
         * @param {string} activityId Activity Id
         * @param {string} athleteId Athlete Id
         * @param {string} effortTypes Comma Separated Effort Type Values (can only include \&#39;acceleration\&#39; or \&#39;velocity\&#39; or both separated by comma)
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {string} [velocityBands] One or more comma separated velocity band parameters may be supplied to restrict the list of bands returned, as an integer. Valid values are numbers 1 to 8. If not velocity bands values are specified, all bands are included.
         * @param {string} [accelerationBands] One or more comma separated acceleration band parameters may be supplied to restrict the list of bands returned, as an integer. Valid values are numbers -3 to 3, matching deceleration and acceleration bands 1 to 3 in OpenField Cloud including 0. If no band values are specified, bands -3, -2,-1 and 1, 2 and 3 will be included. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEffortsDataForAthleteInActivity: async (activityId: string, athleteId: string, effortTypes: string, startTime?: number, endTime?: number, velocityBands?: string, accelerationBands?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('getEffortsDataForAthleteInActivity', 'activityId', activityId)
            // verify required parameter 'athleteId' is not null or undefined
            assertParamExists('getEffortsDataForAthleteInActivity', 'athleteId', athleteId)
            // verify required parameter 'effortTypes' is not null or undefined
            assertParamExists('getEffortsDataForAthleteInActivity', 'effortTypes', effortTypes)
            const localVarPath = `/activities/{activity_id}/athletes/{athlete_id}/efforts`
                .replace(`{${"activity_id"}}`, encodeURIComponent(String(activityId)))
                .replace(`{${"athlete_id"}}`, encodeURIComponent(String(athleteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (effortTypes !== undefined) {
                localVarQueryParameter['effort_types'] = effortTypes;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }

            if (velocityBands !== undefined) {
                localVarQueryParameter['velocity_bands'] = velocityBands;
            }

            if (accelerationBands !== undefined) {
                localVarQueryParameter['acceleration_bands'] = accelerationBands;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API retrieves efforts data for athlete in a period.
         * @summary Get Efforts Data For Athlete in Period
         * @param {string} periodId Period Id
         * @param {string} athleteId Athlete Id
         * @param {string} effortTypes Comma Separated Effort Type Values (can only include \&#39;acceleration\&#39; or \&#39;velocity\&#39; or both separated by comma)
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {string} [velocityBands] One or more comma separated velocity band parameters may be supplied to restrict the list of bands returned, as an integer. Valid values are numbers 1 to 8. If not velocity bands values are specified, all bands are included.
         * @param {string} [accelerationBands] One or more comma separated acceleration band parameters may be supplied to restrict the list of bands returned, as an integer. Valid values are numbers -3 to 3, matching deceleration and acceleration bands 1 to 3 in OpenField Cloud including 0. If no band values are specified, bands -3, -2,-1 and 1, 2 and 3 will be included. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEffortsDataForAthleteInPeriod: async (periodId: string, athleteId: string, effortTypes: string, startTime?: number, endTime?: number, velocityBands?: string, accelerationBands?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'periodId' is not null or undefined
            assertParamExists('getEffortsDataForAthleteInPeriod', 'periodId', periodId)
            // verify required parameter 'athleteId' is not null or undefined
            assertParamExists('getEffortsDataForAthleteInPeriod', 'athleteId', athleteId)
            // verify required parameter 'effortTypes' is not null or undefined
            assertParamExists('getEffortsDataForAthleteInPeriod', 'effortTypes', effortTypes)
            const localVarPath = `/periods/{period_id}/athletes/{athlete_id}/efforts`
                .replace(`{${"period_id"}}`, encodeURIComponent(String(periodId)))
                .replace(`{${"athlete_id"}}`, encodeURIComponent(String(athleteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (effortTypes !== undefined) {
                localVarQueryParameter['effort_types'] = effortTypes;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }

            if (velocityBands !== undefined) {
                localVarQueryParameter['velocity_bands'] = velocityBands;
            }

            if (accelerationBands !== undefined) {
                localVarQueryParameter['acceleration_bands'] = accelerationBands;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EffortsDataApi - functional programming interface
 * @export
 */
export const EffortsDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EffortsDataApiAxiosParamCreator(configuration)
    return {
        /**
         * This API retrieves efforts data for athlete in an activity.
         * @summary Get Efforts Data for Athlete in Activity
         * @param {string} activityId Activity Id
         * @param {string} athleteId Athlete Id
         * @param {string} effortTypes Comma Separated Effort Type Values (can only include \&#39;acceleration\&#39; or \&#39;velocity\&#39; or both separated by comma)
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {string} [velocityBands] One or more comma separated velocity band parameters may be supplied to restrict the list of bands returned, as an integer. Valid values are numbers 1 to 8. If not velocity bands values are specified, all bands are included.
         * @param {string} [accelerationBands] One or more comma separated acceleration band parameters may be supplied to restrict the list of bands returned, as an integer. Valid values are numbers -3 to 3, matching deceleration and acceleration bands 1 to 3 in OpenField Cloud including 0. If no band values are specified, bands -3, -2,-1 and 1, 2 and 3 will be included. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEffortsDataForAthleteInActivity(activityId: string, athleteId: string, effortTypes: string, startTime?: number, endTime?: number, velocityBands?: string, accelerationBands?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Efforts>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEffortsDataForAthleteInActivity(activityId, athleteId, effortTypes, startTime, endTime, velocityBands, accelerationBands, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API retrieves efforts data for athlete in a period.
         * @summary Get Efforts Data For Athlete in Period
         * @param {string} periodId Period Id
         * @param {string} athleteId Athlete Id
         * @param {string} effortTypes Comma Separated Effort Type Values (can only include \&#39;acceleration\&#39; or \&#39;velocity\&#39; or both separated by comma)
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {string} [velocityBands] One or more comma separated velocity band parameters may be supplied to restrict the list of bands returned, as an integer. Valid values are numbers 1 to 8. If not velocity bands values are specified, all bands are included.
         * @param {string} [accelerationBands] One or more comma separated acceleration band parameters may be supplied to restrict the list of bands returned, as an integer. Valid values are numbers -3 to 3, matching deceleration and acceleration bands 1 to 3 in OpenField Cloud including 0. If no band values are specified, bands -3, -2,-1 and 1, 2 and 3 will be included. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEffortsDataForAthleteInPeriod(periodId: string, athleteId: string, effortTypes: string, startTime?: number, endTime?: number, velocityBands?: string, accelerationBands?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Efforts>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEffortsDataForAthleteInPeriod(periodId, athleteId, effortTypes, startTime, endTime, velocityBands, accelerationBands, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EffortsDataApi - factory interface
 * @export
 */
export const EffortsDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EffortsDataApiFp(configuration)
    return {
        /**
         * This API retrieves efforts data for athlete in an activity.
         * @summary Get Efforts Data for Athlete in Activity
         * @param {string} activityId Activity Id
         * @param {string} athleteId Athlete Id
         * @param {string} effortTypes Comma Separated Effort Type Values (can only include \&#39;acceleration\&#39; or \&#39;velocity\&#39; or both separated by comma)
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {string} [velocityBands] One or more comma separated velocity band parameters may be supplied to restrict the list of bands returned, as an integer. Valid values are numbers 1 to 8. If not velocity bands values are specified, all bands are included.
         * @param {string} [accelerationBands] One or more comma separated acceleration band parameters may be supplied to restrict the list of bands returned, as an integer. Valid values are numbers -3 to 3, matching deceleration and acceleration bands 1 to 3 in OpenField Cloud including 0. If no band values are specified, bands -3, -2,-1 and 1, 2 and 3 will be included. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEffortsDataForAthleteInActivity(activityId: string, athleteId: string, effortTypes: string, startTime?: number, endTime?: number, velocityBands?: string, accelerationBands?: string, options?: any): AxiosPromise<Array<Efforts>> {
            return localVarFp.getEffortsDataForAthleteInActivity(activityId, athleteId, effortTypes, startTime, endTime, velocityBands, accelerationBands, options).then((request) => request(axios, basePath));
        },
        /**
         * This API retrieves efforts data for athlete in a period.
         * @summary Get Efforts Data For Athlete in Period
         * @param {string} periodId Period Id
         * @param {string} athleteId Athlete Id
         * @param {string} effortTypes Comma Separated Effort Type Values (can only include \&#39;acceleration\&#39; or \&#39;velocity\&#39; or both separated by comma)
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {string} [velocityBands] One or more comma separated velocity band parameters may be supplied to restrict the list of bands returned, as an integer. Valid values are numbers 1 to 8. If not velocity bands values are specified, all bands are included.
         * @param {string} [accelerationBands] One or more comma separated acceleration band parameters may be supplied to restrict the list of bands returned, as an integer. Valid values are numbers -3 to 3, matching deceleration and acceleration bands 1 to 3 in OpenField Cloud including 0. If no band values are specified, bands -3, -2,-1 and 1, 2 and 3 will be included. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEffortsDataForAthleteInPeriod(periodId: string, athleteId: string, effortTypes: string, startTime?: number, endTime?: number, velocityBands?: string, accelerationBands?: string, options?: any): AxiosPromise<Array<Efforts>> {
            return localVarFp.getEffortsDataForAthleteInPeriod(periodId, athleteId, effortTypes, startTime, endTime, velocityBands, accelerationBands, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EffortsDataApi - object-oriented interface
 * @export
 * @class EffortsDataApi
 * @extends {BaseAPI}
 */
export class EffortsDataApi extends BaseAPI {
    /**
     * This API retrieves efforts data for athlete in an activity.
     * @summary Get Efforts Data for Athlete in Activity
     * @param {string} activityId Activity Id
     * @param {string} athleteId Athlete Id
     * @param {string} effortTypes Comma Separated Effort Type Values (can only include \&#39;acceleration\&#39; or \&#39;velocity\&#39; or both separated by comma)
     * @param {number} [startTime] Start Time as UNIX timestamp in seconds
     * @param {number} [endTime] End Time as UNIX timestamp in seconds
     * @param {string} [velocityBands] One or more comma separated velocity band parameters may be supplied to restrict the list of bands returned, as an integer. Valid values are numbers 1 to 8. If not velocity bands values are specified, all bands are included.
     * @param {string} [accelerationBands] One or more comma separated acceleration band parameters may be supplied to restrict the list of bands returned, as an integer. Valid values are numbers -3 to 3, matching deceleration and acceleration bands 1 to 3 in OpenField Cloud including 0. If no band values are specified, bands -3, -2,-1 and 1, 2 and 3 will be included. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EffortsDataApi
     */
    public getEffortsDataForAthleteInActivity(activityId: string, athleteId: string, effortTypes: string, startTime?: number, endTime?: number, velocityBands?: string, accelerationBands?: string, options?: AxiosRequestConfig) {
        return EffortsDataApiFp(this.configuration).getEffortsDataForAthleteInActivity(activityId, athleteId, effortTypes, startTime, endTime, velocityBands, accelerationBands, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API retrieves efforts data for athlete in a period.
     * @summary Get Efforts Data For Athlete in Period
     * @param {string} periodId Period Id
     * @param {string} athleteId Athlete Id
     * @param {string} effortTypes Comma Separated Effort Type Values (can only include \&#39;acceleration\&#39; or \&#39;velocity\&#39; or both separated by comma)
     * @param {number} [startTime] Start Time as UNIX timestamp in seconds
     * @param {number} [endTime] End Time as UNIX timestamp in seconds
     * @param {string} [velocityBands] One or more comma separated velocity band parameters may be supplied to restrict the list of bands returned, as an integer. Valid values are numbers 1 to 8. If not velocity bands values are specified, all bands are included.
     * @param {string} [accelerationBands] One or more comma separated acceleration band parameters may be supplied to restrict the list of bands returned, as an integer. Valid values are numbers -3 to 3, matching deceleration and acceleration bands 1 to 3 in OpenField Cloud including 0. If no band values are specified, bands -3, -2,-1 and 1, 2 and 3 will be included. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EffortsDataApi
     */
    public getEffortsDataForAthleteInPeriod(periodId: string, athleteId: string, effortTypes: string, startTime?: number, endTime?: number, velocityBands?: string, accelerationBands?: string, options?: AxiosRequestConfig) {
        return EffortsDataApiFp(this.configuration).getEffortsDataForAthleteInPeriod(periodId, athleteId, effortTypes, startTime, endTime, velocityBands, accelerationBands, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EntityTagsApi - axios parameter creator
 * @export
 */
export const EntityTagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create EntityTag
         * @param {Array<EntityTagRequestObject>} [entityTagRequestObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEntityTag: async (entityTagRequestObject?: Array<EntityTagRequestObject>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/entity_tags/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityTagRequestObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete EntityTag
         * @param {EntityTagRequestObject} [entityTagRequestObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntityTag: async (entityTagRequestObject?: EntityTagRequestObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/entity_tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityTagRequestObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Multiple Tag Entities
         * @param {Array<EntityTagRequestObject>} [entityTagRequestObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntityTagBatch: async (entityTagRequestObject?: Array<EntityTagRequestObject>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/entity_tags/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(entityTagRequestObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EntityTagsApi - functional programming interface
 * @export
 */
export const EntityTagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EntityTagsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create EntityTag
         * @param {Array<EntityTagRequestObject>} [entityTagRequestObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEntityTag(entityTagRequestObject?: Array<EntityTagRequestObject>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEntityTag(entityTagRequestObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete EntityTag
         * @param {EntityTagRequestObject} [entityTagRequestObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEntityTag(entityTagRequestObject?: EntityTagRequestObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEntityTag(entityTagRequestObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Multiple Tag Entities
         * @param {Array<EntityTagRequestObject>} [entityTagRequestObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteEntityTagBatch(entityTagRequestObject?: Array<EntityTagRequestObject>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteEntityTagBatch(entityTagRequestObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EntityTagsApi - factory interface
 * @export
 */
export const EntityTagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EntityTagsApiFp(configuration)
    return {
        /**
         * 
         * @summary Create EntityTag
         * @param {Array<EntityTagRequestObject>} [entityTagRequestObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEntityTag(entityTagRequestObject?: Array<EntityTagRequestObject>, options?: any): AxiosPromise<void> {
            return localVarFp.createEntityTag(entityTagRequestObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete EntityTag
         * @param {EntityTagRequestObject} [entityTagRequestObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntityTag(entityTagRequestObject?: EntityTagRequestObject, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEntityTag(entityTagRequestObject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Multiple Tag Entities
         * @param {Array<EntityTagRequestObject>} [entityTagRequestObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEntityTagBatch(entityTagRequestObject?: Array<EntityTagRequestObject>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteEntityTagBatch(entityTagRequestObject, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EntityTagsApi - object-oriented interface
 * @export
 * @class EntityTagsApi
 * @extends {BaseAPI}
 */
export class EntityTagsApi extends BaseAPI {
    /**
     * 
     * @summary Create EntityTag
     * @param {Array<EntityTagRequestObject>} [entityTagRequestObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityTagsApi
     */
    public createEntityTag(entityTagRequestObject?: Array<EntityTagRequestObject>, options?: AxiosRequestConfig) {
        return EntityTagsApiFp(this.configuration).createEntityTag(entityTagRequestObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete EntityTag
     * @param {EntityTagRequestObject} [entityTagRequestObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityTagsApi
     */
    public deleteEntityTag(entityTagRequestObject?: EntityTagRequestObject, options?: AxiosRequestConfig) {
        return EntityTagsApiFp(this.configuration).deleteEntityTag(entityTagRequestObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Multiple Tag Entities
     * @param {Array<EntityTagRequestObject>} [entityTagRequestObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EntityTagsApi
     */
    public deleteEntityTagBatch(entityTagRequestObject?: Array<EntityTagRequestObject>, options?: AxiosRequestConfig) {
        return EntityTagsApiFp(this.configuration).deleteEntityTagBatch(entityTagRequestObject, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EventsDataApi - axios parameter creator
 * @export
 */
export const EventsDataApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This API returns the events data for an athlete in the activity.
         * @summary Get Events Data for Athlete in Activity
         * @param {string} activityId Activity Id
         * @param {string} athleteId Athlete Id
         * @param {string} eventTypes Comma Separated Event Type Values (can be a number, string or both for e.g. 5,6,7,ima_acceleration,ima_jump)
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {number} [imaAccelerationIntensityThreshold] If specified, only events within an intensity value greater than the threshold value should be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsDataForAthleteInActivity: async (activityId: string, athleteId: string, eventTypes: string, startTime?: number, endTime?: number, imaAccelerationIntensityThreshold?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('getEventsDataForAthleteInActivity', 'activityId', activityId)
            // verify required parameter 'athleteId' is not null or undefined
            assertParamExists('getEventsDataForAthleteInActivity', 'athleteId', athleteId)
            // verify required parameter 'eventTypes' is not null or undefined
            assertParamExists('getEventsDataForAthleteInActivity', 'eventTypes', eventTypes)
            const localVarPath = `/activities/{activity_id}/athletes/{athlete_id}/events`
                .replace(`{${"activity_id"}}`, encodeURIComponent(String(activityId)))
                .replace(`{${"athlete_id"}}`, encodeURIComponent(String(athleteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (eventTypes !== undefined) {
                localVarQueryParameter['event_types'] = eventTypes;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }

            if (imaAccelerationIntensityThreshold !== undefined) {
                localVarQueryParameter['ima_acceleration_intensity_threshold'] = imaAccelerationIntensityThreshold;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API returns the events data for an athlete in the period.
         * @summary Get Events Data For Athlete in Period
         * @param {string} eventTypes Comma Separated Event Types Values (can be a number, string or both for e.g. 5, 6, 7, ima_acceleration, ima_jump)
         * @param {string} periodId Period Id
         * @param {string} athleteId Athlete Id
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {number} [imaAccelerationIntensityThreshold] If specified, only events within an intensity value greater than the threshold value should be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsDataForAthleteInPeriod: async (eventTypes: string, periodId: string, athleteId: string, startTime?: number, endTime?: number, imaAccelerationIntensityThreshold?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventTypes' is not null or undefined
            assertParamExists('getEventsDataForAthleteInPeriod', 'eventTypes', eventTypes)
            // verify required parameter 'periodId' is not null or undefined
            assertParamExists('getEventsDataForAthleteInPeriod', 'periodId', periodId)
            // verify required parameter 'athleteId' is not null or undefined
            assertParamExists('getEventsDataForAthleteInPeriod', 'athleteId', athleteId)
            const localVarPath = `/periods/{period_id}/athletes/{athlete_id}/events`
                .replace(`{${"period_id"}}`, encodeURIComponent(String(periodId)))
                .replace(`{${"athlete_id"}}`, encodeURIComponent(String(athleteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (eventTypes !== undefined) {
                localVarQueryParameter['event_types'] = eventTypes;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }

            if (imaAccelerationIntensityThreshold !== undefined) {
                localVarQueryParameter['ima_acceleration_intensity_threshold'] = imaAccelerationIntensityThreshold;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EventsDataApi - functional programming interface
 * @export
 */
export const EventsDataApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EventsDataApiAxiosParamCreator(configuration)
    return {
        /**
         * This API returns the events data for an athlete in the activity.
         * @summary Get Events Data for Athlete in Activity
         * @param {string} activityId Activity Id
         * @param {string} athleteId Athlete Id
         * @param {string} eventTypes Comma Separated Event Type Values (can be a number, string or both for e.g. 5,6,7,ima_acceleration,ima_jump)
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {number} [imaAccelerationIntensityThreshold] If specified, only events within an intensity value greater than the threshold value should be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventsDataForAthleteInActivity(activityId: string, athleteId: string, eventTypes: string, startTime?: number, endTime?: number, imaAccelerationIntensityThreshold?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Events>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventsDataForAthleteInActivity(activityId, athleteId, eventTypes, startTime, endTime, imaAccelerationIntensityThreshold, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API returns the events data for an athlete in the period.
         * @summary Get Events Data For Athlete in Period
         * @param {string} eventTypes Comma Separated Event Types Values (can be a number, string or both for e.g. 5, 6, 7, ima_acceleration, ima_jump)
         * @param {string} periodId Period Id
         * @param {string} athleteId Athlete Id
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {number} [imaAccelerationIntensityThreshold] If specified, only events within an intensity value greater than the threshold value should be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventsDataForAthleteInPeriod(eventTypes: string, periodId: string, athleteId: string, startTime?: number, endTime?: number, imaAccelerationIntensityThreshold?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Events>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventsDataForAthleteInPeriod(eventTypes, periodId, athleteId, startTime, endTime, imaAccelerationIntensityThreshold, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EventsDataApi - factory interface
 * @export
 */
export const EventsDataApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EventsDataApiFp(configuration)
    return {
        /**
         * This API returns the events data for an athlete in the activity.
         * @summary Get Events Data for Athlete in Activity
         * @param {string} activityId Activity Id
         * @param {string} athleteId Athlete Id
         * @param {string} eventTypes Comma Separated Event Type Values (can be a number, string or both for e.g. 5,6,7,ima_acceleration,ima_jump)
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {number} [imaAccelerationIntensityThreshold] If specified, only events within an intensity value greater than the threshold value should be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsDataForAthleteInActivity(activityId: string, athleteId: string, eventTypes: string, startTime?: number, endTime?: number, imaAccelerationIntensityThreshold?: number, options?: any): AxiosPromise<Array<Events>> {
            return localVarFp.getEventsDataForAthleteInActivity(activityId, athleteId, eventTypes, startTime, endTime, imaAccelerationIntensityThreshold, options).then((request) => request(axios, basePath));
        },
        /**
         * This API returns the events data for an athlete in the period.
         * @summary Get Events Data For Athlete in Period
         * @param {string} eventTypes Comma Separated Event Types Values (can be a number, string or both for e.g. 5, 6, 7, ima_acceleration, ima_jump)
         * @param {string} periodId Period Id
         * @param {string} athleteId Athlete Id
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {number} [imaAccelerationIntensityThreshold] If specified, only events within an intensity value greater than the threshold value should be returned.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsDataForAthleteInPeriod(eventTypes: string, periodId: string, athleteId: string, startTime?: number, endTime?: number, imaAccelerationIntensityThreshold?: number, options?: any): AxiosPromise<Array<Events>> {
            return localVarFp.getEventsDataForAthleteInPeriod(eventTypes, periodId, athleteId, startTime, endTime, imaAccelerationIntensityThreshold, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EventsDataApi - object-oriented interface
 * @export
 * @class EventsDataApi
 * @extends {BaseAPI}
 */
export class EventsDataApi extends BaseAPI {
    /**
     * This API returns the events data for an athlete in the activity.
     * @summary Get Events Data for Athlete in Activity
     * @param {string} activityId Activity Id
     * @param {string} athleteId Athlete Id
     * @param {string} eventTypes Comma Separated Event Type Values (can be a number, string or both for e.g. 5,6,7,ima_acceleration,ima_jump)
     * @param {number} [startTime] Start Time as UNIX timestamp in seconds
     * @param {number} [endTime] End Time as UNIX timestamp in seconds
     * @param {number} [imaAccelerationIntensityThreshold] If specified, only events within an intensity value greater than the threshold value should be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsDataApi
     */
    public getEventsDataForAthleteInActivity(activityId: string, athleteId: string, eventTypes: string, startTime?: number, endTime?: number, imaAccelerationIntensityThreshold?: number, options?: AxiosRequestConfig) {
        return EventsDataApiFp(this.configuration).getEventsDataForAthleteInActivity(activityId, athleteId, eventTypes, startTime, endTime, imaAccelerationIntensityThreshold, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API returns the events data for an athlete in the period.
     * @summary Get Events Data For Athlete in Period
     * @param {string} eventTypes Comma Separated Event Types Values (can be a number, string or both for e.g. 5, 6, 7, ima_acceleration, ima_jump)
     * @param {string} periodId Period Id
     * @param {string} athleteId Athlete Id
     * @param {number} [startTime] Start Time as UNIX timestamp in seconds
     * @param {number} [endTime] End Time as UNIX timestamp in seconds
     * @param {number} [imaAccelerationIntensityThreshold] If specified, only events within an intensity value greater than the threshold value should be returned.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EventsDataApi
     */
    public getEventsDataForAthleteInPeriod(eventTypes: string, periodId: string, athleteId: string, startTime?: number, endTime?: number, imaAccelerationIntensityThreshold?: number, options?: AxiosRequestConfig) {
        return EventsDataApiFp(this.configuration).getEventsDataForAthleteInPeriod(eventTypes, periodId, athleteId, startTime, endTime, imaAccelerationIntensityThreshold, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FieldsApi - axios parameter creator
 * @export
 */
export const FieldsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary List fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFields: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FieldsApi - functional programming interface
 * @export
 */
export const FieldsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FieldsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary List fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllFields(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Fields>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllFields(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FieldsApi - factory interface
 * @export
 */
export const FieldsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FieldsApiFp(configuration)
    return {
        /**
         * 
         * @summary List fields
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFields(options?: any): AxiosPromise<Array<Fields>> {
            return localVarFp.getAllFields(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FieldsApi - object-oriented interface
 * @export
 * @class FieldsApi
 * @extends {BaseAPI}
 */
export class FieldsApi extends BaseAPI {
    /**
     * 
     * @summary List fields
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FieldsApi
     */
    public getAllFields(options?: AxiosRequestConfig) {
        return FieldsApiFp(this.configuration).getAllFields(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FilesApi - axios parameter creator
 * @export
 */
export const FilesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Register an import task and create a pre-signed url for uploading a catr file
         * @param {string} [filename] The filename used to create the upload url
         * @param {Tags1} [tags1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadUrl: async (filename?: string, tags1?: Tags1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/file/createUploadUrl`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tags1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get content of the file by name
         * @param {string} [filename] Download the named file. This is the \&#39;name\&#39; field from the file list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileByName: async (filename?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user?filename=`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List userfiles.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFiles: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload a file.
         * @param {string} [filename] The filename to store this file as.
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserFiles: async (filename?: string, body?: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FilesApi - functional programming interface
 * @export
 */
export const FilesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FilesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Register an import task and create a pre-signed url for uploading a catr file
         * @param {string} [filename] The filename used to create the upload url
         * @param {Tags1} [tags1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUploadUrl(filename?: string, tags1?: Tags1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CatrCreateUrlResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUploadUrl(filename, tags1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get content of the file by name
         * @param {string} [filename] Download the named file. This is the \&#39;name\&#39; field from the file list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileByName(filename?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileByName(filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List userfiles.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserFiles(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserFile>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserFiles(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload a file.
         * @param {string} [filename] The filename to store this file as.
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserFiles(filename?: string, body?: any, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserFiles(filename, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FilesApi - factory interface
 * @export
 */
export const FilesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FilesApiFp(configuration)
    return {
        /**
         * 
         * @summary Register an import task and create a pre-signed url for uploading a catr file
         * @param {string} [filename] The filename used to create the upload url
         * @param {Tags1} [tags1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUploadUrl(filename?: string, tags1?: Tags1, options?: any): AxiosPromise<CatrCreateUrlResponse> {
            return localVarFp.createUploadUrl(filename, tags1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get content of the file by name
         * @param {string} [filename] Download the named file. This is the \&#39;name\&#39; field from the file list.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileByName(filename?: string, options?: any): AxiosPromise<void> {
            return localVarFp.getFileByName(filename, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List userfiles.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFiles(options?: any): AxiosPromise<Array<UserFile>> {
            return localVarFp.getUserFiles(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload a file.
         * @param {string} [filename] The filename to store this file as.
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserFiles(filename?: string, body?: any, options?: any): AxiosPromise<void> {
            return localVarFp.putUserFiles(filename, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FilesApi - object-oriented interface
 * @export
 * @class FilesApi
 * @extends {BaseAPI}
 */
export class FilesApi extends BaseAPI {
    /**
     * 
     * @summary Register an import task and create a pre-signed url for uploading a catr file
     * @param {string} [filename] The filename used to create the upload url
     * @param {Tags1} [tags1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public createUploadUrl(filename?: string, tags1?: Tags1, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).createUploadUrl(filename, tags1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get content of the file by name
     * @param {string} [filename] Download the named file. This is the \&#39;name\&#39; field from the file list.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public getFileByName(filename?: string, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).getFileByName(filename, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List userfiles.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public getUserFiles(options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).getUserFiles(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload a file.
     * @param {string} [filename] The filename to store this file as.
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FilesApi
     */
    public putUserFiles(filename?: string, body?: any, options?: AxiosRequestConfig) {
        return FilesApiFp(this.configuration).putUserFiles(filename, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FrontendApi - axios parameter creator
 * @export
 */
export const FrontendApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/frontend/config`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FrontendApi - functional programming interface
 * @export
 */
export const FrontendApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FrontendApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConfig(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrontendConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConfig(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FrontendApi - factory interface
 * @export
 */
export const FrontendApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FrontendApiFp(configuration)
    return {
        /**
         * 
         * @summary Get configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConfig(options?: any): AxiosPromise<FrontendConfig> {
            return localVarFp.getConfig(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FrontendApi - object-oriented interface
 * @export
 * @class FrontendApi
 * @extends {BaseAPI}
 */
export class FrontendApi extends BaseAPI {
    /**
     * 
     * @summary Get configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FrontendApi
     */
    public getConfig(options?: AxiosRequestConfig) {
        return FrontendApiFp(this.configuration).getConfig(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LeagueApi - axios parameter creator
 * @export
 */
export const LeagueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get current user
         * @summary Get activity detail
         * @param {number} customerId 
         * @param {string} activityId 
         * @param {number} [isParent] If true, customerId is the league parent, otherwise child
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeagueActivityDetail: async (customerId: number, activityId: string, isParent?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('getLeagueActivityDetail', 'customerId', customerId)
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('getLeagueActivityDetail', 'activityId', activityId)
            const localVarPath = `/league/teams/{customerId}/activity/{activityId}`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)))
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (isParent !== undefined) {
                localVarQueryParameter['is_parent'] = isParent;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get info about a league child team
         * @param {number} childId Customer Id
         * @param {number} [startTime] Filter out activities with start time less than this value (POSIX time in seconds)
         * @param {number} [endTime] Filter out activities with end time greater than this value (POSIX time in seconds)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeagueTeam: async (childId: number, startTime?: number, endTime?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('getLeagueTeam', 'childId', childId)
            const localVarPath = `/league/teams/{childId}`
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get info about league child teams
         * @param {number} [startTime] Filter out activities with start time less than this value (POSIX time in seconds)
         * @param {number} [endTime] Filter out activities with end time greater than this value (POSIX time in seconds)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeagueTeams: async (startTime?: number, endTime?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/league/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get current user
         * @summary Get running migrations
         * @param {number} [limit] Maximum number of rows to return
         * @param {number} [customerId] Only return results for this child customer id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMigrations: async (limit?: number, customerId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/league/migrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (customerId !== undefined) {
                localVarQueryParameter['customer_id'] = customerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Trigger migrations for a list of activities for given child
         * @param {number} childId Customer Id
         * @param {LeagueMigrationParameters} [leagueMigrationParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLeagueTeam: async (childId: number, leagueMigrationParameters?: LeagueMigrationParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'childId' is not null or undefined
            assertParamExists('postLeagueTeam', 'childId', childId)
            const localVarPath = `/league/teams/{childId}`
                .replace(`{${"childId"}}`, encodeURIComponent(String(childId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leagueMigrationParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retry migrations for a list of activities for given child
         * @param {LeagueRefreshActiveMigrationParameters} [leagueRefreshActiveMigrationParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMigrations: async (leagueRefreshActiveMigrationParameters?: LeagueRefreshActiveMigrationParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/league/migrations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(leagueRefreshActiveMigrationParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LeagueApi - functional programming interface
 * @export
 */
export const LeagueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LeagueApiAxiosParamCreator(configuration)
    return {
        /**
         * Get current user
         * @summary Get activity detail
         * @param {number} customerId 
         * @param {string} activityId 
         * @param {number} [isParent] If true, customerId is the league parent, otherwise child
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeagueActivityDetail(customerId: number, activityId: string, isParent?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeagueActivityDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeagueActivityDetail(customerId, activityId, isParent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get info about a league child team
         * @param {number} childId Customer Id
         * @param {number} [startTime] Filter out activities with start time less than this value (POSIX time in seconds)
         * @param {number} [endTime] Filter out activities with end time greater than this value (POSIX time in seconds)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeagueTeam(childId: number, startTime?: number, endTime?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeagueTeamDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeagueTeam(childId, startTime, endTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get info about league child teams
         * @param {number} [startTime] Filter out activities with start time less than this value (POSIX time in seconds)
         * @param {number} [endTime] Filter out activities with end time greater than this value (POSIX time in seconds)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeagueTeams(startTime?: number, endTime?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeagueTeams>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeagueTeams(startTime, endTime, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get current user
         * @summary Get running migrations
         * @param {number} [limit] Maximum number of rows to return
         * @param {number} [customerId] Only return results for this child customer id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMigrations(limit?: number, customerId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeagueMigrations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMigrations(limit, customerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Trigger migrations for a list of activities for given child
         * @param {number} childId Customer Id
         * @param {LeagueMigrationParameters} [leagueMigrationParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLeagueTeam(childId: number, leagueMigrationParameters?: LeagueMigrationParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLeagueTeam(childId, leagueMigrationParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retry migrations for a list of activities for given child
         * @param {LeagueRefreshActiveMigrationParameters} [leagueRefreshActiveMigrationParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMigrations(leagueRefreshActiveMigrationParameters?: LeagueRefreshActiveMigrationParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMigrations(leagueRefreshActiveMigrationParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LeagueApi - factory interface
 * @export
 */
export const LeagueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LeagueApiFp(configuration)
    return {
        /**
         * Get current user
         * @summary Get activity detail
         * @param {number} customerId 
         * @param {string} activityId 
         * @param {number} [isParent] If true, customerId is the league parent, otherwise child
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeagueActivityDetail(customerId: number, activityId: string, isParent?: number, options?: any): AxiosPromise<LeagueActivityDetail> {
            return localVarFp.getLeagueActivityDetail(customerId, activityId, isParent, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get info about a league child team
         * @param {number} childId Customer Id
         * @param {number} [startTime] Filter out activities with start time less than this value (POSIX time in seconds)
         * @param {number} [endTime] Filter out activities with end time greater than this value (POSIX time in seconds)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeagueTeam(childId: number, startTime?: number, endTime?: number, options?: any): AxiosPromise<LeagueTeamDetail> {
            return localVarFp.getLeagueTeam(childId, startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get info about league child teams
         * @param {number} [startTime] Filter out activities with start time less than this value (POSIX time in seconds)
         * @param {number} [endTime] Filter out activities with end time greater than this value (POSIX time in seconds)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeagueTeams(startTime?: number, endTime?: number, options?: any): AxiosPromise<LeagueTeams> {
            return localVarFp.getLeagueTeams(startTime, endTime, options).then((request) => request(axios, basePath));
        },
        /**
         * Get current user
         * @summary Get running migrations
         * @param {number} [limit] Maximum number of rows to return
         * @param {number} [customerId] Only return results for this child customer id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMigrations(limit?: number, customerId?: number, options?: any): AxiosPromise<LeagueMigrations> {
            return localVarFp.getMigrations(limit, customerId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Trigger migrations for a list of activities for given child
         * @param {number} childId Customer Id
         * @param {LeagueMigrationParameters} [leagueMigrationParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLeagueTeam(childId: number, leagueMigrationParameters?: LeagueMigrationParameters, options?: any): AxiosPromise<void> {
            return localVarFp.postLeagueTeam(childId, leagueMigrationParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retry migrations for a list of activities for given child
         * @param {LeagueRefreshActiveMigrationParameters} [leagueRefreshActiveMigrationParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMigrations(leagueRefreshActiveMigrationParameters?: LeagueRefreshActiveMigrationParameters, options?: any): AxiosPromise<void> {
            return localVarFp.postMigrations(leagueRefreshActiveMigrationParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LeagueApi - object-oriented interface
 * @export
 * @class LeagueApi
 * @extends {BaseAPI}
 */
export class LeagueApi extends BaseAPI {
    /**
     * Get current user
     * @summary Get activity detail
     * @param {number} customerId 
     * @param {string} activityId 
     * @param {number} [isParent] If true, customerId is the league parent, otherwise child
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueApi
     */
    public getLeagueActivityDetail(customerId: number, activityId: string, isParent?: number, options?: AxiosRequestConfig) {
        return LeagueApiFp(this.configuration).getLeagueActivityDetail(customerId, activityId, isParent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get info about a league child team
     * @param {number} childId Customer Id
     * @param {number} [startTime] Filter out activities with start time less than this value (POSIX time in seconds)
     * @param {number} [endTime] Filter out activities with end time greater than this value (POSIX time in seconds)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueApi
     */
    public getLeagueTeam(childId: number, startTime?: number, endTime?: number, options?: AxiosRequestConfig) {
        return LeagueApiFp(this.configuration).getLeagueTeam(childId, startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get info about league child teams
     * @param {number} [startTime] Filter out activities with start time less than this value (POSIX time in seconds)
     * @param {number} [endTime] Filter out activities with end time greater than this value (POSIX time in seconds)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueApi
     */
    public getLeagueTeams(startTime?: number, endTime?: number, options?: AxiosRequestConfig) {
        return LeagueApiFp(this.configuration).getLeagueTeams(startTime, endTime, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get current user
     * @summary Get running migrations
     * @param {number} [limit] Maximum number of rows to return
     * @param {number} [customerId] Only return results for this child customer id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueApi
     */
    public getMigrations(limit?: number, customerId?: number, options?: AxiosRequestConfig) {
        return LeagueApiFp(this.configuration).getMigrations(limit, customerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Trigger migrations for a list of activities for given child
     * @param {number} childId Customer Id
     * @param {LeagueMigrationParameters} [leagueMigrationParameters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueApi
     */
    public postLeagueTeam(childId: number, leagueMigrationParameters?: LeagueMigrationParameters, options?: AxiosRequestConfig) {
        return LeagueApiFp(this.configuration).postLeagueTeam(childId, leagueMigrationParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retry migrations for a list of activities for given child
     * @param {LeagueRefreshActiveMigrationParameters} [leagueRefreshActiveMigrationParameters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LeagueApi
     */
    public postMigrations(leagueRefreshActiveMigrationParameters?: LeagueRefreshActiveMigrationParameters, options?: AxiosRequestConfig) {
        return LeagueApiFp(this.configuration).postMigrations(leagueRefreshActiveMigrationParameters, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LiveApi - axios parameter creator
 * @export
 */
export const LiveApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get live athletes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveAthletes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/live`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get live info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveInfo: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/live/info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LiveApi - functional programming interface
 * @export
 */
export const LiveApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LiveApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get live athletes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLiveAthletes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LiveAthlete>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLiveAthletes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get live info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLiveInfo(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LiveInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLiveInfo(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LiveApi - factory interface
 * @export
 */
export const LiveApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LiveApiFp(configuration)
    return {
        /**
         * 
         * @summary Get live athletes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveAthletes(options?: any): AxiosPromise<Array<LiveAthlete>> {
            return localVarFp.getLiveAthletes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get live info
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLiveInfo(options?: any): AxiosPromise<LiveInfo> {
            return localVarFp.getLiveInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LiveApi - object-oriented interface
 * @export
 * @class LiveApi
 * @extends {BaseAPI}
 */
export class LiveApi extends BaseAPI {
    /**
     * 
     * @summary Get live athletes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveApi
     */
    public getLiveAthletes(options?: AxiosRequestConfig) {
        return LiveApiFp(this.configuration).getLiveAthletes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get live info
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LiveApi
     */
    public getLiveInfo(options?: AxiosRequestConfig) {
        return LiveApiFp(this.configuration).getLiveInfo(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MatchesApi - axios parameter creator
 * @export
 */
export const MatchesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates match
         * @summary Create match day info
         * @param {MatchRequest} [matchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMatch: async (matchRequest?: MatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/matches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(matchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete match info
         * @summary Delete match info
         * @param {string} id ID of the match
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMatch: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMatch', 'id', id)
            const localVarPath = `/matches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete match opponent
         * @summary Delete match opponent
         * @param {string} id ID of the opponent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMatchOpponent: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteMatchOpponent', 'id', id)
            const localVarPath = `/matches/opponents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of match opponents
         * @summary List all match day opponents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMatchOpponents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/matches/opponents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of matches
         * @summary List all match day info
         * @param {string} [activityId] Which activity to retrieve match info for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMatches: async (activityId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/matches`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (activityId !== undefined) {
                localVarQueryParameter['activity_id'] = activityId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates match info
         * @summary Update match day info
         * @param {string} id ID of the match
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMatch: async (id: string, inlineObject1?: InlineObject1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateMatch', 'id', id)
            const localVarPath = `/matches/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MatchesApi - functional programming interface
 * @export
 */
export const MatchesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MatchesApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates match
         * @summary Create match day info
         * @param {MatchRequest} [matchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMatch(matchRequest?: MatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMatch(matchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete match info
         * @summary Delete match info
         * @param {string} id ID of the match
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMatch(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMatch(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete match opponent
         * @summary Delete match opponent
         * @param {string} id ID of the opponent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMatchOpponent(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMatchOpponent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of match opponents
         * @summary List all match day opponents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMatchOpponents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MatchOpponent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMatchOpponents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of matches
         * @summary List all match day info
         * @param {string} [activityId] Which activity to retrieve match info for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMatches(activityId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Match>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMatches(activityId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates match info
         * @summary Update match day info
         * @param {string} id ID of the match
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMatch(id: string, inlineObject1?: InlineObject1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineObject1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMatch(id, inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MatchesApi - factory interface
 * @export
 */
export const MatchesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MatchesApiFp(configuration)
    return {
        /**
         * Creates match
         * @summary Create match day info
         * @param {MatchRequest} [matchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMatch(matchRequest?: MatchRequest, options?: any): AxiosPromise<object> {
            return localVarFp.createMatch(matchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete match info
         * @summary Delete match info
         * @param {string} id ID of the match
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMatch(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMatch(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete match opponent
         * @summary Delete match opponent
         * @param {string} id ID of the opponent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMatchOpponent(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteMatchOpponent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List of match opponents
         * @summary List all match day opponents
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMatchOpponents(options?: any): AxiosPromise<Array<MatchOpponent>> {
            return localVarFp.listMatchOpponents(options).then((request) => request(axios, basePath));
        },
        /**
         * List of matches
         * @summary List all match day info
         * @param {string} [activityId] Which activity to retrieve match info for
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMatches(activityId?: string, options?: any): AxiosPromise<Array<Match>> {
            return localVarFp.listMatches(activityId, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates match info
         * @summary Update match day info
         * @param {string} id ID of the match
         * @param {InlineObject1} [inlineObject1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMatch(id: string, inlineObject1?: InlineObject1, options?: any): AxiosPromise<InlineObject1> {
            return localVarFp.updateMatch(id, inlineObject1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MatchesApi - object-oriented interface
 * @export
 * @class MatchesApi
 * @extends {BaseAPI}
 */
export class MatchesApi extends BaseAPI {
    /**
     * Creates match
     * @summary Create match day info
     * @param {MatchRequest} [matchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchesApi
     */
    public createMatch(matchRequest?: MatchRequest, options?: AxiosRequestConfig) {
        return MatchesApiFp(this.configuration).createMatch(matchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete match info
     * @summary Delete match info
     * @param {string} id ID of the match
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchesApi
     */
    public deleteMatch(id: string, options?: AxiosRequestConfig) {
        return MatchesApiFp(this.configuration).deleteMatch(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete match opponent
     * @summary Delete match opponent
     * @param {string} id ID of the opponent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchesApi
     */
    public deleteMatchOpponent(id: string, options?: AxiosRequestConfig) {
        return MatchesApiFp(this.configuration).deleteMatchOpponent(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of match opponents
     * @summary List all match day opponents
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchesApi
     */
    public listMatchOpponents(options?: AxiosRequestConfig) {
        return MatchesApiFp(this.configuration).listMatchOpponents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of matches
     * @summary List all match day info
     * @param {string} [activityId] Which activity to retrieve match info for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchesApi
     */
    public listMatches(activityId?: string, options?: AxiosRequestConfig) {
        return MatchesApiFp(this.configuration).listMatches(activityId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates match info
     * @summary Update match day info
     * @param {string} id ID of the match
     * @param {InlineObject1} [inlineObject1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchesApi
     */
    public updateMatch(id: string, inlineObject1?: InlineObject1, options?: AxiosRequestConfig) {
        return MatchesApiFp(this.configuration).updateMatch(id, inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MicroAuthApi - axios parameter creator
 * @export
 */
export const MicroAuthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create MicroAuth Token
         * @param {MicroAuthTokenRequest} [microAuthTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMicroAuthToken: async (microAuthTokenRequest?: MicroAuthTokenRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/microauth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(microAuthTokenRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Check Resources Authorisation
         * @param {MicroAuthResourcesCheckRequest} [microAuthResourcesCheckRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        microAuthResourcesCheck: async (microAuthResourcesCheckRequest?: MicroAuthResourcesCheckRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/microauth/resources/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(microAuthResourcesCheckRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MicroAuthApi - functional programming interface
 * @export
 */
export const MicroAuthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MicroAuthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create MicroAuth Token
         * @param {MicroAuthTokenRequest} [microAuthTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMicroAuthToken(microAuthTokenRequest?: MicroAuthTokenRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MicroAuthToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMicroAuthToken(microAuthTokenRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Check Resources Authorisation
         * @param {MicroAuthResourcesCheckRequest} [microAuthResourcesCheckRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async microAuthResourcesCheck(microAuthResourcesCheckRequest?: MicroAuthResourcesCheckRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MicroAuthResourcesCheckResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.microAuthResourcesCheck(microAuthResourcesCheckRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MicroAuthApi - factory interface
 * @export
 */
export const MicroAuthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MicroAuthApiFp(configuration)
    return {
        /**
         * 
         * @summary Create MicroAuth Token
         * @param {MicroAuthTokenRequest} [microAuthTokenRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMicroAuthToken(microAuthTokenRequest?: MicroAuthTokenRequest, options?: any): AxiosPromise<MicroAuthToken> {
            return localVarFp.createMicroAuthToken(microAuthTokenRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Check Resources Authorisation
         * @param {MicroAuthResourcesCheckRequest} [microAuthResourcesCheckRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        microAuthResourcesCheck(microAuthResourcesCheckRequest?: MicroAuthResourcesCheckRequest, options?: any): AxiosPromise<MicroAuthResourcesCheckResponse> {
            return localVarFp.microAuthResourcesCheck(microAuthResourcesCheckRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MicroAuthApi - object-oriented interface
 * @export
 * @class MicroAuthApi
 * @extends {BaseAPI}
 */
export class MicroAuthApi extends BaseAPI {
    /**
     * 
     * @summary Create MicroAuth Token
     * @param {MicroAuthTokenRequest} [microAuthTokenRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MicroAuthApi
     */
    public createMicroAuthToken(microAuthTokenRequest?: MicroAuthTokenRequest, options?: AxiosRequestConfig) {
        return MicroAuthApiFp(this.configuration).createMicroAuthToken(microAuthTokenRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Check Resources Authorisation
     * @param {MicroAuthResourcesCheckRequest} [microAuthResourcesCheckRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MicroAuthApi
     */
    public microAuthResourcesCheck(microAuthResourcesCheckRequest?: MicroAuthResourcesCheckRequest, options?: AxiosRequestConfig) {
        return MicroAuthApiFp(this.configuration).microAuthResourcesCheck(microAuthResourcesCheckRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ModulesApi - axios parameter creator
 * @export
 */
export const ModulesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get module names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleNames: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/modules/names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModulesApi - functional programming interface
 * @export
 */
export const ModulesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModulesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get module names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getModuleNames(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getModuleNames(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModulesApi - factory interface
 * @export
 */
export const ModulesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModulesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get module names
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getModuleNames(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getModuleNames(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModulesApi - object-oriented interface
 * @export
 * @class ModulesApi
 * @extends {BaseAPI}
 */
export class ModulesApi extends BaseAPI {
    /**
     * 
     * @summary Get module names
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModulesApi
     */
    public getModuleNames(options?: AxiosRequestConfig) {
        return ModulesApiFp(this.configuration).getModuleNames(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NoticesApi - axios parameter creator
 * @export
 */
export const NoticesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get notices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotices: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update one notice\'s \'read\' and/or \'notified\' status
         * @summary Update one notice
         * @param {string} id Notice id
         * @param {NoticeUpdateRequest} [noticeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotice: async (id: string, noticeUpdateRequest?: NoticeUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putNotice', 'id', id)
            const localVarPath = `/notices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noticeUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update all notices\' \'read\' and/or \'notified\' status
         * @summary Update all notices at once
         * @param {NoticeUpdateRequest} [noticeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotices: async (noticeUpdateRequest?: NoticeUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(noticeUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NoticesApi - functional programming interface
 * @export
 */
export const NoticesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NoticesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get notices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotices(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notice>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotices(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update one notice\'s \'read\' and/or \'notified\' status
         * @summary Update one notice
         * @param {string} id Notice id
         * @param {NoticeUpdateRequest} [noticeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putNotice(id: string, noticeUpdateRequest?: NoticeUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putNotice(id, noticeUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update all notices\' \'read\' and/or \'notified\' status
         * @summary Update all notices at once
         * @param {NoticeUpdateRequest} [noticeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putNotices(noticeUpdateRequest?: NoticeUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putNotices(noticeUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NoticesApi - factory interface
 * @export
 */
export const NoticesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NoticesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get notices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotices(options?: any): AxiosPromise<Array<Notice>> {
            return localVarFp.getNotices(options).then((request) => request(axios, basePath));
        },
        /**
         * Update one notice\'s \'read\' and/or \'notified\' status
         * @summary Update one notice
         * @param {string} id Notice id
         * @param {NoticeUpdateRequest} [noticeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotice(id: string, noticeUpdateRequest?: NoticeUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putNotice(id, noticeUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update all notices\' \'read\' and/or \'notified\' status
         * @summary Update all notices at once
         * @param {NoticeUpdateRequest} [noticeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotices(noticeUpdateRequest?: NoticeUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.putNotices(noticeUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NoticesApi - object-oriented interface
 * @export
 * @class NoticesApi
 * @extends {BaseAPI}
 */
export class NoticesApi extends BaseAPI {
    /**
     * 
     * @summary Get notices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticesApi
     */
    public getNotices(options?: AxiosRequestConfig) {
        return NoticesApiFp(this.configuration).getNotices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update one notice\'s \'read\' and/or \'notified\' status
     * @summary Update one notice
     * @param {string} id Notice id
     * @param {NoticeUpdateRequest} [noticeUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticesApi
     */
    public putNotice(id: string, noticeUpdateRequest?: NoticeUpdateRequest, options?: AxiosRequestConfig) {
        return NoticesApiFp(this.configuration).putNotice(id, noticeUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update all notices\' \'read\' and/or \'notified\' status
     * @summary Update all notices at once
     * @param {NoticeUpdateRequest} [noticeUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NoticesApi
     */
    public putNotices(noticeUpdateRequest?: NoticeUpdateRequest, options?: AxiosRequestConfig) {
        return NoticesApiFp(this.configuration).putNotices(noticeUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Action notifications
         * @summary Action notifications
         * @param {NotificationIds} [notificationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionNotifications: async (notificationIds?: NotificationIds, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications/action`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationIds, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes one or more notifications for the user
         * @summary Delete notifications for the user
         * @param {NotificationsDeleteRequest} [notificationsDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotifications: async (notificationsDeleteRequest?: NotificationsDeleteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationsDeleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update multiple notifications\' \'read\' and/or \'notified\' status
         * @summary Update multiple notifications
         * @param {NotificationsUpdateRequest} [notificationsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNotifications: async (notificationsUpdateRequest?: NotificationsUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(notificationsUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Action notifications
         * @summary Action notifications
         * @param {NotificationIds} [notificationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async actionNotifications(notificationIds?: NotificationIds, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.actionNotifications(notificationIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes one or more notifications for the user
         * @summary Delete notifications for the user
         * @param {NotificationsDeleteRequest} [notificationsDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNotifications(notificationsDeleteRequest?: NotificationsDeleteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNotifications(notificationsDeleteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotifications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotifications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update multiple notifications\' \'read\' and/or \'notified\' status
         * @summary Update multiple notifications
         * @param {NotificationsUpdateRequest} [notificationsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchNotifications(notificationsUpdateRequest?: NotificationsUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchNotifications(notificationsUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * Action notifications
         * @summary Action notifications
         * @param {NotificationIds} [notificationIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        actionNotifications(notificationIds?: NotificationIds, options?: any): AxiosPromise<void> {
            return localVarFp.actionNotifications(notificationIds, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes one or more notifications for the user
         * @summary Delete notifications for the user
         * @param {NotificationsDeleteRequest} [notificationsDeleteRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNotifications(notificationsDeleteRequest?: NotificationsDeleteRequest, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNotifications(notificationsDeleteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get notifications
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(options?: any): AxiosPromise<Array<Notification>> {
            return localVarFp.getNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * Update multiple notifications\' \'read\' and/or \'notified\' status
         * @summary Update multiple notifications
         * @param {NotificationsUpdateRequest} [notificationsUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchNotifications(notificationsUpdateRequest?: NotificationsUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.patchNotifications(notificationsUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * Action notifications
     * @summary Action notifications
     * @param {NotificationIds} [notificationIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public actionNotifications(notificationIds?: NotificationIds, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).actionNotifications(notificationIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes one or more notifications for the user
     * @summary Delete notifications for the user
     * @param {NotificationsDeleteRequest} [notificationsDeleteRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public deleteNotifications(notificationsDeleteRequest?: NotificationsDeleteRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).deleteNotifications(notificationsDeleteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get notifications
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotifications(options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update multiple notifications\' \'read\' and/or \'notified\' status
     * @summary Update multiple notifications
     * @param {NotificationsUpdateRequest} [notificationsUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public patchNotifications(notificationsUpdateRequest?: NotificationsUpdateRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).patchNotifications(notificationsUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ParametersApi - axios parameter creator
 * @export
 */
export const ParametersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a parameter. Currently only parameters with the \"Custom\" parameter type can be deleted.
         * @summary Delete a parameter
         * @param {string} id Parameter Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParameter: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteParameter', 'id', id)
            const localVarPath = `/parameters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will retrieve all parameter types in an account.
         * @summary Get All Parameter Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllParameterTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parameter_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will retrieve details for a single parameter
         * @summary Get Parameter Details
         * @param {string} id Parameter Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParameter: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getParameter', 'id', id)
            const localVarPath = `/parameters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API retrieves all the parameter groups under your account.
         * @summary Get parameter groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParameterGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parameter_groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API retrieves all the parameters under your account.
         * @summary Get all Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParameters: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a parameter. Currently only parameters with the \"Custom\" parameter type can be updated.
         * @summary Update a parameter
         * @param {string} id Parameter Id
         * @param {ParameterUpdateRequest} [parameterUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchParameter: async (id: string, parameterUpdateRequest?: ParameterUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('patchParameter', 'id', id)
            const localVarPath = `/parameters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parameterUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a parameter. Currently all parameters are created with the \"Custom\" parameter type.
         * @summary Create a parameter
         * @param {ParameterCreateRequest} [parameterCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postParameters: async (parameterCreateRequest?: ParameterCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/parameters`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parameterCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ParametersApi - functional programming interface
 * @export
 */
export const ParametersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ParametersApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a parameter. Currently only parameters with the \"Custom\" parameter type can be deleted.
         * @summary Delete a parameter
         * @param {string} id Parameter Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteParameter(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteParameter(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API will retrieve all parameter types in an account.
         * @summary Get All Parameter Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllParameterTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParameterType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllParameterTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API will retrieve details for a single parameter
         * @summary Get Parameter Details
         * @param {string} id Parameter Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParameter(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Parameter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParameter(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API retrieves all the parameter groups under your account.
         * @summary Get parameter groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParameterGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ParameterGroup>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParameterGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API retrieves all the parameters under your account.
         * @summary Get all Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getParameters(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Parameter>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getParameters(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a parameter. Currently only parameters with the \"Custom\" parameter type can be updated.
         * @summary Update a parameter
         * @param {string} id Parameter Id
         * @param {ParameterUpdateRequest} [parameterUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchParameter(id: string, parameterUpdateRequest?: ParameterUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Parameter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchParameter(id, parameterUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a parameter. Currently all parameters are created with the \"Custom\" parameter type.
         * @summary Create a parameter
         * @param {ParameterCreateRequest} [parameterCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postParameters(parameterCreateRequest?: ParameterCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Parameter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postParameters(parameterCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ParametersApi - factory interface
 * @export
 */
export const ParametersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ParametersApiFp(configuration)
    return {
        /**
         * Delete a parameter. Currently only parameters with the \"Custom\" parameter type can be deleted.
         * @summary Delete a parameter
         * @param {string} id Parameter Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteParameter(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteParameter(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will retrieve all parameter types in an account.
         * @summary Get All Parameter Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllParameterTypes(options?: any): AxiosPromise<Array<ParameterType>> {
            return localVarFp.getAllParameterTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * This API will retrieve details for a single parameter
         * @summary Get Parameter Details
         * @param {string} id Parameter Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParameter(id: string, options?: any): AxiosPromise<Parameter> {
            return localVarFp.getParameter(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This API retrieves all the parameter groups under your account.
         * @summary Get parameter groups
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParameterGroups(options?: any): AxiosPromise<Array<ParameterGroup>> {
            return localVarFp.getParameterGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * This API retrieves all the parameters under your account.
         * @summary Get all Parameters
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getParameters(options?: any): AxiosPromise<Array<Parameter>> {
            return localVarFp.getParameters(options).then((request) => request(axios, basePath));
        },
        /**
         * Update a parameter. Currently only parameters with the \"Custom\" parameter type can be updated.
         * @summary Update a parameter
         * @param {string} id Parameter Id
         * @param {ParameterUpdateRequest} [parameterUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchParameter(id: string, parameterUpdateRequest?: ParameterUpdateRequest, options?: any): AxiosPromise<Parameter> {
            return localVarFp.patchParameter(id, parameterUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a parameter. Currently all parameters are created with the \"Custom\" parameter type.
         * @summary Create a parameter
         * @param {ParameterCreateRequest} [parameterCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postParameters(parameterCreateRequest?: ParameterCreateRequest, options?: any): AxiosPromise<Parameter> {
            return localVarFp.postParameters(parameterCreateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ParametersApi - object-oriented interface
 * @export
 * @class ParametersApi
 * @extends {BaseAPI}
 */
export class ParametersApi extends BaseAPI {
    /**
     * Delete a parameter. Currently only parameters with the \"Custom\" parameter type can be deleted.
     * @summary Delete a parameter
     * @param {string} id Parameter Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersApi
     */
    public deleteParameter(id: string, options?: AxiosRequestConfig) {
        return ParametersApiFp(this.configuration).deleteParameter(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API will retrieve all parameter types in an account.
     * @summary Get All Parameter Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersApi
     */
    public getAllParameterTypes(options?: AxiosRequestConfig) {
        return ParametersApiFp(this.configuration).getAllParameterTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API will retrieve details for a single parameter
     * @summary Get Parameter Details
     * @param {string} id Parameter Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersApi
     */
    public getParameter(id: string, options?: AxiosRequestConfig) {
        return ParametersApiFp(this.configuration).getParameter(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API retrieves all the parameter groups under your account.
     * @summary Get parameter groups
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersApi
     */
    public getParameterGroups(options?: AxiosRequestConfig) {
        return ParametersApiFp(this.configuration).getParameterGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API retrieves all the parameters under your account.
     * @summary Get all Parameters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersApi
     */
    public getParameters(options?: AxiosRequestConfig) {
        return ParametersApiFp(this.configuration).getParameters(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a parameter. Currently only parameters with the \"Custom\" parameter type can be updated.
     * @summary Update a parameter
     * @param {string} id Parameter Id
     * @param {ParameterUpdateRequest} [parameterUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersApi
     */
    public patchParameter(id: string, parameterUpdateRequest?: ParameterUpdateRequest, options?: AxiosRequestConfig) {
        return ParametersApiFp(this.configuration).patchParameter(id, parameterUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a parameter. Currently all parameters are created with the \"Custom\" parameter type.
     * @summary Create a parameter
     * @param {ParameterCreateRequest} [parameterCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ParametersApi
     */
    public postParameters(parameterCreateRequest?: ParameterCreateRequest, options?: AxiosRequestConfig) {
        return ParametersApiFp(this.configuration).postParameters(parameterCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PerformanceFlagsApi - axios parameter creator
 * @export
 */
export const PerformanceFlagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates performance flags
         * @summary Create performance flags
         * @param {PerformanceFlagRequest} [performanceFlagRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerformanceFlags: async (performanceFlagRequest?: PerformanceFlagRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/performance_flags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(performanceFlagRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete performance flag
         * @summary Delete performance flag
         * @param {string} id ID of the flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerformanceFlag: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deletePerformanceFlag', 'id', id)
            const localVarPath = `/performance_flags/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List of performance flags
         * @summary List performance flags
         * @param {string} [setName] Which set to retrieve flags from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPerformanceFlags: async (setName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/performance_flags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (setName !== undefined) {
                localVarQueryParameter['set_name'] = setName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates performance flags
         * @summary Update performance flags
         * @param {PerformanceFlagRequest1} [performanceFlagRequest1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerformanceFlags: async (performanceFlagRequest1?: PerformanceFlagRequest1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/performance_flags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(performanceFlagRequest1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PerformanceFlagsApi - functional programming interface
 * @export
 */
export const PerformanceFlagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PerformanceFlagsApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates performance flags
         * @summary Create performance flags
         * @param {PerformanceFlagRequest} [performanceFlagRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPerformanceFlags(performanceFlagRequest?: PerformanceFlagRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPerformanceFlags(performanceFlagRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete performance flag
         * @summary Delete performance flag
         * @param {string} id ID of the flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePerformanceFlag(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePerformanceFlag(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List of performance flags
         * @summary List performance flags
         * @param {string} [setName] Which set to retrieve flags from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPerformanceFlags(setName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PerformanceFlag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPerformanceFlags(setName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates performance flags
         * @summary Update performance flags
         * @param {PerformanceFlagRequest1} [performanceFlagRequest1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePerformanceFlags(performanceFlagRequest1?: PerformanceFlagRequest1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePerformanceFlags(performanceFlagRequest1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PerformanceFlagsApi - factory interface
 * @export
 */
export const PerformanceFlagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PerformanceFlagsApiFp(configuration)
    return {
        /**
         * Creates performance flags
         * @summary Create performance flags
         * @param {PerformanceFlagRequest} [performanceFlagRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPerformanceFlags(performanceFlagRequest?: PerformanceFlagRequest, options?: any): AxiosPromise<object> {
            return localVarFp.createPerformanceFlags(performanceFlagRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete performance flag
         * @summary Delete performance flag
         * @param {string} id ID of the flag
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePerformanceFlag(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deletePerformanceFlag(id, options).then((request) => request(axios, basePath));
        },
        /**
         * List of performance flags
         * @summary List performance flags
         * @param {string} [setName] Which set to retrieve flags from
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPerformanceFlags(setName?: string, options?: any): AxiosPromise<Array<PerformanceFlag>> {
            return localVarFp.listPerformanceFlags(setName, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates performance flags
         * @summary Update performance flags
         * @param {PerformanceFlagRequest1} [performanceFlagRequest1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePerformanceFlags(performanceFlagRequest1?: PerformanceFlagRequest1, options?: any): AxiosPromise<object> {
            return localVarFp.updatePerformanceFlags(performanceFlagRequest1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PerformanceFlagsApi - object-oriented interface
 * @export
 * @class PerformanceFlagsApi
 * @extends {BaseAPI}
 */
export class PerformanceFlagsApi extends BaseAPI {
    /**
     * Creates performance flags
     * @summary Create performance flags
     * @param {PerformanceFlagRequest} [performanceFlagRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceFlagsApi
     */
    public createPerformanceFlags(performanceFlagRequest?: PerformanceFlagRequest, options?: AxiosRequestConfig) {
        return PerformanceFlagsApiFp(this.configuration).createPerformanceFlags(performanceFlagRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete performance flag
     * @summary Delete performance flag
     * @param {string} id ID of the flag
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceFlagsApi
     */
    public deletePerformanceFlag(id: string, options?: AxiosRequestConfig) {
        return PerformanceFlagsApiFp(this.configuration).deletePerformanceFlag(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List of performance flags
     * @summary List performance flags
     * @param {string} [setName] Which set to retrieve flags from
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceFlagsApi
     */
    public listPerformanceFlags(setName?: string, options?: AxiosRequestConfig) {
        return PerformanceFlagsApiFp(this.configuration).listPerformanceFlags(setName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates performance flags
     * @summary Update performance flags
     * @param {PerformanceFlagRequest1} [performanceFlagRequest1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PerformanceFlagsApi
     */
    public updatePerformanceFlags(performanceFlagRequest1?: PerformanceFlagRequest1, options?: AxiosRequestConfig) {
        return PerformanceFlagsApiFp(this.configuration).updatePerformanceFlags(performanceFlagRequest1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PeriodsApi - axios parameter creator
 * @export
 */
export const PeriodsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This API returns all the athletes under provided period id.
         * @summary Get all Athletes in a Period
         * @param {string} id Period Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthletesInPeriod: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAthletesInPeriod', 'id', id)
            const localVarPath = `/periods/{id}/athletes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API returns the list of all periods.
         * @summary Get all Periods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriods: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/periods`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Period Details
         * @param {string} id Period Id
         * @param {Array<'period_athletes'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        periodsIdGet: async (id: string, include?: Array<'period_athletes'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('periodsIdGet', 'id', id)
            const localVarPath = `/periods/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API call will upsert all periods information for an activity if request has periods-update scope
         * @summary Upsert all Periods, PeriodAthletes, PeriodAthleteRotations, Period-Tags and PeriodAthlete-Tags for an activity
         * @param {PeriodBatchRequest} [periodBatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPeriodBatch: async (periodBatchRequest?: PeriodBatchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/periods/batch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(periodBatchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PeriodsApi - functional programming interface
 * @export
 */
export const PeriodsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PeriodsApiAxiosParamCreator(configuration)
    return {
        /**
         * This API returns all the athletes under provided period id.
         * @summary Get all Athletes in a Period
         * @param {string} id Period Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAthletesInPeriod(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityAthlete>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAthletesInPeriod(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API returns the list of all periods.
         * @summary Get all Periods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPeriods(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityPeriod>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPeriods(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Period Details
         * @param {string} id Period Id
         * @param {Array<'period_athletes'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async periodsIdGet(id: string, include?: Array<'period_athletes'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivityPeriodAthlete>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.periodsIdGet(id, include, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API call will upsert all periods information for an activity if request has periods-update scope
         * @summary Upsert all Periods, PeriodAthletes, PeriodAthleteRotations, Period-Tags and PeriodAthlete-Tags for an activity
         * @param {PeriodBatchRequest} [periodBatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPeriodBatch(periodBatchRequest?: PeriodBatchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityPeriod>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPeriodBatch(periodBatchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PeriodsApi - factory interface
 * @export
 */
export const PeriodsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PeriodsApiFp(configuration)
    return {
        /**
         * This API returns all the athletes under provided period id.
         * @summary Get all Athletes in a Period
         * @param {string} id Period Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthletesInPeriod(id: string, options?: any): AxiosPromise<Array<ActivityAthlete>> {
            return localVarFp.getAthletesInPeriod(id, options).then((request) => request(axios, basePath));
        },
        /**
         * This API returns the list of all periods.
         * @summary Get all Periods
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPeriods(options?: any): AxiosPromise<Array<ActivityPeriod>> {
            return localVarFp.getPeriods(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Period Details
         * @param {string} id Period Id
         * @param {Array<'period_athletes'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        periodsIdGet(id: string, include?: Array<'period_athletes'>, options?: any): AxiosPromise<ActivityPeriodAthlete> {
            return localVarFp.periodsIdGet(id, include, options).then((request) => request(axios, basePath));
        },
        /**
         * This API call will upsert all periods information for an activity if request has periods-update scope
         * @summary Upsert all Periods, PeriodAthletes, PeriodAthleteRotations, Period-Tags and PeriodAthlete-Tags for an activity
         * @param {PeriodBatchRequest} [periodBatchRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPeriodBatch(periodBatchRequest?: PeriodBatchRequest, options?: any): AxiosPromise<Array<ActivityPeriod>> {
            return localVarFp.putPeriodBatch(periodBatchRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PeriodsApi - object-oriented interface
 * @export
 * @class PeriodsApi
 * @extends {BaseAPI}
 */
export class PeriodsApi extends BaseAPI {
    /**
     * This API returns all the athletes under provided period id.
     * @summary Get all Athletes in a Period
     * @param {string} id Period Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeriodsApi
     */
    public getAthletesInPeriod(id: string, options?: AxiosRequestConfig) {
        return PeriodsApiFp(this.configuration).getAthletesInPeriod(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API returns the list of all periods.
     * @summary Get all Periods
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeriodsApi
     */
    public getPeriods(options?: AxiosRequestConfig) {
        return PeriodsApiFp(this.configuration).getPeriods(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Period Details
     * @param {string} id Period Id
     * @param {Array<'period_athletes'>} [include] Entities to include under each item, comma separated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeriodsApi
     */
    public periodsIdGet(id: string, include?: Array<'period_athletes'>, options?: AxiosRequestConfig) {
        return PeriodsApiFp(this.configuration).periodsIdGet(id, include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API call will upsert all periods information for an activity if request has periods-update scope
     * @summary Upsert all Periods, PeriodAthletes, PeriodAthleteRotations, Period-Tags and PeriodAthlete-Tags for an activity
     * @param {PeriodBatchRequest} [periodBatchRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeriodsApi
     */
    public putPeriodBatch(periodBatchRequest?: PeriodBatchRequest, options?: AxiosRequestConfig) {
        return PeriodsApiFp(this.configuration).putPeriodBatch(periodBatchRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get build information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get build information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ping(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2003>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ping(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @summary Get build information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options?: any): AxiosPromise<InlineResponse2003> {
            return localVarFp.ping(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @summary Get build information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public ping(options?: AxiosRequestConfig) {
        return PingApiFp(this.configuration).ping(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PositionsApi - axios parameter creator
 * @export
 */
export const PositionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This API returns all the available positions in an account.
         * @summary Get All Positions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/positions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PositionsApi - functional programming interface
 * @export
 */
export const PositionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PositionsApiAxiosParamCreator(configuration)
    return {
        /**
         * This API returns all the available positions in an account.
         * @summary Get All Positions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPositions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Position>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPositions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PositionsApi - factory interface
 * @export
 */
export const PositionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PositionsApiFp(configuration)
    return {
        /**
         * This API returns all the available positions in an account.
         * @summary Get All Positions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPositions(options?: any): AxiosPromise<Array<Position>> {
            return localVarFp.getPositions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PositionsApi - object-oriented interface
 * @export
 * @class PositionsApi
 * @extends {BaseAPI}
 */
export class PositionsApi extends BaseAPI {
    /**
     * This API returns all the available positions in an account.
     * @summary Get All Positions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionsApi
     */
    public getPositions(options?: AxiosRequestConfig) {
        return PositionsApiFp(this.configuration).getPositions(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generate CTR report for single activity
         * @param {string} id Activity Id
         * @param {CtrSingleReportParameters} [ctrSingleReportParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCtrReport: async (id: string, ctrSingleReportParameters?: CtrSingleReportParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('generateCtrReport', 'id', id)
            const localVarPath = `/report/ctr/generate/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ctrSingleReportParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate CTR reports for multiple activities
         * @param {CtrReportParameters} [ctrReportParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCtrReports: async (ctrReportParameters?: CtrReportParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/report/ctr/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ctrReportParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Generate proximity reports for one or more activities
         * @param {ProximityReportParameters} [proximityReportParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProximityReport: async (proximityReportParameters?: ProximityReportParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reporting/proximity/generate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(proximityReportParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get ctr export settings
         * @summary Get ctr export settings
         * @param {CtrActivityIds} [ctrActivityIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCtrExportSettings: async (ctrActivityIds?: CtrActivityIds, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/report/ctr/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(ctrActivityIds, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generate CTR report for single activity
         * @param {string} id Activity Id
         * @param {CtrSingleReportParameters} [ctrSingleReportParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateCtrReport(id: string, ctrSingleReportParameters?: CtrSingleReportParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CtrReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateCtrReport(id, ctrSingleReportParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate CTR reports for multiple activities
         * @param {CtrReportParameters} [ctrReportParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateCtrReports(ctrReportParameters?: CtrReportParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateCtrReports(ctrReportParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Generate proximity reports for one or more activities
         * @param {ProximityReportParameters} [proximityReportParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateProximityReport(proximityReportParameters?: ProximityReportParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProximityReportResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateProximityReport(proximityReportParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get ctr export settings
         * @summary Get ctr export settings
         * @param {CtrActivityIds} [ctrActivityIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCtrExportSettings(ctrActivityIds?: CtrActivityIds, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CtrExportSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCtrExportSettings(ctrActivityIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Generate CTR report for single activity
         * @param {string} id Activity Id
         * @param {CtrSingleReportParameters} [ctrSingleReportParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCtrReport(id: string, ctrSingleReportParameters?: CtrSingleReportParameters, options?: any): AxiosPromise<CtrReportResponse> {
            return localVarFp.generateCtrReport(id, ctrSingleReportParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate CTR reports for multiple activities
         * @param {CtrReportParameters} [ctrReportParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateCtrReports(ctrReportParameters?: CtrReportParameters, options?: any): AxiosPromise<void> {
            return localVarFp.generateCtrReports(ctrReportParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Generate proximity reports for one or more activities
         * @param {ProximityReportParameters} [proximityReportParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateProximityReport(proximityReportParameters?: ProximityReportParameters, options?: any): AxiosPromise<ProximityReportResponse> {
            return localVarFp.generateProximityReport(proximityReportParameters, options).then((request) => request(axios, basePath));
        },
        /**
         * Get ctr export settings
         * @summary Get ctr export settings
         * @param {CtrActivityIds} [ctrActivityIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCtrExportSettings(ctrActivityIds?: CtrActivityIds, options?: any): AxiosPromise<CtrExportSettings> {
            return localVarFp.getCtrExportSettings(ctrActivityIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Generate CTR report for single activity
     * @param {string} id Activity Id
     * @param {CtrSingleReportParameters} [ctrSingleReportParameters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public generateCtrReport(id: string, ctrSingleReportParameters?: CtrSingleReportParameters, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).generateCtrReport(id, ctrSingleReportParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate CTR reports for multiple activities
     * @param {CtrReportParameters} [ctrReportParameters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public generateCtrReports(ctrReportParameters?: CtrReportParameters, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).generateCtrReports(ctrReportParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Generate proximity reports for one or more activities
     * @param {ProximityReportParameters} [proximityReportParameters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public generateProximityReport(proximityReportParameters?: ProximityReportParameters, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).generateProximityReport(proximityReportParameters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get ctr export settings
     * @summary Get ctr export settings
     * @param {CtrActivityIds} [ctrActivityIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getCtrExportSettings(ctrActivityIds?: CtrActivityIds, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getCtrExportSettings(ctrActivityIds, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SecretsApi - axios parameter creator
 * @export
 */
export const SecretsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a token for a given service based on a key
         * @param {string} key Represents a unique key for the specific secret, typically a service identifier like SMT or NGS etc...
         * @param {string} [region] API region to call for SMT [east, west]
         * @param {string} [version] API version to call if specific version is desired
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken: async (key: string, region?: string, version?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getToken', 'key', key)
            const localVarPath = `/secrets/{key}/token`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get response if token exist for key
         * @param {string} key Represents a unique key for the specific secret, typically a service identifier like SMT or NGS etc...
         * @param {string} [region] API region to call for SMT [east, west]
         * @param {string} [version] API version to call if specific version is desired
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenStatus: async (key: string, region?: string, version?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('getTokenStatus', 'key', key)
            const localVarPath = `/secrets/{key}/token/status`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecretsApi - functional programming interface
 * @export
 */
export const SecretsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecretsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a token for a given service based on a key
         * @param {string} key Represents a unique key for the specific secret, typically a service identifier like SMT or NGS etc...
         * @param {string} [region] API region to call for SMT [east, west]
         * @param {string} [version] API version to call if specific version is desired
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getToken(key: string, region?: string, version?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretsToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getToken(key, region, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get response if token exist for key
         * @param {string} key Represents a unique key for the specific secret, typically a service identifier like SMT or NGS etc...
         * @param {string} [region] API region to call for SMT [east, west]
         * @param {string} [version] API version to call if specific version is desired
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTokenStatus(key: string, region?: string, version?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecretsTokenStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTokenStatus(key, region, version, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SecretsApi - factory interface
 * @export
 */
export const SecretsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecretsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a token for a given service based on a key
         * @param {string} key Represents a unique key for the specific secret, typically a service identifier like SMT or NGS etc...
         * @param {string} [region] API region to call for SMT [east, west]
         * @param {string} [version] API version to call if specific version is desired
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getToken(key: string, region?: string, version?: string, options?: any): AxiosPromise<SecretsToken> {
            return localVarFp.getToken(key, region, version, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get response if token exist for key
         * @param {string} key Represents a unique key for the specific secret, typically a service identifier like SMT or NGS etc...
         * @param {string} [region] API region to call for SMT [east, west]
         * @param {string} [version] API version to call if specific version is desired
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTokenStatus(key: string, region?: string, version?: string, options?: any): AxiosPromise<SecretsTokenStatus> {
            return localVarFp.getTokenStatus(key, region, version, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SecretsApi - object-oriented interface
 * @export
 * @class SecretsApi
 * @extends {BaseAPI}
 */
export class SecretsApi extends BaseAPI {
    /**
     * 
     * @summary Get a token for a given service based on a key
     * @param {string} key Represents a unique key for the specific secret, typically a service identifier like SMT or NGS etc...
     * @param {string} [region] API region to call for SMT [east, west]
     * @param {string} [version] API version to call if specific version is desired
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public getToken(key: string, region?: string, version?: string, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).getToken(key, region, version, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get response if token exist for key
     * @param {string} key Represents a unique key for the specific secret, typically a service identifier like SMT or NGS etc...
     * @param {string} [region] API region to call for SMT [east, west]
     * @param {string} [version] API version to call if specific version is desired
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecretsApi
     */
    public getTokenStatus(key: string, region?: string, version?: string, options?: AxiosRequestConfig) {
        return SecretsApiFp(this.configuration).getTokenStatus(key, region, version, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SensorData10HzApi - axios parameter creator
 * @export
 */
export const SensorData10HzApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This API retrieves dual stream 10hz sensor data for an athlete in the activity.
         * @summary Get Dual Stream 10Hz Sensor Data for Athlete in Activity
         * @param {string} activityId Activity Id
         * @param {string} athleteId Athlete Id
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {number} [page] Page number when paginating
         * @param {number} [pageSizeSeconds] Page size in seconds when paginating. For example, if this is set to 10, you would expect approximately 100 rows to be returned (10 records per second).
         * @param {string} [streamType] Stream Type (only \&#39;gps\&#39; and \&#39;lps\&#39; are allowed)
         * @param {string} [dataSource] Optional parameter to query \&#39;live\&#39; instead of the default post-activity sync workflow (\&#39;munged\&#39;)
         * @param {number} [nulls] Set to \&#39;1\&#39; to return null for invalid positional data instead of zero
         * @param {string} [lpsField] Set to \&#39;1\&#39; to return relative field co-ordinate x,y values correctly for LPS activities
         * @param {string} [xyMiddle] Set to \&#39;1\&#39; to use the field relative co-ordinate origin (0,0) as the field middle instead of field bottom left.
         * @param {number} [frequency] Optional parameter to downsample with value in Hz. The default (0.0) is not resampling
         * @param {string} [parameters] **Comma separated parameters. Allowed values**  * Parameter - Description  * id - SensorData database identifier  * ts - UnixTimestamp (seconds)  * cs - Centiseconds  * lat - Latitude  * long - Longitude  * o - Odometer  * v - Velocity  * a - Acceleration  * hr - HeartRate  * pl - Player Load  * pli - Instantaneous Player Load  * mp - Metabolic Power  * sl - Smooth Load  * pq - Position Quality  * ref - Number of reference GNSS satellites or RTLS anchors used in a fix  * hdop - GPS Horizontal Dilution of Precision  * rv - Raw Velocity  * alt - Altitude  * face - Facing  * xy - Field relative coordinates 
         * @param {number} [fieldCentreLatitude] Latitude of field center (optional override)
         * @param {number} [fieldCentreLongitude] Longitude of field center (optional override)
         * @param {number} [fieldRotation] Field rotation (optional override)
         * @param {number} [fieldLength] Field length (optional override)
         * @param {number} [fieldWidth] Field width (optional override)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get10HzDualStreamSensorDataForAthleteInActivity: async (activityId: string, athleteId: string, startTime?: number, endTime?: number, page?: number, pageSizeSeconds?: number, streamType?: string, dataSource?: string, nulls?: number, lpsField?: string, xyMiddle?: string, frequency?: number, parameters?: string, fieldCentreLatitude?: number, fieldCentreLongitude?: number, fieldRotation?: number, fieldLength?: number, fieldWidth?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('get10HzDualStreamSensorDataForAthleteInActivity', 'activityId', activityId)
            // verify required parameter 'athleteId' is not null or undefined
            assertParamExists('get10HzDualStreamSensorDataForAthleteInActivity', 'athleteId', athleteId)
            const localVarPath = `/activities/{activity_id}/athletes/{athlete_id}/sensor`
                .replace(`{${"activity_id"}}`, encodeURIComponent(String(activityId)))
                .replace(`{${"athlete_id"}}`, encodeURIComponent(String(athleteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSizeSeconds !== undefined) {
                localVarQueryParameter['page_size_seconds'] = pageSizeSeconds;
            }

            if (streamType !== undefined) {
                localVarQueryParameter['stream_type'] = streamType;
            }

            if (dataSource !== undefined) {
                localVarQueryParameter['data_source'] = dataSource;
            }

            if (nulls !== undefined) {
                localVarQueryParameter['nulls'] = nulls;
            }

            if (lpsField !== undefined) {
                localVarQueryParameter['lps_field'] = lpsField;
            }

            if (xyMiddle !== undefined) {
                localVarQueryParameter['xy_middle'] = xyMiddle;
            }

            if (frequency !== undefined) {
                localVarQueryParameter['frequency'] = frequency;
            }

            if (parameters !== undefined) {
                localVarQueryParameter['parameters'] = parameters;
            }

            if (fieldCentreLatitude !== undefined) {
                localVarQueryParameter['field_centre_latitude'] = fieldCentreLatitude;
            }

            if (fieldCentreLongitude !== undefined) {
                localVarQueryParameter['field_centre_longitude'] = fieldCentreLongitude;
            }

            if (fieldRotation !== undefined) {
                localVarQueryParameter['field_rotation'] = fieldRotation;
            }

            if (fieldLength !== undefined) {
                localVarQueryParameter['field_length'] = fieldLength;
            }

            if (fieldWidth !== undefined) {
                localVarQueryParameter['field_width'] = fieldWidth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API retrieves dual stream 10hz sensor data for an athlete in a period.
         * @summary Get Dual Stream 10hz Sensor Data for Athlete in Period
         * @param {string} periodId Period Id
         * @param {string} athleteId Athlete Id
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {number} [page] Page number when paginating
         * @param {number} [pageSizeSeconds] Page size in seconds when paginating. For example, if this is set to 10, you would expect approximately 100 rows to be returned (10 records per second).
         * @param {string} [streamType] Stream Type (only \&#39;gps\&#39; and \&#39;lps\&#39; are allowed)
         * @param {string} [dataSource] Optional parameter to query \&#39;live\&#39; instead of the default post-activity sync workflow (\&#39;munged\&#39;)
         * @param {number} [nulls] Set to \&#39;1\&#39; to return null for invalid positional data instead of zero
         * @param {string} [lpsField] Set to \&#39;1\&#39; to return relative field co-ordinate x,y values correctly for LPS activities
         * @param {string} [xyMiddle] Set to \&#39;1\&#39; to use the field relative co-ordinate origin (0,0) as the field middle instead of field bottom left.
         * @param {number} [frequency] Optional parameter to downsample with value in Hz. The default (0.0) is not resampling
         * @param {string} [parameters] **Comma separated parameters. Allowed values**  * Parameter - Description  * id - SensorData database identifier  * ts - UnixTimestamp (seconds)  * cs - Centiseconds  * lat - Latitude  * long - Longitude  * o - Odometer  * v - Velocity  * a - Acceleration  * hr - HeartRate  * pl - Player Load  * pli - Instantaneous Player Load  * mp - Metabolic Power  * sl - Smooth Load  * pq - Position Quality  * ref - Number of reference GNSS satellites or RTLS anchors used in a fix  * hdop - GPS Horizontal Dilution of Precision  * rv - Raw Velocity  * alt - Altitude  * face - Facing  * xy - Field relative coordinates 
         * @param {number} [fieldCentreLatitude] Latitude of field center (optional override)
         * @param {number} [fieldCentreLongitude] Longitude of field center (optional override)
         * @param {number} [fieldRotation] Field rotation (optional override)
         * @param {number} [fieldLength] Field length (optional override)
         * @param {number} [fieldWidth] Field width (optional override)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get10HzDualStreamSensorDataForAthleteInPeriod: async (periodId: string, athleteId: string, startTime?: number, endTime?: number, page?: number, pageSizeSeconds?: number, streamType?: string, dataSource?: string, nulls?: number, lpsField?: string, xyMiddle?: string, frequency?: number, parameters?: string, fieldCentreLatitude?: number, fieldCentreLongitude?: number, fieldRotation?: number, fieldLength?: number, fieldWidth?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'periodId' is not null or undefined
            assertParamExists('get10HzDualStreamSensorDataForAthleteInPeriod', 'periodId', periodId)
            // verify required parameter 'athleteId' is not null or undefined
            assertParamExists('get10HzDualStreamSensorDataForAthleteInPeriod', 'athleteId', athleteId)
            const localVarPath = `/periods/{period_id}/athletes/{athlete_id}/sensor`
                .replace(`{${"period_id"}}`, encodeURIComponent(String(periodId)))
                .replace(`{${"athlete_id"}}`, encodeURIComponent(String(athleteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSizeSeconds !== undefined) {
                localVarQueryParameter['page_size_seconds'] = pageSizeSeconds;
            }

            if (streamType !== undefined) {
                localVarQueryParameter['stream_type'] = streamType;
            }

            if (dataSource !== undefined) {
                localVarQueryParameter['data_source'] = dataSource;
            }

            if (nulls !== undefined) {
                localVarQueryParameter['nulls'] = nulls;
            }

            if (lpsField !== undefined) {
                localVarQueryParameter['lps_field'] = lpsField;
            }

            if (xyMiddle !== undefined) {
                localVarQueryParameter['xy_middle'] = xyMiddle;
            }

            if (frequency !== undefined) {
                localVarQueryParameter['frequency'] = frequency;
            }

            if (parameters !== undefined) {
                localVarQueryParameter['parameters'] = parameters;
            }

            if (fieldCentreLatitude !== undefined) {
                localVarQueryParameter['field_centre_latitude'] = fieldCentreLatitude;
            }

            if (fieldCentreLongitude !== undefined) {
                localVarQueryParameter['field_centre_longitude'] = fieldCentreLongitude;
            }

            if (fieldRotation !== undefined) {
                localVarQueryParameter['field_rotation'] = fieldRotation;
            }

            if (fieldLength !== undefined) {
                localVarQueryParameter['field_length'] = fieldLength;
            }

            if (fieldWidth !== undefined) {
                localVarQueryParameter['field_width'] = fieldWidth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API retrieves dual stream 10hz sensor data for an athlete in the activity.
         * @summary Get Dual Stream 10Hz Sensor Data for Device in Activity
         * @param {string} activityId Activity Id
         * @param {number} deviceId Device Id
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {number} [page] Page number when paginating
         * @param {number} [pageSizeSeconds] Page size in seconds when paginating. For example, if this is set to 10, you would expect approximately 100 rows to be returned (10 records per second).
         * @param {string} [streamType] Stream Type (only \&#39;gps\&#39; and \&#39;lps\&#39; are allowed)
         * @param {string} [dataSource] Optional parameter to query \&#39;live\&#39; instead of the default post-activity sync workflow (\&#39;munged\&#39;)
         * @param {number} [nulls] Set to \&#39;1\&#39; to return null for invalid positional data instead of zero
         * @param {string} [lpsField] Set to \&#39;1\&#39; to return relative field co-ordinate x,y values correctly for LPS activities
         * @param {string} [xyMiddle] Set to \&#39;1\&#39; to use the field relative co-ordinate origin (0,0) as the field middle instead of field bottom left.
         * @param {number} [frequency] Optional parameter to downsample with value in Hz. The default (0.0) is not resampling
         * @param {string} [parameters] **Comma separated parameters. Allowed values**  * Parameter - Description  * id - SensorData database identifier  * ts - UnixTimestamp (seconds)  * cs - Centiseconds  * lat - Latitude  * long - Longitude  * o - Odometer  * v - Velocity  * a - Acceleration  * hr - HeartRate  * pl - Player Load  * pli - Instantaneous Player Load  * mp - Metabolic Power  * sl - Smooth Load  * pq - Position Quality  * ref - Number of reference GNSS satellites or RTLS anchors used in a fix  * hdop - GPS Horizontal Dilution of Precision  * rv - Raw Velocity  * alt - Altitude  * face - Facing  * xy - Field relative coordinates 
         * @param {number} [fieldCentreLatitude] Latitude of field center (optional override)
         * @param {number} [fieldCentreLongitude] Longitude of field center (optional override)
         * @param {number} [fieldRotation] Field rotation (optional override)
         * @param {number} [fieldLength] Field length (optional override)
         * @param {number} [fieldWidth] Field width (optional override)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get10HzDualStreamSensorDataForDeviceInActivity: async (activityId: string, deviceId: number, startTime?: number, endTime?: number, page?: number, pageSizeSeconds?: number, streamType?: string, dataSource?: string, nulls?: number, lpsField?: string, xyMiddle?: string, frequency?: number, parameters?: string, fieldCentreLatitude?: number, fieldCentreLongitude?: number, fieldRotation?: number, fieldLength?: number, fieldWidth?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'activityId' is not null or undefined
            assertParamExists('get10HzDualStreamSensorDataForDeviceInActivity', 'activityId', activityId)
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('get10HzDualStreamSensorDataForDeviceInActivity', 'deviceId', deviceId)
            const localVarPath = `/activities/{activity_id}/devices/{device_id}/sensor`
                .replace(`{${"activity_id"}}`, encodeURIComponent(String(activityId)))
                .replace(`{${"device_id"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startTime !== undefined) {
                localVarQueryParameter['start_time'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['end_time'] = endTime;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSizeSeconds !== undefined) {
                localVarQueryParameter['page_size_seconds'] = pageSizeSeconds;
            }

            if (streamType !== undefined) {
                localVarQueryParameter['stream_type'] = streamType;
            }

            if (dataSource !== undefined) {
                localVarQueryParameter['data_source'] = dataSource;
            }

            if (nulls !== undefined) {
                localVarQueryParameter['nulls'] = nulls;
            }

            if (lpsField !== undefined) {
                localVarQueryParameter['lps_field'] = lpsField;
            }

            if (xyMiddle !== undefined) {
                localVarQueryParameter['xy_middle'] = xyMiddle;
            }

            if (frequency !== undefined) {
                localVarQueryParameter['frequency'] = frequency;
            }

            if (parameters !== undefined) {
                localVarQueryParameter['parameters'] = parameters;
            }

            if (fieldCentreLatitude !== undefined) {
                localVarQueryParameter['field_centre_latitude'] = fieldCentreLatitude;
            }

            if (fieldCentreLongitude !== undefined) {
                localVarQueryParameter['field_centre_longitude'] = fieldCentreLongitude;
            }

            if (fieldRotation !== undefined) {
                localVarQueryParameter['field_rotation'] = fieldRotation;
            }

            if (fieldLength !== undefined) {
                localVarQueryParameter['field_length'] = fieldLength;
            }

            if (fieldWidth !== undefined) {
                localVarQueryParameter['field_width'] = fieldWidth;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SensorData10HzApi - functional programming interface
 * @export
 */
export const SensorData10HzApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SensorData10HzApiAxiosParamCreator(configuration)
    return {
        /**
         * This API retrieves dual stream 10hz sensor data for an athlete in the activity.
         * @summary Get Dual Stream 10Hz Sensor Data for Athlete in Activity
         * @param {string} activityId Activity Id
         * @param {string} athleteId Athlete Id
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {number} [page] Page number when paginating
         * @param {number} [pageSizeSeconds] Page size in seconds when paginating. For example, if this is set to 10, you would expect approximately 100 rows to be returned (10 records per second).
         * @param {string} [streamType] Stream Type (only \&#39;gps\&#39; and \&#39;lps\&#39; are allowed)
         * @param {string} [dataSource] Optional parameter to query \&#39;live\&#39; instead of the default post-activity sync workflow (\&#39;munged\&#39;)
         * @param {number} [nulls] Set to \&#39;1\&#39; to return null for invalid positional data instead of zero
         * @param {string} [lpsField] Set to \&#39;1\&#39; to return relative field co-ordinate x,y values correctly for LPS activities
         * @param {string} [xyMiddle] Set to \&#39;1\&#39; to use the field relative co-ordinate origin (0,0) as the field middle instead of field bottom left.
         * @param {number} [frequency] Optional parameter to downsample with value in Hz. The default (0.0) is not resampling
         * @param {string} [parameters] **Comma separated parameters. Allowed values**  * Parameter - Description  * id - SensorData database identifier  * ts - UnixTimestamp (seconds)  * cs - Centiseconds  * lat - Latitude  * long - Longitude  * o - Odometer  * v - Velocity  * a - Acceleration  * hr - HeartRate  * pl - Player Load  * pli - Instantaneous Player Load  * mp - Metabolic Power  * sl - Smooth Load  * pq - Position Quality  * ref - Number of reference GNSS satellites or RTLS anchors used in a fix  * hdop - GPS Horizontal Dilution of Precision  * rv - Raw Velocity  * alt - Altitude  * face - Facing  * xy - Field relative coordinates 
         * @param {number} [fieldCentreLatitude] Latitude of field center (optional override)
         * @param {number} [fieldCentreLongitude] Longitude of field center (optional override)
         * @param {number} [fieldRotation] Field rotation (optional override)
         * @param {number} [fieldLength] Field length (optional override)
         * @param {number} [fieldWidth] Field width (optional override)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get10HzDualStreamSensorDataForAthleteInActivity(activityId: string, athleteId: string, startTime?: number, endTime?: number, page?: number, pageSizeSeconds?: number, streamType?: string, dataSource?: string, nulls?: number, lpsField?: string, xyMiddle?: string, frequency?: number, parameters?: string, fieldCentreLatitude?: number, fieldCentreLongitude?: number, fieldRotation?: number, fieldLength?: number, fieldWidth?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Sensor>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get10HzDualStreamSensorDataForAthleteInActivity(activityId, athleteId, startTime, endTime, page, pageSizeSeconds, streamType, dataSource, nulls, lpsField, xyMiddle, frequency, parameters, fieldCentreLatitude, fieldCentreLongitude, fieldRotation, fieldLength, fieldWidth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API retrieves dual stream 10hz sensor data for an athlete in a period.
         * @summary Get Dual Stream 10hz Sensor Data for Athlete in Period
         * @param {string} periodId Period Id
         * @param {string} athleteId Athlete Id
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {number} [page] Page number when paginating
         * @param {number} [pageSizeSeconds] Page size in seconds when paginating. For example, if this is set to 10, you would expect approximately 100 rows to be returned (10 records per second).
         * @param {string} [streamType] Stream Type (only \&#39;gps\&#39; and \&#39;lps\&#39; are allowed)
         * @param {string} [dataSource] Optional parameter to query \&#39;live\&#39; instead of the default post-activity sync workflow (\&#39;munged\&#39;)
         * @param {number} [nulls] Set to \&#39;1\&#39; to return null for invalid positional data instead of zero
         * @param {string} [lpsField] Set to \&#39;1\&#39; to return relative field co-ordinate x,y values correctly for LPS activities
         * @param {string} [xyMiddle] Set to \&#39;1\&#39; to use the field relative co-ordinate origin (0,0) as the field middle instead of field bottom left.
         * @param {number} [frequency] Optional parameter to downsample with value in Hz. The default (0.0) is not resampling
         * @param {string} [parameters] **Comma separated parameters. Allowed values**  * Parameter - Description  * id - SensorData database identifier  * ts - UnixTimestamp (seconds)  * cs - Centiseconds  * lat - Latitude  * long - Longitude  * o - Odometer  * v - Velocity  * a - Acceleration  * hr - HeartRate  * pl - Player Load  * pli - Instantaneous Player Load  * mp - Metabolic Power  * sl - Smooth Load  * pq - Position Quality  * ref - Number of reference GNSS satellites or RTLS anchors used in a fix  * hdop - GPS Horizontal Dilution of Precision  * rv - Raw Velocity  * alt - Altitude  * face - Facing  * xy - Field relative coordinates 
         * @param {number} [fieldCentreLatitude] Latitude of field center (optional override)
         * @param {number} [fieldCentreLongitude] Longitude of field center (optional override)
         * @param {number} [fieldRotation] Field rotation (optional override)
         * @param {number} [fieldLength] Field length (optional override)
         * @param {number} [fieldWidth] Field width (optional override)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get10HzDualStreamSensorDataForAthleteInPeriod(periodId: string, athleteId: string, startTime?: number, endTime?: number, page?: number, pageSizeSeconds?: number, streamType?: string, dataSource?: string, nulls?: number, lpsField?: string, xyMiddle?: string, frequency?: number, parameters?: string, fieldCentreLatitude?: number, fieldCentreLongitude?: number, fieldRotation?: number, fieldLength?: number, fieldWidth?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Sensor>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get10HzDualStreamSensorDataForAthleteInPeriod(periodId, athleteId, startTime, endTime, page, pageSizeSeconds, streamType, dataSource, nulls, lpsField, xyMiddle, frequency, parameters, fieldCentreLatitude, fieldCentreLongitude, fieldRotation, fieldLength, fieldWidth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API retrieves dual stream 10hz sensor data for an athlete in the activity.
         * @summary Get Dual Stream 10Hz Sensor Data for Device in Activity
         * @param {string} activityId Activity Id
         * @param {number} deviceId Device Id
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {number} [page] Page number when paginating
         * @param {number} [pageSizeSeconds] Page size in seconds when paginating. For example, if this is set to 10, you would expect approximately 100 rows to be returned (10 records per second).
         * @param {string} [streamType] Stream Type (only \&#39;gps\&#39; and \&#39;lps\&#39; are allowed)
         * @param {string} [dataSource] Optional parameter to query \&#39;live\&#39; instead of the default post-activity sync workflow (\&#39;munged\&#39;)
         * @param {number} [nulls] Set to \&#39;1\&#39; to return null for invalid positional data instead of zero
         * @param {string} [lpsField] Set to \&#39;1\&#39; to return relative field co-ordinate x,y values correctly for LPS activities
         * @param {string} [xyMiddle] Set to \&#39;1\&#39; to use the field relative co-ordinate origin (0,0) as the field middle instead of field bottom left.
         * @param {number} [frequency] Optional parameter to downsample with value in Hz. The default (0.0) is not resampling
         * @param {string} [parameters] **Comma separated parameters. Allowed values**  * Parameter - Description  * id - SensorData database identifier  * ts - UnixTimestamp (seconds)  * cs - Centiseconds  * lat - Latitude  * long - Longitude  * o - Odometer  * v - Velocity  * a - Acceleration  * hr - HeartRate  * pl - Player Load  * pli - Instantaneous Player Load  * mp - Metabolic Power  * sl - Smooth Load  * pq - Position Quality  * ref - Number of reference GNSS satellites or RTLS anchors used in a fix  * hdop - GPS Horizontal Dilution of Precision  * rv - Raw Velocity  * alt - Altitude  * face - Facing  * xy - Field relative coordinates 
         * @param {number} [fieldCentreLatitude] Latitude of field center (optional override)
         * @param {number} [fieldCentreLongitude] Longitude of field center (optional override)
         * @param {number} [fieldRotation] Field rotation (optional override)
         * @param {number} [fieldLength] Field length (optional override)
         * @param {number} [fieldWidth] Field width (optional override)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get10HzDualStreamSensorDataForDeviceInActivity(activityId: string, deviceId: number, startTime?: number, endTime?: number, page?: number, pageSizeSeconds?: number, streamType?: string, dataSource?: string, nulls?: number, lpsField?: string, xyMiddle?: string, frequency?: number, parameters?: string, fieldCentreLatitude?: number, fieldCentreLongitude?: number, fieldRotation?: number, fieldLength?: number, fieldWidth?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Sensor>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get10HzDualStreamSensorDataForDeviceInActivity(activityId, deviceId, startTime, endTime, page, pageSizeSeconds, streamType, dataSource, nulls, lpsField, xyMiddle, frequency, parameters, fieldCentreLatitude, fieldCentreLongitude, fieldRotation, fieldLength, fieldWidth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SensorData10HzApi - factory interface
 * @export
 */
export const SensorData10HzApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SensorData10HzApiFp(configuration)
    return {
        /**
         * This API retrieves dual stream 10hz sensor data for an athlete in the activity.
         * @summary Get Dual Stream 10Hz Sensor Data for Athlete in Activity
         * @param {string} activityId Activity Id
         * @param {string} athleteId Athlete Id
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {number} [page] Page number when paginating
         * @param {number} [pageSizeSeconds] Page size in seconds when paginating. For example, if this is set to 10, you would expect approximately 100 rows to be returned (10 records per second).
         * @param {string} [streamType] Stream Type (only \&#39;gps\&#39; and \&#39;lps\&#39; are allowed)
         * @param {string} [dataSource] Optional parameter to query \&#39;live\&#39; instead of the default post-activity sync workflow (\&#39;munged\&#39;)
         * @param {number} [nulls] Set to \&#39;1\&#39; to return null for invalid positional data instead of zero
         * @param {string} [lpsField] Set to \&#39;1\&#39; to return relative field co-ordinate x,y values correctly for LPS activities
         * @param {string} [xyMiddle] Set to \&#39;1\&#39; to use the field relative co-ordinate origin (0,0) as the field middle instead of field bottom left.
         * @param {number} [frequency] Optional parameter to downsample with value in Hz. The default (0.0) is not resampling
         * @param {string} [parameters] **Comma separated parameters. Allowed values**  * Parameter - Description  * id - SensorData database identifier  * ts - UnixTimestamp (seconds)  * cs - Centiseconds  * lat - Latitude  * long - Longitude  * o - Odometer  * v - Velocity  * a - Acceleration  * hr - HeartRate  * pl - Player Load  * pli - Instantaneous Player Load  * mp - Metabolic Power  * sl - Smooth Load  * pq - Position Quality  * ref - Number of reference GNSS satellites or RTLS anchors used in a fix  * hdop - GPS Horizontal Dilution of Precision  * rv - Raw Velocity  * alt - Altitude  * face - Facing  * xy - Field relative coordinates 
         * @param {number} [fieldCentreLatitude] Latitude of field center (optional override)
         * @param {number} [fieldCentreLongitude] Longitude of field center (optional override)
         * @param {number} [fieldRotation] Field rotation (optional override)
         * @param {number} [fieldLength] Field length (optional override)
         * @param {number} [fieldWidth] Field width (optional override)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get10HzDualStreamSensorDataForAthleteInActivity(activityId: string, athleteId: string, startTime?: number, endTime?: number, page?: number, pageSizeSeconds?: number, streamType?: string, dataSource?: string, nulls?: number, lpsField?: string, xyMiddle?: string, frequency?: number, parameters?: string, fieldCentreLatitude?: number, fieldCentreLongitude?: number, fieldRotation?: number, fieldLength?: number, fieldWidth?: number, options?: any): AxiosPromise<Array<Sensor>> {
            return localVarFp.get10HzDualStreamSensorDataForAthleteInActivity(activityId, athleteId, startTime, endTime, page, pageSizeSeconds, streamType, dataSource, nulls, lpsField, xyMiddle, frequency, parameters, fieldCentreLatitude, fieldCentreLongitude, fieldRotation, fieldLength, fieldWidth, options).then((request) => request(axios, basePath));
        },
        /**
         * This API retrieves dual stream 10hz sensor data for an athlete in a period.
         * @summary Get Dual Stream 10hz Sensor Data for Athlete in Period
         * @param {string} periodId Period Id
         * @param {string} athleteId Athlete Id
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {number} [page] Page number when paginating
         * @param {number} [pageSizeSeconds] Page size in seconds when paginating. For example, if this is set to 10, you would expect approximately 100 rows to be returned (10 records per second).
         * @param {string} [streamType] Stream Type (only \&#39;gps\&#39; and \&#39;lps\&#39; are allowed)
         * @param {string} [dataSource] Optional parameter to query \&#39;live\&#39; instead of the default post-activity sync workflow (\&#39;munged\&#39;)
         * @param {number} [nulls] Set to \&#39;1\&#39; to return null for invalid positional data instead of zero
         * @param {string} [lpsField] Set to \&#39;1\&#39; to return relative field co-ordinate x,y values correctly for LPS activities
         * @param {string} [xyMiddle] Set to \&#39;1\&#39; to use the field relative co-ordinate origin (0,0) as the field middle instead of field bottom left.
         * @param {number} [frequency] Optional parameter to downsample with value in Hz. The default (0.0) is not resampling
         * @param {string} [parameters] **Comma separated parameters. Allowed values**  * Parameter - Description  * id - SensorData database identifier  * ts - UnixTimestamp (seconds)  * cs - Centiseconds  * lat - Latitude  * long - Longitude  * o - Odometer  * v - Velocity  * a - Acceleration  * hr - HeartRate  * pl - Player Load  * pli - Instantaneous Player Load  * mp - Metabolic Power  * sl - Smooth Load  * pq - Position Quality  * ref - Number of reference GNSS satellites or RTLS anchors used in a fix  * hdop - GPS Horizontal Dilution of Precision  * rv - Raw Velocity  * alt - Altitude  * face - Facing  * xy - Field relative coordinates 
         * @param {number} [fieldCentreLatitude] Latitude of field center (optional override)
         * @param {number} [fieldCentreLongitude] Longitude of field center (optional override)
         * @param {number} [fieldRotation] Field rotation (optional override)
         * @param {number} [fieldLength] Field length (optional override)
         * @param {number} [fieldWidth] Field width (optional override)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get10HzDualStreamSensorDataForAthleteInPeriod(periodId: string, athleteId: string, startTime?: number, endTime?: number, page?: number, pageSizeSeconds?: number, streamType?: string, dataSource?: string, nulls?: number, lpsField?: string, xyMiddle?: string, frequency?: number, parameters?: string, fieldCentreLatitude?: number, fieldCentreLongitude?: number, fieldRotation?: number, fieldLength?: number, fieldWidth?: number, options?: any): AxiosPromise<Array<Sensor>> {
            return localVarFp.get10HzDualStreamSensorDataForAthleteInPeriod(periodId, athleteId, startTime, endTime, page, pageSizeSeconds, streamType, dataSource, nulls, lpsField, xyMiddle, frequency, parameters, fieldCentreLatitude, fieldCentreLongitude, fieldRotation, fieldLength, fieldWidth, options).then((request) => request(axios, basePath));
        },
        /**
         * This API retrieves dual stream 10hz sensor data for an athlete in the activity.
         * @summary Get Dual Stream 10Hz Sensor Data for Device in Activity
         * @param {string} activityId Activity Id
         * @param {number} deviceId Device Id
         * @param {number} [startTime] Start Time as UNIX timestamp in seconds
         * @param {number} [endTime] End Time as UNIX timestamp in seconds
         * @param {number} [page] Page number when paginating
         * @param {number} [pageSizeSeconds] Page size in seconds when paginating. For example, if this is set to 10, you would expect approximately 100 rows to be returned (10 records per second).
         * @param {string} [streamType] Stream Type (only \&#39;gps\&#39; and \&#39;lps\&#39; are allowed)
         * @param {string} [dataSource] Optional parameter to query \&#39;live\&#39; instead of the default post-activity sync workflow (\&#39;munged\&#39;)
         * @param {number} [nulls] Set to \&#39;1\&#39; to return null for invalid positional data instead of zero
         * @param {string} [lpsField] Set to \&#39;1\&#39; to return relative field co-ordinate x,y values correctly for LPS activities
         * @param {string} [xyMiddle] Set to \&#39;1\&#39; to use the field relative co-ordinate origin (0,0) as the field middle instead of field bottom left.
         * @param {number} [frequency] Optional parameter to downsample with value in Hz. The default (0.0) is not resampling
         * @param {string} [parameters] **Comma separated parameters. Allowed values**  * Parameter - Description  * id - SensorData database identifier  * ts - UnixTimestamp (seconds)  * cs - Centiseconds  * lat - Latitude  * long - Longitude  * o - Odometer  * v - Velocity  * a - Acceleration  * hr - HeartRate  * pl - Player Load  * pli - Instantaneous Player Load  * mp - Metabolic Power  * sl - Smooth Load  * pq - Position Quality  * ref - Number of reference GNSS satellites or RTLS anchors used in a fix  * hdop - GPS Horizontal Dilution of Precision  * rv - Raw Velocity  * alt - Altitude  * face - Facing  * xy - Field relative coordinates 
         * @param {number} [fieldCentreLatitude] Latitude of field center (optional override)
         * @param {number} [fieldCentreLongitude] Longitude of field center (optional override)
         * @param {number} [fieldRotation] Field rotation (optional override)
         * @param {number} [fieldLength] Field length (optional override)
         * @param {number} [fieldWidth] Field width (optional override)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get10HzDualStreamSensorDataForDeviceInActivity(activityId: string, deviceId: number, startTime?: number, endTime?: number, page?: number, pageSizeSeconds?: number, streamType?: string, dataSource?: string, nulls?: number, lpsField?: string, xyMiddle?: string, frequency?: number, parameters?: string, fieldCentreLatitude?: number, fieldCentreLongitude?: number, fieldRotation?: number, fieldLength?: number, fieldWidth?: number, options?: any): AxiosPromise<Array<Sensor>> {
            return localVarFp.get10HzDualStreamSensorDataForDeviceInActivity(activityId, deviceId, startTime, endTime, page, pageSizeSeconds, streamType, dataSource, nulls, lpsField, xyMiddle, frequency, parameters, fieldCentreLatitude, fieldCentreLongitude, fieldRotation, fieldLength, fieldWidth, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SensorData10HzApi - object-oriented interface
 * @export
 * @class SensorData10HzApi
 * @extends {BaseAPI}
 */
export class SensorData10HzApi extends BaseAPI {
    /**
     * This API retrieves dual stream 10hz sensor data for an athlete in the activity.
     * @summary Get Dual Stream 10Hz Sensor Data for Athlete in Activity
     * @param {string} activityId Activity Id
     * @param {string} athleteId Athlete Id
     * @param {number} [startTime] Start Time as UNIX timestamp in seconds
     * @param {number} [endTime] End Time as UNIX timestamp in seconds
     * @param {number} [page] Page number when paginating
     * @param {number} [pageSizeSeconds] Page size in seconds when paginating. For example, if this is set to 10, you would expect approximately 100 rows to be returned (10 records per second).
     * @param {string} [streamType] Stream Type (only \&#39;gps\&#39; and \&#39;lps\&#39; are allowed)
     * @param {string} [dataSource] Optional parameter to query \&#39;live\&#39; instead of the default post-activity sync workflow (\&#39;munged\&#39;)
     * @param {number} [nulls] Set to \&#39;1\&#39; to return null for invalid positional data instead of zero
     * @param {string} [lpsField] Set to \&#39;1\&#39; to return relative field co-ordinate x,y values correctly for LPS activities
     * @param {string} [xyMiddle] Set to \&#39;1\&#39; to use the field relative co-ordinate origin (0,0) as the field middle instead of field bottom left.
     * @param {number} [frequency] Optional parameter to downsample with value in Hz. The default (0.0) is not resampling
     * @param {string} [parameters] **Comma separated parameters. Allowed values**  * Parameter - Description  * id - SensorData database identifier  * ts - UnixTimestamp (seconds)  * cs - Centiseconds  * lat - Latitude  * long - Longitude  * o - Odometer  * v - Velocity  * a - Acceleration  * hr - HeartRate  * pl - Player Load  * pli - Instantaneous Player Load  * mp - Metabolic Power  * sl - Smooth Load  * pq - Position Quality  * ref - Number of reference GNSS satellites or RTLS anchors used in a fix  * hdop - GPS Horizontal Dilution of Precision  * rv - Raw Velocity  * alt - Altitude  * face - Facing  * xy - Field relative coordinates 
     * @param {number} [fieldCentreLatitude] Latitude of field center (optional override)
     * @param {number} [fieldCentreLongitude] Longitude of field center (optional override)
     * @param {number} [fieldRotation] Field rotation (optional override)
     * @param {number} [fieldLength] Field length (optional override)
     * @param {number} [fieldWidth] Field width (optional override)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorData10HzApi
     */
    public get10HzDualStreamSensorDataForAthleteInActivity(activityId: string, athleteId: string, startTime?: number, endTime?: number, page?: number, pageSizeSeconds?: number, streamType?: string, dataSource?: string, nulls?: number, lpsField?: string, xyMiddle?: string, frequency?: number, parameters?: string, fieldCentreLatitude?: number, fieldCentreLongitude?: number, fieldRotation?: number, fieldLength?: number, fieldWidth?: number, options?: AxiosRequestConfig) {
        return SensorData10HzApiFp(this.configuration).get10HzDualStreamSensorDataForAthleteInActivity(activityId, athleteId, startTime, endTime, page, pageSizeSeconds, streamType, dataSource, nulls, lpsField, xyMiddle, frequency, parameters, fieldCentreLatitude, fieldCentreLongitude, fieldRotation, fieldLength, fieldWidth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API retrieves dual stream 10hz sensor data for an athlete in a period.
     * @summary Get Dual Stream 10hz Sensor Data for Athlete in Period
     * @param {string} periodId Period Id
     * @param {string} athleteId Athlete Id
     * @param {number} [startTime] Start Time as UNIX timestamp in seconds
     * @param {number} [endTime] End Time as UNIX timestamp in seconds
     * @param {number} [page] Page number when paginating
     * @param {number} [pageSizeSeconds] Page size in seconds when paginating. For example, if this is set to 10, you would expect approximately 100 rows to be returned (10 records per second).
     * @param {string} [streamType] Stream Type (only \&#39;gps\&#39; and \&#39;lps\&#39; are allowed)
     * @param {string} [dataSource] Optional parameter to query \&#39;live\&#39; instead of the default post-activity sync workflow (\&#39;munged\&#39;)
     * @param {number} [nulls] Set to \&#39;1\&#39; to return null for invalid positional data instead of zero
     * @param {string} [lpsField] Set to \&#39;1\&#39; to return relative field co-ordinate x,y values correctly for LPS activities
     * @param {string} [xyMiddle] Set to \&#39;1\&#39; to use the field relative co-ordinate origin (0,0) as the field middle instead of field bottom left.
     * @param {number} [frequency] Optional parameter to downsample with value in Hz. The default (0.0) is not resampling
     * @param {string} [parameters] **Comma separated parameters. Allowed values**  * Parameter - Description  * id - SensorData database identifier  * ts - UnixTimestamp (seconds)  * cs - Centiseconds  * lat - Latitude  * long - Longitude  * o - Odometer  * v - Velocity  * a - Acceleration  * hr - HeartRate  * pl - Player Load  * pli - Instantaneous Player Load  * mp - Metabolic Power  * sl - Smooth Load  * pq - Position Quality  * ref - Number of reference GNSS satellites or RTLS anchors used in a fix  * hdop - GPS Horizontal Dilution of Precision  * rv - Raw Velocity  * alt - Altitude  * face - Facing  * xy - Field relative coordinates 
     * @param {number} [fieldCentreLatitude] Latitude of field center (optional override)
     * @param {number} [fieldCentreLongitude] Longitude of field center (optional override)
     * @param {number} [fieldRotation] Field rotation (optional override)
     * @param {number} [fieldLength] Field length (optional override)
     * @param {number} [fieldWidth] Field width (optional override)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorData10HzApi
     */
    public get10HzDualStreamSensorDataForAthleteInPeriod(periodId: string, athleteId: string, startTime?: number, endTime?: number, page?: number, pageSizeSeconds?: number, streamType?: string, dataSource?: string, nulls?: number, lpsField?: string, xyMiddle?: string, frequency?: number, parameters?: string, fieldCentreLatitude?: number, fieldCentreLongitude?: number, fieldRotation?: number, fieldLength?: number, fieldWidth?: number, options?: AxiosRequestConfig) {
        return SensorData10HzApiFp(this.configuration).get10HzDualStreamSensorDataForAthleteInPeriod(periodId, athleteId, startTime, endTime, page, pageSizeSeconds, streamType, dataSource, nulls, lpsField, xyMiddle, frequency, parameters, fieldCentreLatitude, fieldCentreLongitude, fieldRotation, fieldLength, fieldWidth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API retrieves dual stream 10hz sensor data for an athlete in the activity.
     * @summary Get Dual Stream 10Hz Sensor Data for Device in Activity
     * @param {string} activityId Activity Id
     * @param {number} deviceId Device Id
     * @param {number} [startTime] Start Time as UNIX timestamp in seconds
     * @param {number} [endTime] End Time as UNIX timestamp in seconds
     * @param {number} [page] Page number when paginating
     * @param {number} [pageSizeSeconds] Page size in seconds when paginating. For example, if this is set to 10, you would expect approximately 100 rows to be returned (10 records per second).
     * @param {string} [streamType] Stream Type (only \&#39;gps\&#39; and \&#39;lps\&#39; are allowed)
     * @param {string} [dataSource] Optional parameter to query \&#39;live\&#39; instead of the default post-activity sync workflow (\&#39;munged\&#39;)
     * @param {number} [nulls] Set to \&#39;1\&#39; to return null for invalid positional data instead of zero
     * @param {string} [lpsField] Set to \&#39;1\&#39; to return relative field co-ordinate x,y values correctly for LPS activities
     * @param {string} [xyMiddle] Set to \&#39;1\&#39; to use the field relative co-ordinate origin (0,0) as the field middle instead of field bottom left.
     * @param {number} [frequency] Optional parameter to downsample with value in Hz. The default (0.0) is not resampling
     * @param {string} [parameters] **Comma separated parameters. Allowed values**  * Parameter - Description  * id - SensorData database identifier  * ts - UnixTimestamp (seconds)  * cs - Centiseconds  * lat - Latitude  * long - Longitude  * o - Odometer  * v - Velocity  * a - Acceleration  * hr - HeartRate  * pl - Player Load  * pli - Instantaneous Player Load  * mp - Metabolic Power  * sl - Smooth Load  * pq - Position Quality  * ref - Number of reference GNSS satellites or RTLS anchors used in a fix  * hdop - GPS Horizontal Dilution of Precision  * rv - Raw Velocity  * alt - Altitude  * face - Facing  * xy - Field relative coordinates 
     * @param {number} [fieldCentreLatitude] Latitude of field center (optional override)
     * @param {number} [fieldCentreLongitude] Longitude of field center (optional override)
     * @param {number} [fieldRotation] Field rotation (optional override)
     * @param {number} [fieldLength] Field length (optional override)
     * @param {number} [fieldWidth] Field width (optional override)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SensorData10HzApi
     */
    public get10HzDualStreamSensorDataForDeviceInActivity(activityId: string, deviceId: number, startTime?: number, endTime?: number, page?: number, pageSizeSeconds?: number, streamType?: string, dataSource?: string, nulls?: number, lpsField?: string, xyMiddle?: string, frequency?: number, parameters?: string, fieldCentreLatitude?: number, fieldCentreLongitude?: number, fieldRotation?: number, fieldLength?: number, fieldWidth?: number, options?: AxiosRequestConfig) {
        return SensorData10HzApiFp(this.configuration).get10HzDualStreamSensorDataForDeviceInActivity(activityId, deviceId, startTime, endTime, page, pageSizeSeconds, streamType, dataSource, nulls, lpsField, xyMiddle, frequency, parameters, fieldCentreLatitude, fieldCentreLongitude, fieldRotation, fieldLength, fieldWidth, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get User Settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create default athlete profile settings
         * @param {AthleteProfileSettings} [athleteProfileSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDefaultAthleteProfileSettings: async (athleteProfileSettings?: AthleteProfileSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/settings/default`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(athleteProfileSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a user setting
         * @param {string} key Setting key
         * @param {SettingUpdateRequest} [settingUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSetting: async (key: string, settingUpdateRequest?: SettingUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('updateSetting', 'key', key)
            const localVarPath = `/settings/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(settingUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get User Settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Setting>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllSettings(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create default athlete profile settings
         * @param {AthleteProfileSettings} [athleteProfileSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDefaultAthleteProfileSettings(athleteProfileSettings?: AthleteProfileSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDefaultAthleteProfileSettings(athleteProfileSettings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a user setting
         * @param {string} key Setting key
         * @param {SettingUpdateRequest} [settingUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSetting(key: string, settingUpdateRequest?: SettingUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSetting(key, settingUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get User Settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSettings(options?: any): AxiosPromise<Array<Setting>> {
            return localVarFp.getAllSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create default athlete profile settings
         * @param {AthleteProfileSettings} [athleteProfileSettings] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDefaultAthleteProfileSettings(athleteProfileSettings?: AthleteProfileSettings, options?: any): AxiosPromise<void> {
            return localVarFp.postDefaultAthleteProfileSettings(athleteProfileSettings, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a user setting
         * @param {string} key Setting key
         * @param {SettingUpdateRequest} [settingUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSetting(key: string, settingUpdateRequest?: SettingUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateSetting(key, settingUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * 
     * @summary Get User Settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getAllSettings(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getAllSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create default athlete profile settings
     * @param {AthleteProfileSettings} [athleteProfileSettings] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public postDefaultAthleteProfileSettings(athleteProfileSettings?: AthleteProfileSettings, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).postDefaultAthleteProfileSettings(athleteProfileSettings, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a user setting
     * @param {string} key Setting key
     * @param {SettingUpdateRequest} [settingUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public updateSetting(key: string, settingUpdateRequest?: SettingUpdateRequest, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).updateSetting(key, settingUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShareApi - axios parameter creator
 * @export
 */
export const ShareApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get personal tokens for the current user
         * @param {string} path The destination path (URL-encoded if necessary)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShareableToken: async (path: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('getShareableToken', 'path', path)
            const localVarPath = `/share`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShareApi - functional programming interface
 * @export
 */
export const ShareApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShareApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get personal tokens for the current user
         * @param {string} path The destination path (URL-encoded if necessary)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShareableToken(path: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShareToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShareableToken(path, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShareApi - factory interface
 * @export
 */
export const ShareApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShareApiFp(configuration)
    return {
        /**
         * 
         * @summary Get personal tokens for the current user
         * @param {string} path The destination path (URL-encoded if necessary)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShareableToken(path: string, options?: any): AxiosPromise<ShareToken> {
            return localVarFp.getShareableToken(path, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShareApi - object-oriented interface
 * @export
 * @class ShareApi
 * @extends {BaseAPI}
 */
export class ShareApi extends BaseAPI {
    /**
     * 
     * @summary Get personal tokens for the current user
     * @param {string} path The destination path (URL-encoded if necessary)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShareApi
     */
    public getShareableToken(path: string, options?: AxiosRequestConfig) {
        return ShareApiFp(this.configuration).getShareableToken(path, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StatsApi - axios parameter creator
 * @export
 */
export const StatsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Query Stats Data
         * @param {StatsRequest} [statsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStats: async (statsRequest?: StatsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stats`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(statsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StatsApi - functional programming interface
 * @export
 */
export const StatsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StatsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Query Stats Data
         * @param {StatsRequest} [statsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postStats(statsRequest?: StatsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StatRow>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postStats(statsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StatsApi - factory interface
 * @export
 */
export const StatsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatsApiFp(configuration)
    return {
        /**
         * 
         * @summary Query Stats Data
         * @param {StatsRequest} [statsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postStats(statsRequest?: StatsRequest, options?: any): AxiosPromise<Array<StatRow>> {
            return localVarFp.postStats(statsRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StatsApi - object-oriented interface
 * @export
 * @class StatsApi
 * @extends {BaseAPI}
 */
export class StatsApi extends BaseAPI {
    /**
     * 
     * @summary Query Stats Data
     * @param {StatsRequest} [statsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatsApi
     */
    public postStats(statsRequest?: StatsRequest, options?: AxiosRequestConfig) {
        return StatsApiFp(this.configuration).postStats(statsRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SyncApi - axios parameter creator
 * @export
 */
export const SyncApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Request sequence: 1. GET `.../sync/incremental?from=<last-sync-time>` - Returns records in pre-defined table order until nearing memory limit. - If more records are left, returns a `next` object containing `from`, `to`, `table`, `table_from`, `table_from_id` values to be passed in the next request. 2. GET `.../sync/incremental?from=<next.from>&to=<next.to>&table=<next.table>&table_from=<next.table_from>&table_from_id=<next.table_from_id>` 3. Repeat step 2 until response structure has no `next` object.
         * @summary Return new data since the given sync time in a pre-defined table order
         * @param {number} version Expected database version.
         * @param {number} from Unix timestamp. Only return records updated at or after this time.
         * @param {number} [to] Unix timestamp. Only return records updated before this time. If not supplied, defaults to current time which must be passed in subsequent requests for     this sync. Required if &#x60;table&#x60; is present.
         * @param {string} [table] Start at this table in the ordered list of tables. Required if &#x60;table_from&#x60; is present.
         * @param {number} [tableFrom] Unix timestamp, start point for &#x60;table&#x60;. If only &#x60;table&#x60; is supplied, returns records updated **at or after** this time in the given table, or if     &#x60;table&#x60; and &#x60;table_from_id&#x60; are supplied, returns records updated **at** this time with an ID alphabetically greater than &#x60;table_from_id&#x60;, and records updated after this time.     Required if &#x60;table&#x60; is present.
         * @param {number} [tableFromId] String, ID of last record received. See &#x60;table_from&#x60; for details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSyncIncremental: async (version: number, from: number, to?: number, table?: string, tableFrom?: number, tableFromId?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'version' is not null or undefined
            assertParamExists('getSyncIncremental', 'version', version)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('getSyncIncremental', 'from', from)
            const localVarPath = `/sync/incremental`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (table !== undefined) {
                localVarQueryParameter['table'] = table;
            }

            if (tableFrom !== undefined) {
                localVarQueryParameter['table_from'] = tableFrom;
            }

            if (tableFromId !== undefined) {
                localVarQueryParameter['table_from_id'] = tableFromId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SyncApi - functional programming interface
 * @export
 */
export const SyncApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SyncApiAxiosParamCreator(configuration)
    return {
        /**
         * Request sequence: 1. GET `.../sync/incremental?from=<last-sync-time>` - Returns records in pre-defined table order until nearing memory limit. - If more records are left, returns a `next` object containing `from`, `to`, `table`, `table_from`, `table_from_id` values to be passed in the next request. 2. GET `.../sync/incremental?from=<next.from>&to=<next.to>&table=<next.table>&table_from=<next.table_from>&table_from_id=<next.table_from_id>` 3. Repeat step 2 until response structure has no `next` object.
         * @summary Return new data since the given sync time in a pre-defined table order
         * @param {number} version Expected database version.
         * @param {number} from Unix timestamp. Only return records updated at or after this time.
         * @param {number} [to] Unix timestamp. Only return records updated before this time. If not supplied, defaults to current time which must be passed in subsequent requests for     this sync. Required if &#x60;table&#x60; is present.
         * @param {string} [table] Start at this table in the ordered list of tables. Required if &#x60;table_from&#x60; is present.
         * @param {number} [tableFrom] Unix timestamp, start point for &#x60;table&#x60;. If only &#x60;table&#x60; is supplied, returns records updated **at or after** this time in the given table, or if     &#x60;table&#x60; and &#x60;table_from_id&#x60; are supplied, returns records updated **at** this time with an ID alphabetically greater than &#x60;table_from_id&#x60;, and records updated after this time.     Required if &#x60;table&#x60; is present.
         * @param {number} [tableFromId] String, ID of last record received. See &#x60;table_from&#x60; for details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSyncIncremental(version: number, from: number, to?: number, table?: string, tableFrom?: number, tableFromId?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SyncIncremental>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSyncIncremental(version, from, to, table, tableFrom, tableFromId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SyncApi - factory interface
 * @export
 */
export const SyncApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SyncApiFp(configuration)
    return {
        /**
         * Request sequence: 1. GET `.../sync/incremental?from=<last-sync-time>` - Returns records in pre-defined table order until nearing memory limit. - If more records are left, returns a `next` object containing `from`, `to`, `table`, `table_from`, `table_from_id` values to be passed in the next request. 2. GET `.../sync/incremental?from=<next.from>&to=<next.to>&table=<next.table>&table_from=<next.table_from>&table_from_id=<next.table_from_id>` 3. Repeat step 2 until response structure has no `next` object.
         * @summary Return new data since the given sync time in a pre-defined table order
         * @param {number} version Expected database version.
         * @param {number} from Unix timestamp. Only return records updated at or after this time.
         * @param {number} [to] Unix timestamp. Only return records updated before this time. If not supplied, defaults to current time which must be passed in subsequent requests for     this sync. Required if &#x60;table&#x60; is present.
         * @param {string} [table] Start at this table in the ordered list of tables. Required if &#x60;table_from&#x60; is present.
         * @param {number} [tableFrom] Unix timestamp, start point for &#x60;table&#x60;. If only &#x60;table&#x60; is supplied, returns records updated **at or after** this time in the given table, or if     &#x60;table&#x60; and &#x60;table_from_id&#x60; are supplied, returns records updated **at** this time with an ID alphabetically greater than &#x60;table_from_id&#x60;, and records updated after this time.     Required if &#x60;table&#x60; is present.
         * @param {number} [tableFromId] String, ID of last record received. See &#x60;table_from&#x60; for details.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSyncIncremental(version: number, from: number, to?: number, table?: string, tableFrom?: number, tableFromId?: number, options?: any): AxiosPromise<SyncIncremental> {
            return localVarFp.getSyncIncremental(version, from, to, table, tableFrom, tableFromId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SyncApi - object-oriented interface
 * @export
 * @class SyncApi
 * @extends {BaseAPI}
 */
export class SyncApi extends BaseAPI {
    /**
     * Request sequence: 1. GET `.../sync/incremental?from=<last-sync-time>` - Returns records in pre-defined table order until nearing memory limit. - If more records are left, returns a `next` object containing `from`, `to`, `table`, `table_from`, `table_from_id` values to be passed in the next request. 2. GET `.../sync/incremental?from=<next.from>&to=<next.to>&table=<next.table>&table_from=<next.table_from>&table_from_id=<next.table_from_id>` 3. Repeat step 2 until response structure has no `next` object.
     * @summary Return new data since the given sync time in a pre-defined table order
     * @param {number} version Expected database version.
     * @param {number} from Unix timestamp. Only return records updated at or after this time.
     * @param {number} [to] Unix timestamp. Only return records updated before this time. If not supplied, defaults to current time which must be passed in subsequent requests for     this sync. Required if &#x60;table&#x60; is present.
     * @param {string} [table] Start at this table in the ordered list of tables. Required if &#x60;table_from&#x60; is present.
     * @param {number} [tableFrom] Unix timestamp, start point for &#x60;table&#x60;. If only &#x60;table&#x60; is supplied, returns records updated **at or after** this time in the given table, or if     &#x60;table&#x60; and &#x60;table_from_id&#x60; are supplied, returns records updated **at** this time with an ID alphabetically greater than &#x60;table_from_id&#x60;, and records updated after this time.     Required if &#x60;table&#x60; is present.
     * @param {number} [tableFromId] String, ID of last record received. See &#x60;table_from&#x60; for details.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public getSyncIncremental(version: number, from: number, to?: number, table?: string, tableFrom?: number, tableFromId?: number, options?: AxiosRequestConfig) {
        return SyncApiFp(this.configuration).getSyncIncremental(version, from, to, table, tableFrom, tableFromId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TagTypesApi - axios parameter creator
 * @export
 */
export const TagTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This API retrieves the list of all tag types.
         * @summary Get all Tag Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTagTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tagtype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns TagType details of the provided id.
         * @summary Get TagType Details
         * @param {string} id TagType Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagTypeDetails: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTagTypeDetails', 'id', id)
            const localVarPath = `/tagtype/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an Tag Type
         * @param {TagTypeCreateRequest} [tagTypeCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTagType: async (tagTypeCreateRequest?: TagTypeCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tagtype`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagTypeCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API will update and return tag type details.
         * @summary Update a Tag Type
         * @param {string} id TagType Id
         * @param {TagTypeUpdateRequest} [tagTypeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTagType: async (id: string, tagTypeUpdateRequest?: TagTypeUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putTagType', 'id', id)
            const localVarPath = `/tagtype/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagTypeUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagTypesApi - functional programming interface
 * @export
 */
export const TagTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * This API retrieves the list of all tag types.
         * @summary Get all Tag Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTagTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TagType>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTagTypes(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns TagType details of the provided id.
         * @summary Get TagType Details
         * @param {string} id TagType Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTagTypeDetails(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTagTypeDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an Tag Type
         * @param {TagTypeCreateRequest} [tagTypeCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTagType(tagTypeCreateRequest?: TagTypeCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTagType(tagTypeCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API will update and return tag type details.
         * @summary Update a Tag Type
         * @param {string} id TagType Id
         * @param {TagTypeUpdateRequest} [tagTypeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTagType(id: string, tagTypeUpdateRequest?: TagTypeUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TagType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTagType(id, tagTypeUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagTypesApi - factory interface
 * @export
 */
export const TagTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagTypesApiFp(configuration)
    return {
        /**
         * This API retrieves the list of all tag types.
         * @summary Get all Tag Types
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTagTypes(options?: any): AxiosPromise<Array<TagType>> {
            return localVarFp.getAllTagTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns TagType details of the provided id.
         * @summary Get TagType Details
         * @param {string} id TagType Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagTypeDetails(id: string, options?: any): AxiosPromise<TagType> {
            return localVarFp.getTagTypeDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an Tag Type
         * @param {TagTypeCreateRequest} [tagTypeCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTagType(tagTypeCreateRequest?: TagTypeCreateRequest, options?: any): AxiosPromise<TagType> {
            return localVarFp.postTagType(tagTypeCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This API will update and return tag type details.
         * @summary Update a Tag Type
         * @param {string} id TagType Id
         * @param {TagTypeUpdateRequest} [tagTypeUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTagType(id: string, tagTypeUpdateRequest?: TagTypeUpdateRequest, options?: any): AxiosPromise<TagType> {
            return localVarFp.putTagType(id, tagTypeUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagTypesApi - object-oriented interface
 * @export
 * @class TagTypesApi
 * @extends {BaseAPI}
 */
export class TagTypesApi extends BaseAPI {
    /**
     * This API retrieves the list of all tag types.
     * @summary Get all Tag Types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagTypesApi
     */
    public getAllTagTypes(options?: AxiosRequestConfig) {
        return TagTypesApiFp(this.configuration).getAllTagTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns TagType details of the provided id.
     * @summary Get TagType Details
     * @param {string} id TagType Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagTypesApi
     */
    public getTagTypeDetails(id: string, options?: AxiosRequestConfig) {
        return TagTypesApiFp(this.configuration).getTagTypeDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an Tag Type
     * @param {TagTypeCreateRequest} [tagTypeCreateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagTypesApi
     */
    public postTagType(tagTypeCreateRequest?: TagTypeCreateRequest, options?: AxiosRequestConfig) {
        return TagTypesApiFp(this.configuration).postTagType(tagTypeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API will update and return tag type details.
     * @summary Update a Tag Type
     * @param {string} id TagType Id
     * @param {TagTypeUpdateRequest} [tagTypeUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagTypesApi
     */
    public putTagType(id: string, tagTypeUpdateRequest?: TagTypeUpdateRequest, options?: AxiosRequestConfig) {
        return TagTypesApiFp(this.configuration).putTagType(id, tagTypeUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TagsApi - axios parameter creator
 * @export
 */
export const TagsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the list of all tags.
         * @summary Get all Tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTags: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of all Tags of the given Tag type.
         * @summary Get All Tags Of TagType
         * @param {string} tagType Tag Type name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTagsOfTagType: async (tagType: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagType' is not null or undefined
            assertParamExists('getAllTagsOfTagType', 'tagType', tagType)
            const localVarPath = `/tags/{tag_type}`
                .replace(`{${"tag_type"}}`, encodeURIComponent(String(tagType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create an Tag
         * @param {TagCreationParameters} [tagCreationParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTag: async (tagCreationParameters?: TagCreationParameters, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tagCreationParameters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TagsApi - functional programming interface
 * @export
 */
export const TagsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TagsApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the list of all tags.
         * @summary Get all Tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTags(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTags(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the list of all Tags of the given Tag type.
         * @summary Get All Tags Of TagType
         * @param {string} tagType Tag Type name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTagsOfTagType(tagType: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTagsOfTagType(tagType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create an Tag
         * @param {TagCreationParameters} [tagCreationParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postTag(tagCreationParameters?: TagCreationParameters, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tags>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postTag(tagCreationParameters, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TagsApi - factory interface
 * @export
 */
export const TagsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TagsApiFp(configuration)
    return {
        /**
         * Retrieves the list of all tags.
         * @summary Get all Tags
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTags(options?: any): AxiosPromise<Array<Tag>> {
            return localVarFp.getAllTags(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of all Tags of the given Tag type.
         * @summary Get All Tags Of TagType
         * @param {string} tagType Tag Type name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTagsOfTagType(tagType: string, options?: any): AxiosPromise<Array<Tag>> {
            return localVarFp.getAllTagsOfTagType(tagType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create an Tag
         * @param {TagCreationParameters} [tagCreationParameters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postTag(tagCreationParameters?: TagCreationParameters, options?: any): AxiosPromise<Tags> {
            return localVarFp.postTag(tagCreationParameters, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TagsApi - object-oriented interface
 * @export
 * @class TagsApi
 * @extends {BaseAPI}
 */
export class TagsApi extends BaseAPI {
    /**
     * Retrieves the list of all tags.
     * @summary Get all Tags
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public getAllTags(options?: AxiosRequestConfig) {
        return TagsApiFp(this.configuration).getAllTags(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of all Tags of the given Tag type.
     * @summary Get All Tags Of TagType
     * @param {string} tagType Tag Type name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public getAllTagsOfTagType(tagType: string, options?: AxiosRequestConfig) {
        return TagsApiFp(this.configuration).getAllTagsOfTagType(tagType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create an Tag
     * @param {TagCreationParameters} [tagCreationParameters] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TagsApi
     */
    public postTag(tagCreationParameters?: TagCreationParameters, options?: AxiosRequestConfig) {
        return TagsApiFp(this.configuration).postTag(tagCreationParameters, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TeamsApi - axios parameter creator
 * @export
 */
export const TeamsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This API retrieves the list of teams in an account.
         * @summary Get list of Teams
         * @param {Array<'settings'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTeams: async (include?: Array<'settings'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/teams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API retrieves the list of athletes for a provided team id.
         * @summary Get all Athletes For a Team
         * @param {string} id Team Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthletesForTeam: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAthletesForTeam', 'id', id)
            const localVarPath = `/teams/{id}/athletes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves team details for the provided team id.
         * @summary Get Team Details
         * @param {string} id Team Id
         * @param {Array<'settings'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamDetails: async (id: string, include?: Array<'settings'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTeamDetails', 'id', id)
            const localVarPath = `/teams/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Move athletes to team
         * @summary Move athletes to team
         * @param {string} id Team Id
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveTeamAthletes: async (id: string, inlineObject?: InlineObject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('moveTeamAthletes', 'id', id)
            const localVarPath = `/teams/{id}/athletes`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TeamsApi - functional programming interface
 * @export
 */
export const TeamsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TeamsApiAxiosParamCreator(configuration)
    return {
        /**
         * This API retrieves the list of teams in an account.
         * @summary Get list of Teams
         * @param {Array<'settings'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllTeams(include?: Array<'settings'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Team>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllTeams(include, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API retrieves the list of athletes for a provided team id.
         * @summary Get all Athletes For a Team
         * @param {string} id Team Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAthletesForTeam(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityAthlete>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAthletesForTeam(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves team details for the provided team id.
         * @summary Get Team Details
         * @param {string} id Team Id
         * @param {Array<'settings'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTeamDetails(id: string, include?: Array<'settings'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Team>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTeamDetails(id, include, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Move athletes to team
         * @summary Move athletes to team
         * @param {string} id Team Id
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async moveTeamAthletes(id: string, inlineObject?: InlineObject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityAthlete>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.moveTeamAthletes(id, inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TeamsApi - factory interface
 * @export
 */
export const TeamsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TeamsApiFp(configuration)
    return {
        /**
         * This API retrieves the list of teams in an account.
         * @summary Get list of Teams
         * @param {Array<'settings'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllTeams(include?: Array<'settings'>, options?: any): AxiosPromise<Array<Team>> {
            return localVarFp.getAllTeams(include, options).then((request) => request(axios, basePath));
        },
        /**
         * This API retrieves the list of athletes for a provided team id.
         * @summary Get all Athletes For a Team
         * @param {string} id Team Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAthletesForTeam(id: string, options?: any): AxiosPromise<Array<ActivityAthlete>> {
            return localVarFp.getAthletesForTeam(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves team details for the provided team id.
         * @summary Get Team Details
         * @param {string} id Team Id
         * @param {Array<'settings'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTeamDetails(id: string, include?: Array<'settings'>, options?: any): AxiosPromise<Team> {
            return localVarFp.getTeamDetails(id, include, options).then((request) => request(axios, basePath));
        },
        /**
         * Move athletes to team
         * @summary Move athletes to team
         * @param {string} id Team Id
         * @param {InlineObject} [inlineObject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveTeamAthletes(id: string, inlineObject?: InlineObject, options?: any): AxiosPromise<Array<ActivityAthlete>> {
            return localVarFp.moveTeamAthletes(id, inlineObject, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TeamsApi - object-oriented interface
 * @export
 * @class TeamsApi
 * @extends {BaseAPI}
 */
export class TeamsApi extends BaseAPI {
    /**
     * This API retrieves the list of teams in an account.
     * @summary Get list of Teams
     * @param {Array<'settings'>} [include] Entities to include under each item, comma separated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public getAllTeams(include?: Array<'settings'>, options?: AxiosRequestConfig) {
        return TeamsApiFp(this.configuration).getAllTeams(include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API retrieves the list of athletes for a provided team id.
     * @summary Get all Athletes For a Team
     * @param {string} id Team Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public getAthletesForTeam(id: string, options?: AxiosRequestConfig) {
        return TeamsApiFp(this.configuration).getAthletesForTeam(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves team details for the provided team id.
     * @summary Get Team Details
     * @param {string} id Team Id
     * @param {Array<'settings'>} [include] Entities to include under each item, comma separated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public getTeamDetails(id: string, include?: Array<'settings'>, options?: AxiosRequestConfig) {
        return TeamsApiFp(this.configuration).getTeamDetails(id, include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Move athletes to team
     * @summary Move athletes to team
     * @param {string} id Team Id
     * @param {InlineObject} [inlineObject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TeamsApi
     */
    public moveTeamAthletes(id: string, inlineObject?: InlineObject, options?: AxiosRequestConfig) {
        return TeamsApiFp(this.configuration).moveTeamAthletes(id, inlineObject, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TranslationsApi - axios parameter creator
 * @export
 */
export const TranslationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get translations in the specified language. Defaults to authenticated user\'s current language.
         * @summary Get translations
         * @param {SupportedLanguage} [lng] Language.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslations: async (lng?: SupportedLanguage, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/translations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lng !== undefined) {
                localVarQueryParameter['lng'] = lng;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TranslationsApi - functional programming interface
 * @export
 */
export const TranslationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TranslationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get translations in the specified language. Defaults to authenticated user\'s current language.
         * @summary Get translations
         * @param {SupportedLanguage} [lng] Language.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTranslations(lng?: SupportedLanguage, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: string; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTranslations(lng, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TranslationsApi - factory interface
 * @export
 */
export const TranslationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TranslationsApiFp(configuration)
    return {
        /**
         * Get translations in the specified language. Defaults to authenticated user\'s current language.
         * @summary Get translations
         * @param {SupportedLanguage} [lng] Language.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTranslations(lng?: SupportedLanguage, options?: any): AxiosPromise<{ [key: string]: string; }> {
            return localVarFp.getTranslations(lng, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TranslationsApi - object-oriented interface
 * @export
 * @class TranslationsApi
 * @extends {BaseAPI}
 */
export class TranslationsApi extends BaseAPI {
    /**
     * Get translations in the specified language. Defaults to authenticated user\'s current language.
     * @summary Get translations
     * @param {SupportedLanguage} [lng] Language.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TranslationsApi
     */
    public getTranslations(lng?: SupportedLanguage, options?: AxiosRequestConfig) {
        return TranslationsApiFp(this.configuration).getTranslations(lng, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get current user
         * @summary Get current user
         * @param {Array<'permissions'>} [include] Entities to include, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: async (include?: Array<'permissions'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update current user
         * @summary Update current user
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (updateUserRequest?: UpdateUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update default and additional teams
         * @summary Update user preferences
         * @param {UpdateUserPreferencesRequest} [updateUserPreferencesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreferences: async (updateUserPreferencesRequest?: UpdateUserPreferencesRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/me/preferences`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserPreferencesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Get current user
         * @summary Get current user
         * @param {Array<'permissions'>} [include] Entities to include, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMe(include?: Array<'permissions'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMe(include, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update current user
         * @summary Update current user
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(updateUserRequest?: UpdateUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(updateUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update default and additional teams
         * @summary Update user preferences
         * @param {UpdateUserPreferencesRequest} [updateUserPreferencesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePreferences(updateUserPreferencesRequest?: UpdateUserPreferencesRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePreferences(updateUserPreferencesRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Get current user
         * @summary Get current user
         * @param {Array<'permissions'>} [include] Entities to include, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(include?: Array<'permissions'>, options?: any): AxiosPromise<User> {
            return localVarFp.getMe(include, options).then((request) => request(axios, basePath));
        },
        /**
         * Update current user
         * @summary Update current user
         * @param {UpdateUserRequest} [updateUserRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(updateUserRequest?: UpdateUserRequest, options?: any): AxiosPromise<User> {
            return localVarFp.update(updateUserRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update default and additional teams
         * @summary Update user preferences
         * @param {UpdateUserPreferencesRequest} [updateUserPreferencesRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePreferences(updateUserPreferencesRequest?: UpdateUserPreferencesRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updatePreferences(updateUserPreferencesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Get current user
     * @summary Get current user
     * @param {Array<'permissions'>} [include] Entities to include, comma separated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getMe(include?: Array<'permissions'>, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getMe(include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update current user
     * @summary Update current user
     * @param {UpdateUserRequest} [updateUserRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public update(updateUserRequest?: UpdateUserRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).update(updateUserRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update default and additional teams
     * @summary Update user preferences
     * @param {UpdateUserPreferencesRequest} [updateUserPreferencesRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updatePreferences(updateUserPreferencesRequest?: UpdateUserPreferencesRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updatePreferences(updateUserPreferencesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VenuesApi - axios parameter creator
 * @export
 */
export const VenuesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a venue
         * @param {VenueCreateRequest} venueCreateRequest Venue object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVenue: async (venueCreateRequest: VenueCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueCreateRequest' is not null or undefined
            assertParamExists('createVenue', 'venueCreateRequest', venueCreateRequest)
            const localVarPath = `/venues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(venueCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List venues
         * @param {Array<'venue_fields' | 'tags'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVenues: async (include?: Array<'venue_fields' | 'tags'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/venues`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get one venue
         * @param {string} id Venue ID
         * @param {Array<'venue_fields' | 'tags'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenueDetails: async (id: string, include?: Array<'venue_fields' | 'tags'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVenueDetails', 'id', id)
            const localVarPath = `/venues/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (include) {
                localVarQueryParameter['include'] = include.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a venue field
         * @param {string} venueId Venue ID
         * @param {string} venueFieldId Venue field ID
         * @param {VenueFieldUpdateRequest} venueFieldUpdateRequest Venue field object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVenueField: async (venueId: string, venueFieldId: string, venueFieldUpdateRequest: VenueFieldUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('updateVenueField', 'venueId', venueId)
            // verify required parameter 'venueFieldId' is not null or undefined
            assertParamExists('updateVenueField', 'venueFieldId', venueFieldId)
            // verify required parameter 'venueFieldUpdateRequest' is not null or undefined
            assertParamExists('updateVenueField', 'venueFieldUpdateRequest', venueFieldUpdateRequest)
            const localVarPath = `/venues/{venue_id}/venue_fields/{venue_field_id}`
                .replace(`{${"venue_id"}}`, encodeURIComponent(String(venueId)))
                .replace(`{${"venue_field_id"}}`, encodeURIComponent(String(venueFieldId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(venueFieldUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VenuesApi - functional programming interface
 * @export
 */
export const VenuesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VenuesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a venue
         * @param {VenueCreateRequest} venueCreateRequest Venue object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVenue(venueCreateRequest: VenueCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Venue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVenue(venueCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List venues
         * @param {Array<'venue_fields' | 'tags'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllVenues(include?: Array<'venue_fields' | 'tags'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Venue>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllVenues(include, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get one venue
         * @param {string} id Venue ID
         * @param {Array<'venue_fields' | 'tags'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVenueDetails(id: string, include?: Array<'venue_fields' | 'tags'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Venue>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVenueDetails(id, include, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a venue field
         * @param {string} venueId Venue ID
         * @param {string} venueFieldId Venue field ID
         * @param {VenueFieldUpdateRequest} venueFieldUpdateRequest Venue field object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVenueField(venueId: string, venueFieldId: string, venueFieldUpdateRequest: VenueFieldUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VenueField>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVenueField(venueId, venueFieldId, venueFieldUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VenuesApi - factory interface
 * @export
 */
export const VenuesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VenuesApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a venue
         * @param {VenueCreateRequest} venueCreateRequest Venue object to be created
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVenue(venueCreateRequest: VenueCreateRequest, options?: any): AxiosPromise<Venue> {
            return localVarFp.createVenue(venueCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List venues
         * @param {Array<'venue_fields' | 'tags'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllVenues(include?: Array<'venue_fields' | 'tags'>, options?: any): AxiosPromise<Array<Venue>> {
            return localVarFp.getAllVenues(include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get one venue
         * @param {string} id Venue ID
         * @param {Array<'venue_fields' | 'tags'>} [include] Entities to include under each item, comma separated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVenueDetails(id: string, include?: Array<'venue_fields' | 'tags'>, options?: any): AxiosPromise<Venue> {
            return localVarFp.getVenueDetails(id, include, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a venue field
         * @param {string} venueId Venue ID
         * @param {string} venueFieldId Venue field ID
         * @param {VenueFieldUpdateRequest} venueFieldUpdateRequest Venue field object to be updated
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVenueField(venueId: string, venueFieldId: string, venueFieldUpdateRequest: VenueFieldUpdateRequest, options?: any): AxiosPromise<VenueField> {
            return localVarFp.updateVenueField(venueId, venueFieldId, venueFieldUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VenuesApi - object-oriented interface
 * @export
 * @class VenuesApi
 * @extends {BaseAPI}
 */
export class VenuesApi extends BaseAPI {
    /**
     * 
     * @summary Create a venue
     * @param {VenueCreateRequest} venueCreateRequest Venue object to be created
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenuesApi
     */
    public createVenue(venueCreateRequest: VenueCreateRequest, options?: AxiosRequestConfig) {
        return VenuesApiFp(this.configuration).createVenue(venueCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List venues
     * @param {Array<'venue_fields' | 'tags'>} [include] Entities to include under each item, comma separated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenuesApi
     */
    public getAllVenues(include?: Array<'venue_fields' | 'tags'>, options?: AxiosRequestConfig) {
        return VenuesApiFp(this.configuration).getAllVenues(include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get one venue
     * @param {string} id Venue ID
     * @param {Array<'venue_fields' | 'tags'>} [include] Entities to include under each item, comma separated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenuesApi
     */
    public getVenueDetails(id: string, include?: Array<'venue_fields' | 'tags'>, options?: AxiosRequestConfig) {
        return VenuesApiFp(this.configuration).getVenueDetails(id, include, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a venue field
     * @param {string} venueId Venue ID
     * @param {string} venueFieldId Venue field ID
     * @param {VenueFieldUpdateRequest} venueFieldUpdateRequest Venue field object to be updated
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenuesApi
     */
    public updateVenueField(venueId: string, venueFieldId: string, venueFieldUpdateRequest: VenueFieldUpdateRequest, options?: AxiosRequestConfig) {
        return VenuesApiFp(this.configuration).updateVenueField(venueId, venueFieldId, venueFieldUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * This API is creates a webhook config.
         * @summary Create Webhook Config
         * @param {WebhookConfigCreateRequest} [webhookConfigCreateRequest] Webhook Config Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebhookConfiguration: async (webhookConfigCreateRequest?: WebhookConfigCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webhook_configs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webhookConfigCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes an entry of webhook configuration by id.
         * @summary Delete Webhook Configuration
         * @param {string} webhookConfigId Webhook Config Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebhookConfigById: async (webhookConfigId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookConfigId' is not null or undefined
            assertParamExists('deleteWebhookConfigById', 'webhookConfigId', webhookConfigId)
            const localVarPath = `/webhook_configs/{webhook_config_id}`
                .replace(`{${"webhook_config_id"}}`, encodeURIComponent(String(webhookConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API retrieves the collection of webhooks configuration.
         * @summary Get the list of Webhook Configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWebhookConfigurations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webhook_configs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a collection of webhook events.
         * @summary Get the list of Webhook Events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWebhookEvents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webhook_events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Gets a single entry of webhook events by id.
         * @summary Get Webhook Events for Webhook Config
         * @param {string} webhookConfigId Webhook Config Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWebhookEventsForConfig: async (webhookConfigId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookConfigId' is not null or undefined
            assertParamExists('getAllWebhookEventsForConfig', 'webhookConfigId', webhookConfigId)
            const localVarPath = `/webhook_events/{webhook_config_id}`
                .replace(`{${"webhook_config_id"}}`, encodeURIComponent(String(webhookConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API retrieves webhook config details for the given id.
         * @summary Get Webhook Config Details
         * @param {string} webhookConfigId Webhook Config Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhookConfigDetails: async (webhookConfigId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookConfigId' is not null or undefined
            assertParamExists('getWebhookConfigDetails', 'webhookConfigId', webhookConfigId)
            const localVarPath = `/webhook_configs/{webhook_config_id}`
                .replace(`{${"webhook_config_id"}}`, encodeURIComponent(String(webhookConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API simulates a webhook configurion to the recieving service.
         * @summary Simulate a Webhook
         * @param {WebhookSimulationRequest} [webhookSimulationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulateWebhook: async (webhookSimulationRequest?: WebhookSimulationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/webhooks/simulate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webhookSimulationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * This API updates an existing webhook configuration by id, then simulates webhook call if user chooses to make the config active.
         * @summary Update Webhook Configuration
         * @param {string} webhookConfigId Webhook Config Id
         * @param {WebhookConfigUpdateRequest} [webhookConfigUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebhookConfig: async (webhookConfigId: string, webhookConfigUpdateRequest?: WebhookConfigUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webhookConfigId' is not null or undefined
            assertParamExists('updateWebhookConfig', 'webhookConfigId', webhookConfigId)
            const localVarPath = `/webhook_configs/{webhook_config_id}`
                .replace(`{${"webhook_config_id"}}`, encodeURIComponent(String(webhookConfigId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(webhookConfigUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WebhooksApiAxiosParamCreator(configuration)
    return {
        /**
         * This API is creates a webhook config.
         * @summary Create Webhook Config
         * @param {WebhookConfigCreateRequest} [webhookConfigCreateRequest] Webhook Config Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createWebhookConfiguration(webhookConfigCreateRequest?: WebhookConfigCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookConfigCreateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createWebhookConfiguration(webhookConfigCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes an entry of webhook configuration by id.
         * @summary Delete Webhook Configuration
         * @param {string} webhookConfigId Webhook Config Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWebhookConfigById(webhookConfigId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse2004>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWebhookConfigById(webhookConfigId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API retrieves the collection of webhooks configuration.
         * @summary Get the list of Webhook Configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWebhookConfigurations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WebhookConfig>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWebhookConfigurations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a collection of webhook events.
         * @summary Get the list of Webhook Events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWebhookEvents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WebhookEvent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWebhookEvents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Gets a single entry of webhook events by id.
         * @summary Get Webhook Events for Webhook Config
         * @param {string} webhookConfigId Webhook Config Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllWebhookEventsForConfig(webhookConfigId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WebhookEvent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllWebhookEventsForConfig(webhookConfigId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API retrieves webhook config details for the given id.
         * @summary Get Webhook Config Details
         * @param {string} webhookConfigId Webhook Config Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWebhookConfigDetails(webhookConfigId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookConfigDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWebhookConfigDetails(webhookConfigId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API simulates a webhook configurion to the recieving service.
         * @summary Simulate a Webhook
         * @param {WebhookSimulationRequest} [webhookSimulationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async simulateWebhook(webhookSimulationRequest?: WebhookSimulationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookSimulationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.simulateWebhook(webhookSimulationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * This API updates an existing webhook configuration by id, then simulates webhook call if user chooses to make the config active.
         * @summary Update Webhook Configuration
         * @param {string} webhookConfigId Webhook Config Id
         * @param {WebhookConfigUpdateRequest} [webhookConfigUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWebhookConfig(webhookConfigId: string, webhookConfigUpdateRequest?: WebhookConfigUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebhookConfigUpdateResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWebhookConfig(webhookConfigId, webhookConfigUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WebhooksApiFp(configuration)
    return {
        /**
         * This API is creates a webhook config.
         * @summary Create Webhook Config
         * @param {WebhookConfigCreateRequest} [webhookConfigCreateRequest] Webhook Config Object
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWebhookConfiguration(webhookConfigCreateRequest?: WebhookConfigCreateRequest, options?: any): AxiosPromise<WebhookConfigCreateResponse> {
            return localVarFp.createWebhookConfiguration(webhookConfigCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes an entry of webhook configuration by id.
         * @summary Delete Webhook Configuration
         * @param {string} webhookConfigId Webhook Config Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWebhookConfigById(webhookConfigId: string, options?: any): AxiosPromise<InlineResponse2004> {
            return localVarFp.deleteWebhookConfigById(webhookConfigId, options).then((request) => request(axios, basePath));
        },
        /**
         * This API retrieves the collection of webhooks configuration.
         * @summary Get the list of Webhook Configurations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWebhookConfigurations(options?: any): AxiosPromise<Array<WebhookConfig>> {
            return localVarFp.getAllWebhookConfigurations(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a collection of webhook events.
         * @summary Get the list of Webhook Events
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWebhookEvents(options?: any): AxiosPromise<Array<WebhookEvent>> {
            return localVarFp.getAllWebhookEvents(options).then((request) => request(axios, basePath));
        },
        /**
         * Gets a single entry of webhook events by id.
         * @summary Get Webhook Events for Webhook Config
         * @param {string} webhookConfigId Webhook Config Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllWebhookEventsForConfig(webhookConfigId: string, options?: any): AxiosPromise<Array<WebhookEvent>> {
            return localVarFp.getAllWebhookEventsForConfig(webhookConfigId, options).then((request) => request(axios, basePath));
        },
        /**
         * This API retrieves webhook config details for the given id.
         * @summary Get Webhook Config Details
         * @param {string} webhookConfigId Webhook Config Id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWebhookConfigDetails(webhookConfigId: string, options?: any): AxiosPromise<WebhookConfigDetail> {
            return localVarFp.getWebhookConfigDetails(webhookConfigId, options).then((request) => request(axios, basePath));
        },
        /**
         * This API simulates a webhook configurion to the recieving service.
         * @summary Simulate a Webhook
         * @param {WebhookSimulationRequest} [webhookSimulationRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        simulateWebhook(webhookSimulationRequest?: WebhookSimulationRequest, options?: any): AxiosPromise<WebhookSimulationResponse> {
            return localVarFp.simulateWebhook(webhookSimulationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * This API updates an existing webhook configuration by id, then simulates webhook call if user chooses to make the config active.
         * @summary Update Webhook Configuration
         * @param {string} webhookConfigId Webhook Config Id
         * @param {WebhookConfigUpdateRequest} [webhookConfigUpdateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWebhookConfig(webhookConfigId: string, webhookConfigUpdateRequest?: WebhookConfigUpdateRequest, options?: any): AxiosPromise<WebhookConfigUpdateResponse> {
            return localVarFp.updateWebhookConfig(webhookConfigId, webhookConfigUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     * This API is creates a webhook config.
     * @summary Create Webhook Config
     * @param {WebhookConfigCreateRequest} [webhookConfigCreateRequest] Webhook Config Object
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public createWebhookConfiguration(webhookConfigCreateRequest?: WebhookConfigCreateRequest, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).createWebhookConfiguration(webhookConfigCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes an entry of webhook configuration by id.
     * @summary Delete Webhook Configuration
     * @param {string} webhookConfigId Webhook Config Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public deleteWebhookConfigById(webhookConfigId: string, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).deleteWebhookConfigById(webhookConfigId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API retrieves the collection of webhooks configuration.
     * @summary Get the list of Webhook Configurations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public getAllWebhookConfigurations(options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).getAllWebhookConfigurations(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a collection of webhook events.
     * @summary Get the list of Webhook Events
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public getAllWebhookEvents(options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).getAllWebhookEvents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Gets a single entry of webhook events by id.
     * @summary Get Webhook Events for Webhook Config
     * @param {string} webhookConfigId Webhook Config Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public getAllWebhookEventsForConfig(webhookConfigId: string, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).getAllWebhookEventsForConfig(webhookConfigId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API retrieves webhook config details for the given id.
     * @summary Get Webhook Config Details
     * @param {string} webhookConfigId Webhook Config Id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public getWebhookConfigDetails(webhookConfigId: string, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).getWebhookConfigDetails(webhookConfigId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API simulates a webhook configurion to the recieving service.
     * @summary Simulate a Webhook
     * @param {WebhookSimulationRequest} [webhookSimulationRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public simulateWebhook(webhookSimulationRequest?: WebhookSimulationRequest, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).simulateWebhook(webhookSimulationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * This API updates an existing webhook configuration by id, then simulates webhook call if user chooses to make the config active.
     * @summary Update Webhook Configuration
     * @param {string} webhookConfigId Webhook Config Id
     * @param {WebhookConfigUpdateRequest} [webhookConfigUpdateRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    public updateWebhookConfig(webhookConfigId: string, webhookConfigUpdateRequest?: WebhookConfigUpdateRequest, options?: AxiosRequestConfig) {
        return WebhooksApiFp(this.configuration).updateWebhookConfig(webhookConfigId, webhookConfigUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


