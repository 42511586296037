import { useAlerts } from '@SBGSports/referee-react';
import axios from 'axios';
import { useIframeListener } from 'iframing';
import { Configuration, User, UserApi } from 'oas';
import * as React from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export interface AuthValues {
    csrfAuthd: boolean;
    indeterminate: boolean;
    user?: User;
    login: (username: string, password: string) => Promise<void>;
    loginWithToken: (token: string) => Promise<void>;
    loginWithSharedToken: (token: string) => Promise<string>;
    verifyMfa: (code: string) => Promise<void>;
    updateEmailPassword: (
        email: string,
        password: string,
        password2: string,
        password_strength: string,
    ) => Promise<void>;
    logout: () => void;
    checkAuthentication: () => void;
    reset: () => void;
    reloadUser: () => void;
    isAuthenticated: boolean;
}

const AuthContext = React.createContext<AuthValues | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const auth = useProvideAuth();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthValues => {
    const context = React.useContext(AuthContext);
    const checked = React.useRef(false);

    if (context === undefined) {
        throw new Error('useAuth must be used within AuthProvider');
    }

    React.useEffect(() => {
        if (checked.current) {
            return;
        }

        checked.current = true;

        if (context.indeterminate) {
            context.checkAuthentication();
        }
    }, [context]);

    return context;
};

const fetchUser = async (apiConfig: Configuration) => {
    const meApi = new UserApi(apiConfig);
    const { data: newUser } = await meApi.getMe(['permissions']);

    return newUser;
};

const useProvideAuth = (): AuthValues => {
    const [csrfAuthd, setCsrfAuthd] = React.useState(false);
    const [indeterminate, setIndeterminate] = React.useState<boolean>(true);
    const [user, setUser] = React.useState<User | undefined>();
    const [needsAuthCheck, setNeedsAuthCheck] = React.useState<boolean>(false);
    const apiConfig = React.useMemo(() => new Configuration({ basePath: process.env.REACT_APP_API_HOST + '/v6' }), []);
    const iframeUnauthenticatedEvent = useIframeListener('__authentication_unauthenticated');
    const iframeReauthenticateEvent = useIframeListener('__authentication_reauthenticate');
    const queryClient = useQueryClient();

    // Trigger a fetch to /me to see if we are authenticated with the backend
    const checkAuthentication = () => {
        setNeedsAuthCheck(true);
    };

    // Login using username and password
    const login: AuthValues['login'] = async (username, password) => {
        reset();
        try {
            await axios.post(`${process.env.REACT_APP_AUTH_HOST}/login`, {
                name: username,
                password,
            });
        } catch (error) {
            // If csrf token mismatch error, refetch the csrf token
            if (axios.isAxiosError(error) && error.response?.status === 419) {
                setCsrfAuthd(false);
            }

            throw error;
        }
        setNeedsAuthCheck(true);
    };

    // Login using a JWT
    const loginWithToken: AuthValues['loginWithToken'] = async (token) => {
        reset();
        await axios.post(`${process.env.REACT_APP_AUTH_HOST}/auth/registration/login`, { token });

        setNeedsAuthCheck(true);
    };

    // Login using a shareable JWT
    const loginWithSharedToken: AuthValues['loginWithSharedToken'] = async (token) => {
        reset();
        const res = await axios.post(`${process.env.REACT_APP_AUTH_HOST}/auth/share`, { token });

        setNeedsAuthCheck(true);
        return res.data;
    };

    const verifyMfa: AuthValues['verifyMfa'] = async (code) => {
        await axios.post(`${process.env.REACT_APP_AUTH_HOST}/loginSecondStep`, {
            userId: user?.id,
            verificationCode: code,
        });

        setUser(undefined);
        setNeedsAuthCheck(true);
    };

    const updateEmailPassword: AuthValues['updateEmailPassword'] = async (
        email,
        password,
        password2,
        password_strength,
    ) => {
        await axios.post(`${process.env.REACT_APP_AUTH_HOST}/registration/update`, {
            email,
            password,
            password2,
            password_strength,
        });

        setUser(undefined);
        setNeedsAuthCheck(true);
    };

    // Reset client-side auth
    const reset = React.useCallback((): void => {
        setUser(undefined);
        setNeedsAuthCheck(false);
        queryClient.clear();
    }, [queryClient]);

    const reloadUser = React.useCallback(() => {
        (async () => {
            setUser(await fetchUser(apiConfig));
        })();
    }, [apiConfig]);

    // Send to logout page
    const logout = React.useCallback(() => {
        window.location.href = '/logout';
    }, []);

    const { showAlert, closeAlert } = useAlerts();
    const isSnackbarDisplayed = React.useRef(false);
    const snackbarRef = React.useRef<string>();

    // Get csrf-cookie
    useQuery({
        queryKey: ['csrf-cookie'],

        queryFn: async () => {
            const response = await axios.get(process.env.REACT_APP_AUTH_HOST + '/sanctum/csrf-cookie');
            setCsrfAuthd(true);
            snackbarRef.current && closeAlert(snackbarRef.current);
            isSnackbarDisplayed.current = false;
            return response;
        },

        staleTime: 3600 * 8 * 1000,
        enabled: !csrfAuthd,

        retry: (failureCount) => {
            if (!isSnackbarDisplayed.current) {
                snackbarRef.current = showAlert('Connection error, retrying...', '', { variant: 'alert' });
                isSnackbarDisplayed.current = true;
            }

            return failureCount < 100;
        },
    });

    // Fetch user
    React.useEffect(() => {
        if (!csrfAuthd || !needsAuthCheck || user) {
            return;
        }

        (async () => {
            setIndeterminate(true);

            try {
                setUser(await fetchUser(apiConfig));

                setNeedsAuthCheck(false);
            } catch (error) {
                reset();
            }

            setIndeterminate(false);
        })();
    }, [apiConfig, csrfAuthd, needsAuthCheck, reset, user]);

    // Logout when iframe 'unauthenticated' event is received
    React.useEffect(() => {
        if (iframeUnauthenticatedEvent !== undefined) {
            logout();
        }
    }, [iframeUnauthenticatedEvent, logout]);

    // Reload user when iframe 'reauthenticate' event is received
    React.useEffect(() => {
        if (iframeReauthenticateEvent !== undefined) {
            setUser(undefined);
            setNeedsAuthCheck(true);
        }
    }, [iframeReauthenticateEvent]);

    return {
        csrfAuthd,
        indeterminate,
        user,
        login,
        loginWithToken,
        loginWithSharedToken,
        verifyMfa,
        updateEmailPassword,
        logout,
        checkAuthentication,
        reset,
        reloadUser,
        isAuthenticated: !!user && !user.require_mfa && !user.needs_password_update,
    };
};
