import * as React from 'react';
import { Button, ThemeProvider } from '@SBGSports/referee-react';
import { useTranslations } from 'i18n';
import { NotificationAlert, NotificationAlertProps } from 'notifications/notification-alert';
import {
    OptionsObject,
    ProviderContext,
    SnackbarContent,
    SnackbarKey,
    SnackbarMessage,
    useSnackbar as notistackUseSnackbar,
} from 'notistack';
import { getValidationErrors } from '../utils';

type EnqueueSnackbar = (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
type EnqueueNotificationSnackbar = (props: NotificationAlertProps) => SnackbarKey;
export interface SnackbarContext extends ProviderContext {
    successSnackbar: EnqueueSnackbar;
    warningSnackbar: EnqueueSnackbar;
    errorSnackbar: EnqueueSnackbar;
    infoSnackbar: EnqueueSnackbar;
    notificationSnackbar: EnqueueNotificationSnackbar;
    displayValidationErrors: (responseError: unknown, responseStatus?: number) => void;
    toastSnackbar: EnqueueSnackbar;
}

export const useSnackbar = (): SnackbarContext => {
    const { enqueueSnackbar, closeSnackbar } = notistackUseSnackbar();
    const { __ } = useTranslations();

    const messageWithTitle = React.useCallback(
        (message: SnackbarMessage, options?: OptionsObject) => (
            <div>
                {options?.title && (
                    <strong>
                        {options.title}
                        <br />
                    </strong>
                )}

                {message}
            </div>
        ),
        [],
    );

    const notificationSnackbar: EnqueueNotificationSnackbar = React.useCallback(
        (props: NotificationAlertProps) => {
            return enqueueSnackbar('', {
                content: (key) => (
                    <SnackbarContent key={key}>
                        <NotificationAlert {...props} />
                    </SnackbarContent>
                ),
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                persist: true,
            });
        },
        [enqueueSnackbar],
    );

    const successSnackbar: EnqueueSnackbar = React.useCallback(
        (message, options) => enqueueSnackbar(messageWithTitle(message, options), { variant: 'success', ...options }),
        [enqueueSnackbar, messageWithTitle],
    );
    const warningSnackbar: EnqueueSnackbar = React.useCallback(
        (message, options) => enqueueSnackbar(messageWithTitle(message, options), { variant: 'warning', ...options }),
        [enqueueSnackbar, messageWithTitle],
    );
    const errorSnackbar: EnqueueSnackbar = React.useCallback(
        (message, options) =>
            enqueueSnackbar(messageWithTitle(message, options), {
                variant: 'error',
                persist: true,
                action: (key) => (
                    <ThemeProvider theme="dark">
                        <Button variant="outline" onClick={() => closeSnackbar(key)}>
                            {__('misc.ok')}
                        </Button>
                    </ThemeProvider>
                ),

                ...options,
            }),
        [__, closeSnackbar, enqueueSnackbar, messageWithTitle],
    );
    const infoSnackbar: EnqueueSnackbar = React.useCallback(
        (message, options) => enqueueSnackbar(messageWithTitle(message, options), { variant: 'info', ...options }),
        [enqueueSnackbar, messageWithTitle],
    );

    const toastSnackbar: EnqueueSnackbar = React.useCallback(
        (message, options) =>
            enqueueSnackbar('', {
                content: (key) => (
                    <SnackbarContent
                        key={key}
                        style={{
                            backgroundColor: 'white',
                            padding: '16px',
                            borderRadius: '8px',
                            boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -2px rgba(0, 0, 0, 0.05)',
                            color: '#54595C',
                            alignContent: 'center',
                        }}
                    >
                        {message}
                    </SnackbarContent>
                ),
                ...options,
            }),
        [enqueueSnackbar],
    );

    const displayValidationErrors = React.useCallback(
        (responseError: unknown) => {
            const [title, messages] = getValidationErrors(responseError);
            const content = (
                <>
                    <div>{title}</div>
                    {messages}
                </>
            );

            errorSnackbar(content);
        },
        [errorSnackbar],
    );

    return React.useMemo(
        () => ({
            enqueueSnackbar,
            closeSnackbar,
            successSnackbar,
            warningSnackbar,
            errorSnackbar,
            infoSnackbar,
            notificationSnackbar,
            displayValidationErrors,
            toastSnackbar,
        }),
        [
            enqueueSnackbar,
            closeSnackbar,
            successSnackbar,
            warningSnackbar,
            errorSnackbar,
            infoSnackbar,
            notificationSnackbar,
            displayValidationErrors,
            toastSnackbar,
        ],
    );
};
